import { useCallback } from 'react'
import { useAuth0 } from '@auth0/auth0-react'
import { createCache } from '~/services/cache'

export type GetBlobByUrl = ReturnType<typeof useAuthenticatedCache>['getBlobByUrl']

export function useAuthenticatedCache(cacheName: string) {
  const { getAccessTokenSilently } = useAuth0()

  const getBlobByUrl = useCallback(
    async (url: string, secure: boolean, forcedMimeType?: string): Promise<Blob | undefined> => {
      const cache = createCache(cacheName)
      const token = secure ? await getAccessTokenSilently() : undefined
      return cache.getBlobByUrl(url, token, forcedMimeType)
    },
    [getAccessTokenSilently, cacheName]
  )

  return { getBlobByUrl }
}
