import { Button, Icon } from '~/components'
import { type SpriteName } from '@zolargmbh/zolar-icons'
import classNames from 'classnames'
import { type Prettify } from '~/utils/types'
import RadialProgress from '../RadialProgress'
import { type ReactNode } from 'react'

type AlertMessageVariant = 'success' | 'warning' | 'error' | 'neutral' | 'loading'

const DEFAULT_ALERT_ICONS = Object.freeze<
  Prettify<Record<AlertMessageVariant, SpriteName | undefined>>
>({
  success: 'general:check-circle',
  warning: 'general:alert-triangle',
  error: 'general:alert-circle',
  neutral: 'general:placeholder',
  loading: undefined,
})

type Props = {
  variant: AlertMessageVariant
  /**
   * @default
   * variant='success' -> 'general:check-circle'
   * variant='warning' -> 'general:alert-triangle'
   * variant='error' -> 'general:alert-circle'
   * variant='neutral' -> 'general:placeholder'
   */
  icon?: SpriteName
  headline?: string
  content?: ReactNode
  onClose?: VoidFunction
  cta?: {
    label: string
    onClick: VoidFunction
  }
  /**
   * @default 0
   */
  progressValue?: number
}

const Alert = (props: Props) => {
  const {
    variant,
    icon = DEFAULT_ALERT_ICONS[variant],
    headline,
    content,
    onClose,
    cta,
    progressValue = 0,
  } = props

  return (
    <div className='alert w-full' role='alert'>
      {variant === 'loading' && (
        <RadialProgress className='alert-loading-icon' value={progressValue} />
      )}
      {variant !== 'loading' && (
        <div
          className={classNames('icon-underlay icon-underlay-small', {
            'icon-underlay-success': variant === 'success',
            'icon-underlay-warning': variant === 'warning',
            'icon-underlay-error': variant === 'error',
            'icon-underlay-lavender': variant === 'neutral',
          })}
        >
          <Icon icon={icon!} />
        </div>
      )}
      <div className='alert-content'>
        {headline && <p className='alert-text'>{headline}</p>}
        {content && <p className='alert-supportive-text'>{content}</p>}
        {cta && (
          <Button variant='text-dark' onClick={cta.onClick} size='sm'>
            {cta.label}
          </Button>
        )}
      </div>
      {onClose && (
        <button className='alert-close-button' type='button' aria-label='close' onClick={onClose}>
          <Icon icon='general:x-close' />
        </button>
      )}
    </div>
  )
}

export default Alert
