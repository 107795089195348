import classNames from 'classnames'
import { type ComponentProps, type ReactNode, forwardRef } from 'react'

export type TextareaProps = Omit<ComponentProps<'textarea'>, 'prefix'> & {
  label?: ReactNode
  name: string
  description?: ReactNode
  prefix?: ReactNode
  suffix?: ReactNode
  hasError?: boolean
  leadingIcon?: ReactNode
  trailingIcon?: ReactNode
}

const Textarea = forwardRef<HTMLTextAreaElement, TextareaProps>((props, ref) => {
  const {
    label,
    description,
    prefix,
    suffix,
    leadingIcon,
    trailingIcon,
    disabled,
    readOnly,
    hasError,
    ...rest
  } = props

  return (
    <div
      className={classNames('form-textarea', {
        'form-textarea-disabled': disabled,
        'form-textarea-error': hasError,
        'form-textarea-readonly': readOnly,
      })}
    >
      <label className='form-textarea-field'>
        {label && <span className='form-textarea-label'>{label}</span>}
        {leadingIcon}
        {prefix && <span>{prefix}</span>}
        <textarea ref={ref} disabled={disabled} readOnly={readOnly} {...rest} />
        {suffix && <span>{suffix}</span>}
        {trailingIcon}
      </label>
      {description && <span className='form-textarea-description'>{description}</span>}
    </div>
  )
})

Textarea.displayName = 'Textarea'

export default Textarea
