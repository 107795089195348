import { Page, Text, View, Document, Svg, Path, StyleSheet, Font } from '@react-pdf/renderer'
import { format } from 'date-fns'
import { de } from 'date-fns/locale'
import { type CommissioningForm } from '../formValidationSchema'
import robotoRegularSrc from '~/assets/Roboto-Regular.ttf'
import PdfSectionGeneralData from './PdfSectionGeneralData'
import PdfSectionPvSystem from './PdfSectionPvSystem'
import PdfSectionInverters from './PdfSectionInverters'
import PdfSectionPvGenerator from './PdfSectionPvGenerator'
import PdfSectionElectricalMeasurement from './PdfSectionElectricalMeasurement'
import PdfSectionCompassManager from './PdfSectionCompassManager'
import PdfSectionBatteryStorage from './PdfSectionBatteryStorage'
import PdfSectionWallbox from './PdfSectionWallbox'
import PdfSectionBriefingOfOperation from './PdfSectionBriefingOfOperation'
import PdfSectionSignature from './PdfSectionSignature'
import PdfSectionAdditionalRemarks from '~/pages/Documentation/Commissioning/Pdf/PdfSectionAdditionalRemarks'

// Helvetica does not support Ω symbol so Roboto is being used as an alternative
// note: unfortunately we cannot further optimise this by importing a subset of Roboto as it won't register the font correctly
Font.register({
  family: 'Roboto-regular',
  src: robotoRegularSrc,
})

const styles = StyleSheet.create({
  document: {
    fontFamily: 'Helvetica',
    fontStyle: 'normal',
    color: '#212121',
  },
  page: {
    flexDirection: 'column',
    paddingTop: 48,
    paddingLeft: 56,
    paddingRight: 56,
  },
})

export type PdfCommissioningContentProps = {
  formValues: CommissioningForm
}

const PdfCommissioningContent = (props: PdfCommissioningContentProps) => {
  const {
    formValues: {
      generalData,
      pvSystem,
      gridManagement,
      inverters = [],
      pvGenerators = [],
      electricalMeasurement,
      compass,
      batteryStorage,
      wallbox,
      briefingOfOperation,
      signature,
    },
  } = props

  const hasPvSystem = pvSystem.hasPvSystem === 'yes'
  const pvGenerator1 = pvGenerators.at(0)
  const maxStringsPvGenerator1 = Math.max(
    pvGenerator1?.mppts.at(0)?.stringElectricalValues.length ?? 0,
    pvGenerator1?.mppts.at(1)?.stringElectricalValues.length ?? 0
  )
  const pvGenerator2 = pvGenerators.at(1)
  const maxStringsPvGenerator2 = Math.max(
    pvGenerator2?.mppts.at(0)?.stringElectricalValues.length ?? 0,
    pvGenerator2?.mppts.at(1)?.stringElectricalValues.length ?? 0
  )
  const doPvGeneratorsFitInOnePage = maxStringsPvGenerator1 + maxStringsPvGenerator2 < 4

  return (
    <Document style={styles.document}>
      <Page size='A4' orientation='portrait' style={styles.page}>
        <MainHeader />
        <PdfSectionGeneralData data={generalData} />
        <PdfSectionPvSystem data={{ ...pvSystem, ...gridManagement }} />
        {hasPvSystem && <PdfSectionInverters data={inverters} />}
        {!hasPvSystem && (
          <>
            <PdfSectionBatteryStorage data={batteryStorage} />
            <PdfSectionWallbox data={wallbox} />
          </>
        )}
        <Footer />
      </Page>

      {!hasPvSystem && (
        <Page size='A4' orientation='portrait' style={styles.page}>
          <SecondaryHeader />
          <PdfSectionBriefingOfOperation data={briefingOfOperation} />
          <PdfSectionAdditionalRemarks data={briefingOfOperation.remarks} />
          <PdfSectionSignature data={signature} />
          <Footer />
        </Page>
      )}

      {hasPvSystem && (
        <>
          {pvGenerator1 && !pvGenerator2 && (
            <>
              <Page size='A4' orientation='portrait' style={styles.page}>
                <SecondaryHeader />
                <PdfSectionPvGenerator data={pvGenerator1} inverterIdx={0} />
                <PdfSectionElectricalMeasurement data={electricalMeasurement} />
                <PdfSectionCompassManager data={compass} />
                <Footer />
              </Page>
              <Page size='A4' orientation='portrait' style={styles.page}>
                <SecondaryHeader />
                <PdfSectionBatteryStorage data={batteryStorage} />
                <PdfSectionWallbox data={wallbox} />
                <PdfSectionBriefingOfOperation data={briefingOfOperation} />
                <Footer />
              </Page>
              <Page size='A4' orientation='portrait' style={styles.page}>
                <SecondaryHeader />
                <PdfSectionAdditionalRemarks data={briefingOfOperation.remarks} />
                <PdfSectionSignature data={signature} />
                <Footer />
              </Page>
            </>
          )}
          {pvGenerator1 && pvGenerator2 && (
            <>
              {doPvGeneratorsFitInOnePage && (
                <>
                  <Page size='A4' orientation='portrait' style={styles.page}>
                    <SecondaryHeader />
                    <PdfSectionPvGenerator data={pvGenerator1} inverterIdx={0} />
                    <PdfSectionPvGenerator data={pvGenerator2} inverterIdx={1} />
                    <Footer />
                  </Page>
                  <Page size='A4' orientation='portrait' style={styles.page}>
                    <SecondaryHeader />
                    <PdfSectionElectricalMeasurement data={electricalMeasurement} />
                    <PdfSectionCompassManager data={compass} />
                    <PdfSectionBatteryStorage data={batteryStorage} />
                    <PdfSectionWallbox data={wallbox} />
                    <Footer />
                  </Page>
                  <Page size='A4' orientation='portrait' style={styles.page}>
                    <SecondaryHeader />
                    <PdfSectionBriefingOfOperation data={briefingOfOperation} />
                    <PdfSectionAdditionalRemarks data={briefingOfOperation.remarks} />
                    <PdfSectionSignature data={signature} />
                    <Footer />
                  </Page>
                </>
              )}
              {!doPvGeneratorsFitInOnePage && maxStringsPvGenerator1 === 2 && (
                <>
                  <Page size='A4' orientation='portrait' style={styles.page}>
                    <SecondaryHeader />
                    <PdfSectionPvGenerator data={pvGenerator1} inverterIdx={0} />
                    <Footer />
                  </Page>
                  <Page size='A4' orientation='portrait' style={styles.page}>
                    <SecondaryHeader />
                    <PdfSectionPvGenerator data={pvGenerator2} inverterIdx={1} />
                    <PdfSectionElectricalMeasurement data={electricalMeasurement} />
                    <PdfSectionCompassManager data={compass} />
                    <Footer />
                  </Page>
                  <Page size='A4' orientation='portrait' style={styles.page}>
                    <SecondaryHeader />
                    <PdfSectionBatteryStorage data={batteryStorage} />
                    <PdfSectionWallbox data={wallbox} />
                    <PdfSectionBriefingOfOperation data={briefingOfOperation} />
                    <Footer />
                  </Page>
                  <Page size='A4' orientation='portrait' style={styles.page}>
                    <SecondaryHeader />
                    <PdfSectionAdditionalRemarks data={briefingOfOperation.remarks} />
                    <PdfSectionSignature data={signature} />
                    <Footer />
                  </Page>
                </>
              )}
            </>
          )}
        </>
      )}
    </Document>
  )
}

export default PdfCommissioningContent

const MainHeader = () => {
  return (
    <View
      style={{
        flexDirection: 'row',
        alignItems: 'center',
        marginBottom: 48,
        gap: 48,
      }}
    >
      <Svg width={124} viewBox='0 0 366 93'>
        <Path
          fill='black'
          d='m139.491 63.42 24.459-41.14v-1.459h-43.903v12.914h22.4l-24.76 41.713v1.076h46.281V63.42h-24.477Zm58.946-44.202c-8.441 0-16.165 2.978-21.751 8.385-5.587 5.408-8.663 12.892-8.663 21.072 0 16.792 13.075 29.455 30.414 29.455 17.339 0 30.415-12.663 30.415-29.455 0-16.793-13.076-29.457-30.415-29.457Zm0 45.492c-9.269 0-15.741-6.594-15.741-16.035 0-9.442 6.472-16.035 15.741-16.035 9.268 0 15.741 6.593 15.741 16.035 0 9.441-6.473 16.035-15.741 16.035Zm38.823 11.815h14.462V0L237.26 12.708v63.817Zm52.356-57.307c-8.182 0-15.675 2.975-21.096 8.377-5.419 5.398-8.403 12.854-8.403 20.994 0 16.841 12.682 29.541 29.499 29.541 6.905 0 13.199-2.295 18.203-6.639l.61-.53 1.436 5.48h11.233V20.736h-10.672l-1.768 5.808-.586-.517c-5.04-4.454-11.422-6.809-18.456-6.809Zm1.261 45.406c-9.172 0-16.088-6.894-16.088-16.035 0-9.142 6.916-16.036 16.088-16.036 9.16 0 16.068 6.894 16.068 16.036 0 9.141-6.908 16.035-16.068 16.035Zm54.346-37.045-.54.666-2.939-7.428h-9.073v55.705h14.461v-28.96c0-9.652 3.667-13.59 12.658-13.59H366V20.505l-4.584-.002c-7.225 0-12.219 2.183-16.193 7.077Z'
        />
        <Path
          fill='#bedce1'
          d='m27.504 69.242 1.029-2.395a29.95 29.95 0 0 1 11.112-13.22 30.334 30.334 0 0 1 16.623-4.954h33.155a44.083 44.083 0 0 0-9.52-27.344c-6.168-7.803-14.797-13.33-24.507-15.7a45.068 45.068 0 0 0-29.057 2.633c-9.116 4.074-16.592 11.06-21.232 19.842H61.92l-1.028 2.394a29.95 29.95 0 0 1-11.112 13.22 30.334 30.334 0 0 1-16.623 4.955H0a44.083 44.083 0 0 0 9.52 27.344c6.168 7.803 14.797 13.33 24.507 15.699a45.067 45.067 0 0 0 29.057-2.632c9.117-4.074 16.592-11.06 21.232-19.842H27.504Z'
        />
      </Svg>
      <View>
        <Text style={{ fontFamily: 'Helvetica-Bold', fontSize: 13, color: 'black' }}>
          Prüfbericht zur Inbetriebnahme
        </Text>
        <Text style={{ fontFamily: 'Helvetica-Bold', fontSize: 13, color: 'black' }}>
          Photovoltaikanlage / Batteriespeicher
        </Text>
      </View>
    </View>
  )
}

const SecondaryHeader = () => {
  return (
    <Svg
      width={64}
      viewBox='0 0 366 93'
      style={{
        marginBottom: 32,
      }}
    >
      <Path
        fill='black'
        d='m139.491 63.42 24.459-41.14v-1.459h-43.903v12.914h22.4l-24.76 41.713v1.076h46.281V63.42h-24.477Zm58.946-44.202c-8.441 0-16.165 2.978-21.751 8.385-5.587 5.408-8.663 12.892-8.663 21.072 0 16.792 13.075 29.455 30.414 29.455 17.339 0 30.415-12.663 30.415-29.455 0-16.793-13.076-29.457-30.415-29.457Zm0 45.492c-9.269 0-15.741-6.594-15.741-16.035 0-9.442 6.472-16.035 15.741-16.035 9.268 0 15.741 6.593 15.741 16.035 0 9.441-6.473 16.035-15.741 16.035Zm38.823 11.815h14.462V0L237.26 12.708v63.817Zm52.356-57.307c-8.182 0-15.675 2.975-21.096 8.377-5.419 5.398-8.403 12.854-8.403 20.994 0 16.841 12.682 29.541 29.499 29.541 6.905 0 13.199-2.295 18.203-6.639l.61-.53 1.436 5.48h11.233V20.736h-10.672l-1.768 5.808-.586-.517c-5.04-4.454-11.422-6.809-18.456-6.809Zm1.261 45.406c-9.172 0-16.088-6.894-16.088-16.035 0-9.142 6.916-16.036 16.088-16.036 9.16 0 16.068 6.894 16.068 16.036 0 9.141-6.908 16.035-16.068 16.035Zm54.346-37.045-.54.666-2.939-7.428h-9.073v55.705h14.461v-28.96c0-9.652 3.667-13.59 12.658-13.59H366V20.505l-4.584-.002c-7.225 0-12.219 2.183-16.193 7.077Z'
      />
      <Path
        fill='#bedce1'
        d='m27.504 69.242 1.029-2.395a29.95 29.95 0 0 1 11.112-13.22 30.334 30.334 0 0 1 16.623-4.954h33.155a44.083 44.083 0 0 0-9.52-27.344c-6.168-7.803-14.797-13.33-24.507-15.7a45.068 45.068 0 0 0-29.057 2.633c-9.116 4.074-16.592 11.06-21.232 19.842H61.92l-1.028 2.394a29.95 29.95 0 0 1-11.112 13.22 30.334 30.334 0 0 1-16.623 4.955H0a44.083 44.083 0 0 0 9.52 27.344c6.168 7.803 14.797 13.33 24.507 15.699a45.067 45.067 0 0 0 29.057-2.632c9.117-4.074 16.592-11.06 21.232-19.842H27.504Z'
      />
    </Svg>
  )
}

const Footer = () => {
  return (
    <View
      style={{
        position: 'absolute',
        bottom: 16,
        left: 56,
        right: 56,
        flex: 1,
        flexDirection: 'row',
        justifyContent: 'space-between',
        alignItems: 'center',
        color: '#616161',
        fontSize: 10,
        fontWeight: 400,
        lineHeight: '1.6',
      }}
    >
      <Text>Datum: {format(new Date(), 'dd MMM yyyy', { locale: de })}</Text>
      <Text render={({ pageNumber, totalPages }) => `${pageNumber}/${totalPages}`} />
    </View>
  )
}
