import { Button } from '~/components'
import { Link, type LoaderFunctionArgs, useLoaderData, useParams } from 'react-router-dom'
import ActivityPageLayout from './ActivityPageLayout'
import { useAppStore } from '~/stores'
import { ComReportState } from '~/models'
import classNames from 'classnames'
import { format } from 'date-fns'
import { de } from 'date-fns/locale'

type CommissioningActivityPageParams = {
  orderId: string
  activityId: string
}

type CommissioningLoaderResponse = Awaited<ReturnType<typeof loader>>

export const loader = async ({ params }: LoaderFunctionArgs) => {
  const { orderId, activityId } = params
  const { orders, activities, comReports } = useAppStore.getState()
  const order = orders.find(({ id }) => id === orderId)
  const activity = activities.find(
    ({ id, order_uid }) => id === activityId && order_uid === orderId
  )
  if (!order || !activity) throw new Error('Order or activity not found')
  const comReport = comReports.find(({ activity_uid }) => activity_uid === activityId)
  let tag = 'Offen'
  const isActivitySubmittable = [
    'initialized',
    'documentation_pending_online',
    'documentation_pending_offline',
  ].includes(activity.state)
  const isReportInDraft = comReport?.state === ComReportState.DRAFT
  const isReportOpen = isReportInDraft && isActivitySubmittable
  const isReportStartedViaAppButSubmittedViaZPC = isReportInDraft && !isActivitySubmittable
  const isReportSubmittedViaApp = comReport?.state === ComReportState.SUBMITTED
  if (isReportOpen) tag = 'Begonnen'
  if (isReportSubmittedViaApp) tag = 'Abgeschlossen'
  if (isReportStartedViaAppButSubmittedViaZPC) tag = 'Übermittelt in ZPC'

  const date =
    comReport?.submittedAt && comReport.state === ComReportState.SUBMITTED
      ? format(new Date(comReport.submittedAt), 'EE, d. MMM HH:mm', { locale: de }).replace(
          /(\w+)\./,
          '$1'
        )
      : 'Datum nicht verfügbar'
  return { tag, date, canStartEditOrViewDocumentation: !isReportStartedViaAppButSubmittedViaZPC }
}

const CommissioningActivityPage = () => {
  const { orderId = '', activityId = '' } = useParams<CommissioningActivityPageParams>()
  const { tag, date, canStartEditOrViewDocumentation } =
    useLoaderData() as CommissioningLoaderResponse

  return (
    <ActivityPageLayout>
      <div className='flex flex-col gap-5 relative mb-24'>
        <div className='flex flex-col gap-2'>
          <h1 className='text-headline-1'>Inbetriebname</h1>
          <div className='flex gap-2 items-center'>
            <span
              className={classNames('tag', {
                'tag-sky': tag === 'Offen',
                'tag-mist': tag === 'Begonnen',
                'tag-lavender': tag === 'Abgeschlossen',
              })}
            >
              {tag}
            </span>
            <div className='border-l border-dark-3 h-4' />
            <p className='text-description-1 text-dark-secondary'>{date}</p>
          </div>
        </div>
        <div className='flex flex-col gap-4'>
          <p className='text-static-lg-semibold'>Formularstruktur:</p>
          <ul className='list-disc list-inside flex flex-col gap-2'>
            <li>Allgemeine Daten</li>
            <li>PV System</li>
            <li>Wechselrichter</li>
            <li>PV Generator</li>
            <li>Elektrische Messung</li>
            <li>Compass Manager</li>
            <li>Batteriespeicher</li>
            <li>Wallbox</li>
            <li>Einweisung des Betriebes</li>
            <li>Unterschrift</li>
          </ul>
        </div>
        {canStartEditOrViewDocumentation && (
          <Link
            to={`/documentation/${orderId}/${activityId}/com/generalData`}
            className='fixed bottom-6 left-4 right-4'
          >
            <Button size='lg' variant='filled-accent' className='w-full'>
              {tag === 'Offen' && 'Dokumentation starten'}
              {tag === 'Begonnen' && 'Dokumentation fortsetzen'}
              {tag === 'Abgeschlossen' && 'Dokumentation anzeigen'}
            </Button>
          </Link>
        )}
      </div>
    </ActivityPageLayout>
  )
}

export default CommissioningActivityPage
