import { View } from '@react-pdf/renderer'
import { type CommissioningForm } from '../formValidationSchema'
import { ItemTextInline, ItemTextVertical, Section, SectionSubtitle } from './SharedWrappers'

const SectionSingleInverter = (props: { data: CommissioningForm['inverters'] }) => {
  const { data = [] } = props
  const inverter = data.at(0) ?? {}
  return (
    <Section label='Wechselrichter'>
      <ItemTextInline label='Hersteller' value={inverter.manufacturer} />
      <ItemTextInline label='Modell' value={inverter.type} />
      <ItemTextInline label='Seriennummer' value={inverter.serialNumber} />
    </Section>
  )
}

const SectionMultipleInverter = (props: { data: CommissioningForm['inverters'] }) => {
  const { data = [] } = props
  const inverter1 = data.at(0) ?? {}
  const inverter2 = data.at(1) ?? {}
  return (
    <Section label='Wechselrichter'>
      <View style={{ flexDirection: 'row', width: '100%', gap: 12 }}>
        <View style={{ gap: 12, flex: 1 }}>
          <SectionSubtitle>Wechselrichter #1</SectionSubtitle>
          <ItemTextVertical label='Hersteller' value={inverter1.manufacturer} />
          <ItemTextVertical label='Modell' value={inverter1.type} />
          <ItemTextVertical label='Seriennummer' value={inverter1.serialNumber} />
        </View>
        <View style={{ gap: 12, flex: 1 }}>
          <SectionSubtitle>Wechselrichter #2</SectionSubtitle>
          <ItemTextVertical label='Hersteller' value={inverter2.manufacturer} />
          <ItemTextVertical label='Modell' value={inverter2.type} />
          <ItemTextVertical label='Seriennummer' value={inverter2.serialNumber} />
        </View>
      </View>
    </Section>
  )
}

const PdfSectionInverters = (props: { data: CommissioningForm['inverters'] }) => {
  const { data = [] } = props

  if (data.length > 2) return <></>

  if (data.length === 1) return <SectionSingleInverter {...props} />
  if (data.length > 1) return <SectionMultipleInverter {...props} />
  return <></>
}

export default PdfSectionInverters
