import { Button, Icon } from '~/components'
import { useAppStore } from '~/stores'

export function DefaultModal() {
  const modalState = useAppStore.use.defaultModal()
  const { closeDefaultModal } = useAppStore.use.modalActions()

  const onClose = !modalState.enforceAction ? closeDefaultModal : undefined

  if (!modalState.visible) return null

  return (
    <div className='modal z-50 modal-active animate-opacity' onClick={onClose}>
      <div className='modal-dialog animate-entrance-grow' onClick={(e) => e.stopPropagation()}>
        {onClose && (
          <Button
            size='sm'
            variant='outlined-dark'
            isIconButton
            className='absolute right-4 top-2'
            onClick={onClose}
          >
            <Icon icon='general:x-close' size='md' />
          </Button>
        )}
        <div className='modal-content'>
          <h2 className='modal-heading text-subheadline-1'>{modalState.title}</h2>
          {modalState.description && (
            <div className='modal-paragraph text-body-1-regular'>{modalState.description}</div>
          )}
        </div>
        <div className='modal-footer'>
          {modalState.secondaryButton && (
            <Button variant='outlined-dark' onClick={modalState.secondaryButton.action}>
              {modalState.secondaryButton.label}
            </Button>
          )}
          {modalState.primaryButton && (
            <Button variant='filled-accent' onClick={modalState.primaryButton.action}>
              {modalState.primaryButton.label}
            </Button>
          )}
        </div>
      </div>
    </div>
  )
}
