import { Fragment } from 'react'
import { useFieldArray, useFormContext } from 'react-hook-form'
import { Button, FormInput, Icon } from '~/components'
import { type CommissioningForm } from './formValidationSchema'

const FormInverters = () => {
  const { control, setValue, getValues } = useFormContext<CommissioningForm>()
  const { fields, append, remove } = useFieldArray({
    control,
    name: 'inverters',
  })

  const removePvGenerator = (idx: number) => {
    const pvGeneratorsValue = getValues('pvGenerators')
    const newPvGeneratorsValue = pvGeneratorsValue
      ? pvGeneratorsValue.slice(0, idx).concat(pvGeneratorsValue.slice(idx + 1))
      : undefined
    setValue('pvGenerators', newPvGeneratorsValue)
  }

  const addPvGenerator = () => {
    const pvGeneratorsValue = getValues('pvGenerators')
    const a = pvGeneratorsValue?.[0]
    a?.mppts
    const newPvGeneratorsValue = [
      ...(pvGeneratorsValue ?? []),
      { mppts: [{ amountOfstrings: 1, stringElectricalValues: [{}] }] },
    ]
    setValue('pvGenerators', newPvGeneratorsValue)
  }

  const onRemoveInverter = (idx: number) => () => {
    remove(idx)
    removePvGenerator(idx)
  }

  const onAddInverter = () => {
    append({
      manufacturer: '',
      serialNumber: '',
      type: '',
    })
    addPvGenerator()
  }

  return (
    <div className='flex flex-col gap-4'>
      {fields.map(({ id }, i) => (
        <Fragment key={id}>
          <div className='flex items-center justify-between h-10'>
            <p className='text-static-lg-bold'>Wechselrichter #{i + 1}</p>
            {fields.length > 1 && (
              <Button
                variant='text-dark'
                isIconButton
                onClick={onRemoveInverter(i)}
                aria-label='Wechselrichter löschen'
              >
                <Icon icon='general:trash' />
              </Button>
            )}
          </div>
          <FormInput type='text' name={`inverters[${i}].manufacturer`} label='Hersteller' />
          <FormInput type='text' name={`inverters[${i}].type`} label='Modell' />
          <FormInput type='text' name={`inverters[${i}].serialNumber`} label='Seriennummer' />
        </Fragment>
      ))}
      {fields.length < 2 && (
        <div className='flex items-center justify-between'>
          <p className='text-static-lg-bold'>Wechselrichter hinzufügen</p>
          <Button
            variant='text-dark'
            isIconButton
            aria-label='Wechselrichter hinzufügen'
            onClick={onAddInverter}
          >
            <Icon icon='finance:plus' />
          </Button>
        </div>
      )}
    </div>
  )
}

export default FormInverters
