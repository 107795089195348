import { useTaskManager } from '~/hooks'
import { useTaskStore } from '~/stores/useTaskStore'
import { type MouseEvent } from 'react'
import { useAuth0 } from '@auth0/auth0-react'
import { Button } from '~/components'

export function TaskManagerActions() {
  const { clearTasks } = useTaskStore.use.actions()
  const { getAccessTokenSilently } = useAuth0()
  const { runTasks } = useTaskManager()

  function onClearTasks(event: MouseEvent) {
    event.stopPropagation()
    clearTasks()
  }

  function onRerunTasks(event: MouseEvent) {
    event.stopPropagation()
    void runTasks(getAccessTokenSilently)
  }

  return (
    <div className='my-1'>
      <Button size='sm' onClick={onClearTasks}>
        Clear tasks
      </Button>
      <Button size='sm' onClick={onRerunTasks}>
        Rerun tasks
      </Button>
    </div>
  )
}
