import { SketchThumbnail, Thumbnail } from '~/components/Documentation/Thumbnail'
import { type ReportItemEntityStatus } from '~/models'
import { Icon } from '~/components'

interface ThumbnailListProps {
  status: ReportItemEntityStatus | keyof typeof ReportItemEntityStatus
  itemId: string
  cacheId: string
  url: string
  imageUids: Array<string>
  onSelect: (index: number) => void
  removeImage: (index: number) => void
  selectedIndex?: number
}

export function ThumbnailList(props: ThumbnailListProps) {
  const itemCount = Math.max(props.imageUids.length + 1, 6)
  return (
    <div className='flex h-[104px] overflow-x-auto'>
      <SketchThumbnail onSelect={() => props.onSelect(-1)}>
        <div className='text-description-1 flex h-full w-full flex-col items-center justify-center gap-1 pt-2'>
          <Icon icon='general:info-circle' size='lg' className='text-white' />
          Fototipp
        </div>
      </SketchThumbnail>
      {Array.from(Array(itemCount)).map((_, index) => (
        <Thumbnail
          key={`${props.cacheId}-${props.itemId}-${index}`}
          cacheId={props.cacheId}
          url={props.url}
          imageUid={props.imageUids[index]}
          status={props.status}
          onSelect={() => props.onSelect(index)}
          removeImage={() => props.removeImage(index)}
          selected={index === props.selectedIndex}
        />
      ))}
    </div>
  )
}
