import classNames from 'classnames'
import { useCallback, useEffect, type MouseEventHandler, type ReactNode } from 'react'
import { createPortal } from 'react-dom'
import { useFocusTrap } from '~/hooks/useFocusTrap'

type Props = {
  children: ReactNode
  isOpen: boolean
  onClose?: () => void | Promise<void>
  variant?: 'bottom' | 'center'
  className?: string
}

const Modal = (props: Props) => {
  const { children, isOpen, onClose, variant = 'center', className } = props
  const trapRef = useFocusTrap(isOpen)

  const onClickAway = useCallback<MouseEventHandler<HTMLDivElement>>(
    (e) => {
      e.preventDefault()
      e.stopPropagation()
      onClose?.()
    },
    [onClose]
  )

  useEffect(() => {
    const onKeydown = (e: KeyboardEvent) => {
      if (e.key === 'Escape') {
        onClose?.()
      }
    }
    if (isOpen) document.addEventListener('keydown', onKeydown)
    return () => {
      document.removeEventListener('keydown', onKeydown)
    }
  }, [onClose, isOpen])

  if (!isOpen) return null
  return createPortal(
    <div
      role='presentation'
      className={classNames(
        'fixed inset-0 z-modal backdrop-blur-[2px] backdrop-brightness-75 flex justify-center',
        {
          'items-center': variant === 'center',
          'items-end': variant === 'bottom',
        }
      )}
      onClick={onClickAway}
    >
      <div ref={trapRef} role='dialog' className={className} onClick={(e) => e.stopPropagation()}>
        {children}
      </div>
    </div>,
    document.body
  )
}

export default Modal
