import { FormInput } from '~/components'

const FormGeneralData = () => {
  return (
    <div className='flex flex-col gap-4'>
      <FormInput type='text' name='generalData.operator' label='Betreiber (Kundenname)' />
      <FormInput type='text' name='generalData.specialistPlanner' label='Fachplaner' />
      <FormInput type='text' name='generalData.installer' label='Installateur' />
      <p className='text-static-lg-semibold'>Standort der Anlage</p>
      <FormInput type='text' name='generalData.plantLocationStreet' label='Straße und Hausnummer' />
      <FormInput type='text' name='generalData.plantLocationPostCode' label='PLZ' />
      <FormInput type='text' name='generalData.plantLocationCity' label='Stadt' />
    </div>
  )
}

export default FormGeneralData
