import { generateClient } from '@aws-amplify/api'
import { subWeeks } from 'date-fns'
import { syncAppointments } from '~/graphql/queries'
import { useEffect, useState } from 'react'
import { getLocalStorageValue } from '~/utils/localStorageUtils'
import { DATA_STORE_INITIAL_STATE_LOCAL_STORAGE_KEY } from '~/hooks/useDataStoreListen'
import { serializeError } from 'serialize-error'

let isInitial = getLocalStorageValue(DATA_STORE_INITIAL_STATE_LOCAL_STORAGE_KEY, true)

export const changeIsInitialFlagInUseInitialSyncOrderIds = () => {
  isInitial = false
}

const client = generateClient()

export function useInitialSyncOrderIds(isAuthenticated: boolean) {
  const [orderData, setOrderData] = useState<{
    orderIds: string[]
  }>({ orderIds: [] })
  const [isOrderDataReady, setIsOrderDataReady] = useState(false)

  useEffect(() => {
    if (!isAuthenticated || !isInitial) {
      return
    }
    const initData = async () => {
      const fourWeeksAgo = subWeeks(new Date(), 4).toISOString()
      return await client.graphql({
        query: syncAppointments,
        variables: {
          filter: {
            end_date: { gt: fourWeeksAgo },
            state: { ne: 'cancelled' },
          },
          limit: 5_000,
        },
      })
    }
    initData()
      .then(({ data }) => {
        const uniqueOrderIds: Set<string> = new Set()
        const appointments =
          data.syncAppointments?.items.sort(
            (a, b) => new Date(b.end_date).getTime() - new Date(a.end_date).getTime()
          ) ?? []

        for (let i = 0; i <= appointments.length; i++) {
          // the filter expression size is limited to 4KB, so this cuts on first 70 (just empirical number) latest order IDs (+ there are also permission filters on the BE)
          // see https://docs.aws.amazon.com/amazondynamodb/latest/developerguide/ServiceQuotas.html#limits-expression-parameters
          if (uniqueOrderIds.size > 70) {
            console.log(
              'Number of orders exceeded the limit with appointments list of: ',
              appointments.length
            )
            break
          }
          if (appointments?.[i]?.order_uid != null) {
            uniqueOrderIds.add(appointments[i].order_uid)
          }
        }

        setOrderData({
          orderIds: [...uniqueOrderIds],
        })
      })
      .catch((error) => console.log('Initial appointments loading failed: ', serializeError(error)))
      .finally(() => setIsOrderDataReady(true))
  }, [isAuthenticated])

  return {
    orderData,
    isOrderDataReady,
  }
}
