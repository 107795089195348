import { ChooseImage } from '~/components/Documentation/ChooseImage'
import { ReportStatus } from '~/models'

interface ControlsProps {
  status: ReportStatus | keyof typeof ReportStatus
  onNewImages: (files: FileList) => void
  imageCount: number
}

export function Controls(props: ControlsProps) {
  const isReportSubmitted = props.status !== ReportStatus.DRAFT

  return (
    <div className='flex items-end justify-evenly pt-2'>
      <div className='w-1/2 text-center pb-1.5'>
        <ChooseImage
          label='Mediathek'
          icon='general:image'
          onNewImages={props.onNewImages}
          size='lg'
          variant='outlined-light'
          imageCount={props.imageCount}
          disabled={isReportSubmitted}
        />
      </div>
      <div className='w-1/2 text-center pb-1.5'>
        <ChooseImage
          label='Kamera starten'
          icon='social:camera'
          onNewImages={props.onNewImages}
          className='!h-20 !w-20'
          iconClassName='!h-12 !w-12'
          variant='filled-light'
          imageCount={props.imageCount}
          camera
          disabled={isReportSubmitted}
        />
      </div>
    </div>
  )
}
