import { type CommissioningForm } from '../formValidationSchema'
import { ItemTextInline, Section, formatNumber } from './SharedWrappers'

const PdfSectionPvSystem = (props: {
  data: CommissioningForm['pvSystem'] & CommissioningForm['gridManagement']
}) => {
  const {
    data: {
      amountOfModules,
      installedCapacityInKwp,
      hasLightningProtectionSystemIntegration,
      moduleManufacturer,
      moduleType,
      hasPlantOnLowVoltageGridConditions,
      powerLimitPercentage,
      ...rest
    },
  } = props
  const hasPvSystem = rest.hasPvSystem === 'yes'
  return (
    <Section label='PV System'>
      <ItemTextInline label='Solaranlage' value={hasPvSystem ? 'Ja' : 'Nein'} />
      {hasPvSystem && (
        <>
          <ItemTextInline label='Hersteller' value={moduleManufacturer} />
          <ItemTextInline label='Module Modell' value={moduleType} />
          <ItemTextInline
            label='Anzahl Module'
            value={amountOfModules ? formatNumber(amountOfModules) : undefined}
          />
          <ItemTextInline
            label='Installierte Leistung/kWp'
            value={installedCapacityInKwp ? formatNumber(installedCapacityInKwp) : undefined}
          />
          <ItemTextInline
            label='Einbildung in das Blitzschutzsystem'
            value={hasLightningProtectionSystemIntegration === 'yes' ? 'Ja' : 'Nein'}
          />
          <ItemTextInline
            label='Die Betriebsparameter der WR sind entsprechend der örtlichen Anschlussbedienungen
                  (VDE-AR-N 4105) eingestellt'
            value={hasPlantOnLowVoltageGridConditions === 'yes' ? 'Ja' : 'Nein'}
          />
          <ItemTextInline
            label='Dynamische Begrenzung der Wirkleistung'
            value={powerLimitPercentage ? `${formatNumber(powerLimitPercentage)}%` : undefined}
          />
        </>
      )}
    </Section>
  )
}

export default PdfSectionPvSystem
