import { type SpriteName } from '@zolargmbh/zolar-icons'
import classNames from 'classnames'
import { ConditionalWrapper, Icon, TouchableImage, Watermark } from '~/components'
import { useFetchImage } from '~/hooks'

interface CacheDocumentImageProps {
  cacheId: string
  url?: string
  placeholderUrl?: string
  placeholderLocalSrc?: string
  secure?: boolean
  handleClick?: (index: number) => void
  index?: number
  className?: string
  watermark?: boolean
  touchable?: boolean
  iconFallback?: SpriteName
}
export function CacheDocumentImage(props: CacheDocumentImageProps) {
  const {
    cacheId,
    url,
    placeholderUrl,
    placeholderLocalSrc,
    secure = false,
    handleClick,
    index = 0,
    className,
    touchable = false,
    iconFallback = 'general:image',
  } = props
  const imageSrc = useFetchImage(cacheId, url, secure)
  const placeholderSrc = useFetchImage(cacheId, placeholderUrl, secure)

  const src = imageSrc ?? placeholderSrc ?? placeholderLocalSrc

  return src ? (
    <span className='relative h-full w-full'>
      {props.watermark && <Watermark />}
      <ConditionalWrapper
        condition={touchable}
        wrapper={(children) => <TouchableImage>{children}</TouchableImage>}
      >
        <img
          src={src}
          loading='lazy'
          alt=''
          className={classNames(className, 'w-full object-cover')}
          onClick={() => {
            if (src === placeholderLocalSrc) return
            handleClick?.(index)
          }}
        />
      </ConditionalWrapper>
    </span>
  ) : (
    <div
      className={classNames(
        'flex aspect-video w-full items-center justify-center bg-grey-200 text-center',
        className
      )}
      onClick={() => {
        handleClick?.(index)
      }}
    >
      <Icon icon={iconFallback} size='2xl' className='text-grey-300 ' />
    </div>
  )
}
