import { IconLink } from '~/components'

interface CustomerInfoProps {
  email?: string | null
  phone?: string | null
}

export function CustomerInfo({ email, phone }: CustomerInfoProps) {
  return (
    <div className='flex flex-col items-start'>
      <div className='mb-2'>
        <IconLink
          icon='social:phone'
          to={'tel:' + phone}
          label={phone ?? 'Keine Telefonnummer hinterlegt'}
        />
      </div>
      <div className='mb-2'>
        <IconLink
          icon='social:envelope'
          to={'mailto:' + email}
          label={email ?? 'Keine E-Mail hinterlegt'}
        />
      </div>
    </div>
  )
}
