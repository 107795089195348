import TagManager from 'react-gtm-module'
import { isDevelopment } from '~/utils/isDevelopment'
import { useEffect } from 'react'
import { initDatadogLogs, initDatadogRum } from './datadog'
import { initSentry } from './sentry'

const GOOGLE_TAG_MANAGER_ID = 'GTM-WBX5QGC'

/**
 *
 * @description Google Tag Manager is responsible for injecting usercentrics script
 * which will then provide us with user consent via ucEvent on the window object.
 * From that we can enable/disable our third party services like Sentry or Datadog
 * based on user tracking consent.
 *
 * Observed tools which scripts are added automatically: Usercentrics, Hotjar, Userflow
 */
export const initGoogleTagManager = () => {
  if (isDevelopment()) return
  TagManager.initialize({
    gtmId: GOOGLE_TAG_MANAGER_ID,
  })
}

const onUsercentricsEvent = (event: any) => {
  if (!event.detail) return
  if (event.detail['Datadog']) initDatadogLogs()
  if (event.detail['Datadog Real User Monitoring']) initDatadogRum()
  if (event.detail['Sentry']) initSentry()
}

/**
 * @description Responsible for initialising 3rd party services based on user consent
 */
export const useTrackingConsent = () => {
  useEffect(() => {
    window.addEventListener('ucEvent', onUsercentricsEvent)
    return () => {
      window.removeEventListener('ucEvent', onUsercentricsEvent)
    }
  }, [])
}
