import { useEffect } from 'react'
import { useAppStore } from '~/stores/useAppStore'
import { useAuth0 } from '@auth0/auth0-react'
import { useTaskManager } from '~/hooks/task/useTaskManager'
import { failingConditions, setTaskRunnerCondition, useTaskStore } from '~/stores/useTaskStore'

export function useTaskManagerInitialize(): void {
  useTaskRunnerDatastoreCondition()
  useTaskRunnerAuthenticatedCondition()
  useRunTasks()
}

function useRunTasks() {
  const conditions = useTaskStore((state) => state.conditions)
  const { runTasks } = useTaskManager()
  const { getAccessTokenSilently } = useAuth0()
  useEffect(() => {
    const failedConditions = failingConditions(conditions)
    if (failedConditions.length > 0) {
      return
    }
    void runTasks(getAccessTokenSilently)
  }, [conditions, getAccessTokenSilently, runTasks])
}

function useTaskRunnerDatastoreCondition() {
  const { network, ready } = useAppStore.use.dataStoreStatus()
  useEffect(() => {
    setTaskRunnerCondition('online', network === 'online')
  }, [network])

  useEffect(() => {
    setTaskRunnerCondition('datastoreReady', ready)
  }, [ready])
}

function useTaskRunnerAuthenticatedCondition() {
  const { isAuthenticated } = useAuth0()

  useEffect(() => {
    setTaskRunnerCondition('authenticated', isAuthenticated)
  }, [isAuthenticated])
}
