import { type ChangeEvent, type ComponentProps, Fragment, useRef } from 'react'
import { Button, Icon } from '~/components'
import { maxImageCountPerStep } from '~/components/Documentation/utitlities'
import { useAppStore } from '~/stores/useAppStore'
import { type SpriteName } from '@zolargmbh/zolar-icons'

interface ChooseImageProps {
  icon?: SpriteName
  label: string
  camera?: boolean
  disabled?: boolean
  imageCount?: number
  variant?: ComponentProps<typeof Button>['variant']
  size?: ComponentProps<typeof Button>['size']
  fullWidth?: boolean
  type?: 'button' | 'circular'
  className?: HTMLElement['className']
  iconClassName?: HTMLElement['className']
  onNewImages: (files: FileList) => void
}

export function ChooseImage(props: ChooseImageProps) {
  const { imageCount = 0, type = 'circular', icon } = props
  const { closeDefaultModal, setDefaultModal } = useAppStore.use.modalActions()

  const inputFileRef = useRef<HTMLInputElement>(null)

  function onImagesChange(e: ChangeEvent<HTMLInputElement>) {
    if (e.target.files === null) {
      return
    }
    props.onNewImages(e.target.files)
  }

  function onClick() {
    if (imageCount >= maxImageCountPerStep) {
      setDefaultModal({
        title: 'Maximum images reached',
        description: `You can't add more then ${maxImageCountPerStep} images per step`,
        primaryButton: {
          label: 'Close',
          action: closeDefaultModal,
        },
      })
    } else {
      inputFileRef.current?.click()
    }
  }

  return (
    <Fragment>
      {type === 'circular' ? (
        <div className='flex flex-col items-center'>
          <Button
            className={props.className}
            isIconButton
            variant={props.variant}
            size={props.size}
            onClick={onClick}
            disabled={props.disabled}
            id={props.label}
          >
            {icon && <Icon icon={icon} className={props.iconClassName} />}
          </Button>
          {props.label && (
            <label
              className='mt-2 text-sm'
              style={{
                ...(props.disabled && { color: 'rgba(255, 255, 255, 0.28)' }),
              }}
              htmlFor={props.label}
            >
              {props.label}
            </label>
          )}
        </div>
      ) : (
        <Button
          size={props.size}
          onClick={onClick}
          {...(props.fullWidth && { className: 'w-full' })}
        >
          {icon && <Icon icon={icon} className={props.iconClassName} />}
          <span>{props.label}</span>
        </Button>
      )}
      <input
        ref={inputFileRef}
        onChange={onImagesChange}
        type='file'
        accept='image/*'
        className='hidden'
        multiple
        {...(props.camera && { capture: 'environment' })}
      />
    </Fragment>
  )
}
