import {
  type Activity,
  type LqvReport,
  type Order,
  Report,
  ReportItemEntityStatus,
  ReportStatus,
  type ZpcStandardReportShape,
} from '~/models'

export interface IsLqvReport {
  status: 'report-is-editable' | 'reportable'
  type: 'lqv-report'
  hash: string
  report: LqvReport
}

export interface IsReport {
  status: 'report-is-editable' | 'reportable'
  type: 'report'
  hash: string
  report: Report
}

interface IsNotReportable {
  status: 'incompatible' | 'prior' | 'missing_lqv_report'
  type?: undefined
  hash?: undefined
  report?: undefined
}

export type Reportable = IsReport | IsLqvReport | IsNotReportable

class ReportService {
  public isActivityReportEditable(activityState: string): boolean {
    const activityConfirmed = activityState === 'confirmed'
    const activityDocumentationPending = activityState.startsWith('documentation_pending')
    return activityConfirmed || activityDocumentationPending
  }

  public getReportable(
    activity?: Activity,
    order?: Order,
    report?: Report | LqvReport,
    reportShape?: ZpcStandardReportShape
  ): Reportable {
    if (!activity?.state || !order || !reportShape) {
      return {
        status: 'incompatible',
      }
    }

    const isActivityInPreDocumentationState = [
      'initialized',
      'reinitialized',
      'accepted',
      'scheduled',
    ].includes(activity.state)

    if (isActivityInPreDocumentationState) {
      return { status: 'prior' }
    }

    const status = this.isActivityReportEditable(activity.state)
      ? 'report-is-editable'
      : 'reportable'

    const hash = order?.customer_hash ?? 'no_hash_provided'

    // TODO: Now that it can be a Report or LqvReport, we should check that it is actually the correct report type
    if (activity.use_lqv) {
      if (!report) {
        return { status: 'missing_lqv_report' }
      }

      return { status, type: 'lqv-report', hash, report: report as LqvReport }
    }

    if (status === 'reportable' && !report) {
      return { status: 'incompatible' }
    }
    return {
      status,
      type: 'report',
      hash,
      report: this.ensureReportHasOrderUid(
        order,
        (report as Report) ?? this.createReport(order, reportShape)
      ),
    }
  }

  private ensureReportHasOrderUid(order: Order, report: Report): Report {
    if (report.order_uid) return report
    return {
      ...report,
      order_uid: order.id,
    }
  }

  public createReport(order: Order, reportShape: ZpcStandardReportShape): Report {
    const { id: order_uid, customer_hash, order_hash } = order
    const { activity_uid, id: report_uid, partner_uid, type } = reportShape
    if (
      !(
        activity_uid &&
        report_uid &&
        partner_uid &&
        type &&
        customer_hash &&
        order_hash &&
        order_uid
      )
    ) {
      throw Error('The activity, shape or order is missing some properties')
    }

    const steps = this.getDocumentationSteps(reportShape)

    return new Report({
      activity_uid,
      order_uid,
      report_uid,
      partner_uid,
      customer_hash,
      order_hash,
      report_type: type as any, // typing is wrong here due to incorrect type generation by amplify
      status: ReportStatus.DRAFT,
      steps: steps,
    })
  }

  private getDocumentationSteps = (reportShape: ZpcStandardReportShape) => {
    const steps: { key: string; items: any[] }[] = []
    for (const itemStructure of reportShape.structure.items) {
      const itemKey = itemStructure.key.replace(/_title$/, '') // if we decide to keep it maybe its better to strip it directly in the import lambda or in the sql query using the REPLACE function
      const stepKey = itemCategoryMap[itemKey]
      const stepIdx = steps.findIndex((s) => s.key === stepKey)
      const step = steps[stepIdx] ?? { key: stepKey ?? 'additional_information', items: [] }
      const item = {
        key: itemKey,
        title: `${itemKey}_title`,
        content: `${itemKey}_content`,
        type: 'text',
        required: false,
        position: itemStructure.position,
        status: ReportItemEntityStatus.DRAFT,
        image_uids: [],
      }
      step.items.push(item)
      if (stepIdx === -1) steps.push(step)
    }
    const transformedQuestions = reportShape.structure.questions.map((zpcQuestion) => ({
      name: zpcQuestion.key,
      format: zpcQuestion.inputAs,
      render_format: zpcQuestion.inputAs,
      required: zpcQuestion.required,
      position: zpcQuestion.position,
      multi_answer: false,
      possible_answers: [...(zpcQuestion.possibleAnswers || [])].reverse(),
      answer: '',
    }))

    const lastStep = steps[steps.length - 1]
    const lastItemOfLastStep = lastStep.items[lastStep.items.length - 1]
    lastItemOfLastStep.questions = transformedQuestions
    return steps
  }
}

const itemCategoryMap: Record<string, string> = {
  report_dc_underconstruction: 'sub_construction',
  report_dc_roof: 'sub_construction',
  report_dc_pv_system_side: 'pv_system',
  report_dc_pv_system_front: 'pv_system',
  report_dc_pv_system_roof: 'pv_system',
  report_dc_open_circuit_voltage: 'pv_system',
  report_dc_cable_outside: 'pv_system',
  report_dc_wall_outside: 'pv_system',
  report_dc_optimizer_position: 'pv_system',
  report_dc_fall_protection: 'pv_system',
  report_dc_cable_routing: 'pv_system',
  report_dc_additional_documents: 'additional_information',
  report_dc_questions: 'additional_information',

  report_ac_overview_counter_carbinet: 'installation',
  report_ac_component_pv_system: 'installation',
  report_ac_cable_inside: 'installation',
  report_ac_type_ac_component: 'installation',
  report_ac_wall_inside: 'installation',
  report_ac_additional_documents: 'additional_information',
  report_ac_questions: 'additional_information',
}

export const reportService = new ReportService()
