import {
  getItemWithValues,
  getStepWithValues,
  type StepData,
} from '~/pages/Documentation/documentationSteps'
import { type RefObject, useEffect, useRef, useState } from 'react'
import { type Report } from '~/models'
import { useEffectOnce } from 'usehooks-ts'

interface StepsProps {
  onItemChange: (itemId: string) => void
  selectedItem: string
  steps: Report['steps']
}

export function Steps(props: StepsProps) {
  const containerRef = useRef<HTMLDivElement | null>(null)

  return (
    <div
      ref={containerRef}
      className='flex h-full items-center overflow-x-auto overscroll-contain py-4'
    >
      {props.steps.map((stepEntity) => {
        const step = getStepWithValues(stepEntity)
        return step === undefined ? null : (
          <Step
            {...step}
            onItemChange={props.onItemChange}
            selectedItem={props.selectedItem}
            containerRef={containerRef}
          />
        )
      })}
    </div>
  )
}

interface StepProps extends StepData {
  onItemChange: (step: string) => void
  selectedItem: string
  containerRef: RefObject<HTMLDivElement>
}

function Step(props: StepProps) {
  const selectedItemRef = useScrollToElement(props.containerRef, props.selectedItem)

  return (
    <div className='mr-10 inline-block first:ml-16 last:mr-16'>
      <div className='text-body-2-regular whitespace-nowrap uppercase'>{props.title}</div>
      <div className='whitespace-nowrap'>
        {props.items.map((itemEntity) => {
          const item = getItemWithValues(itemEntity)
          const isSelected = props.selectedItem === item.key
          const textColor = isSelected ? 'text-accent' : 'text-white'
          return (
            <div
              key={item.key}
              ref={isSelected ? selectedItemRef : null}
              className={`text-body-1-bold inline-block pr-6 text-xl last:pr-0 ${textColor}`}
              onClick={() => props.onItemChange(item.key)}
            >
              {item.title}
            </div>
          )
        })}
      </div>
    </div>
  )
}

function useScrollToElement(containerRef: RefObject<HTMLDivElement>, selectedItem: string) {
  const selectedElementRef = useRef<HTMLDivElement | null>(null)
  const [firstRender, setFirstRender] = useState(true)

  useEffectOnce(() => {
    setFirstRender(false)
  })

  useEffect(() => {
    if (selectedElementRef.current && containerRef.current) {
      const containerLeft = containerRef.current.getBoundingClientRect().left
      const itemLeft = selectedElementRef.current.getBoundingClientRect().left
      const scrollLeftPosition = containerRef.current.scrollLeft + (itemLeft - containerLeft) - 36

      containerRef.current.scrollTo({
        left: scrollLeftPosition,
        behavior: firstRender ? 'auto' : 'smooth',
      })
    }
  }, [selectedItem, containerRef, firstRender])
  return selectedElementRef
}
