import { Fragment, type ReactElement, type ReactNode } from 'react'
import {
  type Report,
  type ReportItemEntity,
  ReportItemEntityStatus,
  type ReportStepEntity,
} from '~/models'
import {
  AcComponent,
  CableInside,
  CableOutside,
  CounterCarbinet,
  OpenCircuitVoltage,
  OptimizerPosition,
  PvSystem,
  PvSystemFront,
  PvSystemRoof,
  PvSystemSide,
  Roof,
  UnderConstruction,
  WallInside,
  WallOutside,
} from '~/svg/item-sketches'

interface ItemValue {
  title: string
  description: ReactNode
  sketch: ReactElement
}

const itemValues: Record<string, ItemValue> = {
  report_ac_overview_counter_carbinet: {
    title: 'Vollansicht Zählerschrank',
    description: (
      <div className='pb-10'>
        <p>Bitte stell sicher, dass auf den Fotos folgendes erkennbar ist:</p>
        <ul className='list-disc pl-6'>
          <li>Gesamter Zählerschrank ohne Feldabdeckungen</li>
          <li>Alle Zähler (Zählernummer und aktueller Zählerstand)</li>
          <li>Alle installierten Komponenten (AC-Überspannungsschutz, SLS, APZ-Feld)</li>
          <li>AAR</li>
          <li>Position der Messklemmen mit erkennbarer Messrichtung (falls installiert)</li>
          <li>Zählerschrank mit PV-Aufkleber u. ggf. Notstrom</li>
          <li>Nahaufnahme Sicherungen + FI (falls verbaut) vom Wechselrichter</li>
          <li>Energy Meter mit Pinbelegung</li>
        </ul>
      </div>
    ),
    sketch: <CounterCarbinet />,
  },
  report_ac_component_pv_system: {
    title: 'Komponenten der PV-Anlage',
    description: (
      <div className='pb-10'>
        <p>Bitte stell sicher, dass auf den Fotos folgendes erkennbar ist:</p>
        <ul className='list-disc pl-6'>
          <li>
            Alle verbauten Komponenten (Wechselrichter, Speicher, Wallbox, Compass Manager, Compass
            Box/Notstromsteckdose), komplett verkabelt und angeschaltet mit leuchtenden LEDs
          </li>
          <li>Geräteanschlüsse (Nahaufnahme) inklusive Erdungen</li>
          <li>Abstände zu anderen Objekten</li>
          <li>Pinbelegung des COM-Steckers am Wechselrichter</li>
          <li>Foto vom Wechselrichter-Display (Status "On Grid")</li>
        </ul>
      </div>
    ),
    sketch: <PvSystem />,
  },
  report_ac_cable_inside: {
    title: 'Kabelführung & Erdung',
    description: (
      <div className='pb-10'>
        <p>Bitte stell sicher, dass auf den Fotos folgendes erkennbar ist:</p>
        <ul className='list-disc pl-6'>
          <li>Kabelführung im geschlossenen Kabelkanal</li>
          <li>Potentialausgleichsschiene</li>
          <li>Erdung</li>
        </ul>
        <p className='pt-4'>Zusätzlich für Sungrow und Fox:</p>
        <ul className='list-disc pl-6'>
          <li>Falls Tiefenerder eingebucht {'-->'} Foto in Endzustand + Widerstandsmessung</li>
        </ul>
      </div>
    ),
    sketch: <CableInside />,
  },
  report_ac_type_ac_component: {
    title: 'Typenschilder d. Elektrokomponenten',
    description: (
      <div className='pb-10'>
        <p>Bitte stell sicher, dass auf den Fotos folgendes erkennbar ist:</p>
        <ul className='list-disc pl-6'>
          <li>
            Nahaufnahme aller Typenschilder der verbauten Komponenten:
            <ul className='list-[circle] pl-6'>
              <li>Wechselrichter</li>
              <li>Speicher</li>
              <li>Wallbox</li>
              <li>Compass Manager</li>
              <li>Compass Box</li>
            </ul>
          </li>
        </ul>
      </div>
    ),
    sketch: <AcComponent />,
  },
  report_ac_wall_inside: {
    title: 'Wanddurchbrüche im Innenbereich',
    description: (
      <div className='pb-10'>
        <p>Bitte stell sicher, dass auf den Fotos folgendes erkennbar ist:</p>
        <ul className='list-disc pl-6'>
          <li>Kabelführung zwischen HWR und Wanddurchbruch ordentlich gemacht</li>
          <li>
            Ausführung Wanddurchbrüche (Eintritt und Austritt) im Innenbereich nach abgeschlossener
            Kabelführung
          </li>
          <li>zusätzliche DC-ÜSS</li>
          <li>Foto WB Kabelführung</li>
        </ul>
      </div>
    ),
    sketch: <WallInside />,
  },
  report_ac_questions: {
    title: 'Fragen',
    description: '',
    sketch: <Fragment />,
  },
  report_ac_additional_documents: {
    title: 'Zusätzliche Informationen',
    description: '',
    sketch: <Fragment />,
  },
  report_dc_underconstruction: {
    title: 'UK-Gesamtansicht',
    description: (
      <div className='pb-10'>
        <p>Bitte stell sicher, dass auf den Fotos folgendes erkennbar ist:</p>
        <ul className='list-disc pl-6'>
          <li>Gesamte Unterkonstruktion (alle Modulfelder)</li>
          <li>Anzahl und Abstände der Haken</li>
          <li>mind. 1 Haken in Nahaufnahme</li>
          <li>Auskragung über den letzten Haken</li>
          <li>Durchkontaktierter Potentialausgleich</li>
        </ul>
        <p className='pt-4'>Falls projektbedingt notwendig:</p>
        <ul className='list-disc pl-6'>
          <li>Thermische Trennung</li>
        </ul>
        <p className='pt-4'>Bei Flachdächern:</p>
        <ul className='list-disc pl-6'>
          <li>Ballastierung</li>
        </ul>
      </div>
    ),
    sketch: <UnderConstruction />,
  },
  report_dc_roof: {
    title: 'Dacheinführung',
    description: (
      <div className='pb-10'>
        <p>Bitte stell sicher, dass auf den Fotos eines der umgesetzten Szenarien erkennbar ist:</p>
        <ul className='list-disc pl-6'>
          <li>Stringkabel mit Schutz durch Ziegel geführt oder durch Lüfterziegel</li>
          <li>
            Kabeldurchführung durch die Unterspannbahn mit einer der folgenden Methoden:
            <ul className='list-[circle] pl-6'>
              <li>Muffe</li>
              <li>Einschnitt und anschließende Abdichtung</li>
              <li>Durch Überlappung der Unterspannbahn, ohne dass diese einreißt</li>
            </ul>
          </li>
        </ul>
        <p className='pt-4'>Hinweis: bei Flachdächern kann dieser Bereich leer bleiben</p>
      </div>
    ),
    sketch: <Roof />,
  },
  report_dc_pv_system_side: {
    title: 'PV-Anlage Seitenansicht (links o. rechts)',
    description: (
      <div className='pb-10'>
        <p>Bitte stell sicher, dass auf den Fotos folgendes erkennbar ist:</p>
        <ul className='list-disc pl-6'>
          <li>Gesamtzahl der Module und das umgesetzte Design von der Seite</li>
          <li>Alle Teilflächen</li>
          <li>Flucht der Modulverlegung</li>
          <li>Abstände zwischen Modulen</li>
          <li>Höhenunterschiede zwischen Modulen</li>
          <li>Einheitlicher Überstand der Schienen von 2,5-3cm</li>
          <li>Endkappen</li>
        </ul>
        <p className='pt-4'>Flachdach:</p>
        <ul className='list-disc pl-6'>
          <li>Modulfeld mit allen Teilflächen</li>
          <li>Windleitbleche</li>
        </ul>
      </div>
    ),
    sketch: <PvSystemSide />,
  },
  report_dc_pv_system_front: {
    title: 'PV-Anlage Frontalansicht',
    description: (
      <div className='pb-10'>
        <p>Bitte stell sicher, dass auf den Fotos folgendes erkennbar ist:</p>
        <ul className='list-disc pl-6'>
          <li>Gesamtanzahl der Module und das umgesetzte Design von vorn</li>
          <li>Alle Teilflächen</li>
          <li>Abstände zwischen Modulen</li>
          <li>Höhenunterschiede zwischen Modulen</li>
          <li>Klemmpunkte</li>
        </ul>
      </div>
    ),
    sketch: <PvSystemFront />,
  },
  report_dc_pv_system_roof: {
    title: 'Dachfläche unter der PV-Anlage',
    description: (
      <div className='pb-10'>
        <p>Bitte stell sicher, dass auf den Fotos folgendes erkennbar ist:</p>
        <ul className='list-disc pl-6'>
          <li>Kabelführung unter der Modulfäche</li>
          <li>Kabel und/oder Stecker liegen nicht auf der Dachhaut auf</li>
        </ul>
        <p className='pt-4'>Für Flachdächer:</p>
        <ul className='list-disc pl-6'>
          <li>Kabelverlegung zwischen den Modulreihen und/oder Modulfeldern</li>
        </ul>
      </div>
    ),
    sketch: <PvSystemRoof />,
  },
  report_dc_open_circuit_voltage: {
    title: 'Leerlaufspannung',
    description: (
      <div className='pb-10'>
        <p>Bitte stell sicher, dass auf den Fotos folgendes erkennbar ist:</p>
        <ul className='list-disc pl-6'>
          <li>
            Messgerät mit erkennbarer Leerlaufspannung zur Überprüfung der Funktionalität sämtlicher
            Strings
          </li>
        </ul>
      </div>
    ),
    sketch: <OpenCircuitVoltage />,
  },
  // Present in older reports, not in new ones
  report_dc_cable_outside: {
    title: 'Kabelführung Außenwand [optional]',
    description: 'Gesamtansicht der Kabelführung an der Außenwand (sofern vorgenommen).',
    sketch: <CableOutside />,
  },
  // Present in older reports, not in new ones
  report_dc_wall_outside: {
    title: 'Wanddurchbruch im Außenbereich [optional]',
    description:
      'Nahaufnahme der Kabelführung durch den abgedichteten Wanddurchbruch vom Außen- in den Innenbereich (sofern vorgenommen).',
    sketch: <WallOutside />,
  },
  // Present in older reports, not in new ones
  report_dc_optimizer_position: {
    title: 'Optimierer Positionen [optional]',
    description:
      'Falls Optimierer verbaut wurden, wird eine Übersicht mit deren Positionen und vollständiger Seriennummer benötigt.',
    sketch: <OptimizerPosition />,
  },
  report_dc_fall_protection: {
    title: 'Absturzsicherung [optional]',
    description: (
      <div className='pb-10'>
        <p>Bitte stell sicher, dass auf den Fotos folgendes erkennbar ist:</p>
        <ul className='list-disc pl-6'>
          <li>Gesamte verwendete Absturzsicherung (Gerüst, Einhängegerüst, PSA o.ä.)</li>
          <li>
            Wenn du keine Absturzsicherung benutzt hast, teile uns das bitte deutlich in den
            zusätzlichen Bemerkungen mit, damit wir es ausbuchen können.
          </li>
        </ul>
      </div>
    ),
    sketch: <></>,
  },
  report_dc_cable_routing: {
    title: 'Kabelführung der DC-Kabel [optional]',
    description: (
      <div className='pb-10'>
        <p>Bitte stell sicher, dass auf den Fotos folgendes erkennbar ist:</p>
        <ul className='list-disc pl-6'>
          <li>Kabelführung bis zum Ort des Durchbruchs im Außenbereich (bspw. Alurohr)</li>
          <li>
            Alle Wanddurchbrüche
            <ul className='list-[circle] pl-6'>
              <li>Winkel der Einführung</li>
              <li>Abdichtung</li>
            </ul>
          </li>
          <li>
            Gesamte Kabelführung der DC-Kabel im Innenbereich (ab Hauseinführung Dach oder Wand bis
            Wechselrichter)
          </li>
        </ul>
      </div>
    ),
    sketch: <></>,
  },
  report_dc_additional_documents: {
    title: 'Zusätzliche Informationen',
    description: '',
    sketch: <Fragment />,
  },
  report_dc_questions: {
    title: 'Fragen',
    description: '',
    sketch: <Fragment />,
  },
}

interface StepValue {
  title: string
}

const stepValues: Record<string, StepValue> = {
  installation: {
    title: 'Installation',
  },
  additional_information: {
    title: 'Zusätzliche Informationen',
  },
  sub_construction: {
    title: 'Unterkonstruktion',
  },
  pv_system: {
    title: 'Modulfläche & Kabelführung',
  },
}

export const documentationSteps: Record<string, Report['steps']> = {
  ac: [
    {
      key: 'installation',
      items: [
        {
          key: 'report_ac_overview_counter_carbinet',
          title: 'report_ac_overview_counter_carbinet_title',
          content: 'report_ac_overview_counter_carbinet_content',
          type: 'text',
          required: true,
          position: 0,
          status: ReportItemEntityStatus.DRAFT,
          image_uids: [],
        },
        {
          key: 'report_ac_component_pv_system',
          title: 'report_ac_component_pv_system_title',
          content: 'report_ac_component_pv_system_content',
          type: 'text',
          required: true,
          position: 1,
          status: ReportItemEntityStatus.DRAFT,
          image_uids: [],
        },
        {
          key: 'report_ac_cable_inside',
          title: 'report_ac_cable_inside_title',
          content: 'report_ac_cable_inside_content',
          type: 'text',
          required: true,
          position: 2,
          status: ReportItemEntityStatus.DRAFT,
          image_uids: [],
        },
        {
          key: 'report_ac_type_ac_component',
          title: 'report_ac_type_ac_component_title',
          content: 'report_ac_type_ac_component_content',
          type: 'text',
          required: true,
          position: 3,
          status: ReportItemEntityStatus.DRAFT,
          image_uids: [],
        },
        {
          key: 'report_ac_wall_inside',
          title: 'report_ac_wall_inside_title',
          content: 'report_ac_wall_inside_content',
          type: 'text',
          required: true,
          position: 4,
          status: ReportItemEntityStatus.DRAFT,
          image_uids: [],
        },
      ],
    },
    {
      key: 'additional_information',
      items: [
        {
          key: 'report_ac_questions',
          title: 'report_ac_questions_title',
          content: 'report_ac_questions_content',
          type: 'text',
          required: false,
          position: 999,
          status: ReportItemEntityStatus.DRAFT,
          image_uids: [],
          questions: [
            {
              name: 'ac_measurement',
              format: 'boolean',
              render_format: 'radio',
              required: true,
              position: 0,
              multi_answer: false,
              possible_answers: ['answer_yes', 'answer_no'],
              answer: null,
            },
            {
              name: 'ac_monitoring',
              format: 'boolean',
              render_format: 'radio',
              required: true,
              position: 1,
              multi_answer: false,
              possible_answers: ['answer_yes', 'answer_no'],
              answer: null,
            },
            {
              name: 'ac_zolar_compass',
              format: 'boolean',
              render_format: 'radio',
              required: true,
              position: 1,
              multi_answer: false,
              possible_answers: ['answer_yes', 'answer_no', 'answer_not_part_of_order'],
              answer: null,
            },
            {
              name: 'ac_remark',
              format: 'text',
              render_format: 'textarea',
              required: false,
              position: 2,
              multi_answer: false,
              possible_answers: null,
              answer: null,
            },
          ],
        },
      ],
    },
  ],
  dc: [
    {
      key: 'sub_construction',
      items: [
        {
          key: 'report_dc_underconstruction',
          title: 'report_dc_underconstruction_title',
          content: 'report_dc_underconstruction_content',
          type: 'text',
          required: true,
          position: 0,
          status: ReportItemEntityStatus.DRAFT,
          image_uids: [],
        },
        {
          key: 'report_dc_roof',
          title: 'report_dc_roof_title',
          content: 'report_dc_roof_content',
          type: 'text',
          required: true,
          position: 1,
          status: ReportItemEntityStatus.DRAFT,
          image_uids: [],
        },
      ],
    },
    {
      key: 'pv_system',
      items: [
        {
          key: 'report_dc_pv_system_side',
          title: 'report_dc_pv_system_side_title',
          content: 'report_dc_pv_system_side_content',
          type: 'text',
          required: true,
          position: 2,
          status: ReportItemEntityStatus.DRAFT,
          image_uids: [],
        },
        {
          key: 'report_dc_pv_system_front',
          title: 'report_dc_pv_system_front_title',
          content: 'report_dc_pv_system_front_content',
          type: 'text',
          required: true,
          position: 3,
          status: ReportItemEntityStatus.DRAFT,
          image_uids: [],
        },
        {
          key: 'report_dc_pv_system_roof',
          title: 'report_dc_pv_system_roof_title',
          content: 'report_dc_pv_system_roof_content',
          type: 'text',
          required: true,
          position: 4,
          status: ReportItemEntityStatus.DRAFT,
          image_uids: [],
        },
        {
          key: 'report_dc_open_circuit_voltage',
          title: 'report_dc_open_circuit_voltage_title',
          content: 'report_dc_open_circuit_voltage_content',
          type: 'text',
          required: true,
          position: 5,
          status: ReportItemEntityStatus.DRAFT,
          image_uids: [],
        },
        {
          key: 'report_dc_cable_outside',
          title: 'report_dc_cable_outside_title',
          content: 'report_dc_cable_outside_content',
          type: 'text',
          required: false,
          position: 6,
          status: ReportItemEntityStatus.DRAFT,
          image_uids: [],
        },
        {
          key: 'report_dc_wall_outside',
          title: 'report_dc_wall_outside_title',
          content: 'report_dc_wall_outside_content',
          type: 'text',
          required: false,
          position: 7,
          status: ReportItemEntityStatus.DRAFT,
          image_uids: [],
        },
        {
          key: 'report_dc_optimizer_position',
          title: 'report_dc_optimizer_position_title',
          content: 'report_dc_optimizer_position_content',
          type: 'text',
          required: false,
          position: 8,
          status: ReportItemEntityStatus.DRAFT,
          image_uids: [],
        },
      ],
    },
    {
      key: 'additional_information',
      items: [
        {
          key: 'report_dc_questions',
          title: 'report_dc_questions_title',
          content: 'report_dc_questions_content',
          type: 'questions',
          required: true,
          position: 999,
          status: ReportItemEntityStatus.DRAFT,
          image_uids: [],
          questions: [
            {
              name: 'dc_design',
              format: 'boolean',
              render_format: 'radio',
              required: true,
              position: 0,
              multi_answer: false,
              possible_answers: ['answer_yes', 'answer_no'],
              answer: null,
            },
            {
              name: 'dc_string_plan',
              format: 'boolean',
              render_format: 'radio',
              required: true,
              position: 1,
              multi_answer: false,
              possible_answers: ['answer_yes', 'answer_no'],
              answer: null,
            },
            {
              name: 'dc_cable',
              format: 'boolean',
              render_format: 'radio',
              required: true,
              position: 2,
              multi_answer: false,
              possible_answers: ['answer_yes', 'answer_no'],
              answer: null,
            },
            {
              name: 'dc_remark',
              format: 'text',
              render_format: 'textarea',
              required: false,
              position: 3,
              multi_answer: false,
              possible_answers: null,
              answer: null,
            },
            {
              name: 'dc_sub_construction',
              format: 'text',
              render_format: 'select',
              required: true,
              position: 4,
              multi_answer: false,
              possible_answers: null,
              answer: null,
            },
          ],
        },
      ],
    },
  ],
}

export type ItemData = ReportItemEntity & ItemValue

export function getItemWithValues(item: ReportItemEntity): ItemData {
  const itemValue = itemValues[item.key]
  if (itemValue === undefined) {
    throw Error(`Could not find values for item: ${item.key}`)
  }
  return { ...item, ...itemValue }
}
export type StepData = ReportStepEntity & StepValue
export function getStepWithValues(step: ReportStepEntity): StepData | undefined {
  const stepValue = stepValues[step.key]
  if (stepValue === undefined) {
    throw Error(`Could not find step: ${step.key}`)
  }
  return { ...step, ...stepValue }
}
