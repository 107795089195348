import { Children, Fragment, type ReactNode } from 'react'
import styled from 'styled-components'
import { useSwipeable } from 'react-swipeable'
import { Button, Icon } from '~/components'

interface CarouselProps {
  className?: string
  activeIndex: number
  onChangeActiveIndex: (index: number | undefined) => void
  children: ReactNode
  showNavigation?: boolean
}

export function Carousel(props: CarouselProps) {
  const { className, activeIndex, showNavigation = true } = props
  const children = Children.toArray(props.children)

  function nextIndex() {
    const index = Math.min(activeIndex + 1, children.length - 1)
    props.onChangeActiveIndex(index)
  }

  function previousIndex() {
    const index = Math.max(activeIndex - 1, 0)
    props.onChangeActiveIndex(index)
  }

  const handlers = useSwipeable({
    onSwipedLeft: nextIndex,
    onSwipedRight: previousIndex,
  })

  return (
    <StyledCarousel className={className} {...handlers}>
      <div className='carousel-inner' style={{ transform: `translateX(-${activeIndex * 100}%)` }}>
        {children}
      </div>
      {showNavigation && (
        <Fragment>
          <div className='fixed top-6 flex w-full justify-end pr-4'>
            <Button
              isIconButton
              variant='filled-light'
              size='lg'
              onClick={() => props.onChangeActiveIndex(undefined)}
            >
              <Icon icon='general:x-close' />
            </Button>
          </div>
          <div className='carousel-navigation bottom-6'>
            <div className='navigation-buttons'>
              <Button
                isIconButton
                variant='filled-light'
                size='lg'
                onClick={previousIndex}
                disabled={activeIndex === 0}
              >
                <Icon icon='arrows:chevron-left' />
              </Button>
              <div className='flex w-32 items-center justify-center font-semibold text-white'>{`Bild ${
                activeIndex + 1
              } von ${children.length}`}</div>
              <Button
                isIconButton
                variant='filled-light'
                size='lg'
                onClick={nextIndex}
                disabled={activeIndex === children.length - 1}
              >
                <Icon icon='arrows:chevron-right' />
              </Button>
            </div>
          </div>
        </Fragment>
      )}
    </StyledCarousel>
  )
}

const StyledCarousel = styled.div`
  display: flex;
  flex-direction: column;
  height: 100%;
  overflow: hidden;
  position: relative;
  width: 100%;

  .carousel-navigation {
    width: 100%;
    position: fixed;
    display: flex;
    flex-direction: column;
    justify-content: center;

    .navigation-buttons {
      align-self: center;
      display: flex;
      flex-direction: row;
      gap: 16px;
      white-space: nowrap;
    }
  }

  .carousel-inner {
    flex: 1;
    transition: transform 0.5s ease-in-out;
    white-space: nowrap;
  }
`
