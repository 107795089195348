import { FormInput } from '~/components'

const FormCompassManager = () => {
  return (
    <div className='flex flex-col gap-4'>
      <FormInput
        type='text'
        name='compass.managerSerialNumber'
        label='Compass Manager Seriennummer'
      />
    </div>
  )
}

export default FormCompassManager
