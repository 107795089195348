import classNames from 'classnames'
import styled from 'styled-components'
import { Fragment } from 'react'

interface ProgressBarProps {
  children?: React.ReactNode
  className?: string
  max?: number
  progress?: number
  showPercentage?: boolean
}

export function ProgressBar(props: ProgressBarProps) {
  const { progress = 0, max = 100, showPercentage } = props
  const percentage = Math.min(Math.round((progress / max) * 100), 100)
  return (
    <Fragment>
      <div className='flex items-center justify-between'>
        <div className={classNames('progress-bar flex-grow', props.className)}>
          <ProgressIndicator className='bg-primary-700' percent={percentage} />
        </div>
        {showPercentage && (
          <div className='text-description-1 pl-2 align-middle text-grey-500'>{percentage}%</div>
        )}
      </div>
      <div className='text-description-1 text-grey-500'>{props.children}</div>
    </Fragment>
  )
}

interface ProgressIndicatorProps {
  percent: number
}

const ProgressIndicator = styled.div<ProgressIndicatorProps>`
  height: 0.375rem;
  transition: width 0.3s ease-in-out;
  width: ${(props) => props.percent}%;
`
