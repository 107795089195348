import { Fragment, type ReactNode } from 'react'
import Check from '~/svg/check.svg?react'
import { type DataStoreStatus, useAppStore } from '~/stores/useAppStore'
import { type ReportItemEntity } from '~/models'
import classNames from 'classnames'
import { itemIsReady } from '~/services/standardReport'
import { useTaskManagerProgress } from '~/hooks'
import { type GroupedTaskProgress } from '~/stores/useTaskStore'

const iconStyle = { height: '1rem', marginRight: '0.75rem' }

interface DocumentationStatusProps {
  orderId: string
  item: ReportItemEntity
}

export function DocumentationStatus(props: DocumentationStatusProps) {
  const dataStoreStatus = useAppStore.use.dataStoreStatus()
  const progress = useTaskManagerProgress(props.orderId, props.item.key)
  const status = getStatus(props.item, dataStoreStatus.network, progress)

  return (
    <Fragment>
      {status.body && (
        // Button component can't be used here as,
        // a) it isn't really a button,
        // b) It deviates too much from the standard button in its usage
        // The button classes are just used here as the styling from specific classes actually does apply
        <div className={classNames('button-md button-primary mx-2 select-none', status.classes)}>
          {status.body}
        </div>
      )}
    </Fragment>
  )
}

interface Status {
  body: ReactNode
  classes: string
}

function getStatus(
  item: ReportItemEntity,
  networkStatus: DataStoreStatus['network'],
  progress?: Omit<GroupedTaskProgress, 'payload'>
): Status {
  const total = progress?.total ?? 0
  const current = progress?.value ?? 0
  const queueLength = total - current

  if (networkStatus === 'offline') {
    return {
      body: null,
      classes: '',
    }
  }

  const fontStyle = 'text-description-1 font-bold'
  if (itemIsReady(item)) {
    return {
      body: (
        <Fragment>
          <Check style={{ ...iconStyle, fill: 'green' }} />
          <span className={`${fontStyle} text-green`}>Arbeitsschritt ist hochgeladen</span>
        </Fragment>
      ),
      classes: 'button-light',
    }
  }
  if (queueLength > 0) {
    return {
      body: (
        <div className={`${fontStyle} flex flex-col`}>
          <span className='text-dark-secondary'>Bilder werden hochgeladen</span>
          <span className='text-sky-900'>{queueLength} verbleibend...</span>
        </div>
      ),
      classes: 'button-light',
    }
  }
  return {
    body: null,
    classes: '',
  }
}
