import { useNavigate, useParams } from 'react-router-dom'
import { Button, CacheDocumentImage, Icon, ImageWall } from '~/components'
import { useState } from 'react'
import { Carousel } from '~/components/Carousel/Carousel'
import { CarouselItem } from '~/components/Carousel/CarouselItem'
import { type OrderDocument } from '~/models'
import { useOrder } from '~/hooks'

export function ImageGallery() {
  const [selectedImageIndex, setSelectedImageIndex] = useState<number | undefined>(undefined)
  const { id = '' } = useParams()
  const navigate = useNavigate()
  const order = useOrder(id)
  const images = order.getDocuments({ mimeType: 'image' })

  if (images.length === 0) {
    navigate(`/order/${order.id}`)
  }

  return (
    <div className='container absolute flex h-full w-full flex-col'>
      <div className='mb-4 mt-md flex flex-row items-center'>
        <Button
          isIconButton
          variant='outlined-dark'
          size='lg'
          onClick={() => {
            selectedImageIndex === undefined ? navigate(-1) : setSelectedImageIndex(undefined)
          }}
        >
          <Icon icon='arrows:chevron-left' />
        </Button>
        <span className='text-headline-6 flex-grow text-center'>Fotos</span>
        <div className='w-[48px]' />
      </div>

      <ImageWall cacheId={id} documents={images} onSelectedImageChange={setSelectedImageIndex} />
      {selectedImageIndex !== undefined && (
        <div className='bg- absolute bottom-0 left-0 right-0 top-0 bg-[#344054B2] backdrop-blur'>
          <Carousel
            activeIndex={selectedImageIndex}
            onChangeActiveIndex={setSelectedImageIndex}
            showNavigation={true}
          >
            {images.map((document: OrderDocument, index: number) => (
              <CarouselItem key={index}>
                <CacheDocumentImage
                  cacheId={id}
                  url={
                    (selectedImageIndex === index
                      ? document.url
                      : document.url + '?thumbnail=true') ?? ''
                  }
                  placeholderUrl={
                    selectedImageIndex === index ? document.url + '?thumbnail=true' : undefined
                  }
                  secure={true}
                  touchable={selectedImageIndex === index}
                  className='aspect-video'
                />
              </CarouselItem>
            ))}
          </Carousel>
        </div>
      )}
    </div>
  )
}
