/*
  NOT USED IN PRODUCTION
  Quick and dirty solution for saving (i.e. downloading) an image base on a blob
*/
import { type RefObject } from 'react'

export const maxImageCountPerStep = 25
export const localImagePrefix = 'local_'

/*
  NOT USED IN PRODUCTION
  Quick and dirty solution grabbing a frame from a video element and saving the blob
*/
export const saveBlob = (function () {
  const a = document.createElement('a')
  document.body.appendChild(a)
  a.style.display = 'none'
  return function (blob: Blob, fileName: string) {
    const url = window.URL.createObjectURL(blob)
    a.href = url
    a.download = fileName
    a.click()
    window.URL.revokeObjectURL(url)
  }
})()

/*
  NOT USED IN PRODUCTION
  Quick and dirty solution grabbing a frame from a video element and saving the blob
*/
export function grabFrameFromImage(
  canvasRef: RefObject<HTMLCanvasElement>,
  videoRef: RefObject<HTMLVideoElement>
) {
  if (canvasRef.current === null || videoRef.current === null) {
    return
  }
  canvasRef.current.width = videoRef.current.videoWidth
  canvasRef.current.height = videoRef.current.videoHeight
  canvasRef.current
    .getContext('2d')
    ?.drawImage(videoRef.current, 0, 0, videoRef.current.videoWidth, videoRef.current.videoHeight)
  canvasRef.current.toBlob((blob) => {
    if (blob === null) {
      return
    }
    saveBlob(blob, 'testGrab')
  })
}
