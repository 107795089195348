import { type OrderDocument, type LqvReport, type Report } from '~/models'
import { Button, Icon } from '~/components'
import {
  type ProductWithQuantity,
  useTaskManager,
  useTaskManagerProgress,
  getProductManuals,
} from '~/hooks'
import { type DownloadTask } from '~/services/taskRunners/downloadRunner'
import { localImagePrefix } from '~/components/Documentation/utitlities'
import { getDocumentServiceUrl } from '~/config/documents'
import { type Reportable } from '~/services/ReportService'

interface DownloadOrderDocumentsProps {
  orderId: string
  documents: OrderDocument[]
  products: ProductWithQuantity[]
  reports: Reportable[]
  taskGroup: string
}

export function DownloadOrderDocuments(props: DownloadOrderDocumentsProps) {
  const { documents, products, orderId, reports } = props
  const { queueTasks } = useTaskManager()
  const progress = useTaskManagerProgress(orderId, props.taskGroup)

  function onDownload() {
    if (progress) {
      return
    }
    const documentTaskPayloads = getDocumentTaskPayloads(documents)
    const productTaskPayloads = getProductTaskPayloads(products)

    // TODO we should find an improved solution over manual casting. Hard to finalize currently as the
    //      structure might still be changing. We either need custom TS guards or look if at the Reportable typing.
    const reportImageTaskPayloads = reports.reduce<Array<DownloadTask['payload']>>(
      (reportTasks, report) => {
        if (!report.report) return reportTasks

        const reportImages =
          report.type === 'lqv-report'
            ? getLqvReportImages(report.report as LqvReport, report.hash)
            : getReportImages(report.report as Report, report.hash)

        return [...reportTasks, ...reportImages]
      },
      []
    )

    const downloadTasks: Array<DownloadTask> = [
      ...documentTaskPayloads,
      ...productTaskPayloads,
      ...reportImageTaskPayloads,
    ].map((taskPayload) => ({
      runner: 'download',
      payload: taskPayload,
    }))

    void queueTasks(orderId, downloadTasks, props.taskGroup)
  }

  if (!progress) {
    return (
      <Button variant='filled-light' className='absolute bottom-4 left-4' onClick={onDownload}>
        <Icon icon='general:download' />
        <span>Offline verfügbar machen</span>
      </Button>
    )
  }

  if (progress.value === progress.total) {
    return (
      <Button variant='filled-light' className='absolute bottom-4 left-4'>
        <Icon icon='general:check' />
        <span>Offline verfügbar</span>
      </Button>
    )
  }

  const progressPercentage = Math.round((progress.value / progress.total) * 100)
  const progressCounter = `${progress.value}/${progress.total}`

  return (
    <Button size='sm' variant='filled-light' className='absolute bottom-4 left-4'>
      <Icon icon='general:check' />
      <span>{`Offline verfügbar ${progressPercentage}% (${progressCounter})`}</span>
    </Button>
  )
}

function getDocumentTaskPayloads(documents: OrderDocument[]) {
  return documents.reduce<Array<DownloadTask['payload']>>((documentTasks, document) => {
    const { url, mimeType } = document
    documentTasks.push({ url, mimeType, secure: true })
    if (mimeType.startsWith('image')) {
      documentTasks.push({ url: `${url}?thumbnail=true`, mimeType, secure: true })
    }
    return documentTasks
  }, [])
}

function getProductTaskPayloads(products: ProductWithQuantity[]) {
  return products.reduce<Array<DownloadTask['payload']>>((productTasks, product) => {
    const productDocuments = getProductManuals(product).map((manual) => ({
      url: manual.url,
      mimeType: 'application/pdf',
    }))

    return [...productTasks, ...productDocuments]
  }, [])
}

function getReportImages(
  report: Report | undefined,
  hash: string | undefined
): Array<DownloadTask['payload']> {
  const reportImages: Array<DownloadTask['payload']> = []

  if (!report || !hash) return reportImages

  report.steps?.forEach((step) => {
    step.items?.forEach((item) => {
      if (!item.image_uids) return
      item.image_uids.forEach((imageUid) => {
        if (imageUid.includes(localImagePrefix)) return

        const reportImage = {
          url: `${getDocumentServiceUrl()}/customers/${hash}/files/${imageUid}`,
          secure: true,
          mimeType: 'image',
        }
        const reportImageThumbnail = {
          ...reportImage,
          url: `${reportImage.url}?thumbnail=true`,
        }
        reportImages.push(reportImage, reportImageThumbnail)
      })
    })
  })

  return reportImages
}

function getLqvReportImages(
  report: LqvReport | undefined,
  hash: string | undefined
): Array<DownloadTask['payload']> {
  const reportImages: Array<DownloadTask['payload']> = []

  if (!report || !hash) return reportImages

  // TODO: implement lqv image aggregation for offline usage

  return reportImages
}
