import { type DistributiveOmit } from '../types'
import { emitter } from './taskEventEmitter'
import { clearAllTasksFromIdb } from './taskIdbOperations'
import { type Task } from './taskProcessors'

export type StatelessTask = DistributiveOmit<Task, 'state'>

const toStatefulTask = (task: StatelessTask): Task => ({ ...task, state: 'ReadyToProcess' })

export const taskManager = {
  create: (tasks: StatelessTask[]) =>
    emitter.emit('create', { payload: tasks.map(toStatefulTask) }),
  retry: (task: Task) => emitter.emit('retry', { payload: task }),
  /**
   * @description This will clear all tasks from idb regardless of their state, use with caution.
   */
  clear: clearAllTasksFromIdb,
}
