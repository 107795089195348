import { useNavigate } from 'react-router-dom'
import { type Order } from '~/models'
import { CacheDocumentImage } from '~/components'
import { type Timeframe } from '~/stores'
import { useOrder } from '~/hooks/useOrder'
import houseCoverImageUrl from '~/assets/cover_placeholder.jpg'
import OrderHead from './OrderHead'
import { AppointmentList } from './AppointmentList'
import { type AppointmentsFilter } from '~/stores/useAppStore'

type OrderTileProps = {
  appointmentsFilter: AppointmentsFilter
  timeframe: Timeframe
  order: Order
}

export const OrderTile = (props: OrderTileProps) => {
  const {
    appointmentsFilter,
    timeframe,
    order: { id },
  } = props
  const navigate = useNavigate()
  const order = useOrder(id)

  const imageUrl = order.getCoverImage()
  const thumbnailUrl = imageUrl && imageUrl + '?thumbnail=true'

  return (
    <div
      className='mb-4 rounded-[4px] border border-neutral'
      onClick={() => {
        navigate('/order/' + id)
      }}
    >
      <CacheDocumentImage
        cacheId='thumbnails'
        url={thumbnailUrl}
        secure={true}
        className='h-36 w-full object-cover'
        placeholderLocalSrc={houseCoverImageUrl}
      />
      <div className='p-md'>
        <OrderHead order={order} disableMapsLink />
        <AppointmentList
          appointments={order.getAppointments(appointmentsFilter, timeframe)}
          timeframe={timeframe}
        />
      </div>
    </div>
  )
}
