import { Fragment } from 'react'
import { useNavigate } from 'react-router-dom'
import { Button } from '~/components'

export function PrivacyPolicy() {
  const navigate = useNavigate()

  return (
    <Fragment>
      <div className='ml-4 mt-4'>
        <Button onClick={() => navigate('/profile')} variant='outlined-dark'>
          Zurück
        </Button>
      </div>
      <div className='container h-full w-full flex-col gap-4 pt-2'>
        <h1 className='text-headline-1'>Datenschutzhinweise zolar Installer</h1>
        <p className='mb-8 mt-4 text-base'>
          Diese Datenschutzhinweise klären Sie über die Art,den Umfang und Zweck der Verarbeitung
          von personenbezogenen Daten (nachfolgend kurz „Daten“) innerhalb unserer mobilen
          Applikation zolar Installer App (nachfolgend „ZOLAR App“, „App“) auf:
        </p>

        <div className='mb-8 mt-2'>
          <p className='text-headline-6 mb-2'>1. Datenschutzrechtlich Verantwortlicher</p>
          <p className='text-base'>
            ZOLAR GmbH (nachfolgend „wir“ oder „ZOLAR“) <br />
            Oranienstraße 185 <br />
            10999 Berlin <br />
            Deutschland <br />
            Geschäftsführer: Jamie Heywood, Dr. Sarah Müller, Benjamin Rauser <br />
            Telefon: +49 30 36 428 4567 <br />
            Impressum:{' '}
            <a className='text-link underline' href='https://www.zolar.de/impressum'>
              https://www.zolar.de/impressum
            </a>{' '}
            <br />
            Datenschutzerklärung:{' '}
            <a className='text-link underline' href='http://www.zolar.de/datenschutz'>
              http://www.zolar.de/datenschutz
            </a>
          </p>
        </div>

        <div className='mb-8 mt-2'>
          <p className='text-headline-6 mb-2'>2. Kontakt Datenschutzbeauftragter</p>
          <p className='text-base'>
            ZOLAR GmbH <br />
            Oranienstraße 185 <br />
            10999 Berlin Deutschland <br />
            E-Mail:{' '}
            <a className='text-link' href='mailto:datenschutz@zolar.de'>
              datenschutz@zolar.de
            </a>
          </p>
        </div>

        <div className='mb-8 mt-2'>
          <p className='text-headline-6 mb-2'>3. Verarbeitungszwecke und verarbeitete Daten</p>
          <p className='text-base'>
            Wir erheben und verarbeiten Ihre Daten nur wenn und soweit eine gesetzliche Grundlage
            zur Datenverarbeitung nach Art. 6 DSGVO wie in den nachstehenden Absätzen dargestellt
            vorliegt.
          </p>
        </div>

        <div className='mb-8 mt-2'>
          <p className='text-subheadline-3 mb-2'>
            3.1 Herunterladen der App auf Ihr mobiles Endgerät
          </p>
          <p className='text-base'>
            Wenn Sie unsere App aus einem App Store herunterladen, werden die erforderlichen
            Informationen an den App Store, also insbesondere Nutzername und E- Mail-Adresse Ihres
            Accounts, Zeitpunkt des Downloads und der Gerätekennziffer übertragen. Die Verarbeitung
            erfolgt nicht durch ZOLAR, sondern im Rahmen Ihrer Nutzungsbeziehung mit dem Anbieter
            des jeweiligen App Stores. Wir haben keinen Einfluss auf diese Datenübertragung. Nähere
            Informationen zur Verarbeitung Ihrer Daten finden Sie in den Datenschutzbestimmungen des
            jeweiligen App Stores.
          </p>
        </div>

        <div className='mb-8 mt-2'>
          <p className='text-subheadline-3 mb-2'>
            3.2 Installation der App auf Ihrem mobilen Endgerät
          </p>
          <p className='text-base'>
            Die ZOLAR App steht nur registrierten Installationspartnern der ZOLAR GmbH zur
            Verfügung. Zur erstmaligen Einrichtung der ZOLAR App erhalten Sie einen individuellen,
            ihrem jeweiligen Konto zugeordneten Registrierungscode, über den Sie sich innerhalb der
            ZO LAR App registrieren können. Während der initialen Registrierung, werden Sie
            aufgefordert ein sicheres Passwort einzugeben. Dieses Passwort ist nur Ihnen bekannt und
            wird für zukünftige Anmeldeprozesse sowie Authentifizierungszwecke benötigt. Bewahren
            Sie es daher sicher auf und geben Sie Ihr Passwort unter keinen Umständen weiter. Wir
            erhalten hierbei keinen Zugriff auf Ihr Passwort bei Drittanbietern wie Facebook, Google
            oder Apple. Wir erhalten ledigli ch Ihre E-Mail-Adresse und Informationen über den
            erfolgreichen Login, sowie zusätzliche Daten, wenn Sie die Drittanbieter hierzu
            autorisieren.
            <br /> <br />
            Im Zuge der Installation der ZOLAR APP werden bestimmte, technisch erforderliche Angaben
            Ihres mobilen Endgeräts automatisier t an ZOLAR übertragen. Die Verarbeitung dieser
            Daten ist erforderlich, um die Funktionsfähigkeit der App sicherzustellen, die
            Stabilität zu gewährleisten und zu verbessern, sowie die Sicherheit der App zu
            gewährleisten. Rechtsgrundlage der Verarbeitung ist Art. 6 Abs. 1 Satz 1 lit. b) bzw. f)
            DSGVO. Eine Löschung der Daten erfolgt, sobald sie für die genannten Zwecke nicht mehr
            erforderlich sind. Im Falle der Speicherung einer IP-Adresse erfolgt die Löschung bzw.
            Anonymisierung nach 7 Tagen. Die Verarbeitung und insbesondere die protokollbasierte
            Speicherung dieser Daten ist für die Bereitstellung der App zwingend erforderlich. Zu
            den oben genannten, technisch erforderlichen Daten, zählen die folgenden Angaben:
          </p>
          <ul className='list-inside list-disc text-base'>
            <li className=''>Datum und Uhrzeit der Anfrage</li>
            <li>Geräte-Id. (ID der App (iOS Vendor-Id bzw. Android Werbe-ID) App-Version</li>
            <li>App-Betriebssystemversion IP-Adresse</li>
            <li>Datum und Uhrzeit der Anfrage Zugriffsstatus/HTTP-Statuscode</li>
            <li>
              jeweils übertragene Datenmeng Betriebssystem und dessen Oberfläche Sprache und Version
              der Browsersoftware.
            </li>
          </ul>
          <br />
          <p className='text-base'>
            Zu den im Rahmen der Einrichtung und Nutzung der ZOLAR App verarbeiteten Daten, zählen
            die folgenden Angaben:
          </p>
          <ul className='list-inside list-disc text-base'>
            <li>
              Datum und Uhrzeit der Registrierung bzw. Anmeldung E-Mail, Passwort, Vor- und Nachname
              Registrierungscode
            </li>
            <li>
              Tracking des Nutzungsverhaltens in der App (Häufigkeit und Dauer der Nutzung einzelner
              App- Bereiche und Unterseiten, Häufigkeit der Verwendung bestimmter Schaltflächen)
            </li>
            <li>Einstellen von Push-Notifications</li>
            <li>
              Anfragen (Kategorie, Betreff, Text, Datum und Uhrzeit der Übermittlung) Terminabsagen
              und –zusagen
            </li>
          </ul>
          <br />

          <p className='text-base'>
            Mit Ausnahme der zur Nutzung der ZOLAR App technisch notwendigen Daten erfolgt jegliche
            Bereitstellung personenbezogener Daten durch Sie auf freiwilliger Basis. Es kann jedoch
            sein, dass einzelne Funktionen der App nicht funktionieren, sofern Sie uns die dafür
            benötigten Daten nicht bereitstellen.
            <br /> <br />
            Im Zuge der Nutzung der ZOLAR App werden keine besonderen Kategorien von Daten abgefragt
            oder verarbeitet.
          </p>
          <br />

          <p className='text-subheadline-3'>EingesetzterDienst: AuthO</p>
          <p className='text-base'>
            Auth0 ist ein Identitäts- und Zugriffsverwaltungsdienst, der uns bei der sicheren
            Authentifizierung und Autorisierung von Benutzern unterstützt. Durch die Nutzung von
            Auth0 können Daten gemäß den Datenschutzbestimmungen von Auth0 erfasst und verarbeitet
            werden.
          </p>
          <ul className='list-inside list-disc text-base'>
            <li>Diensteanbieter: Okta Inc, P.O. Box 743620, Los Angeles, CA 90074 – 3620</li>
            <li>Datenschutzerklärung: https://www.okta.com/de/privacy/</li>
            <li>
              Standardvertragsklauseln (Gewährleistung Datenschutzniveau bei Verarbeitung in
              Drittländern): https://www.okta.com/sites/default/files/2021- 02/OKTA-DPA-2-1-21.pdf
            </li>
          </ul>
        </div>

        <div className='mb-8 mt-2'>
          <p className='text-subheadline-3 mb-2'>
            3.3 Verbesserung unserer Dienste und Fehlererkennung
          </p>
          <p className='text-base'>
            Wir setzen Dienste zur Überwachung der Systemstabilität und Ermittlung von Codefehlern
            ein - Angaben zum Gerät oder Fehlerzeitpunkt werden dabei pseudonym erhoben und
            anschließend gelöscht. Die Rechtsgrundlage dieser Datenverarbeitung ist gemäß Art. 6
            Abs. 1 Satz 1 lit. f) DSGVO unser berechtigtes Interesse an der Sicherstellung der
            Verfügbarkeit und Integrität unserer Dienste sowie die Erfüllung vertraglicher Pflichten
            gegenüber unseren Kunden gemäß Art. 6 Abs. 1 Satz 1 lit. b) DSGVO.
            <br />
            <br />
            Eingesetzter Dienst: AWS AppSync: AWS AppSync ist ein verwalteter Dienst von Amazon Web
            Services, der die Bereitstellung von sicheren APIs für mobile und Webanwendungen
            ermöglicht. Die Nutzung von AWS AppSync kann zur Erfassung und Verarbeitung von Daten
            gemäß den Datenschutzbestimmungen von Amazon Web Services führen.
          </p>
          <br />

          <ul className='list-inside list-disc text-base'>
            <li>
              Diensteanbieter: Amazon Web Services, Inc. (USA) und ihrer europäischen
              Tochtergesellschaft Amazon Web Services EMEA Sarl (Lu xemburg) bereitgestellt.
            </li>
            <li>
              Die Datenschutzhinweise finden sich hier:{' '}
              <a
                className='text-link underline'
                href='https://aws.amazon.com/de/compliance/germany-data-protection/'
              >
                https://aws.amazon.com/de/compliance/germany-data-protection/
              </a>
            </li>
            <li>
              Standardvertragsklauseln (Gewährleistung Datenschutzniveau bei Verarbeitung in
              Drittländern): https://d1.awsstatic.com/legal/aws- gdpr/AWS_GDPR_DPA.pdf
            </li>
          </ul>
        </div>

        <div className='mb-8 mt-2'>
          <p className='text-headline-6 mb-2'>4. Rechtsgrundlagen der Verarbeitung</p>
          <br />

          <p className='text-subheadline-3 mb-2'>
            4.1 Verarbeitung Ihrer Daten aufgrund gesetzlicher Verpflichtungen
          </p>
          <p className='text-base'>
            Eine weitergehende Verarbeitung Ihrer Daten erfolgt ausschließlich, sofern wir
            gesetzlichen Verpflichtungen unterliegen, die eine Verarbeitung Ihrer Daten erforderlich
            machen. Die Rechtsgrundlage dieser Datenverarbeitung ist Art. 6 Abs. 1 Satz 1 lit. c)
            DSGVO in Verbindung mit der rechtlichen Norm, welche die jeweilige gesetzliche
            Verpflichtung enthält.
          </p>
          <br />

          <p className='text-subheadline-3 mb-2'>
            4.2 Verarbeitung Ihrer Daten zur Erfüllung vertraglicher Verpflichtungen
          </p>
          <p className='text-base'>
            Eine weitergehende Verarbeitung Ihrer Daten erfolgt zur Erfüllung Ihres PV-Vertrages in
            Bezug auf unsere vertraglichen Verpflichtungen zum Anlagenmonitoring und Fernwartung auf
            Grundlage des Art. 6 Abs. 1 lit. b) DS-GVO.
          </p>
          <br />

          <p className='text-subheadline-3 mb-2'>
            4.3 Verarbeitung Ihrer Daten aufgrund Ihrer Einwilligung
          </p>
          <p className='text-base'>
            Soweit wir Ihre Daten über die zuvor genannten Zwecke hinaus verarbeiten, erfolgt dies
            nur, sofern wir Sie zuvor über die Zwecke der Verarbeitung informiert haben und Sie in
            die Datenverarbeitung gemäß Art. 6 Abs. 1 Satz 1 lit. a) DSGVO eingewilligt haben.
          </p>
        </div>

        <div className='mb-8 mt-2'>
          <p className='text-headline-6 mb-2'>5. Sicherheit der Datenverarbeitung</p>
          <p className='text-base'>
            Wir treffen nach Maßgabe des Art. 32 DSGVO unter Berücksichtigung des Stands der
            Technik, der Implementierungskosten und der Art, des Umfangs, der Umstände und der
            Zwecke der Verarbeitung sowie der unterschiedlichen Eintrittswahrscheinlichkeit und
            Schwere des Risikos für die Rechte und Fre iheiten natürlicher Personen, geeignete
            technische und organisatorische Maßnahmen, um ein dem Risiko angemessenes Schutzniveau
            zu gewährleisten; Zu den Maßnahmen gehören insbesondere die Sicherung der
            Vertraulichkeit, Integrität und Verfügbarkeit von Daten durch Kontrolle des physischen
            Zugangs zu den Daten, als auch des sie betreffenden Zugriffs, der Eingabe, Weitergabe,
            der Sicherung der Verfügbarkeit und ihrer Trennung. Des Weiteren haben wir Verfahren
            eingerichtet, die eine Wahrnehmung von Betroffenenrechten, Löschung von Daten und
            Reaktion auf Gefährdung der Daten gewährleisten. Ferner berücksichtigen wir den Schutz
            personenbezogener Daten bereits bei der Entwicklung, bzw. Auswahl von Hardware, Software
            sowie Verfahren, entsprechend dem Prinzip des Datenschutzes durch Technikgestaltung und
            durch datenschutzfreundliche Voreinstellungen berücksichtigt (Art. 25 DSGVO). Zu den
            Sicherheitsmaßnahmen gehört insbesondere die verschlüsselte Übertragung von Daten
            zwischen Ihrem Browser und unserem Server sowie das Monitoring der eingesetzten Server,
            Datenbanken und Dienste.
            <br />
            Unsere Mitarbeiter werden im Hinblick auf den Datenschutz auf Verschwiegenheit
            verpflichtet, belehrt und instruiert, als auch auf mögliche Haftungsfolgen hingewiesen.
          </p>
        </div>

        <div className='mb-8 mt-2'>
          <p className='text-headline-6 mb-2'>6. Offenlegung und Übermittlung von Daten</p>
          <p className='text-base'>
            Sofern wir im Rahmen unserer Verarbeitung Daten gegenüber anderen Personen und
            Unternehmen (Auftragsverarbeitern oder Dritten) offenbaren, sie an diese übermitteln
            oder ihnen sonst Zugriff auf die Daten gewähren, erfolgt dies nur auf Grundlage einer
            gesetzlichen Erlaubnis (z. B. wenn eine Übermittlung der Daten an Dritte, wie an
            Zahlungsdienstleister, gem. Art. 6 Abs. 1 Satz 1 lit. b DSGVO zur Vertragserfüllung
            erforderlich ist), Sie eingewilligt haben, eine rechtliche Verpflichtung dies vorsieht
            oder auf Grundlage unserer berechtigten Interessen (z.B. beim Einsatz von technischen
            Dienstleistern, etc.). Sofern wir Dritte mit der Verarbeitung von Daten auf Grundlage
            eines sog. „Auftragsverarbeitungsvertrages“ beauftragen, geschieht dies auf Grundlage
            des Art. 28 DSGVO.
            <br />
            Sofern wir Daten anderen Unternehmen unserer Unternehmensgruppe offenbaren, übermitteln
            oder ihnen sonst den Zugriff gewähren, erfolgt dies insbesondere zu administrativen
            Zwecken als berechtigtes Interesse und darüberhinausgehend auf Grundlage eines
            Auftragsverarbeitungsvertrages. Einen Überblick über die Kategorien von Empfängern
            können Sie jederzeit unterer Datenschutzerklärung entnehmen.
          </p>
        </div>

        <div className='mb-8 mt-2'>
          <p className='text-headline-6 mb-2'>7. Push-Nachrichten</p>
          <p className='text-base'>
            Sofern Sie uns hierfür Ihre Zustimmung erteilt haben, senden wir Ihnen so genannte
            "Push-Nachrichten", die Informationen zu Ihrem Projekt (z.B. Terminanfragen, Hinweise
            zum Installationsprozess) sowie Terminerinnerungen (z.B. zu Installationsterminen)
            enthalten können. Dabei handelt es sich um Nachrichten, die auf Bildschirmen, Endgeräten
            oder in Browsern angezeigt werden, auch wenn unser Onlinedienst gerade nicht aktiv
            genutzt wird. Ihre Zustimmung hierzu wird zu Nachweiszwecken von uns dokumentiert und
            gespeichert. Hierzu wird ein pseudonymer Identifikator des Browsers (sog. "Push- Token")
            oder die Geräte-ID Ihres Endgerätes gespeichert. Sie können den Erhalt der
            Push-Nachrichten jederzeit mit Hilfe der Benachrichtigungseinstellungen der durch Sie
            verwendeten Browser, bzw. Endgeräte anpassen.
          </p>
          <ul className='list-inside list-disc text-base'>
            <li>
              Verarbeitete Datenarten: Nutzungsdaten (z.B. genutzte Funktionen, Zugriffszeiten);
              Standortdaten (Angaben zur geografischen Position eines Gerätes).
            </li>
            <li>
              Zwecke der Verarbeitung: Erbringung vertraglicher Leistungen; Reichweitenmessung (z.B.
              Zugriffsstatistiken, Erkennung wiederkehrender Besucher).
            </li>
            <li>
              Rechtsgrundlagen: Einwilligung (Art. 6 Abs. 1 Satz 1 lit. a. DSGVO); Vertragserfüllung
              sowie vorvertragliche Anfragen (Art. 6 Abs. 1 Satz 1 lit. b. DSGVO).
            </li>
          </ul>
        </div>
        <br />

        <p className='text-subheadline-3'>Eingesetzte Dienste:</p>
        <br />
        <p className='text-subheadline-3'>AWS Amplify:</p>
        <ul className='list-inside list-disc text-base'>
          <li>
            Diensteanbieter: AWS Amplify wird von der Amazon Web Services, Inc. (USA) und ihrer
            europäischen Tochtergesellschaft Amazon Web Services EMEA Sarl (Luxemburg)
            bereitgestellt.
          </li>
          <li>
            Die Datenschutzhinweise finden sich hier:{' '}
            <a
              className='text-link underline'
              href='https://aws.amazon.com/de/compliance/germany-data-protection/'
            >
              https://aws.amazon.com/de/compliance/germany-data-protection/
            </a>
          </li>
          <li>
            Standardvertragsklauseln (Gewährleistung Datenschutzniveau bei Verarbeitung in
            Drittländern): https://d1.awsstatic.com/legal/aws- gdpr/AWS_GDPR_DPA.pdf
          </li>
          <li>
            Der Nutzer kann dem Erhalt von Push-Nachrichten über Amazon Pinpoint jederzeit in den
            Einstellungen der APP, die er über Klick auf das Icon Profil aufrufen kann, oder in den
            Einstellungen seines Endgerätes widersprechen
          </li>
        </ul>
        <br />

        <div className='mb-8 mt-2'>
          <p className='text-headline-6 mb-2'>
            8. Reichweitenmessung, Onlinemarketing und Technologiepartner
          </p>
          <p className='text-base'>
            In diesem Abschnitt informieren wir Sie, welche Dienste von Technologiepartnern wir zur
            Reichweitenmessung und zu Onlinemarketingzwecken einsetzen. Der Einsatz erfolgt auf
            Grundlage des Art. 6 Abs. 1 lit. f DSGVO und unseres Interesses an der Steigerung der
            Nutzerfreundlichkeit, Optimierung unseres Angebotes und dessen
            Betriebswirtschaftlichkeit. Zu den verarbeiteten Daten gehören in allen Fällen die
            Nutzungs- und die Metadaten. Weitere Erläuterungen sind in den Begriffsdefinitionen,
            insbesondere zu den Funktionsweisen und Schutzmaßnahmen, am Ende dieser
            Datenschutzerklärung zu finden. Die Löschung der Daten bestimmt sich, sofern nicht
            anders angegeben entsprechend den Datenschutzerklärungen der Technologiepartner.
          </p>
          <br />

          <p className='text-subheadline-3'>Eingesetzte Dienste:</p>
          <br />
          <p className='text-subheadline-3'>Google Tag Manager</p>
          <p className='text-base'>
            Google Tag Manager ist eine Lösung, mit der wir sog. Website-Tags über eine Oberfläche
            verwalten können (und so z.B. Google Analytics sowie andere Google-Marketing-Dienste in
            unser Onlineangebot einbinden). Der Tag Manager selbst (welches die Tags implementiert)
            verarbeitet keine personenbezogenen Daten der Nutzer.
          </p>
          <ul className='list-inside list-disc text-base'>
            <li>
              Diensteanbieter: Google Ireland Limited, Gordon House, Barrow Street, Dublin 4,
              Irland, Mutterunternehmen: Google LLC, 1600 A mphitheatre Parkway, Mountain View, CA
              94043, USA;
            </li>
            <li>
              Website: https://marketingplatform.google.com; Datenschutzerklärung:
              https://policies.google.com/privacy; Auftragsverarbeitungsvertrag:
              https://business.safety.google/adsprocessorterms;
            </li>
            <li>
              Standardvertragsklauseln (Gewährleistung Datenschutzniveau bei Verarbeitung in
              Drittländern):{' '}
              <a
                className='text-link underline'
                href='https://business.safety.google/adsprocessorterms'
              >
                https://business.safety.google/adsprocessorterms
              </a>
            </li>
            <li>
              Weitere Informationen. Arten der Verarbeitung sowie Kategorien der verarbeiteten
              Daten): https://privacy.google.com/businesses/adsservices
            </li>
          </ul>
          <br />

          <p className='text-subheadline-3'>Google Analytics</p>
          <p className='text-base'>
            Wir setzen Google Analytics zu Zwecken der Reichweitenmessung und Zielgruppenbildung
            ein.Verarbeitete Daten: Nutzungsdaten, Metadaten, Kunden-ID bei uns (Google erhält die
            Kunden-ID nur als ein pseudonymes Datum ohne die dazu gehörenden Bestandsdaten, wie
            Namen, Adresse oder E-Mail des Kunden).
            <br />
            Art, Umfang, Funktionsweise der Verarbeitung: Permanent-Cookies, Third-PartyCookies,
            Tracking, interessenbasiertes Marketing, Profiling, Custom Audiences, Remarketing.
          </p>
          <ul className='list-inside list-disc text-base'>
            <li>
              Diensteanbieter: Google Ireland Limited, Gordon House, Barrow Street, Dublin 4,
              Irland, Mutterunternehmen: Google LLC, 1600 Amphitheatre Parkway, Mountain View, CA
              94043, USA.
            </li>
            <li>Datenschutzerklärung: https://www.google.com/policies/privacy/.</li>
            <li>Verarbeitung in Drittländern: Nein. Löschung der Daten: 14 Monate.</li>
            <li>
              Opt-Out: http://tools.google.com/dlpage/gaoptout?hl=de (Browser-Add-On Google
              Analytics), https://adssettings.google.com/ (Einstellung für Werbeanzeigen).
            </li>
            <li>
              Besondere Schutzmaßnahmen: Pseudonymisierung, IP-Masking, Abschluss
              Auftragsverarbeitungsvertrag.
            </li>
            <li>Die Daten werden nach Ablauf von 14 Monaten gelöscht.</li>
          </ul>
          <br />

          <p className='text-subheadline-3'>Hotjar</p>
          <p className='text-base'>
            Wir verwenden den Dienst Hotjar. Die Übermittlung und Verarbeitung personenbezogener
            Daten erfolgt ausschließlich auf Servern in der europäischen Union. Rechtsgrundlage für
            die Übermittlung der personenbezogenen Daten stellt Ihre Einwilligung gem. Art. 6 Abs. 1
            lit. a DSGVO bzw. Art. 9 Abs. 2 lit. a DSGVO dar, die Sie bei der Einrichtung der App
            getätigt haben.
            <br />
            Über Hotjar führen wir eine Verhaltensanalyse über die Nutzung unserer Websites durch
            und werten Feedback üb er Tools wie Heatmaps, Sitzungsaufzeichnungen und Umfragen aus.
            <br />
            Ihre Einwilligung können Sie jederzeit widerrufen. Nähere Informationen zum Widerruf
            Ihrer Einwilligung finden Sie entweder bei der Einwilligung selbst oder am Ende dieser
            Datenschutzerklärung.
          </p>
          <ul className='list-inside list-disc text-base'>
            <li>Diensteanbieter: Hotjar Ltd., 20 Bisazza Street, 1640 Sliema, Malta.</li>
            <li>Datenschutzerklärung: https://www.hotjar.com/privacy.</li>
            <li>
              Ort der Verarbeitung: Die Übermittlung und Verarbeitung personenbezogener Daten
              erfolgt ausschließlich auf Servern in der europäischen
            </li>
            <li>Die Daten werden nach Ablauf von 1 Jahr gelöscht.</li>
          </ul>
        </div>
        <br />

        <p className='text-subheadline-3'>Usercentrics</p>
        <p className='text-base'>
          Wir nutzen der Zustimmungsverwaltungsdienst Usercentrics, der Usercentrics GmbH,
          Sendlinger Str. 7, 80331 München, Deutschland (U sercentrics). Dies ermöglicht uns die
          Einwilligung der Websitenutzer zur Datenverarbeitung einzuholen und zu verwalten. Die
          Verarbe itung ist zur Erfüllung einer rechtlichen Verpflichtung erforderlich, der wir
          unterliegen. Dafür werden folgende Daten verarbeitet: Datum und Uhrzeit des Zugriffs
          Browser-Informationen Geräteinformationen Geografischer Standort Cookie-Präferenzen URL
          der besuchten Seite. Die Funktionsfähigkeit der Website ist ohne die Verarbeitung nicht
          gewährleistet.
        </p>
        <ul className='list-inside list-disc text-base'>
          <li>Diensteanbieter: Usercentrics GmbH, Sendlinger Str. 7, 80331 München, Deutschland</li>
          <li>
            Usercentrics ist Empfänger Ihrer personenbezogenen Daten und als Auftragsverarbeiter für
            uns tätig.
          </li>
          <li>Die Verarbeitung findet in der Europäischen Union statt.</li>
          <li>
            Datenschutzerklärung:{' '}
            <a
              className='text-link underline'
              href='https://usercentrics.com/de/datenschutzerklaerung/'
            >
              https://usercentrics.com/de/datenschutzerklaerung/
            </a>
          </li>
          <li>Die Daten werden nach Ablauf von 3 Jahren gelöscht.</li>
        </ul>
        <br />

        <p className='text-subheadline-3'>Userflow</p>
        <p className='text-base'>
          Wir nutzen die Softwarelösung Userflow,um ein sofortiges, intuitives In-App-Lernen
          innerhalb unserer Anwendung zu ermöglichen. Die an Userflow übermittelten Daten umfassen
          Informationen eine pseudonymisierte User-ID sowie hierzu Account-Informationen, wie z.B.
          Registrierungsdatum, Ablaufdaten (z.B.: Zugänge), Tarif und Abrechnungsinformationen zur
          jeweiligen User-ID, Statistiken zur Nutzung, deskriptive Daten zur Nutzer-ID – falls diese
          vom User selbst bei der Registrierung oder später in seinem Profil angegeben wurden. Diese
          Informationen lassen aufgrund der Beziehung zur pseudonymisierten User-ID keine
          Rückschlüsse auf die direkte Person zu.
        </p>
        <ul className='list-inside list-disc text-base'>
          <li>
            Diensteanbieter: Userflow Inc., 548 Market St PMB 69598, San Francisco, CA 94104-5401,
            USA.
          </li>
          <li>Datenschutzinformationen: https://userflow.com/policies/privacy-regulations</li>
        </ul>
        <br />

        <div className='mb-8 mt-2'>
          <p className='text-headline-6 mb-2'>9. Übermittlungen in Drittländer</p>
          <p className='text-base'>
            Sofern wir Daten in einem Drittland (d.h. außerhalb der Europäischen Union (EU) oder des
            Europäischen Wirtschaftsraums (EWR)) verarbeiten oder dies im Rahmen der Inanspruchnahme
            von Diensten Dritter oder Offenlegung, bzw. Übermittlung von Daten an Dritte geschieht,
            erfolgt dies nur, wen n es zur Erfüllung unserer (vor)vertraglichen Pflichten, auf
            Grundlage Ihrer Einwilligung, aufgrund einer rechtlichen Verpflichtung oder auf
            Grundlage unserer berechtigten Interessen geschieht. Vorbehaltlich gesetzlicher oder
            vertraglicher Erlaubnisse, verarbeiten oder lassen wir die Daten in einem Drittland nur
            beim Vorliegen der besonderen Voraussetzungen der Art. 44 ff. DSGVO verarbeiten. D.h.
            die Verarbeitung erfolgt z.B. auf Grundlage besonderer Garantien, wie der offiziell
            anerkannten Feststellung eines der EU entsprechenden Datenschutzniveaus, z.B. für die
            USA unter Anwendung anerkannter spezieller vertraglicher Verpflichtungen (so genannter
            „Standardvertragsklauseln“).
          </p>
        </div>
        <br />

        <div className='mb-8 mt-2'>
          <p className='text-headline-6 mb-2'>10. Rechte der Betroffenen</p>
          <p className='text-base'>
            Bezugnehmend auf die hier genannten Verarbeitungen Ihrer personenbezogenen Daten haben
            Sie gemäß Art. 15 DSGVO jeder zeit das Recht, Auskunft über die durch uns zu Ihrer
            Person verarbeiteten Daten zu verlangen. Des Weiteren haben Sie das Recht, Daten gemäß
            Art. 16 DSGVO berichtigen, gemäß Art. 17 DSGVO löschen sowie die weitere Verarbeitung
            gemäß Art. 18 DSGVO einschränken zu lassen. Gemäß Art. 20 DSGVO haben Sie das Recht, die
            Herausgabe der durch Sie bereitgestellten personenbezogenen Daten in einem
            strukturierten, gängigen und maschinenlesbaren Format zu verl angen. Einschränkungen
            bzw. Ausnahmen bestehen hinsichtlich Ihres Auskunftsrechts aus § 34 BDSG und bezüglich
            des Löschungsrechts aus § 35 BDSG. Sofern Sie der Ansicht sind, dass die Verarbeitung
            Ihrer personenbezogenen Daten durch uns gegen geltendes Recht verstößt, können Sie sich
            gemäß Art. 77 DSGO jederzeit an eine Datenschutzaufsichtsbehörde wenden.
          </p>
        </div>
        <br />

        <div className='mb-8 mt-2'>
          <p className='text-headline-6 mb-2'>11. Automatisierte Entscheidung im Einzelfall</p>
          <p className='text-base'>
            Nach Art. 22 DSGVO haben Sie das Recht, nicht einer ausschließlich auf einer
            automatisierten Verarbeitung (einschließlich Profiling) beruhenden Entscheidung
            unterworfen zu werden, die Ihnen gegenüber rechtliche Wirkung entfaltet oder Sie in
            vergleichbarer Weise erheblich beeinträchti gt. Wir nehmen keine automatisierten
            Entscheidungen im Einzelfall vor.
          </p>
        </div>
        <br />

        <div className='mb-8 mt-2'>
          <p className='text-headline-6 mb-2'>12. Widerrufsrecht</p>
          <p className='text-base'>
            Sie haben das Recht, erteilte Einwilligungen gem. Art. 7 Abs. 3 DSGVO mit Wirkung für
            die Zukunft (z. B. per E-Mail an datenschutz@zolar.de oder per Brief an die ZOLAR GmbH,
            Oranienstraße 185, 10999 Berlin) zu widerrufen.
          </p>
        </div>
        <br />

        <div className='mb-8 mt-2'>
          <p className='text-headline-6 mb-2'>13. Widerspruchsrecht</p>
          <p className='text-base'>
            Sie können der künftigen Verarbeitung der Sie betreffenden Daten nach Maßgabe des Art.
            21 DSGVO jederzeit widersprechen. Der Widerspruch kann insbesondere gegen die
            Verarbeitung für Zwecke der Direktwerbung erfolgen.
          </p>
        </div>
        <br />

        <div className='mb-8 mt-2'>
          <p className='text-headline-6 mb-2'>14. Verlinkung externer Angebote</p>
          <p className='text-base'>
            Unsere Datenschutzbestimmungen erstrecken sich nicht auf Verlinkungen auf externe
            Angebote und Leistungen (bzw. Verweise zu d iesen) innerhalb ZOLAR App. Diesbezüglich
            verweisen wir auf die Datenschutzbestimmungen der jeweiligen Anbieter.
          </p>
        </div>
        <br />

        <div className='mb-8 mt-2'>
          <p className='text-headline-6 mb-2'>
            15. Änderungen und Aktualisierungen dieser Datenschutzerklärung
          </p>
          <p className='text-base'>
            Wir bitten Sie, sich regelmäßig über den Inhalt unserer Datenschutzhinweise zu
            informieren. Wir passen diese an, sofern Änderungen der von uns durchgeführten
            Datenverarbeitungen oder z. B. gesetzliche Änderungen dies erforderlich machen. Den
            jeweils aktuellen Stand di eser Datenschutzhinweise finden Sie unter dem Link
            Datenschutz in der aktuellen Version Ihrer App (die aktuelle Version finden sie immer im
            App Store).
          </p>
        </div>
        <br />

        <p className='mb-8 text-2xl'>Stand: Juni 2023</p>
      </div>
    </Fragment>
  )
}
