import styled from 'styled-components'
import { Carousel } from '~/components/Carousel/Carousel'
import { type ReactNode } from 'react'
import { Button, Icon } from '~/components'

export interface CarouselModalProps {
  activeIndex: number
  onChangeActiveIndex: (index: number | undefined) => void
  children: ReactNode
  className?: string
  close: () => void
  visible?: boolean
}

export function CarouselModal(props: CarouselModalProps) {
  if (!props.visible) {
    return null
  }

  return (
    <StyledCarouselModal className={props.className}>
      <div className='close mb-4 mr-4 mt-8'>
        <Button isIconButton variant='filled-light' size='lg' onClick={props.close}>
          <Icon icon='general:x-close' />
        </Button>
      </div>
      <div className='dialog'>
        <Carousel
          activeIndex={props.activeIndex}
          onChangeActiveIndex={props.onChangeActiveIndex}
          showNavigation={false}
        >
          {props.children}
        </Carousel>
      </div>
    </StyledCarouselModal>
  )
}

const StyledCarouselModal = styled.div`
  position: absolute;
  inset: 0;
  z-index: 1000;
  background-color: rgba(0, 0, 0, 0.5);
  backdrop-filter: blur(4px);
  display: flex;
  flex-direction: column;
  .close {
    text-align: right;
  }
  .dialog {
    display: block;
    height: 100%;

    img {
      object-fit: contain;
      height: 100%;
    }
  }
`
