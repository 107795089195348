import { type Order } from '~/models'
import { OrderTile } from '~/components/Order'
import { Fragment } from 'react'
import { type Timeframe } from '~/stores'
import { type AppointmentsFilter } from '~/stores/useAppStore'

interface OrderListProps {
  appointmentsFilter: AppointmentsFilter
  timeframe: Timeframe
  orders: Order[]
}

export function OrderList(props: OrderListProps) {
  return (
    <Fragment>
      {props.orders.map((order) => (
        <OrderTile
          key={order.id}
          appointmentsFilter={props.appointmentsFilter}
          timeframe={props.timeframe}
          order={order}
        />
      ))}
    </Fragment>
  )
}
