import { type CommissioningForm } from '../formValidationSchema'
import { ItemTextInline, Section } from './SharedWrappers'

const PdfSectionWallbox = (props: { data: CommissioningForm['wallbox'] }) => {
  const {
    data: { manufacturer, typeAndPerformanceInKW, serialNumber, ...rest },
  } = props
  const hasWallbox = rest.hasWallbox === 'yes'
  return (
    <Section label='Wallbox'>
      <ItemTextInline label='Wallbox' value={hasWallbox ? 'Ja' : 'Nein'} />
      {hasWallbox && (
        <>
          <ItemTextInline label='Hersteller' value={manufacturer} />
          <ItemTextInline label='Modell' value={typeAndPerformanceInKW} />
          <ItemTextInline label='Seriennummer' value={serialNumber} />
        </>
      )}
    </Section>
  )
}

export default PdfSectionWallbox
