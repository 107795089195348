import { useFormContext } from 'react-hook-form'
import { FormInput, RadioGroup } from '~/components'
import { type CommissioningForm } from './formValidationSchema'

const FormWallbox = () => {
  const { watch } = useFormContext<CommissioningForm>()
  const hasWallbox = watch('wallbox.hasWallbox') === 'yes'
  return (
    <div className='flex flex-col gap-4'>
      <RadioGroup label='Wallbox' orientation='horizontal'>
        <FormInput type='radio' name='wallbox.hasWallbox' label='Ja' value='yes' />
        <FormInput type='radio' name='wallbox.hasWallbox' label='Nein' value='no' />
      </RadioGroup>
      <FormInput
        type='text'
        name='wallbox.manufacturer'
        label='Hersteller'
        disabled={!hasWallbox}
      />
      <FormInput
        type='text'
        name='wallbox.typeAndPerformanceInKW'
        label='Modell (inkl. Leistung in kW)'
        disabled={!hasWallbox}
      />
      <FormInput
        type='text'
        name='wallbox.serialNumber'
        label='Seriennummer'
        disabled={!hasWallbox}
      />
    </div>
  )
}

export default FormWallbox
