import classNames from 'classnames'

interface SegmentedControlProps<T> {
  segments: SegmentData<T>[]
  selected: string
  onChange: (state: T) => void
  fullWidth?: boolean
  className?: string
}

export function SegmentedControls<T>(props: SegmentedControlProps<T>) {
  const { segments, selected, onChange } = props

  return (
    <div
      aria-label='Segmented controls'
      className={classNames(
        'segmented-controls segmented-controls-text-only',
        props.fullWidth && 'max-w-full',
        props.className
      )}
      role='tablist'
    >
      {segments.map((segment, key) => (
        <SegmentControl
          key={key}
          label={segment.label}
          checked={selected === segment.value}
          onChange={() => onChange(segment.value)}
        />
      ))}
    </div>
  )
}

interface SegmentData<T = string> {
  label: string
  value: T
}

interface SegmentControlProps {
  label: string
  checked: boolean
  onChange: () => void
}

function SegmentControl(props: SegmentControlProps) {
  return (
    <button
      aria-label={props.label}
      aria-selected={props.checked}
      className={classNames('segmented-control', { 'segmented-control-active': props.checked })}
      onClick={props.onChange}
      role='tab'
      tabIndex={0}
    >
      <span className='segmented-control-label'>{props.label}</span>
    </button>
  )
}
