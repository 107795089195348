import { type ReportQuestionEntity, ReportStatus } from '~/models'
import { NativeDropdown, Button, Icon, FormInput, RadioGroup } from '~/components'
import { FormProvider, useForm, useFormState, useWatch } from 'react-hook-form'
import {
  AdditionalDocuments,
  type AdditionalDocumentsProps,
} from '~/components/Documentation/AdditionalDocuments'
import { useEffect, useMemo } from 'react'
import { debounce } from 'lodash-es'

export type Answers = Record<string, string>

const DEBOUNCE_AUTOSAVE_IN_MS = 300

interface SurveyProps {
  status: ReportStatus | keyof typeof ReportStatus
  missingItems: Array<string>
  questions: Array<ReportQuestionEntity>
  onSubmit: (data: Answers) => void
  onSaveDraftSurvey: (formValues: Answers) => Promise<void>
  additionalDocuments: AdditionalDocumentsProps
  isSubmittable: boolean
}

export function Survey(props: SurveyProps) {
  const {
    status,
    missingItems,
    questions,
    onSubmit,
    additionalDocuments,
    onSaveDraftSurvey,
    isSubmittable,
  } = props

  const formMethods = useForm<Answers>({
    defaultValues: props.questions.reduce<Answers>((existingAnswers, question) => {
      if (typeof question.answer === 'string') {
        existingAnswers[question.name] = question.answer
      }
      return existingAnswers
    }, {}),
  })
  const isSubmitted = status !== ReportStatus.DRAFT

  const formValues = useWatch<Answers>({ control: formMethods.control })

  const debouncedOnSaveDraftSurvey = useMemo(
    () => debounce(onSaveDraftSurvey, DEBOUNCE_AUTOSAVE_IN_MS),
    [onSaveDraftSurvey]
  )

  useEffect(() => {
    ;(async () => {
      try {
        await debouncedOnSaveDraftSurvey(formValues as Answers)
      } catch (error) {
        console.error(error)
      }
    })()
    return () => {
      debouncedOnSaveDraftSurvey.cancel()
    }
  }, [formValues, debouncedOnSaveDraftSurvey])

  return (
    <FormProvider {...formMethods}>
      <div className='m-6 mt-[104px] rounded border border-neutral-500 p-6'>
        <div className='text-headline-4'>Zusätzliche Informationen </div>
        {questions.map((question, index) => {
          const showDivider = index > 0
          return (
            <Question
              key={question.name}
              {...question}
              divider={showDivider}
              disabled={isSubmitted}
            />
          )
        })}
        <AdditionalDocuments {...additionalDocuments} />
        <div className='flex flex-col items-center border-t border-t-neutral-300 pt-8'>
          {isSubmitted ? (
            <Button variant='filled-accent' size='sm' disabled>
              Dokumentation wurde versendet
            </Button>
          ) : (
            <Button
              variant='filled-accent'
              size='sm'
              disabled={!isSubmittable}
              onClick={formMethods.handleSubmit(onSubmit)}
            >
              <Icon icon='social:send' />
              <span>Vollständige Dokumentation senden</span>
            </Button>
          )}
          {!isSubmittable && (
            <div className='text-description-1 mt-4 text-dark-secondary'>
              <p className='mb-2'>
                Aktuell kann <b>nur eine vollständig hochgeladene Dokumentation</b> von{' '}
                <b>zolar geprüft</b> werden.
              </p>
              <p>Folgende Schritte fehlen:</p>
              <ul className='list-inside list-disc'>
                {missingItems.map((item) => (
                  <li key={item}>{item}</li>
                ))}
              </ul>
            </div>
          )}
        </div>
      </div>
    </FormProvider>
  )
}

interface QuestionProps extends ReportQuestionEntity {
  divider?: boolean
  disabled?: boolean
}

function Question(props: QuestionProps) {
  const { errors } = useFormState<Answers>({ name: props.name })
  const divider = props.divider ? 'border-t border-t-neutral-300' : ''
  const error = errors[props.name]

  return (
    <div className={`text-body-1-regular py-8 ${divider}`}>
      <div className='mb-4'>
        {questionLabels[props.name] ?? `Unknown key (${props.name})`}
        {!props.required && ' (optional)'}
      </div>
      {
        {
          radio: (
            <RadioGroup error={error && 'Pflichtfeld'}>
              {(props.possible_answers || []).map((value) => (
                <FormInput
                  type='radio'
                  key={`radio-${value}`}
                  name={props.name}
                  value={value}
                  label={answerLabels[value] ?? value}
                  required={props.required}
                  disabled={props.disabled}
                />
              ))}
            </RadioGroup>
          ),
          textarea: (
            <FormInput
              type='textarea'
              name={props.name}
              required={props.required}
              disabled={props.disabled}
              placeholder='Eine beschreibung eingeben...'
              rows={4}
              hasError={!!error}
              description={error && 'Pflichtfeld'}
            />
          ),
          select: (
            <NativeDropdown
              name={props.name}
              placeholder={dropdownValues[props.name]?.placeholder}
              options={dropdownValues[props.name]?.options}
              required={props.required}
              disabled={props.disabled}
              error={error}
            />
          ),
        }[props.render_format]
      }
    </div>
  )
}

const questionLabels: Record<string, string> = {
  ac_measurement: 'Wurde das Messkonzept wie geplant umgesetzt?',
  ac_monitoring: 'Monitoring wurde erfolgreich eingerichtet und der Kunde sowie zolar hinzugefügt?',
  ac_zolar_compass: 'Wurde der Compass Manager eingerichtet und in Betrieb genommen?',
  ac_remark: 'Bemerkungen zur Elektroinstallation',
  ac_additional_documents: 'Zusätzliche Dokumente zur Elektroinstallation',
  dc_design: 'Wurde das Design wie geplant umgesetzt?',
  dc_string_plan: 'Wurde der Stringplan wie geplant umgesetzt?',
  dc_cable: 'Wurde das DC-Kabel bis zum geplanten Installationsort geführt?',
  dc_remark: 'Bemerkungen zur Dachinstallation',
  dc_sub_construction: 'Welche Unterkonstruktion wurde verwendet?',
  dc_additional_documents: 'Zusätzliche Dokumente zur Dachinstallation',
}

const answerLabels: Record<string, string> = {
  answer_yes: 'Ja',
  answer_no: 'Nein',
  answer_not_part_of_order: 'Compass Manager nicht Angebotsbestandteil',
}

const dropdownValues: Record<string, { placeholder: string; options: Array<string> }> = {
  dc_sub_construction: {
    placeholder: 'Bitte Hersteller angeben...',
    options: [
      'Novotegra BayWa r.e',
      'IBC',
      'K2',
      'Lorenz',
      'Renusol',
      'S:Flex',
      'Schletter',
      'Esdec',
      'Würth',
      'SL Rack',
      'Sonstige',
    ],
  },
}
