import { deleteClientData } from '~/services/cache'
import { useAuth0 } from '@auth0/auth0-react'
import { useAppStore } from '~/stores'

export function useLogoutPrompt() {
  const { logout } = useAuth0()
  const { setDefaultModal, closeDefaultModal } = useAppStore.use.modalActions()
  const dataStoreStatus = useAppStore.use.dataStoreStatus()

  return function logoutPrompt() {
    setDefaultModal({
      title: 'Möchtest Du Dich wirklich abmelden?',
      description:
        'Wenn Du Dich abmeldest, werden alle nicht gespeicherten Daten und heruntergeladene Projekte gelöscht.',
      enforceAction: true,
      primaryButton: {
        label: 'Ausloggen',
        action: async () => {
          await deleteClientData()
          if (dataStoreStatus.network === 'offline') {
            logout({ openUrl: false })
          } else {
            logout({ logoutParams: { returnTo: origin } })
          }
          closeDefaultModal()
        },
      },
      secondaryButton: {
        label: 'Abbrechen',
        action: () => {
          closeDefaultModal()
        },
      },
    })
  }
}
