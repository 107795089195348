import { type GetBlobByUrl } from '~/hooks'
import { wait } from './wait'

export async function openCacheDocumentByUrlInTab(
  url: string | undefined | null,
  getBlobByUrl: GetBlobByUrl,
  secure: boolean = false,
  forcedMimeType?: string,
  showLoadingOverlay?: (loading: boolean | string) => void
) {
  if (!url) return

  try {
    if (showLoadingOverlay) showLoadingOverlay('Dokument wird geladen...')
    const blob = await getBlobByUrl(url, secure, forcedMimeType)
    if (showLoadingOverlay) showLoadingOverlay(false)

    if (!blob) return

    const uri = URL.createObjectURL(blob)
    const link = document.createElement('a')
    link.target = '_blank'
    link.href = uri
    document.body.appendChild(link)
    link.click()
    document.body.removeChild(link)
    await wait(500).finally(() => {
      URL.revokeObjectURL(uri)
    })
  } catch (e) {
    if (showLoadingOverlay) showLoadingOverlay(false)
    console.log('error fetching document', url)
  }
}
