import { Fragment } from 'react'
import { useNavigate, useRouteError } from 'react-router-dom'
import { Button } from '~/components'

export function ErrorPage() {
  const routeError = useRouteError()
  const navigate = useNavigate()
  const message = routeError instanceof Error ? routeError.message : 'Something went wrong...'
  return (
    <Fragment>
      <h1 className='text-headline-1'>Error Page</h1>
      <div>{message}</div>
      <div>
        <Button onClick={() => navigate(-1)} variant='outlined-dark'>
          Zurück
        </Button>
      </div>
    </Fragment>
  )
}
