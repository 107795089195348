import mitt, { type WildcardHandler } from 'mitt'
import { getProcessorFunction, type Task } from './taskProcessors'
import { datadogRum } from '@datadog/browser-rum'
import { serializeError } from 'serialize-error'
import {
  addTasksToIdb,
  getAllTasksFromIdb,
  removeTaskFromIdb,
  updateTasksInIdb,
} from './taskIdbOperations'

// Export only used in testsuite
export const ERROR_MESSAGES = {
  runnerNotFound: (taskType: Task['type']) => `Runner not found for task type ${taskType}`,
  taskExecutionFailed: 'Task execution failed',
} as const

type CreateEvent = {
  payload: Task[]
}

type SuccessEvent = {
  payload: Task
}

type FailEvent = {
  payload: Task
  error: any
}

type RetryEvent = {
  payload: Task
}

type EventMap = {
  create: CreateEvent
  success: SuccessEvent
  fail: FailEvent
  retry: RetryEvent
  start: undefined
  online: undefined
  process: undefined
}

export const emitter = mitt<EventMap>()

const onCreateEvent = async (event: CreateEvent) => {
  const { payload: tasks } = event
  const ok = await addTasksToIdb(tasks)
  if (!ok) return
  emitter.emit('process')
}

const onSuccessEvent = async (event: SuccessEvent) => {
  const { payload: task } = event
  await removeTaskFromIdb(task.id)
}

const onFailEvent = async (event: FailEvent) => {
  const { payload: task, error } = event
  datadogRum.addError(error, {
    message: ERROR_MESSAGES.taskExecutionFailed,
    taskType: task.type,
  })
  const updatedTask: Task = {
    ...task,
    state: 'Failed',
  }
  await updateTasksInIdb([updatedTask])
}

const onRetryEvent = async (event: RetryEvent) => {
  const { payload: task } = event
  const updatedTask: Task = {
    ...task,
    state: 'ReadyToProcess',
  }
  const ok = await updateTasksInIdb([updatedTask])
  if (!ok) return
  emitter.emit('process')
}

const onProcessEvent = async (getToken: () => Promise<string>) => {
  if (!navigator.onLine) return
  const tasks = await getAllTasksFromIdb()
  const updatedTasks: Task[] = tasks.map((task) => ({ ...task, state: 'InProgress' }))
  const ok = await updateTasksInIdb(updatedTasks)
  if (!ok) return
  for (const task of updatedTasks) {
    const runner = getProcessorFunction(task)
    if (!runner) {
      emitter.emit('fail', {
        payload: task,
        error: new Error(ERROR_MESSAGES.runnerNotFound(task.type)),
      })
      continue
    }
    runner(task as any, getToken)
      .then(() => emitter.emit('success', { payload: task }))
      .catch((error) => emitter.emit('fail', { payload: task, error }))
  }
}

const monitorTaskCompleted = (event: SuccessEvent) => {
  const {
    payload: { queuedAtIso, type },
  } = event
  const completedAtInMilliseconds = Date.now()
  const taskDurationInMilliseconds = completedAtInMilliseconds - new Date(queuedAtIso).valueOf()
  datadogRum.addAction('task_completed', {
    queuedAtIso,
    completedAtIso: new Date(completedAtInMilliseconds).toISOString(),
    taskDurationInMilliseconds,
    taskType: type,
  })
}

export const initEventHandlers = (
  getToken: () => Promise<string>,
  onEventProcessed?: (...args: Parameters<WildcardHandler<EventMap>>) => Promise<void>
) => {
  emitter.on('*', async (type, event) => {
    switch (type) {
      case 'create':
        await onCreateEvent(event as CreateEvent)
        break
      case 'success':
        await onSuccessEvent(event as SuccessEvent)
        monitorTaskCompleted(event as SuccessEvent)
        break
      case 'fail':
        await onFailEvent(event as FailEvent)
        break
      case 'retry':
        await onRetryEvent(event as RetryEvent)
        break
      case 'start':
      case 'online':
      case 'process':
        await onProcessEvent(getToken)
        break
      default:
        break
    }
    await onEventProcessed?.(type, event)
  })
}
