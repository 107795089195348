import { useEffect, useRef } from 'react'
import { useAppStore } from '~/stores/useAppStore'
import { useReportSyncStore } from '~/stores'
import { Hub } from 'aws-amplify/utils'
import { datadogRum } from '@datadog/browser-rum'
import { DataStore } from 'aws-amplify/datastore'
import { CONNECTION_STATE_CHANGE, ConnectionState } from 'aws-amplify/api'
import { changeIsInitialInUseAmplifyDataStore } from '~/hooks/useAmplifyDataStore'
import { changeIsInitialFlagInUseInitialSyncOrderIds } from '~/hooks/useInitialSyncOrderIds'
import { getLocalStorageValue, setLocalStorageValue } from '~/utils/localStorageUtils'

type DataStoreEventStorageSubscribed = {
  event: 'storageSubscribed'
}

type DataStoreEventNetworkStatus = {
  event: 'networkStatus'
  data: {
    active: boolean
  }
}

type DataStoreEventSubscriptionsEstablished = {
  event: 'subscriptionsEstablished'
}

type DataStoreEventSyncQueriesStarted = {
  event: 'syncQueriesStarted'
  data: {
    models: string[]
  }
}

type DataStoreEventModelSynced = {
  event: 'modelSynced'
  data: {
    model: {
      name: string
    }
    isFullSync: boolean
    isDeltaSync: boolean
    new: number
    updated: number
    deleted: number
  }
}

type DataStoreEventSyncQueriesReady = {
  event: 'syncQueriesReady'
}

type DataStoreEventReady = {
  event: 'ready'
}

type DataStoreEventOutboxMutationEnqueued = {
  event: 'outboxMutationEnqueued'
  data: {
    model: {
      name: string
    }
    element: {
      model: any
    }
  }
}

type DataStoreEventOutboxMutationProcessed = {
  event: 'outboxMutationProcessed'
  data: {
    model: {
      name: string
    }
    element: {
      model: any
      _version: number
      _lastChangedAt: number
      _deleted: boolean
    }
  }
}

type DataStoreEventOutboxStatus = {
  event: 'outboxStatus'
  data: {
    isEmpty: boolean
  }
}

type DataStoreEvent =
  | DataStoreEventStorageSubscribed
  | DataStoreEventNetworkStatus
  | DataStoreEventSubscriptionsEstablished
  | DataStoreEventSyncQueriesStarted
  | DataStoreEventModelSynced
  | DataStoreEventSyncQueriesReady
  | DataStoreEventReady
  | DataStoreEventOutboxMutationEnqueued
  | DataStoreEventOutboxMutationProcessed
  | DataStoreEventOutboxStatus

type ConnectionStateChange = {
  event: typeof CONNECTION_STATE_CHANGE
  data: {
    connectionState: ConnectionState
  }
}

type APIEvent = ConnectionStateChange

export const DATA_STORE_INITIAL_STATE_LOCAL_STORAGE_KEY = 'is_initial_data_store_sync'

let isInitial = getLocalStorageValue(DATA_STORE_INITIAL_STATE_LOCAL_STORAGE_KEY, true)

const restartDataStore = async () => {
  try {
    isInitial = false
    changeIsInitialInUseAmplifyDataStore()
    changeIsInitialFlagInUseInitialSyncOrderIds()
    setLocalStorageValue(DATA_STORE_INITIAL_STATE_LOCAL_STORAGE_KEY, false)
    await DataStore.stop()
    await DataStore.start()
  } catch (e) {
    console.error('Restarting DataStore failed, reloading the page: ', e)
    window.location.reload()
  }
}

const startDataStore = async () => {
  try {
    await DataStore.start()
  } catch (e) {
    console.error('Starting DataStore failed, reloading the page: ', e)
    window.location.reload()
  }
}

const addDatadogAction = (startedLoadingAt: number, actionName: string) => {
  const startedAtIso = new Date(startedLoadingAt).toISOString()
  const endedAtIso = new Date().toISOString()
  const duration = Date.now() - startedLoadingAt
  datadogRum.addAction(actionName, {
    startedAtIso,
    endedAtIso,
    duration,
  })
}

export function useDataStoreListen(): void {
  const updateDataStoreStatus = useAppStore.use.updateDataStoreStatus()
  const updateDataStoreFullStatus = useAppStore.use.updateDataStoreFullStatus()
  const dataStoreStatus = useAppStore.use.dataStoreStatus()
  const updateOutboxStatus = useReportSyncStore.use.updateOutboxStatus()
  const startedLoadingAtRef = useRef<number | null>(null)
  const startedLoadingFullAtRef = useRef<number | null>(null)

  useEffect(() => {
    const dataStoreUnsubscribe = Hub.listen<DataStoreEvent>('datastore', async (hubData) => {
      switch (hubData.payload.event) {
        case 'storageSubscribed':
          if (isInitial) {
            startedLoadingAtRef.current = Date.now()
            updateDataStoreStatus({
              ready: false,
            })
            return
          }
          startedLoadingFullAtRef.current = Date.now()
          // covers a use case when the page reloaded on not finished full sync
          // to avoid blocking the main view
          updateDataStoreStatus({
            ready: true,
          })
          updateDataStoreFullStatus({
            ready: false,
          })
          break
        case 'syncQueriesStarted':
          updateDataStoreStatus({
            models: hubData.payload.data.models.reduce<Record<string, boolean>>(
              (acc, model) => ({ ...acc, [model]: false }),
              {}
            ),
          })
          break
        case 'syncQueriesReady':
          if (isInitial) {
            updateDataStoreStatus({
              syncQueriesReady: true,
            })
            if (startedLoadingAtRef.current) {
              addDatadogAction(startedLoadingAtRef.current, 'data_sync_duration')
              startedLoadingAtRef.current = null
            }
            await restartDataStore()
            return
          }
          updateDataStoreFullStatus({
            syncQueriesReady: true,
          })
          if (startedLoadingFullAtRef.current) {
            addDatadogAction(startedLoadingFullAtRef.current, 'data_full_sync_duration')
            startedLoadingFullAtRef.current = null
          }
          break
        case 'modelSynced':
          const { name } = hubData.payload.data.model
          updateDataStoreStatus({
            models: {
              ...dataStoreStatus.models,
              [name]: true,
            },
          })
          break
        case 'outboxStatus':
          updateOutboxStatus(hubData.payload.data.isEmpty)
          break
        case 'networkStatus':
          updateDataStoreStatus({
            network: hubData.payload.data.active ? 'online' : 'offline',
          })
          break
        case 'ready':
          if (isInitial) {
            updateDataStoreStatus({
              ready: true,
            })
            return
          }
          updateDataStoreFullStatus({
            ready: true,
          })
          updateDataStoreStatus({
            ready: true,
          })
          break
      }
    })
    return () => dataStoreUnsubscribe()
  }, [dataStoreStatus.models, updateDataStoreFullStatus, updateDataStoreStatus, updateOutboxStatus])

  useEffect(() => {
    const apiUnsubscribe = Hub.listen<APIEvent>('api', async (data) => {
      const { payload } = data
      if (
        payload?.event === CONNECTION_STATE_CHANGE &&
        payload?.data?.connectionState === ConnectionState.Disconnected
      ) {
        if (window.navigator.onLine && dataStoreStatus.ready) {
          await startDataStore()
        }
      }
    })
    return () => apiUnsubscribe()
  }, [dataStoreStatus.ready])
}
