import { useCallback } from 'react'
import { useAuth0 } from '@auth0/auth0-react'
import { executeRunner, type Task } from '~/services'
import { useTaskStore } from '~/stores/useTaskStore'
import { type GetAccessToken } from '~/services/taskRunners/executeRunner'

export function useTaskManager() {
  const { getAccessTokenSilently } = useAuth0()
  const queueTasks = useCallback(
    (orderId: string, tasks: Array<Task>, group?: string) => {
      useTaskStore.getState().actions.queueTasks(orderId, tasks, group)
      void runTasks(getAccessTokenSilently)
    },
    [getAccessTokenSilently]
  )
  const queueTask = useCallback(
    (orderId: string, task: Task) => {
      queueTasks(orderId, [task])
    },
    [queueTasks]
  )

  return {
    runTasks,
    queueTask,
    queueTasks,
  }
}

async function runTasks(getToken: GetAccessToken) {
  const runNextTask = useTaskStore.getState().actions.runNextTask
  const executeRunnerWithToken = executeRunner(getToken)
  const runNext = await runNextTask(executeRunnerWithToken)
  if (runNext) {
    void runTasks(getToken)
  }
}
