import { useFormContext } from 'react-hook-form'
import { FormInput, RadioGroup } from '~/components'
import { type CommissioningForm } from './formValidationSchema'
import { useEffect } from 'react'

const FormPvSystem = () => {
  const { watch, setValue, getValues } = useFormContext<CommissioningForm>()
  const hasPvSystem = watch('pvSystem.hasPvSystem') === 'yes'

  useEffect(() => {
    if (!hasPvSystem) return
    const currentPowerLimitPercentage = getValues('gridManagement.powerLimitPercentage')
    if (!currentPowerLimitPercentage) setValue('gridManagement.powerLimitPercentage', 100)
  }, [hasPvSystem, setValue, getValues])

  return (
    <div className='flex flex-col gap-4'>
      <RadioGroup label='Solaranlage' orientation='horizontal'>
        <FormInput type='radio' name='pvSystem.hasPvSystem' label='Ja' value='yes' />
        <FormInput type='radio' name='pvSystem.hasPvSystem' label='Nein' value='no' />
      </RadioGroup>
      <FormInput
        type='text'
        name='pvSystem.moduleManufacturer'
        label='Module Hersteller'
        disabled={!hasPvSystem}
      />
      <FormInput
        type='text'
        name='pvSystem.moduleType'
        label='Module Modell'
        disabled={!hasPvSystem}
      />
      <FormInput
        type='integer'
        min={0}
        name='pvSystem.amountOfModules'
        label='Module Anzahl'
        disabled={!hasPvSystem}
      />
      <FormInput
        type='decimal'
        name='pvSystem.installedCapacityInKwp'
        label='Installierte Leistung'
        suffix='kWp'
        disabled={!hasPvSystem}
      />
      <RadioGroup label='Einbindung in das äußere Blitzschutzsystem' orientation='horizontal'>
        <FormInput
          type='radio'
          name='pvSystem.hasLightningProtectionSystemIntegration'
          label='Ja'
          value='yes'
          disabled={!hasPvSystem}
        />
        <FormInput
          type='radio'
          name='pvSystem.hasLightningProtectionSystemIntegration'
          label='Nein'
          value='no'
          disabled={!hasPvSystem}
        />
      </RadioGroup>
      <RadioGroup
        label='Die Betriebsparameter der WR sind entsprechend der örtlichen Anschlussbedingungen (VDE-AR-N 4105) eingestellt'
        orientation='horizontal'
      >
        <FormInput
          type='radio'
          name='pvSystem.hasPlantOnLowVoltageGridConditions'
          label='Ja'
          value='yes'
          disabled={!hasPvSystem}
        />
        <FormInput
          type='radio'
          name='pvSystem.hasPlantOnLowVoltageGridConditions'
          label='Nein'
          value='no'
          disabled={!hasPvSystem}
        />
      </RadioGroup>
    </div>
  )
}

export default FormPvSystem
