import { type InstallerCache } from '~/services/cache'
import { type GetAccessToken } from '~/services/taskRunners/executeRunner'

export interface DownloadTask {
  runner: 'download'
  payload: {
    url: string
    mimeType: string
    secure?: boolean
  }
}

export async function downloadRunner(
  task: DownloadTask,
  getToken: GetAccessToken,
  cache: InstallerCache
): Promise<undefined> {
  const { url, secure, mimeType } = task.payload
  const token = secure ? await getToken() : ''
  await cache.getBlobByUrl(url, token, mimeType)
  return
}
