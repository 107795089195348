import { type GetAccessToken } from '~/services/taskRunners/executeRunner'
import { type InstallerCache } from '~/services/cache'
import { getDocumentServiceUrl } from '~/config/documents'
import { ComReport } from '~/models'
import { useReportSyncStore } from '~/stores'
import { DataStore } from 'aws-amplify/datastore'
import { useAppStore } from '~/stores/useAppStore'
import { datadogRum } from '@datadog/browser-rum'
import { serializeError } from 'serialize-error'

export type UploadCommissioningPdfTask = {
  runner: 'upload-com-pdf'
  payload: {
    blobId: string
    customerHash: string
    orderId: string
    activityId: string
    queuedAtIso: string
  }
}

export const uploadCommissioningPdfRunner = async (
  task: UploadCommissioningPdfTask,
  getToken: GetAccessToken,
  cache: InstallerCache
) => {
  const { blobId, customerHash, orderId, activityId, queuedAtIso } = task.payload
  const token = await getToken()
  const blob = await cache.getBlobById(blobId)

  if (blob === undefined) {
    throw Error('Could not find the blobId in IndexedDB')
  }

  const { uid } = await saveBlobInDocumentService(token, blob, customerHash, orderId)
  const completedAtInMilliseconds = Date.now()
  const taskDurationInMilliseconds = completedAtInMilliseconds - new Date(queuedAtIso).valueOf()
  datadogRum.addAction('task_completed', {
    queuedAtIso,
    completedAtIso: new Date(completedAtInMilliseconds).toISOString(),
    taskDurationInMilliseconds,
    taskType: 'uploadComReportPdf',
  })
  await cache.clearById(blobId)
  const latestComReport = await getLatestComReportByActivityId(activityId)

  const updatedReport = ComReport.copyOf(latestComReport, (draft) => {
    draft.pdfId = uid
  })

  return DataStore.save(updatedReport)
}

const saveBlobInDocumentService = async (
  token: string,
  blob: Blob,
  customerHash: string,
  orderId: string
): Promise<{ uid: string }> => {
  const formData = new FormData()
  const headers = new Headers()
  const baseUrl = getDocumentServiceUrl()
  const url = new URL(`${baseUrl}/customers/${customerHash}/files`)
  url.searchParams.set('transactionId', orderId)

  formData.append('file', blob)
  headers.set('Authorization', `Bearer ${token}`)
  headers.set('x-auth-method', 'auth0')

  const startedAtInMs = Date.now()
  try {
    const response = await fetch(url, { method: 'POST', body: formData, headers })
    const endedAtInMs = Date.now()
    datadogRum.addAction('document_upload_to_server_success', {
      startedAtIso: new Date(startedAtInMs).toISOString(),
      endedAtIso: new Date(endedAtInMs).toISOString(),
      durationInMilliseconds: endedAtInMs - startedAtInMs,
      documentSizeInBytes: blob.size,
      documentType: blob.type,
    })
    const result = await response.json()
    return { uid: result.name }
  } catch (error) {
    const endedAtInMs = Date.now()
    datadogRum.addAction('document_upload_to_server_failure', {
      startedAtIso: new Date(startedAtInMs).toISOString(),
      endedAtIso: new Date(endedAtInMs).toISOString(),
      durationInMilliseconds: endedAtInMs - startedAtInMs,
      documentSizeInBytes: blob.size,
      documentType: blob.type,
      error: serializeError(error),
    })
    throw error
  }
}

const getLatestComReport = (comReport: ComReport) => {
  return new Promise<ComReport>((resolve) => {
    const isOffline = useAppStore.getState().dataStoreStatus.network === 'offline'
    const outboxIsEmpty = useReportSyncStore.getState().isEmpty

    if (outboxIsEmpty || isOffline) return resolve(comReport)

    const unsubscribe = useReportSyncStore.subscribe(async (state) => {
      if (!state.isEmpty) return

      const comReportFromDataStore = await DataStore.query(ComReport, comReport.id)

      resolve(comReportFromDataStore ?? comReport)
      unsubscribe()
    })
  })
}

const getLatestComReportByActivityId = async (activityId: string) => {
  const comReports = await DataStore.query(ComReport, (c) => c.activity_uid.eq(activityId))

  if (!comReports.length) throw Error(`Could not find a com report for activity id ${activityId} `)

  return getLatestComReport(comReports[0])
}
