import { Button, Icon } from '~/components'
import { useNavigate } from 'react-router-dom'
import { type ReactNode } from 'react'
import { type ReportItemEntity } from '~/models'
import { type ActivityType } from '~/stores'

interface CameraOverlayProps {
  name: string
  orderId: string
  activityType: ActivityType
  item: ReportItemEntity
  children: ReactNode
}

export function CanvasOverlay(props: CameraOverlayProps) {
  const width = 'w-12'
  const navigate = useNavigate()

  return (
    <div className='absolute top-8 flex w-full items-center justify-between px-6'>
      <div className={width} />
      <div className='flex flex-col items-center font-semibold'>
        <div className='mb-1 flex items-baseline gap-1'>
          <Icon icon='general:marker-pin' size='xs' />
          <span>{props.name}</span>
        </div>
        {props.children}
      </div>
      <div className={width}>
        <Button
          isIconButton
          variant='filled-dark'
          size='lg'
          onClick={() => navigate(`/order/${props.orderId}/${props.activityType}`)}
        >
          <Icon icon='general:x-close' />
        </Button>
      </div>
    </div>
  )
}
