import { Path, Rect, Svg, Text, View } from '@react-pdf/renderer'
import { type CommissioningForm } from '../formValidationSchema'
import { Section, SectionSubtitle } from './SharedWrappers'

type ItemCheckboxProps = {
  isChecked?: boolean
  label: string
}

const ItemCheckbox = (props: ItemCheckboxProps) => {
  const { isChecked, label } = props
  return (
    <View
      style={{
        flexDirection: 'row',
        flexWrap: 'nowrap',
        width: '100%',
        gap: 12,
        alignItems: 'center',
      }}
    >
      <Svg width='14' height='14' viewBox='0 0 14 14' fill='none'>
        <Rect
          x='0.5'
          y='0.5'
          width='13'
          height='13'
          rx='3.5'
          ry='3.5'
          fill='none'
          stroke='#757575'
          strokeLinejoin='round'
        />
        {isChecked && (
          <Path
            fill='none'
            d='M10.267 4.55L5.775 9.041 3.733 7'
            stroke='#616161'
            strokeWidth='2'
            strokeLineCap='round'
            strokeLinejoin='round'
          />
        )}
      </Svg>
      <Text
        style={{
          fontWeight: 400,
          fontSize: 10,
        }}
      >
        {label}
      </Text>
    </View>
  )
}

const PdfSectionBriefingOfOperation = (props: {
  data: CommissioningForm['briefingOfOperation']
}) => {
  const {
    data: {
      isDamageFreeInstallation,
      hasProperCableRoutingInspection,
      hasComponentInstallationInspection,
      hasCustomerSwitchgearPVSticker,
      isCleanSiteNoWaste,
      hasComponentExplanation,
      hasShutdownExplanationACDC,
      hasSystemMonitoringIntroduction,
      hasOperationIndicators,
      hasMaintenanceAndCareGuidancePVA,
    },
  } = props
  return (
    <Section label='Einweisung des Betriebes'>
      <SectionSubtitle>Abschnitt 1</SectionSubtitle>
      <View style={{ gap: 8 }}>
        <ItemCheckbox
          label='Anlage und Gebäude ohne sichtbare Schäden durch die Montage'
          isChecked={isDamageFreeInstallation}
        />
        <ItemCheckbox
          label='Sichtprüfung auf ordnungsgemäße Kabelführung'
          isChecked={hasProperCableRoutingInspection}
        />
        <ItemCheckbox
          label='Sichtprüfung Installation der Komponente'
          isChecked={hasComponentInstallationInspection}
        />
        <ItemCheckbox
          label='PV Aufkleber auf Schaltschrank des Kunden'
          isChecked={hasCustomerSwitchgearPVSticker}
        />
        <ItemCheckbox
          label='Die Baustelle wurde sauber und ohne Abfall hinterlassen'
          isChecked={isCleanSiteNoWaste}
        />
      </View>
      <SectionSubtitle>Abschnitt 2</SectionSubtitle>
      <View style={{ gap: 8 }}>
        <ItemCheckbox
          label='Erklärung der Komponenten und deren Funktionen'
          isChecked={hasComponentExplanation}
        />
        <ItemCheckbox
          label='Erklärung der Abschalteinrichtung (AC, DC)'
          isChecked={hasShutdownExplanationACDC}
        />
        <ItemCheckbox
          label='Vorstellung des Anlagen-Monitorings'
          isChecked={hasSystemMonitoringIntroduction}
        />
        <ItemCheckbox
          label='Kontrollmöglichektein die auf einen korrekten Betrieb der Anlage hinweisen'
          isChecked={hasOperationIndicators}
        />
        <ItemCheckbox
          label='Hinweise auf Wartung und Pflege der PVA'
          isChecked={hasMaintenanceAndCareGuidancePVA}
        />
      </View>
    </Section>
  )
}

export default PdfSectionBriefingOfOperation
