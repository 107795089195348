import { useAuthenticatedCache } from '~/hooks'
import { useEffect, useState } from 'react'
import { localImagePrefix } from '~/components/Documentation/utitlities'
import { createCache } from '~/services/cache'

interface ImageFromUid {
  cacheId: string
  url: string
  imageUid?: string
  thumbnail?: boolean
}

export function useImageFromUid(args: ImageFromUid): string | undefined {
  const { cacheId, url, imageUid, thumbnail } = args
  const { getBlobByUrl } = useAuthenticatedCache(cacheId)
  const [imageUrl, setImageUrl] = useState<string | undefined>()
  useEffect(() => {
    async function resolveIdToUrl(id?: string) {
      if (id === undefined) {
        setImageUrl(undefined)
        return
      }
      const cache = createCache(cacheId)
      const blob = id.includes(localImagePrefix)
        ? await cache.getBlobById(id)
        : await getBlobByUrl(`${url}/${id}${thumbnail ? '?thumbnail=true' : ''}`, true)

      if (blob === undefined) {
        setImageUrl('not-found')
        return
      }
      objectUrl = URL.createObjectURL(blob)
      setImageUrl(objectUrl)
    }
    let objectUrl: string
    void resolveIdToUrl(imageUid)
    return () => {
      if ([undefined, '', 'not-found'].includes(objectUrl)) {
        return
      }
      URL.revokeObjectURL(objectUrl)
    }
  }, [imageUid, url, cacheId, getBlobByUrl, thumbnail])
  return imageUrl
}
