import { failingConditions, useTaskStore } from '~/stores/useTaskStore'
import { usePendingTasks } from '~/hooks'

export function TaskManagerBody() {
  const conditions = useTaskStore.use.conditions()
  const failedConditions = failingConditions(conditions)
  const currentTask = useTaskStore.use.current()
  const progress = useTaskStore.use.progress()
  const pendingTasks = usePendingTasks()

  return (
    <div className='text-description-1'>
      <div>Pending tasks: {pendingTasks.length}</div>
      <div>Current task: {currentTask ? currentTask.orderId : 'none'}</div>

      {failedConditions.length > 0 && (
        <div>
          Failing conditions: {failedConditions.map((condition) => condition[0]).join(', ')}
        </div>
      )}

      <div>
        Activity task progress: <pre>{JSON.stringify(progress, null, 2)}</pre>
      </div>
    </div>
  )
}
