import { FormInput } from '~/components'

const FormGridManagement = () => {
  return (
    <div className='flex flex-col gap-4'>
      <p className='text-static-lg-semibold'>
        Ändere die Wirkleistungsbegrenzung nur bei Abweichungen von 100%
      </p>
      <FormInput
        type='decimal'
        name='gridManagement.powerLimitPercentage'
        label='Wert'
        suffix='%'
      />
    </div>
  )
}

export default FormGridManagement
