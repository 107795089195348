import { type OrderDocument } from '~/models'
import { CacheDocumentImage } from '~/components/index'

interface ImageWallProps {
  cacheId: string
  documents: OrderDocument[]
  onSelectedImageChange: (index: number | undefined) => void
  secure?: boolean
}

export function ImageWall(props: ImageWallProps) {
  const documents = prepareImageWall(props.documents)

  return (
    <div className='flex grow flex-col overflow-auto'>
      {documents.map((layout: LayoutTemplate, key: number) => {
        const LayoutComponent = layoutComponentsMap[layout.type]
        return (
          <LayoutComponent
            cacheId={props.cacheId}
            documents={layout.documents}
            secure={props.secure}
            key={key}
            handleClick={props.onSelectedImageChange}
            nextIndex={layout.nextIndex}
          />
        )
      })}
    </div>
  )
}

const layoutComponentsMap = {
  single: SingleImageLayout,
  double: DoubleImagesLayout,
  triple: TripleImagesLayout,
}

interface ImageLayoutProps {
  cacheId: string
  documents: OrderDocument[]
  handleClick: (index: number) => void
  nextIndex: number
  secure?: boolean
}

function SingleImageLayout(props: ImageLayoutProps) {
  const { cacheId, secure = true, documents, handleClick, nextIndex } = props
  return (
    <div className='mb-4 w-full'>
      <CacheDocumentImage
        cacheId={cacheId}
        url={documents[0].url + '?thumbnail=true'}
        secure={secure}
        handleClick={handleClick}
        index={nextIndex}
        className='aspect-video w-full'
      />
    </div>
  )
}

function DoubleImagesLayout(props: ImageLayoutProps) {
  const { cacheId, secure = true, documents, handleClick, nextIndex } = props

  return (
    <div className='mb-4 w-full columns-2 gap-4'>
      <CacheDocumentImage
        cacheId={cacheId}
        url={documents[0].url + '?thumbnail=true'}
        secure={secure}
        handleClick={handleClick}
        index={nextIndex}
        className='aspect-video'
      />
      {documents[1] && (
        <CacheDocumentImage
          cacheId={cacheId}
          url={documents[1].url + '?thumbnail=true'}
          secure={secure}
          handleClick={handleClick}
          index={nextIndex + 1}
          className='aspect-video'
        />
      )}
    </div>
  )
}

function TripleImagesLayout(props: ImageLayoutProps) {
  const { cacheId, secure = true, documents, handleClick, nextIndex } = props

  return (
    <div className='mb-4 flex w-full flex-row gap-4'>
      <div className='flex flex-1 flex-col gap-4'>
        <CacheDocumentImage
          cacheId={cacheId}
          url={documents[0].url + '?thumbnail=true'}
          secure={secure}
          handleClick={handleClick}
          index={nextIndex}
          className='aspect-video'
        />
        {documents[1] && (
          <CacheDocumentImage
            cacheId={cacheId}
            url={documents[1].url + '?thumbnail=true'}
            secure={secure}
            handleClick={handleClick}
            index={nextIndex + 1}
            className='aspect-video'
          />
        )}
      </div>
      <div className='flex-1'>
        {documents[2] && (
          <CacheDocumentImage
            cacheId={cacheId}
            url={documents[2].url + '?thumbnail=true'}
            secure={secure}
            handleClick={handleClick}
            index={nextIndex + 2}
            className='h-full'
          />
        )}
      </div>
    </div>
  )
}

type ImageWallLayoutType = 'single' | 'double' | 'triple'
interface LayoutTemplate {
  type: 'single' | 'double' | 'triple'
  documents: OrderDocument[]
  nextIndex: number
}

function prepareImageWall(documents: OrderDocument[]) {
  const layout: LayoutTemplate[] = []
  let double = emptyType('double')
  let triple = emptyType('triple')

  let nextIndex = 0

  documents.forEach((doc: OrderDocument, key) => {
    switch (key % 6) {
      case 0:
        layout.push({ type: 'single', documents: [doc], nextIndex: nextIndex })
        nextIndex++
        break
      case 1:
        double.documents.push(doc)
        break
      case 2:
        double.documents.push(doc)
        double.nextIndex = nextIndex
        layout.push(double)
        nextIndex += 2
        double = emptyType('double')
        break
      case 3:
      case 4:
        triple.documents.push(doc)
        break
      case 5:
        triple.documents.push(doc)
        triple.nextIndex = nextIndex
        layout.push(triple)
        nextIndex += 3
        triple = emptyType('triple')
        break
    }
  })

  if (double.documents.length > 0) {
    double.nextIndex = nextIndex
    layout.push(double)
  } else if (triple.documents.length > 0) {
    triple.nextIndex = nextIndex
    layout.push(triple)
  }

  return layout

  function emptyType(type: ImageWallLayoutType): LayoutTemplate {
    return { type: type, documents: new Array<OrderDocument>(), nextIndex: 0 }
  }
}
