import { useTaskStore } from '~/stores/useTaskStore'
import { type DispatchWithoutAction, Fragment, useReducer } from 'react'
import { TaskManagerActions } from './TaskManagerActions'
import { TaskManagerBody } from './TaskManagerBody'

export function TaskManagerDevTool() {
  const conditions = useTaskStore.use.conditions()
  const [expanded, toggle] = useReducer((state) => !state, false)

  const [visible, toggleVisibility] = useReducer((show) => !show, false)
  addDevTools(toggleVisibility)

  const bgColor = conditions.online ? 'bg-green' : 'bg-red'
  const height = expanded ? '' : 'h-4'
  const show = conditions.authenticated && conditions.datastoreReady && visible

  return (
    <Fragment>
      {show && (
        <div className={`absolute top-0 z-50 w-full ${height} ${bgColor}`} onClick={toggle}>
          {expanded && (
            <div className='p-2'>
              <div className='text-headline-6'>Task Manager</div>
              <TaskManagerActions />
              <TaskManagerBody />
            </div>
          )}
        </div>
      )}
    </Fragment>
  )
}

// This is for dev purposes so that we can toggle the TaskManagerDevTools
declare global {
  interface Window {
    toggleTaskManager: () => void
  }
}
export function addDevTools(toggle: DispatchWithoutAction): void {
  window['toggleTaskManager'] = () => toggle()
}
