export function Watermark(props: { opacity?: number }) {
  return (
    <div className='z-1 absolute inset-4 flex flex-col items-center justify-center'>
      <svg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 317 310' fill='none'>
        <g opacity={props.opacity ?? 0.28}>
          <path
            d='M97.4996 226.924L101.147 218.551C109.359 199.699 123.057 183.623 140.538 172.324C158.019 161.024 178.511 155 199.466 155L317 155C317.001 120.334 305.119 86.6693 283.252 59.385C261.385 32.1007 230.797 12.7725 196.375 4.48957C161.954 -3.79334 125.686 -0.552778 93.3697 13.6933C61.053 27.9394 34.5528 52.3685 18.1045 83.0763L219.5 83.0763L215.853 91.4488C207.641 110.301 193.943 126.377 176.462 137.676C158.981 148.976 138.489 155 117.534 155L5.98233e-08 155C-0.000973527 189.666 11.8815 223.331 33.7481 250.615C55.6148 277.899 86.2034 297.227 120.625 305.51C155.046 313.793 191.314 310.553 223.63 296.307C255.947 282.061 282.447 257.632 298.895 226.924L97.4996 226.924Z'
            fill='#BEDCE1'
          />
        </g>
      </svg>
    </div>
  )
}
