import { type OrderDocument } from '~/models'
import { useNavigate } from 'react-router-dom'
import { CacheDocumentImage, Tag } from '~/components'
import { type Reportable } from '~/services/ReportService'
import { DownloadOrderDocuments } from '~/components/Order'
import houseCoverImageUrl from '~/assets/cover_placeholder.jpg'
import { type ProductWithQuantity } from '~/hooks/useOrder'

interface OrderCoverImageProps {
  documents: OrderDocument[]
  products: ProductWithQuantity[]
  imageUrl?: string
  orderId: string
  reports: Reportable[]
}

export function OrderCoverImage(props: OrderCoverImageProps) {
  const { documents, imageUrl, products, orderId, reports } = props
  const navigate = useNavigate()
  const images = documents?.filter((doc) => doc.mimeType.startsWith('image')) || []
  const thumbnailUrl = imageUrl && imageUrl + '?thumbnail=true'

  return (
    <div className='relative'>
      <CacheDocumentImage
        cacheId='thumbnails'
        url={thumbnailUrl}
        placeholderLocalSrc={houseCoverImageUrl}
        secure={true}
        handleClick={() => {
          if (documents.length === 0) return
          navigate('/gallery/' + orderId)
        }}
        className='aspect-video h-64 w-full'
      />
      {documents && documents.length > 0 && (
        <>
          <DownloadOrderDocuments
            orderId={orderId}
            documents={documents}
            products={products}
            reports={reports}
            taskGroup='orderDocuments'
          />
          <Tag
            small
            className='absolute bottom-4 right-4'
            label={String(images.length)}
            icon='general:image'
          />
        </>
      )}
    </div>
  )
}
