import { Button, Icon, Loader, Tag } from '~/components'
import { useAppStore } from '~/stores/useAppStore'
import { type MouseEvent, type ReactNode } from 'react'
import { ReportItemEntityStatus } from '~/models'
import { useImageFromUid } from '~/hooks'
import { localImagePrefix } from '~/components/Documentation/utitlities'

interface ThumbnailProps extends Omit<ThumbnailContainerProps, 'children'> {
  status: ReportItemEntityStatus | keyof typeof ReportItemEntityStatus
  removeImage: () => void
  cacheId: string
  url: string
  imageUid?: string
  variant?: 'survey'
}

export function Thumbnail(props: ThumbnailProps) {
  const { setDefaultModal, closeDefaultModal } = useAppStore.use.modalActions()

  const imageUrl = useImageFromUid({
    cacheId: props.cacheId,
    url: props.url,
    imageUid: props.imageUid,
    thumbnail: true,
  })

  function onRemove(e: MouseEvent<HTMLElement>) {
    e.stopPropagation()
    props.onSelect()
    setDefaultModal({
      title: 'Löschen bestätigen',
      description: 'Dieses Bild wirklich löschen?',
      enforceAction: true,
      primaryButton: {
        label: 'Bestätigen',
        action: () => {
          closeDefaultModal()
          props.removeImage()
        },
      },
      secondaryButton: {
        label: 'Abbrechen',
        action: () => {
          closeDefaultModal()
        },
      },
    })
  }
  const showLoading = props.imageUid && imageUrl === undefined
  const canRemove =
    props.imageUid &&
    (props.variant !== 'survey' ||
      (props.variant === 'survey' && props.status !== ReportItemEntityStatus.READY))
  const uploaded = props.imageUid && !props.imageUid.includes(localImagePrefix)
  const pending = props.imageUid && !uploaded
  const notFound = imageUrl === 'not-found'

  return (
    <ThumbnailContainer
      onSelect={props.onSelect}
      selected={props.selected}
      imageUrl={imageUrl}
      context={props.variant}
    >
      {canRemove && (
        <div className='absolute right-[3px] top-[3px]'>
          <Button
            isIconButton
            variant='filled-destructive'
            onClick={onRemove}
            className='!h-7 !w-7'
          >
            <Icon icon='general:trash' />
          </Button>
        </div>
      )}
      {pending && (
        <div className='text absolute bottom-[1px] left-1/2 -translate-x-1/2'>
          <Tag small label='Ausstehend' tagColor='tag-mist' />
        </div>
      )}
      {showLoading && (
        <div className='flex h-full items-center justify-center text-[2rem]'>
          <Loader />
        </div>
      )}
      {uploaded && (
        <div className='absolute bottom-[1px] left-1/2 -translate-x-1/2'>
          <Tag small label='Gespeichert' tagColor='tag-success' />
        </div>
      )}
      {notFound && (
        <div className='text-description-1 flex h-full w-full flex-col items-center justify-center gap-1 pt-2'>
          <Icon icon='files:image-x' size='lg' />
          Bildfehler
        </div>
      )}
    </ThumbnailContainer>
  )
}

export function SketchThumbnail(props: Omit<ThumbnailContainerProps, 'imageUrl'>) {
  return <ThumbnailContainer onSelect={props.onSelect}>{props.children}</ThumbnailContainer>
}

interface ThumbnailContainerProps {
  onSelect: () => void
  children: ReactNode
  selected?: boolean
  imageUrl?: string
  context?: 'survey'
}

function ThumbnailContainer(props: ThumbnailContainerProps) {
  const borderColor = props.selected ? 'border-accent' : 'border-white'
  const context = props.context ?? 'default'

  return (
    <div
      className={`relative bg-black ${borderColor} ${thumbnailStyle[context]}`}
      onClick={props.onSelect}
      {...(props.imageUrl && {
        style: {
          backgroundImage: `url('${props.imageUrl}')`,
          backgroundPosition: 'center center',
          backgroundSize: 'cover',
          textAlign: 'right',
        },
      })}
    >
      {props.children}
    </div>
  )
}

const thumbnailStyle = {
  default: 'flex-shrink-0 h-[92px] w-[100px] mx-0.5 first:ml-4 last:mr-4 border-2 rounded-sm',
  survey: 'aspect-[1/1] border-0 rounded',
}
