import { useNavigate, useParams } from 'react-router-dom'
import { type CommissioningDocumentationParams } from './CommissioningDocumentationPage'
import { useAppStore } from '~/stores'
import {
  type Activity,
  ComFormBatteryStorage,
  ComFormBriefingOfOperation,
  ComFormCompass,
  ComFormElectricalMaasurement,
  ComFormGeneralData,
  ComFormGridManagement,
  ComFormInverter,
  ComFormPvGenerator,
  ComFormPvGeneratorMppt,
  ComFormPvGeneratorMpptStringElectricalValues,
  ComFormPvSystem,
  ComFormSignature,
  ComFormSignatureItem,
  ComFormWallbox,
  ComReport,
  type ComReportDefaultData,
  ComReportFormData,
  ComReportState,
  type Order,
} from '~/models'
import { type SubmitErrorHandler, type SubmitHandler, useForm, useWatch } from 'react-hook-form'
import { type SyntheticEvent, useCallback, useEffect, useMemo, useRef } from 'react'
import { DataStore } from 'aws-amplify/datastore'
import { toast } from '~/components'
import * as Sentry from '@sentry/react'
import { cloneDeep, debounce } from 'lodash-es'
import { pdf } from '@react-pdf/renderer'
import PdfCommissioningContent from './Pdf/PdfCommissioningContent'
import { yupResolver } from '@hookform/resolvers/yup'
import { type CommissioningForm, commissioningFormSchema } from './formValidationSchema'
import { taskManager } from '~/utils/taskManager'
import { nanoid } from 'nanoid'

const DEBOUNCE_AUTOSAVE_IN_MS = 300

const useCommissioningForm = () => {
  const { orderId = '', activityId = '' } = useParams<CommissioningDocumentationParams>()
  const navigate = useNavigate()
  const order = useAppStore.use.orders().find((order) => order.id === orderId)
  const activity = useAppStore.use.activities().find((activity) => activity.id === activityId)
  const comReportRef = useRef<ComReport>()

  const defaultValues = useMemo<any>(() => {
    const { comReports, comReportDefaultData } = useAppStore.getState()
    const comReport = comReports.find((comReport) => comReport.activity_uid === activityId)
    const comDefaultData = comReportDefaultData
      .filter((comReportDefault) => comReportDefault.order_uid === order?.id)
      .sort((a, b) => (new Date(b.updated_at) > new Date(a.updated_at) ? 1 : -1))
      .at(0)
    if (comReport) return comReportValuesToFormValues(comReport.formData)
    return getInitialValuesFromDefaultData(comDefaultData, order)
  }, [order, activityId])

  const formMethods = useForm({
    defaultValues,
    mode: 'onSubmit',
    reValidateMode: 'onSubmit',
    resolver: yupResolver(commissioningFormSchema),
  })
  const formValues = useWatch<CommissioningForm>({ control: formMethods.control })

  const onSaveDraft = useCallback(
    async (values: CommissioningForm) => {
      try {
        if (!order || !activity) throw new Error('Invalid order or activity')
        if (comReportRef.current?.state === ComReportState.SUBMITTED) return
        if (!comReportRef.current) {
          const newComReport = createNewComReport(order, activity, {
            formData: new ComReportFormData(formValuesToComReportValues(values)),
          })
          await DataStore.save(newComReport)
          return
        }
        const updatedComReport = ComReport.copyOf(comReportRef.current, (draft) => {
          draft.state = ComReportState.DRAFT
          draft.formData = new ComReportFormData(formValuesToComReportValues(values))
        })
        await DataStore.save(updatedComReport)
      } catch (error) {
        Sentry.captureException(error, { extra: { orderId, activityId } })
      }
    },
    [order, activity, orderId, activityId]
  )

  const debouncedOnSaveDraft = useMemo(
    () => debounce(onSaveDraft, DEBOUNCE_AUTOSAVE_IN_MS),
    [onSaveDraft]
  )

  useEffect(() => {
    ;(async () => {
      try {
        await debouncedOnSaveDraft(formValues as CommissioningForm)
      } catch (error) {
        console.error(error)
      }
    })()
    return () => {
      debouncedOnSaveDraft.cancel()
    }
  }, [formValues, debouncedOnSaveDraft])

  useEffect(() => {
    const observer = DataStore.observeQuery(ComReport, (comReport) =>
      comReport.activity_uid.eq(activityId)
    ).subscribe({
      next: ({ items }) => {
        if (!activityId) return
        comReportRef.current = items[0]
      },
    })

    return () => {
      observer.unsubscribe()
    }
  }, [activityId])

  const onSubmitSuccess: SubmitHandler<CommissioningForm> = async (values) => {
    try {
      if (!order || !activity) throw new Error('Invalid order or activity')
      if (!order.customer_hash) throw new Error('Invalid customer_hash')
      if (!comReportRef.current || comReportRef.current.state === ComReportState.SUBMITTED)
        throw new Error('Tried to submit an already submitted report')
      const blob = await pdf(<PdfCommissioningContent formValues={values} />).toBlob()

      const updatedComReport = ComReport.copyOf(comReportRef.current, (draft) => {
        draft.state = ComReportState.SUBMITTED
        draft.formData = new ComReportFormData(formValuesToComReportValues(values))
        draft.submittedAt = new Date().toISOString()
      })
      await DataStore.save(updatedComReport)

      taskManager.create([
        {
          id: nanoid(),
          queuedAtIso: new Date().toISOString(),
          type: 'COM_REPORT_PDF',
          payload: {
            blob,
            orderId,
            customerHash: order.customer_hash,
            reportId: comReportRef.current.id,
          },
        },
      ])

      navigate(`/documentation/${orderId}/${activityId}/com/submitted`)
    } catch (error) {
      Sentry.captureException(error, { extra: { orderId, activityId } })
      toast.spawn({
        variant: 'error',
        headline: 'Fehler bei der Formularübermittlung',
        content:
          'Entschuldigung, es gab ein Problem beim Absenden des Formulars. Bitte überprüfen Sie Ihre Eingaben und versuchen Sie es erneut.',
      })
    }
  }

  const onSubmitError: SubmitErrorHandler<CommissioningForm> = () => {
    toast.spawn({
      variant: 'error',
      headline: 'Fehler bei der Formularübermittlung',
      content:
        'Entschuldigung, es gab ein Problem beim Absenden des Formulars. Bitte gehen Sie einige Schritte zurück und überprüfen Sie fehlende Felder.',
    })
  }

  return {
    formMethods,
    onSubmit: async (e?: SyntheticEvent) => {
      formMethods.clearErrors()
      await formMethods.handleSubmit(onSubmitSuccess, onSubmitError)(e)
    },
  }
}

export default useCommissioningForm

const createNewComReport = (
  order: Order,
  activity: Activity,
  partialComReport?: Partial<ComReport>
) => {
  if (!order.customer_hash) throw new Error('Invalid customer_hash')
  return new ComReport({
    order_uid: order.id,
    activity_uid: activity.id,
    partner_uid: activity.partner_uid,
    order_hash: order.order_hash,
    customer_hash: order.customer_hash,
    state: ComReportState.DRAFT,
    formData: new ComReportFormData({
      generalData: new ComFormGeneralData({}),
      pvSystem: new ComFormPvSystem({ hasPvSystem: false }),
      gridManagement: new ComFormGridManagement({
        powerLimitPercentage: 100,
      }),
      batteryStorage: new ComFormBatteryStorage({ hasBatteryStorage: false }),
      wallbox: new ComFormWallbox({ hasWallbox: false }),
      briefingOfOperation: new ComFormBriefingOfOperation({
        isDamageFreeInstallation: false,
        hasProperCableRoutingInspection: false,
        hasComponentInstallationInspection: false,
        hasCustomerSwitchgearPVSticker: false,
        isCleanSiteNoWaste: false,
        hasComponentExplanation: false,
        hasShutdownExplanationACDC: false,
        hasSystemMonitoringIntroduction: false,
        hasOperationIndicators: false,
        hasMaintenanceAndCareGuidancePVA: false,
      }),
      signature: new ComFormSignature({
        installer: new ComFormSignatureItem({
          date: new Date().toISOString().split('T')[0],
        }),
        client: new ComFormSignatureItem({
          date: new Date().toISOString().split('T')[0],
        }),
      }),
    }),
    ...(partialComReport ?? {}),
  })
}

const getInitialValuesFromDefaultData = (
  comDefaultData?: ComReportDefaultData,
  order?: Order
): CommissioningForm => {
  return {
    generalData: {
      operator: order?.customer_name,
      specialistPlanner: comDefaultData?.specialistPlanner,
      plantLocationCity: order?.customer_city,
      plantLocationStreet: order?.customer_street,
      plantLocationPostCode: order?.customer_post_code,
    },
    pvSystem: {
      hasPvSystem: booleanToLiteral(!!comDefaultData?.pv_system),
      moduleType: comDefaultData?.pv_system?.moduleType,
      moduleManufacturer: comDefaultData?.pv_system?.moduleManufacturer,
      amountOfModules: comDefaultData?.pv_system?.amountModules,
      hasLightningProtectionSystemIntegration: 'no',
      hasPlantOnLowVoltageGridConditions: 'no',
    },
    gridManagement: {
      powerLimitPercentage: 100,
    },
    inverters: comDefaultData?.inverters?.map((inverter) => ({
      type: inverter?.type,
      manufacturer: inverter?.manufacturer,
      serialNumber: inverter?.serialNumber,
    })),
    batteryStorage: {
      hasBatteryStorage: booleanToLiteral(!!comDefaultData?.batteryStorage),
      typeAndCapacityInKWh:
        comDefaultData?.batteryStorage?.type && comDefaultData?.batteryStorage?.capacityInKwh
          ? `${comDefaultData?.batteryStorage?.type} (${comDefaultData?.batteryStorage?.capacityInKwh}kWh)`
          : undefined,
      manufacturer: comDefaultData?.batteryStorage?.manufacturer,
      serialNumber: comDefaultData?.batteryStorage?.serialNumber,
    },
    pvGenerators: comDefaultData?.inverters?.map(({ numberOfMppts, numberOfStringsPerMppt }) => ({
      mppts: Array.from({ length: numberOfMppts ?? 1 }, () => ({
        amountOfStrings: numberOfStringsPerMppt,
        stringElectricalValues: Array.from({ length: numberOfStringsPerMppt ?? 1 }, () => ({})),
      })),
    })),
    wallbox: {
      hasWallbox: booleanToLiteral(!!comDefaultData?.wallbox),
      typeAndPerformanceInKW: comDefaultData?.wallbox?.type,
      manufacturer: comDefaultData?.wallbox?.manufacturer,
      serialNumber: comDefaultData?.wallbox?.serialNumber,
    },
    compass: {
      managerSerialNumber: comDefaultData?.compassHardware?.managerSerialNumber,
    },
    briefingOfOperation: {
      isDamageFreeInstallation: false,
      hasProperCableRoutingInspection: false,
      hasComponentInstallationInspection: false,
      hasCustomerSwitchgearPVSticker: false,
      isCleanSiteNoWaste: false,
      hasComponentExplanation: false,
      hasShutdownExplanationACDC: false,
      hasSystemMonitoringIntroduction: false,
      hasOperationIndicators: false,
      hasMaintenanceAndCareGuidancePVA: false,
    },
    signature: {
      installer: {
        date: new Date().toISOString().split('T')[0],
      },
      client: {
        date: new Date().toISOString().split('T')[0],
      },
    },
  }
}

const booleanToLiteral = (value?: boolean | null) => (!!value ? 'yes' : 'no')
const literalToBoolean = (value?: 'yes' | 'no') => value === 'yes'

const formValuesToComReportValues = (values: CommissioningForm): ComReport['formData'] => {
  const {
    generalData,
    pvSystem,
    gridManagement,
    inverters,
    pvGenerators,
    electricalMeasurement,
    compass,
    batteryStorage,
    wallbox,
    briefingOfOperation,
    signature,
  } = values
  return {
    generalData: new ComFormGeneralData(generalData),
    pvSystem: new ComFormPvSystem({
      ...pvSystem,
      hasPvSystem: literalToBoolean(pvSystem.hasPvSystem),
      hasLightningProtectionSystemIntegration: literalToBoolean(
        pvSystem.hasLightningProtectionSystemIntegration
      ),
      hasPlantOnLowVoltageGridConditions: literalToBoolean(
        pvSystem.hasPlantOnLowVoltageGridConditions
      ),
    }),
    ...(gridManagement && {
      gridManagement: new ComFormGridManagement(gridManagement),
    }),
    ...(inverters && { inverters: inverters.map((inverter) => new ComFormInverter(inverter)) }),
    pvGenerators: pvGenerators?.map(
      ({ mppts }) =>
        new ComFormPvGenerator({
          mppts: mppts.map(
            (mppt) =>
              new ComFormPvGeneratorMppt({
                ...mppt,
                stringElectricalValues: mppt.stringElectricalValues.map(
                  (values) => new ComFormPvGeneratorMpptStringElectricalValues(values)
                ),
              })
          ),
        })
    ),
    ...(electricalMeasurement && {
      electricalMeasurement: new ComFormElectricalMaasurement(electricalMeasurement),
    }),
    ...(compass && { compass: new ComFormCompass(compass) }),
    batteryStorage: new ComFormBatteryStorage({
      ...batteryStorage,
      hasBatteryStorage: literalToBoolean(batteryStorage.hasBatteryStorage),
    }),
    wallbox: new ComFormWallbox({
      ...wallbox,
      hasWallbox: literalToBoolean(wallbox.hasWallbox),
    }),
    ...(briefingOfOperation && {
      briefingOfOperation: new ComFormBriefingOfOperation(briefingOfOperation),
    }),
    ...(signature && {
      signature: new ComFormSignature({
        ...(signature.installer && {
          installer: new ComFormSignatureItem(signature.installer),
        }),
        ...(signature.client && {
          client: new ComFormSignatureItem(signature.client),
        }),
      }),
    }),
  }
}

const comReportValuesToFormValues = (values: ComReport['formData']): CommissioningForm => {
  const {
    generalData,
    pvSystem,
    gridManagement,
    inverters,
    pvGenerators,
    electricalMeasurement,
    compass,
    batteryStorage,
    wallbox,
    briefingOfOperation,
    signature,
  } = values
  return cloneDeep({
    generalData,
    pvSystem: {
      ...pvSystem,
      hasPvSystem: booleanToLiteral(pvSystem.hasPvSystem),
      hasLightningProtectionSystemIntegration: booleanToLiteral(
        pvSystem.hasLightningProtectionSystemIntegration
      ),
      hasPlantOnLowVoltageGridConditions: booleanToLiteral(
        pvSystem.hasPlantOnLowVoltageGridConditions
      ),
    },
    ...(gridManagement && { gridManagement }),
    ...(inverters && { inverters }),
    ...(pvGenerators && { pvGenerators }),
    ...(electricalMeasurement && { electricalMeasurement }),
    ...(compass && { compass }),
    batteryStorage: {
      ...batteryStorage,
      hasBatteryStorage: booleanToLiteral(batteryStorage.hasBatteryStorage),
    },
    ...(wallbox && {
      wallbox: {
        ...wallbox,
        hasWallbox: booleanToLiteral(wallbox.hasWallbox),
      },
    }),
    ...(briefingOfOperation && { briefingOfOperation }),
    ...(signature && { signature }),
  })
}
