import {
  type Activity,
  type Appointment,
  type Order,
  type Comment,
  type OrderDocument,
  type Product,
  type ComReportDefaultData,
  type ComReport,
} from '~/models'
import { type ActivityType, type Timeframe, useAppStore } from '~/stores'
import { useCallback } from 'react'
import { isAfter, isBefore, startOfToday } from 'date-fns'
import { reportService } from '~/services'
import { type Reportable } from '~/services/ReportService'
import { extractGpsCoordinates, type GpsCoordinates } from '~/utils/gps'
import { type ConfigurationInterface } from '~/components/Order'
import { parseFilenameFromUrl } from '~/utils/parseFilenameFromUrl'
import { isAppointmentValidByFilter } from '~/hooks/useTimeline'
import { type AppointmentsFilter } from '~/stores/useAppStore'
import { useAuth0 } from '@auth0/auth0-react'
import { type AuthOUser } from '~/utils/types'

interface DocumentFilter {
  mimeType?: string
  tag?: string
}

export type ProductWithQuantity = Product & {
  quantity: number
}

export interface UseOrder extends Order {
  getActivity: (type: ActivityType) => Activity
  getActivities: (typeFilter?: ActivityType[]) => Activity[]
  getAppointments: (appointmentsFilter?: AppointmentsFilter, timeframe?: Timeframe) => Appointment[]
  getComments: (typeFilter?: ActivityType[]) => Comment[]
  getConfiguration: () => ConfigurationInterface
  getCoverImage: () => string | undefined
  getDocuments: (filter?: DocumentFilter) => OrderDocument[]
  getGpsCoordinates: () => GpsCoordinates | undefined
  getProductsWithQuantity: () => ProductWithQuantity[]
  getProductByFamily: (productFamily: string) => ProductWithQuantity | undefined
  getOrder: () => Order
  getReport: (type: ActivityType) => Reportable
  getReports: () => Reportable[]
  getComReportDefaultData: () => ComReportDefaultData[]
  getComReports: () => ComReport[]
}

export function useOrder(orderId: string = ''): UseOrder {
  const activities = useAppStore.use.activities()
  const comReportDefaultData = useAppStore.use.comReportDefaultData()
  const comReports = useAppStore.use.comReports()
  const reportShapes = useAppStore.use.reportShapes()
  const appointments = useAppStore.use.appointments()
  const orders = useAppStore.use.orders()
  const orderDocuments = useAppStore.use.orderDocuments()
  const comments = useAppStore.use.comments()
  const reports = useAppStore.use.reports()
  const lqvReports = useAppStore.use.lqvReports()
  const products = useAppStore.use.products()

  const { user } = useAuth0<AuthOUser>()
  const installerId = user?.['https://zolar.de/installer_id'] ?? null

  const order = orders.find((order) => order.id === orderId)
  if (!order) throw new Error('Order with id ' + orderId + ' not found')

  const getActivities = useCallback<UseOrder['getActivities']>(
    (typeFilter = []) => {
      return activities.filter(
        (activity) =>
          (typeFilter.length
            ? typeFilter.includes((activity.activity_type as ActivityType) ?? '')
            : true) && activity.order_uid === orderId
      )
    },
    [activities, orderId]
  )

  const getComReportDefaultData = useCallback<UseOrder['getComReportDefaultData']>(() => {
    return comReportDefaultData.filter(
      (comReportDefaultData) => comReportDefaultData.order_uid === orderId
    )
  }, [comReportDefaultData, orderId])

  const getComReports = useCallback<UseOrder['getComReports']>(() => {
    return comReports.filter((comReport) => comReport.order_uid === orderId)
  }, [comReports, orderId])

  const getActivity = useCallback<UseOrder['getActivity']>(
    (type) => {
      const activity = getActivities().find((activity) => activity.activity_type === type)
      if (!activity)
        throw new Error('Activity with type ' + type + ' not found for OrderId: ' + orderId)
      return activity
    },
    [getActivities, orderId]
  )

  const getAppointments = useCallback<UseOrder['getAppointments']>(
    (appointmentsFilter, timeframe) => {
      const timeframeAppointments = appointments.filter((appointment) => {
        if (!(appointment.activity_type && appointment.start_date && appointment.end_date))
          return false
        if (timeframe === 'future')
          return isAfter(new Date(appointment.end_date ?? ''), startOfToday())
        if (timeframe === 'past')
          return isBefore(new Date(appointment.start_date ?? ''), startOfToday())
        return true
      })

      return timeframeAppointments.filter((appointment) => {
        return appointmentsFilter
          ? isAppointmentValidByFilter(appointment, appointmentsFilter, installerId) &&
              appointment.order_uid === orderId
          : appointment.order_uid === orderId
      })
    },
    [appointments, orderId, installerId]
  )

  const getComments = useCallback<UseOrder['getComments']>(
    (typeFilter) => {
      return comments.filter((comment) => {
        if (typeFilter?.length && comment.activity_type) {
          return (
            comment.order_uid === orderId &&
            typeFilter.includes(comment.activity_type as ActivityType)
          )
        }
        return comment.order_uid === orderId
      })
    },
    [comments, orderId]
  )

  const getDocuments = useCallback<UseOrder['getDocuments']>(
    (filter) => {
      if (!orderDocuments || orderDocuments.length === 0) return [] as OrderDocument[]

      let documents = orderDocuments.filter((document) => document.order_id === order.id)

      if (filter?.mimeType) {
        documents = documents.filter((doc) => doc.mimeType.startsWith(filter.mimeType!))
      }

      if (filter?.tag) {
        documents = documents.filter((doc) => doc.tags?.includes(filter.tag!))
      }

      return documents as OrderDocument[]
    },
    [order, orderDocuments]
  )

  const getProductsWithQuantity = useCallback<UseOrder['getProductsWithQuantity']>(() => {
    return (
      (order.products
        ?.map((productQuantity) => {
          const product = products.find((prod) => prod.id === productQuantity.product_id)
          if (!product || !productQuantity.quantity) return undefined
          return {
            ...product,
            quantity: parseInt(productQuantity.quantity),
          }
        })
        ?.filter((item) => item != undefined) as ProductWithQuantity[]) || []
    )
  }, [products, order.products])

  const getProductByFamily = useCallback<UseOrder['getProductByFamily']>(
    (productFamily) => {
      return getProductsWithQuantity().find((product) => product.family === productFamily)
    },
    [getProductsWithQuantity]
  )

  const getConfiguration = useCallback<UseOrder['getConfiguration']>(() => {
    return {
      amountOfModules: order.number_of_modules ?? '-',
      systemPower: order.system_power ?? '-',
      storageCapacity: order.storage_capacity ?? '-',
      requiredPowerLimit: order.required_power_limit ?? '-',
      inverterName: getProductByFamily('Wechselrichter')?.name ?? '-',
      storageName: getProductByFamily('Stromspeicher')?.name ?? '-',
      gridType: order.grid_type ?? '-',
      gridOperator: order.grid_operator ?? '-',
      stringPlanUrl: getDocuments({ tag: 'Stringplan' })[0]?.url ?? undefined,
    }
  }, [getDocuments, getProductByFamily, order])

  const getCoverImage = useCallback<UseOrder['getCoverImage']>(() => {
    const documents = getDocuments({ mimeType: 'image' })

    let image = documents.find((doc) => doc?.tags?.includes('Haus'))
    if (!image) image = documents.find((doc) => doc?.category === 'Bilder')
    if (!image) documents.length ? (image = documents[0]) : undefined

    return image?.url ?? undefined
  }, [getDocuments])

  const getGpsCoordinates = useCallback<UseOrder['getGpsCoordinates']>(() => {
    return extractGpsCoordinates(order.customer_lon_lat)
  }, [order])

  const getOrder = useCallback<UseOrder['getOrder']>(() => order, [order])

  const getReport = useCallback<UseOrder['getReport']>(
    (type) => {
      const activity = getActivity(type)
      const report =
        activity &&
        (activity.use_lqv
          ? lqvReports.find((report) => report.activity_uid === activity.id)
          : reports.find((report) => report.activity_uid === activity.id))
      const reportShape = reportShapes.find(
        (rs) => rs.order_uid === order.id && rs.activity_uid === activity.id
      )
      return reportService.getReportable(activity, order, report, reportShape)
    },
    [reports, order, lqvReports, reportShapes, getActivity]
  )

  const getReports = useCallback<UseOrder['getReports']>(
    () =>
      getActivities(['ac', 'dc']).map((activity) =>
        getReport(activity.activity_type as ActivityType)
      ),
    [getActivities, getReport]
  )

  return {
    ...order,
    getAppointments,
    getActivities,
    getActivity,
    getComments,
    getConfiguration,
    getCoverImage,
    getDocuments,
    getGpsCoordinates,
    getProductByFamily,
    getProductsWithQuantity,
    getOrder,
    getReport,
    getReports,
    getComReportDefaultData,
    getComReports,
  }
}

interface Manual {
  label: string
  url: string
  filename: string
}

export function getProductManuals(product: ProductWithQuantity): Manual[] {
  const manuals: Manual[] = []

  if (product.url_installation_manual) {
    manuals.push({
      label: 'Installationsanleitung',
      url: product.url_installation_manual,
      filename: parseFilenameFromUrl(product.url_installation_manual),
    })
  }

  if (product.url_installation_manual2) {
    manuals.push({
      label: 'Bedienungsanleitung',
      url: product.url_installation_manual2,
      filename: parseFilenameFromUrl(product.url_installation_manual2),
    })
  }

  if (product.url_fuer_datenblaetter) {
    manuals.push({
      label: 'Datenblätter',
      url: product.url_fuer_datenblaetter,
      filename: parseFilenameFromUrl(product.url_fuer_datenblaetter),
    })
  }

  if (product.url_installation_presentation) {
    manuals.push({
      label: 'Installationspräsentation',
      url: product.url_installation_presentation,
      filename: parseFilenameFromUrl(product.url_installation_presentation),
    })
  }

  if (product.url_guarantee_documents) {
    manuals.push({
      label: 'Garantiebestimmungen',
      url: product.url_guarantee_documents,
      filename: parseFilenameFromUrl(product.url_guarantee_documents),
    })
  }

  return manuals
}
