import { type CommissioningForm } from '../formValidationSchema'
import { ItemTextInline, Section, formatNumber } from './SharedWrappers'

const PdfSectionElectricalMeasurement = (props: {
  data: CommissioningForm['electricalMeasurement']
}) => {
  const { data = {} } = props
  const { measuringEquipment, temperatureInCelsius, testingVoltageInVolts, weatherConditions } =
    data
  return (
    <Section label='Elektrische Messung'>
      <ItemTextInline label='Messgerät' value={measuringEquipment} />
      <ItemTextInline
        label='Prüfspannung'
        value={
          typeof testingVoltageInVolts === 'number' && `${formatNumber(testingVoltageInVolts)} V`
        }
      />
      <ItemTextInline label='Wetterbedingungen' value={weatherConditions} />
      <ItemTextInline
        label='Temperatur'
        value={
          typeof temperatureInCelsius === 'number' && `${formatNumber(temperatureInCelsius)} °C`
        }
      />
    </Section>
  )
}

export default PdfSectionElectricalMeasurement
