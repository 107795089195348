import { useFormContext } from 'react-hook-form'
import { FormInput, RadioGroup } from '~/components'
import { type CommissioningForm } from './formValidationSchema'

const FormBatteryStorage = () => {
  const { watch } = useFormContext<CommissioningForm>()
  const hasBatteryStorage = watch('batteryStorage.hasBatteryStorage') === 'yes'
  return (
    <div className='flex flex-col gap-4'>
      <RadioGroup label='Batteriespeicher' orientation='horizontal'>
        <FormInput type='radio' name='batteryStorage.hasBatteryStorage' label='Ja' value='yes' />
        <FormInput type='radio' name='batteryStorage.hasBatteryStorage' label='Nein' value='no' />
      </RadioGroup>
      <FormInput
        type='text'
        name='batteryStorage.manufacturer'
        label='Hersteller'
        disabled={!hasBatteryStorage}
      />
      <FormInput
        type='text'
        name='batteryStorage.typeAndCapacityInKWh'
        label='Modell (inkl. Kapazität in kWh)'
        disabled={!hasBatteryStorage}
      />
      <FormInput
        type='text'
        name='batteryStorage.serialNumber'
        label='Seriennummer'
        disabled={!hasBatteryStorage}
      />
    </div>
  )
}

export default FormBatteryStorage
