import { type Report, ReportItemEntityStatus, ReportStatus } from '~/models'
import { Button, Divider, Icon, ProgressBar, Tag } from '~/components'
import {
  getItemWithValues,
  getStepWithValues,
  type ItemData,
} from '~/pages/Documentation/documentationSteps'
import { Link } from 'react-router-dom'
import classNames from 'classnames'
import { type SpriteName } from '@zolargmbh/zolar-icons'
import { type ComponentProps } from 'react'

interface ActivityDocumentationProps {
  orderId: string
  activityType: string
  report: Report
}

export function ActivityDocumentation(props: ActivityDocumentationProps) {
  const [count, progress] = props.report.steps.reduce(
    ([count, progress], step) => {
      const stepProgress = step.items.reduce<number>(
        (sum, item) => (item.status !== ReportItemEntityStatus.READY ? sum : sum + 1),
        0
      )
      return [count + step.items.length, progress + stepProgress]
    },
    [0, 0]
  )

  function getFirstOpenItem() {
    if (props.report.status === ReportStatus.READY) {
      return
    }

    for (const step of props.report.steps) {
      const item = step.items.find((item) => item.status === ReportItemEntityStatus.DRAFT)
      if (item) {
        return item
      }
    }
  }

  function hasStarted() {
    return !props.report.steps.every((step) =>
      step.items.every((item) => item.image_uids.length === 0)
    )
  }

  const firstOpenItem = getFirstOpenItem()

  return (
    <div className='flex flex-col gap-5'>
      {firstOpenItem && (
        <Link to={`/documentation/${props.orderId}/${props.activityType}/${firstOpenItem?.key}`}>
          <Button size='lg' variant='filled-accent' className='w-full'>
            {`Dokumentation ${hasStarted() ? 'fortsetzen' : 'starten'}`}
          </Button>
        </Link>
      )}
      <h1 className='text-headline-4'>
        Dokumentation {props.activityType === 'ac' ? 'Elektroinstallation' : 'Dachinstallation'}
      </h1>
      <div className='flex flex-col gap-1'>
        <h6 className='text-body-2-bold'>Dokumentationsprozess</h6>
        <ProgressBar
          progress={Math.ceil((100 / count) * progress)}
          className='bg-grey-300'
          showPercentage
        >
          Aktuell kann <b>nur eine vollständig hochgeladene Dokumentation</b> von zolar{' '}
          <b>geprüft werden.</b>
        </ProgressBar>
      </div>
      <Divider className='bg-neutral' />
      {props.report.steps.map((stepEntity, index) => {
        const step = getStepWithValues(stepEntity)
        return step === undefined ? null : (
          <div key={'step-' + props.activityType + '-' + index}>
            <h1 className='text-subheadline-2 mb-4'>
              {step.title} ({index + 1}/{props.report.steps.length})
            </h1>
            <div className='flex flex-col gap-2'>
              {step.items.map((item) => {
                const itemValue = getItemWithValues(item)
                return (
                  <Tile
                    key={item.key}
                    title={itemValue.title}
                    status={item.status}
                    from={`/order/${props.orderId}/${props.activityType}`}
                    to={`/documentation/${props.orderId}/${props.activityType}/${item.key}`}
                  />
                )
              })}
            </div>
          </div>
        )
      })}
    </div>
  )
}

interface TileProps {
  title: string
  status: ItemData['status']
  to: string
  from: string
}

function Tile(props: TileProps) {
  const data = tileProps[props.status as ReportItemEntityStatus]

  return (
    <div className={classNames('rounded-md border border-dark-3 p-4', data.bgColor)}>
      <div className='flex flex-row items-center justify-between'>
        <div>
          <div className='text-body-2-bold mb-2'>{props.title}</div>
          <Tag small label={data.status} />
        </div>
        <Link to={props.to} state={{ from: props.from }}>
          <Button variant={data.variant} isIconButton aria-label={`${props.title} dokumentieren`}>
            {data.icon && <Icon icon={data.icon} />}
          </Button>
        </Link>
      </div>
    </div>
  )
}

interface TileData {
  status: string
  icon?: SpriteName
  variant: ComponentProps<typeof Button>['variant']
  bgColor: 'bg-moss-400' | 'bg-mist-500' | 'bg-white'
}

const tileProps: Record<ReportItemEntityStatus, TileData> = {
  ready: {
    status: 'Gespeichert',
    icon: 'general:check',
    variant: 'outlined-dark',
    bgColor: 'bg-moss-400',
  },
  pending: {
    status: 'Bilder-Upload ausstehend',
    icon: 'arrows:arrow-up',
    variant: 'outlined-dark',
    bgColor: 'bg-mist-500',
  },
  draft: {
    status: 'Dokumentation nicht begonnen',
    icon: 'social:camera',
    variant: 'filled-dark',
    bgColor: 'bg-white',
  },
}
