export const schema = {
    "models": {
        "Activity": {
            "name": "Activity",
            "fields": {
                "id": {
                    "name": "id",
                    "isArray": false,
                    "type": "ID",
                    "isRequired": true,
                    "attributes": []
                },
                "state": {
                    "name": "state",
                    "isArray": false,
                    "type": "String",
                    "isRequired": true,
                    "attributes": []
                },
                "updated_at": {
                    "name": "updated_at",
                    "isArray": false,
                    "type": "String",
                    "isRequired": true,
                    "attributes": []
                },
                "activity_type": {
                    "name": "activity_type",
                    "isArray": false,
                    "type": "String",
                    "isRequired": true,
                    "attributes": []
                },
                "request_uid": {
                    "name": "request_uid",
                    "isArray": false,
                    "type": "ID",
                    "isRequired": true,
                    "attributes": []
                },
                "request_state": {
                    "name": "request_state",
                    "isArray": false,
                    "type": "String",
                    "isRequired": true,
                    "attributes": []
                },
                "partner_uid": {
                    "name": "partner_uid",
                    "isArray": false,
                    "type": "ID",
                    "isRequired": true,
                    "attributes": []
                },
                "order_uid": {
                    "name": "order_uid",
                    "isArray": false,
                    "type": "ID",
                    "isRequired": false,
                    "attributes": []
                },
                "report_uid": {
                    "name": "report_uid",
                    "isArray": false,
                    "type": "ID",
                    "isRequired": false,
                    "attributes": []
                },
                "lqv_report_uid": {
                    "name": "lqv_report_uid",
                    "isArray": false,
                    "type": "ID",
                    "isRequired": false,
                    "attributes": []
                },
                "use_lqv": {
                    "name": "use_lqv",
                    "isArray": false,
                    "type": "Boolean",
                    "isRequired": false,
                    "attributes": []
                },
                "link_vivi_osv": {
                    "name": "link_vivi_osv",
                    "isArray": false,
                    "type": "String",
                    "isRequired": false,
                    "attributes": []
                },
                "appointments": {
                    "name": "appointments",
                    "isArray": true,
                    "type": {
                        "model": "Appointment"
                    },
                    "isRequired": true,
                    "attributes": [],
                    "isArrayNullable": true,
                    "association": {
                        "connectionType": "HAS_MANY",
                        "associatedWith": [
                            "activity_uid"
                        ]
                    }
                },
                "comments": {
                    "name": "comments",
                    "isArray": true,
                    "type": {
                        "model": "Comment"
                    },
                    "isRequired": true,
                    "attributes": [],
                    "isArrayNullable": true,
                    "association": {
                        "connectionType": "HAS_MANY",
                        "associatedWith": [
                            "activity_uid"
                        ]
                    }
                },
                "createdAt": {
                    "name": "createdAt",
                    "isArray": false,
                    "type": "AWSDateTime",
                    "isRequired": false,
                    "attributes": [],
                    "isReadOnly": true
                },
                "updatedAt": {
                    "name": "updatedAt",
                    "isArray": false,
                    "type": "AWSDateTime",
                    "isRequired": false,
                    "attributes": [],
                    "isReadOnly": true
                }
            },
            "syncable": true,
            "pluralName": "Activities",
            "attributes": [
                {
                    "type": "model",
                    "properties": {}
                },
                {
                    "type": "key",
                    "properties": {
                        "name": "byOrderUid",
                        "queryField": null,
                        "fields": [
                            "order_uid"
                        ]
                    }
                },
                {
                    "type": "auth",
                    "properties": {
                        "rules": [
                            {
                                "provider": "oidc",
                                "ownerField": "partner_uid",
                                "allow": "owner",
                                "operations": [
                                    "read"
                                ],
                                "identityClaim": "https://zolar.de/partner_id"
                            },
                            {
                                "groupClaim": "permissions",
                                "provider": "oidc",
                                "allow": "groups",
                                "groups": [
                                    "installer_app:all:sync"
                                ],
                                "operations": [
                                    "create",
                                    "update",
                                    "delete",
                                    "read"
                                ]
                            }
                        ]
                    }
                }
            ]
        },
        "Comment": {
            "name": "Comment",
            "fields": {
                "id": {
                    "name": "id",
                    "isArray": false,
                    "type": "ID",
                    "isRequired": true,
                    "attributes": []
                },
                "updated_at": {
                    "name": "updated_at",
                    "isArray": false,
                    "type": "String",
                    "isRequired": true,
                    "attributes": []
                },
                "created_at": {
                    "name": "created_at",
                    "isArray": false,
                    "type": "String",
                    "isRequired": true,
                    "attributes": []
                },
                "content": {
                    "name": "content",
                    "isArray": false,
                    "type": "String",
                    "isRequired": true,
                    "attributes": []
                },
                "activity_uid": {
                    "name": "activity_uid",
                    "isArray": false,
                    "type": "ID",
                    "isRequired": true,
                    "attributes": []
                },
                "activity_type": {
                    "name": "activity_type",
                    "isArray": false,
                    "type": "String",
                    "isRequired": true,
                    "attributes": []
                },
                "partner_uid": {
                    "name": "partner_uid",
                    "isArray": false,
                    "type": "ID",
                    "isRequired": true,
                    "attributes": []
                },
                "order_uid": {
                    "name": "order_uid",
                    "isArray": false,
                    "type": "ID",
                    "isRequired": true,
                    "attributes": []
                },
                "createdAt": {
                    "name": "createdAt",
                    "isArray": false,
                    "type": "AWSDateTime",
                    "isRequired": false,
                    "attributes": [],
                    "isReadOnly": true
                },
                "updatedAt": {
                    "name": "updatedAt",
                    "isArray": false,
                    "type": "AWSDateTime",
                    "isRequired": false,
                    "attributes": [],
                    "isReadOnly": true
                }
            },
            "syncable": true,
            "pluralName": "Comments",
            "attributes": [
                {
                    "type": "model",
                    "properties": {}
                },
                {
                    "type": "key",
                    "properties": {
                        "name": "by_activity",
                        "fields": [
                            "activity_uid",
                            "updated_at"
                        ]
                    }
                },
                {
                    "type": "key",
                    "properties": {
                        "name": "byOrderUid",
                        "queryField": null,
                        "fields": [
                            "order_uid"
                        ]
                    }
                },
                {
                    "type": "auth",
                    "properties": {
                        "rules": [
                            {
                                "provider": "oidc",
                                "ownerField": "partner_uid",
                                "allow": "owner",
                                "operations": [
                                    "read"
                                ],
                                "identityClaim": "https://zolar.de/partner_id"
                            },
                            {
                                "groupClaim": "permissions",
                                "provider": "oidc",
                                "allow": "groups",
                                "groups": [
                                    "installer_app:all:sync"
                                ],
                                "operations": [
                                    "create",
                                    "update",
                                    "delete",
                                    "read"
                                ]
                            }
                        ]
                    }
                }
            ]
        },
        "OrderDocument": {
            "name": "OrderDocument",
            "fields": {
                "id": {
                    "name": "id",
                    "isArray": false,
                    "type": "ID",
                    "isRequired": true,
                    "attributes": []
                },
                "partner_uids": {
                    "name": "partner_uids",
                    "isArray": true,
                    "type": "ID",
                    "isRequired": true,
                    "attributes": [],
                    "isArrayNullable": true
                },
                "order_id": {
                    "name": "order_id",
                    "isArray": false,
                    "type": "ID",
                    "isRequired": true,
                    "attributes": []
                },
                "url": {
                    "name": "url",
                    "isArray": false,
                    "type": "String",
                    "isRequired": true,
                    "attributes": []
                },
                "name": {
                    "name": "name",
                    "isArray": false,
                    "type": "String",
                    "isRequired": true,
                    "attributes": []
                },
                "originalName": {
                    "name": "originalName",
                    "isArray": false,
                    "type": "String",
                    "isRequired": true,
                    "attributes": []
                },
                "mimeType": {
                    "name": "mimeType",
                    "isArray": false,
                    "type": "String",
                    "isRequired": true,
                    "attributes": []
                },
                "category": {
                    "name": "category",
                    "isArray": false,
                    "type": "String",
                    "isRequired": false,
                    "attributes": []
                },
                "tags": {
                    "name": "tags",
                    "isArray": true,
                    "type": "String",
                    "isRequired": true,
                    "attributes": [],
                    "isArrayNullable": true
                },
                "createdAt": {
                    "name": "createdAt",
                    "isArray": false,
                    "type": "AWSDateTime",
                    "isRequired": false,
                    "attributes": [],
                    "isReadOnly": true
                },
                "updatedAt": {
                    "name": "updatedAt",
                    "isArray": false,
                    "type": "AWSDateTime",
                    "isRequired": false,
                    "attributes": [],
                    "isReadOnly": true
                }
            },
            "syncable": true,
            "pluralName": "OrderDocuments",
            "attributes": [
                {
                    "type": "model",
                    "properties": {}
                },
                {
                    "type": "key",
                    "properties": {
                        "name": "byOrderUid",
                        "queryField": null,
                        "fields": [
                            "order_id"
                        ]
                    }
                },
                {
                    "type": "auth",
                    "properties": {
                        "rules": [
                            {
                                "provider": "oidc",
                                "ownerField": "partner_uids",
                                "allow": "owner",
                                "operations": [
                                    "read"
                                ],
                                "identityClaim": "https://zolar.de/partner_id"
                            },
                            {
                                "groupClaim": "permissions",
                                "provider": "oidc",
                                "allow": "groups",
                                "groups": [
                                    "installer_app:all:sync"
                                ],
                                "operations": [
                                    "create",
                                    "update",
                                    "delete",
                                    "read"
                                ]
                            }
                        ]
                    }
                }
            ]
        },
        "Order": {
            "name": "Order",
            "fields": {
                "id": {
                    "name": "id",
                    "isArray": false,
                    "type": "ID",
                    "isRequired": true,
                    "attributes": []
                },
                "partner_uids": {
                    "name": "partner_uids",
                    "isArray": true,
                    "type": "ID",
                    "isRequired": true,
                    "attributes": [],
                    "isArrayNullable": true
                },
                "system_power": {
                    "name": "system_power",
                    "isArray": false,
                    "type": "String",
                    "isRequired": false,
                    "attributes": []
                },
                "storage_capacity": {
                    "name": "storage_capacity",
                    "isArray": false,
                    "type": "String",
                    "isRequired": false,
                    "attributes": []
                },
                "grid_operator": {
                    "name": "grid_operator",
                    "isArray": false,
                    "type": "String",
                    "isRequired": false,
                    "attributes": []
                },
                "grid_type": {
                    "name": "grid_type",
                    "isArray": false,
                    "type": "String",
                    "isRequired": false,
                    "attributes": []
                },
                "number_of_modules": {
                    "name": "number_of_modules",
                    "isArray": false,
                    "type": "String",
                    "isRequired": false,
                    "attributes": []
                },
                "required_power_limit": {
                    "name": "required_power_limit",
                    "isArray": false,
                    "type": "String",
                    "isRequired": false,
                    "attributes": []
                },
                "order_name": {
                    "name": "order_name",
                    "isArray": false,
                    "type": "String",
                    "isRequired": true,
                    "attributes": []
                },
                "order_number": {
                    "name": "order_number",
                    "isArray": false,
                    "type": "String",
                    "isRequired": true,
                    "attributes": []
                },
                "order_type": {
                    "name": "order_type",
                    "isArray": false,
                    "type": "String",
                    "isRequired": true,
                    "attributes": []
                },
                "order_notes": {
                    "name": "order_notes",
                    "isArray": false,
                    "type": "String",
                    "isRequired": false,
                    "attributes": []
                },
                "order_hash": {
                    "name": "order_hash",
                    "isArray": false,
                    "type": "String",
                    "isRequired": true,
                    "attributes": []
                },
                "extern_hash": {
                    "name": "extern_hash",
                    "isArray": false,
                    "type": "String",
                    "isRequired": false,
                    "attributes": []
                },
                "customer_uid": {
                    "name": "customer_uid",
                    "isArray": false,
                    "type": "String",
                    "isRequired": false,
                    "attributes": []
                },
                "customer_hash": {
                    "name": "customer_hash",
                    "isArray": false,
                    "type": "String",
                    "isRequired": false,
                    "attributes": []
                },
                "customer_name": {
                    "name": "customer_name",
                    "isArray": false,
                    "type": "String",
                    "isRequired": false,
                    "attributes": []
                },
                "customer_email": {
                    "name": "customer_email",
                    "isArray": false,
                    "type": "String",
                    "isRequired": false,
                    "attributes": []
                },
                "customer_mobile": {
                    "name": "customer_mobile",
                    "isArray": false,
                    "type": "String",
                    "isRequired": false,
                    "attributes": []
                },
                "customer_phone": {
                    "name": "customer_phone",
                    "isArray": false,
                    "type": "String",
                    "isRequired": false,
                    "attributes": []
                },
                "customer_street": {
                    "name": "customer_street",
                    "isArray": false,
                    "type": "String",
                    "isRequired": false,
                    "attributes": []
                },
                "customer_post_code": {
                    "name": "customer_post_code",
                    "isArray": false,
                    "type": "String",
                    "isRequired": false,
                    "attributes": []
                },
                "customer_city": {
                    "name": "customer_city",
                    "isArray": false,
                    "type": "String",
                    "isRequired": false,
                    "attributes": []
                },
                "customer_country": {
                    "name": "customer_country",
                    "isArray": false,
                    "type": "String",
                    "isRequired": false,
                    "attributes": []
                },
                "customer_lon_lat": {
                    "name": "customer_lon_lat",
                    "isArray": false,
                    "type": "String",
                    "isRequired": false,
                    "attributes": []
                },
                "products": {
                    "name": "products",
                    "isArray": true,
                    "type": {
                        "nonModel": "ProductQuantity"
                    },
                    "isRequired": true,
                    "attributes": [],
                    "isArrayNullable": true
                },
                "createdAt": {
                    "name": "createdAt",
                    "isArray": false,
                    "type": "AWSDateTime",
                    "isRequired": false,
                    "attributes": [],
                    "isReadOnly": true
                },
                "updatedAt": {
                    "name": "updatedAt",
                    "isArray": false,
                    "type": "AWSDateTime",
                    "isRequired": false,
                    "attributes": [],
                    "isReadOnly": true
                }
            },
            "syncable": true,
            "pluralName": "Orders",
            "attributes": [
                {
                    "type": "model",
                    "properties": {}
                },
                {
                    "type": "key",
                    "properties": {
                        "name": "byCustomerEmail",
                        "queryField": null,
                        "fields": [
                            "customer_email"
                        ]
                    }
                },
                {
                    "type": "auth",
                    "properties": {
                        "rules": [
                            {
                                "provider": "oidc",
                                "ownerField": "partner_uids",
                                "allow": "owner",
                                "operations": [
                                    "read"
                                ],
                                "identityClaim": "https://zolar.de/partner_id"
                            },
                            {
                                "groupClaim": "permissions",
                                "provider": "oidc",
                                "allow": "groups",
                                "groups": [
                                    "installer_app:all:sync"
                                ],
                                "operations": [
                                    "create",
                                    "update",
                                    "delete",
                                    "read"
                                ]
                            }
                        ]
                    }
                }
            ]
        },
        "Product": {
            "name": "Product",
            "fields": {
                "id": {
                    "name": "id",
                    "isArray": false,
                    "type": "ID",
                    "isRequired": true,
                    "attributes": []
                },
                "updated_at": {
                    "name": "updated_at",
                    "isArray": false,
                    "type": "String",
                    "isRequired": false,
                    "attributes": []
                },
                "name": {
                    "name": "name",
                    "isArray": false,
                    "type": "String",
                    "isRequired": false,
                    "attributes": []
                },
                "family": {
                    "name": "family",
                    "isArray": false,
                    "type": "String",
                    "isRequired": false,
                    "attributes": []
                },
                "url_installation_manual": {
                    "name": "url_installation_manual",
                    "isArray": false,
                    "type": "String",
                    "isRequired": false,
                    "attributes": []
                },
                "url_installation_manual2": {
                    "name": "url_installation_manual2",
                    "isArray": false,
                    "type": "String",
                    "isRequired": false,
                    "attributes": []
                },
                "url_fuer_datenblaetter": {
                    "name": "url_fuer_datenblaetter",
                    "isArray": false,
                    "type": "String",
                    "isRequired": false,
                    "attributes": []
                },
                "url_installation_video": {
                    "name": "url_installation_video",
                    "isArray": false,
                    "type": "String",
                    "isRequired": false,
                    "attributes": []
                },
                "url_installation_presentation": {
                    "name": "url_installation_presentation",
                    "isArray": false,
                    "type": "String",
                    "isRequired": false,
                    "attributes": []
                },
                "url_guarantee_documents": {
                    "name": "url_guarantee_documents",
                    "isArray": false,
                    "type": "String",
                    "isRequired": false,
                    "attributes": []
                },
                "partner_uids": {
                    "name": "partner_uids",
                    "isArray": true,
                    "type": "ID",
                    "isRequired": true,
                    "attributes": [],
                    "isArrayNullable": true
                },
                "createdAt": {
                    "name": "createdAt",
                    "isArray": false,
                    "type": "AWSDateTime",
                    "isRequired": false,
                    "attributes": [],
                    "isReadOnly": true
                },
                "updatedAt": {
                    "name": "updatedAt",
                    "isArray": false,
                    "type": "AWSDateTime",
                    "isRequired": false,
                    "attributes": [],
                    "isReadOnly": true
                }
            },
            "syncable": true,
            "pluralName": "Products",
            "attributes": [
                {
                    "type": "model",
                    "properties": {}
                },
                {
                    "type": "auth",
                    "properties": {
                        "rules": [
                            {
                                "provider": "oidc",
                                "ownerField": "partner_uids",
                                "allow": "owner",
                                "operations": [
                                    "read"
                                ],
                                "identityClaim": "https://zolar.de/partner_id"
                            },
                            {
                                "groupClaim": "permissions",
                                "provider": "oidc",
                                "allow": "groups",
                                "groups": [
                                    "installer_app:all:sync"
                                ],
                                "operations": [
                                    "create",
                                    "update",
                                    "delete",
                                    "read"
                                ]
                            }
                        ]
                    }
                }
            ]
        },
        "Appointment": {
            "name": "Appointment",
            "fields": {
                "id": {
                    "name": "id",
                    "isArray": false,
                    "type": "ID",
                    "isRequired": true,
                    "attributes": []
                },
                "start_date": {
                    "name": "start_date",
                    "isArray": false,
                    "type": "String",
                    "isRequired": true,
                    "attributes": []
                },
                "end_date": {
                    "name": "end_date",
                    "isArray": false,
                    "type": "String",
                    "isRequired": true,
                    "attributes": []
                },
                "state": {
                    "name": "state",
                    "isArray": false,
                    "type": "String",
                    "isRequired": true,
                    "attributes": []
                },
                "updated_at": {
                    "name": "updated_at",
                    "isArray": false,
                    "type": "String",
                    "isRequired": false,
                    "attributes": []
                },
                "activity_uid": {
                    "name": "activity_uid",
                    "isArray": false,
                    "type": "ID",
                    "isRequired": false,
                    "attributes": []
                },
                "activity_type": {
                    "name": "activity_type",
                    "isArray": false,
                    "type": "String",
                    "isRequired": true,
                    "attributes": []
                },
                "partner_uid": {
                    "name": "partner_uid",
                    "isArray": false,
                    "type": "ID",
                    "isRequired": true,
                    "attributes": []
                },
                "order_uid": {
                    "name": "order_uid",
                    "isArray": false,
                    "type": "ID",
                    "isRequired": true,
                    "attributes": []
                },
                "assignedInstallers": {
                    "name": "assignedInstallers",
                    "isArray": true,
                    "type": {
                        "nonModel": "Installer"
                    },
                    "isRequired": true,
                    "attributes": [],
                    "isArrayNullable": true
                },
                "createdAt": {
                    "name": "createdAt",
                    "isArray": false,
                    "type": "AWSDateTime",
                    "isRequired": false,
                    "attributes": [],
                    "isReadOnly": true
                },
                "updatedAt": {
                    "name": "updatedAt",
                    "isArray": false,
                    "type": "AWSDateTime",
                    "isRequired": false,
                    "attributes": [],
                    "isReadOnly": true
                }
            },
            "syncable": true,
            "pluralName": "Appointments",
            "attributes": [
                {
                    "type": "model",
                    "properties": {}
                },
                {
                    "type": "key",
                    "properties": {
                        "name": "by_activity",
                        "fields": [
                            "activity_uid",
                            "start_date"
                        ]
                    }
                },
                {
                    "type": "key",
                    "properties": {
                        "name": "byOrderUid",
                        "queryField": null,
                        "fields": [
                            "order_uid"
                        ]
                    }
                },
                {
                    "type": "auth",
                    "properties": {
                        "rules": [
                            {
                                "provider": "oidc",
                                "ownerField": "partner_uid",
                                "allow": "owner",
                                "operations": [
                                    "read"
                                ],
                                "identityClaim": "https://zolar.de/partner_id"
                            },
                            {
                                "groupClaim": "permissions",
                                "provider": "oidc",
                                "allow": "groups",
                                "groups": [
                                    "installer_app:all:sync"
                                ],
                                "operations": [
                                    "create",
                                    "update",
                                    "delete",
                                    "read"
                                ]
                            }
                        ]
                    }
                }
            ]
        },
        "Report": {
            "name": "Report",
            "fields": {
                "id": {
                    "name": "id",
                    "isArray": false,
                    "type": "ID",
                    "isRequired": true,
                    "attributes": []
                },
                "activity_uid": {
                    "name": "activity_uid",
                    "isArray": false,
                    "type": "ID",
                    "isRequired": true,
                    "attributes": []
                },
                "order_uid": {
                    "name": "order_uid",
                    "isArray": false,
                    "type": "ID",
                    "isRequired": false,
                    "attributes": []
                },
                "customer_hash": {
                    "name": "customer_hash",
                    "isArray": false,
                    "type": "String",
                    "isRequired": true,
                    "attributes": []
                },
                "order_hash": {
                    "name": "order_hash",
                    "isArray": false,
                    "type": "String",
                    "isRequired": true,
                    "attributes": []
                },
                "report_uid": {
                    "name": "report_uid",
                    "isArray": false,
                    "type": "ID",
                    "isRequired": true,
                    "attributes": []
                },
                "report_type": {
                    "name": "report_type",
                    "isArray": false,
                    "type": {
                        "enum": "ReportType"
                    },
                    "isRequired": true,
                    "attributes": []
                },
                "status": {
                    "name": "status",
                    "isArray": false,
                    "type": {
                        "enum": "ReportStatus"
                    },
                    "isRequired": true,
                    "attributes": []
                },
                "partner_uid": {
                    "name": "partner_uid",
                    "isArray": false,
                    "type": "ID",
                    "isRequired": true,
                    "attributes": []
                },
                "steps": {
                    "name": "steps",
                    "isArray": true,
                    "type": {
                        "nonModel": "ReportStepEntity"
                    },
                    "isRequired": true,
                    "attributes": [],
                    "isArrayNullable": false
                },
                "createdAt": {
                    "name": "createdAt",
                    "isArray": false,
                    "type": "AWSDateTime",
                    "isRequired": false,
                    "attributes": [],
                    "isReadOnly": true
                },
                "updatedAt": {
                    "name": "updatedAt",
                    "isArray": false,
                    "type": "AWSDateTime",
                    "isRequired": false,
                    "attributes": [],
                    "isReadOnly": true
                }
            },
            "syncable": true,
            "pluralName": "Reports",
            "attributes": [
                {
                    "type": "model",
                    "properties": {}
                },
                {
                    "type": "key",
                    "properties": {
                        "name": "byOrderUid",
                        "queryField": null,
                        "fields": [
                            "order_uid"
                        ]
                    }
                },
                {
                    "type": "auth",
                    "properties": {
                        "rules": [
                            {
                                "provider": "oidc",
                                "ownerField": "partner_uid",
                                "allow": "owner",
                                "operations": [
                                    "create",
                                    "update",
                                    "read"
                                ],
                                "identityClaim": "https://zolar.de/partner_id"
                            },
                            {
                                "groupClaim": "permissions",
                                "provider": "oidc",
                                "allow": "groups",
                                "groups": [
                                    "installer_app:all:sync"
                                ],
                                "operations": [
                                    "create",
                                    "update",
                                    "delete",
                                    "read"
                                ]
                            }
                        ]
                    }
                }
            ]
        },
        "LqvReport": {
            "name": "LqvReport",
            "fields": {
                "id": {
                    "name": "id",
                    "isArray": false,
                    "type": "ID",
                    "isRequired": true,
                    "attributes": []
                },
                "type": {
                    "name": "type",
                    "isArray": false,
                    "type": {
                        "enum": "LqvReportType"
                    },
                    "isRequired": true,
                    "attributes": []
                },
                "state": {
                    "name": "state",
                    "isArray": false,
                    "type": {
                        "enum": "LqvReportState"
                    },
                    "isRequired": true,
                    "attributes": []
                },
                "activity_uid": {
                    "name": "activity_uid",
                    "isArray": false,
                    "type": "ID",
                    "isRequired": true,
                    "attributes": []
                },
                "accepted_by": {
                    "name": "accepted_by",
                    "isArray": false,
                    "type": "String",
                    "isRequired": true,
                    "attributes": []
                },
                "accepted_at": {
                    "name": "accepted_at",
                    "isArray": false,
                    "type": "String",
                    "isRequired": true,
                    "attributes": []
                },
                "partner_uid": {
                    "name": "partner_uid",
                    "isArray": false,
                    "type": "String",
                    "isRequired": true,
                    "attributes": []
                },
                "sections": {
                    "name": "sections",
                    "isArray": true,
                    "type": {
                        "nonModel": "LqvReportSection"
                    },
                    "isRequired": true,
                    "attributes": [],
                    "isArrayNullable": false
                },
                "createdAt": {
                    "name": "createdAt",
                    "isArray": false,
                    "type": "AWSDateTime",
                    "isRequired": false,
                    "attributes": [],
                    "isReadOnly": true
                },
                "updatedAt": {
                    "name": "updatedAt",
                    "isArray": false,
                    "type": "AWSDateTime",
                    "isRequired": false,
                    "attributes": [],
                    "isReadOnly": true
                }
            },
            "syncable": true,
            "pluralName": "LqvReports",
            "attributes": [
                {
                    "type": "model",
                    "properties": {}
                },
                {
                    "type": "auth",
                    "properties": {
                        "rules": [
                            {
                                "provider": "oidc",
                                "ownerField": "partner_uid",
                                "allow": "owner",
                                "operations": [
                                    "read"
                                ],
                                "identityClaim": "https://zolar.de/partner_id"
                            },
                            {
                                "groupClaim": "permissions",
                                "provider": "oidc",
                                "allow": "groups",
                                "groups": [
                                    "installer_app:all:sync"
                                ],
                                "operations": [
                                    "create",
                                    "update",
                                    "delete",
                                    "read"
                                ]
                            }
                        ]
                    }
                }
            ]
        },
        "ComReportDefaultData": {
            "name": "ComReportDefaultData",
            "fields": {
                "id": {
                    "name": "id",
                    "isArray": false,
                    "type": "ID",
                    "isRequired": true,
                    "attributes": []
                },
                "updated_at": {
                    "name": "updated_at",
                    "isArray": false,
                    "type": "String",
                    "isRequired": true,
                    "attributes": []
                },
                "partner_uid": {
                    "name": "partner_uid",
                    "isArray": false,
                    "type": "ID",
                    "isRequired": true,
                    "attributes": []
                },
                "order_uid": {
                    "name": "order_uid",
                    "isArray": false,
                    "type": "ID",
                    "isRequired": true,
                    "attributes": []
                },
                "specialistPlanner": {
                    "name": "specialistPlanner",
                    "isArray": false,
                    "type": "String",
                    "isRequired": false,
                    "attributes": []
                },
                "pv_system": {
                    "name": "pv_system",
                    "isArray": false,
                    "type": {
                        "nonModel": "PVSystem"
                    },
                    "isRequired": false,
                    "attributes": []
                },
                "inverters": {
                    "name": "inverters",
                    "isArray": true,
                    "type": {
                        "nonModel": "Inverter"
                    },
                    "isRequired": true,
                    "attributes": [],
                    "isArrayNullable": true
                },
                "batteryStorage": {
                    "name": "batteryStorage",
                    "isArray": false,
                    "type": {
                        "nonModel": "BatteryStorage"
                    },
                    "isRequired": false,
                    "attributes": []
                },
                "wallbox": {
                    "name": "wallbox",
                    "isArray": false,
                    "type": {
                        "nonModel": "Wallbox"
                    },
                    "isRequired": false,
                    "attributes": []
                },
                "compassHardware": {
                    "name": "compassHardware",
                    "isArray": false,
                    "type": {
                        "nonModel": "CompassHardware"
                    },
                    "isRequired": false,
                    "attributes": []
                },
                "createdAt": {
                    "name": "createdAt",
                    "isArray": false,
                    "type": "AWSDateTime",
                    "isRequired": false,
                    "attributes": [],
                    "isReadOnly": true
                },
                "updatedAt": {
                    "name": "updatedAt",
                    "isArray": false,
                    "type": "AWSDateTime",
                    "isRequired": false,
                    "attributes": [],
                    "isReadOnly": true
                }
            },
            "syncable": true,
            "pluralName": "ComReportDefaultData",
            "attributes": [
                {
                    "type": "model",
                    "properties": {}
                },
                {
                    "type": "key",
                    "properties": {
                        "name": "byOrderUid",
                        "queryField": null,
                        "fields": [
                            "order_uid"
                        ]
                    }
                },
                {
                    "type": "auth",
                    "properties": {
                        "rules": [
                            {
                                "provider": "oidc",
                                "ownerField": "partner_uid",
                                "allow": "owner",
                                "operations": [
                                    "read"
                                ],
                                "identityClaim": "https://zolar.de/partner_id"
                            },
                            {
                                "groupClaim": "permissions",
                                "provider": "oidc",
                                "allow": "groups",
                                "groups": [
                                    "installer_app:all:sync"
                                ],
                                "operations": [
                                    "create",
                                    "update",
                                    "delete",
                                    "read"
                                ]
                            }
                        ]
                    }
                }
            ]
        },
        "ComReport": {
            "name": "ComReport",
            "fields": {
                "id": {
                    "name": "id",
                    "isArray": false,
                    "type": "ID",
                    "isRequired": true,
                    "attributes": []
                },
                "partner_uid": {
                    "name": "partner_uid",
                    "isArray": false,
                    "type": "ID",
                    "isRequired": true,
                    "attributes": []
                },
                "order_uid": {
                    "name": "order_uid",
                    "isArray": false,
                    "type": "ID",
                    "isRequired": true,
                    "attributes": []
                },
                "activity_uid": {
                    "name": "activity_uid",
                    "isArray": false,
                    "type": "ID",
                    "isRequired": true,
                    "attributes": []
                },
                "customer_hash": {
                    "name": "customer_hash",
                    "isArray": false,
                    "type": "String",
                    "isRequired": true,
                    "attributes": []
                },
                "order_hash": {
                    "name": "order_hash",
                    "isArray": false,
                    "type": "String",
                    "isRequired": true,
                    "attributes": []
                },
                "state": {
                    "name": "state",
                    "isArray": false,
                    "type": {
                        "enum": "ComReportState"
                    },
                    "isRequired": true,
                    "attributes": []
                },
                "submittedAt": {
                    "name": "submittedAt",
                    "isArray": false,
                    "type": "String",
                    "isRequired": false,
                    "attributes": []
                },
                "formData": {
                    "name": "formData",
                    "isArray": false,
                    "type": {
                        "nonModel": "ComReportFormData"
                    },
                    "isRequired": true,
                    "attributes": []
                },
                "pdfId": {
                    "name": "pdfId",
                    "isArray": false,
                    "type": "String",
                    "isRequired": false,
                    "attributes": []
                },
                "createdAt": {
                    "name": "createdAt",
                    "isArray": false,
                    "type": "AWSDateTime",
                    "isRequired": false,
                    "attributes": [],
                    "isReadOnly": true
                },
                "updatedAt": {
                    "name": "updatedAt",
                    "isArray": false,
                    "type": "AWSDateTime",
                    "isRequired": false,
                    "attributes": [],
                    "isReadOnly": true
                }
            },
            "syncable": true,
            "pluralName": "ComReports",
            "attributes": [
                {
                    "type": "model",
                    "properties": {}
                },
                {
                    "type": "key",
                    "properties": {
                        "name": "byOrderUid",
                        "queryField": null,
                        "fields": [
                            "order_uid"
                        ]
                    }
                },
                {
                    "type": "auth",
                    "properties": {
                        "rules": [
                            {
                                "provider": "oidc",
                                "ownerField": "partner_uid",
                                "allow": "owner",
                                "operations": [
                                    "create",
                                    "update",
                                    "read"
                                ],
                                "identityClaim": "https://zolar.de/partner_id"
                            },
                            {
                                "groupClaim": "permissions",
                                "provider": "oidc",
                                "allow": "groups",
                                "groups": [
                                    "installer_app:all:sync"
                                ],
                                "operations": [
                                    "create",
                                    "update",
                                    "delete",
                                    "read"
                                ]
                            }
                        ]
                    }
                }
            ]
        },
        "ZpcStandardReportShape": {
            "name": "ZpcStandardReportShape",
            "fields": {
                "id": {
                    "name": "id",
                    "isArray": false,
                    "type": "ID",
                    "isRequired": true,
                    "attributes": []
                },
                "updated_at": {
                    "name": "updated_at",
                    "isArray": false,
                    "type": "String",
                    "isRequired": true,
                    "attributes": []
                },
                "partner_uid": {
                    "name": "partner_uid",
                    "isArray": false,
                    "type": "ID",
                    "isRequired": true,
                    "attributes": []
                },
                "order_uid": {
                    "name": "order_uid",
                    "isArray": false,
                    "type": "ID",
                    "isRequired": true,
                    "attributes": []
                },
                "activity_uid": {
                    "name": "activity_uid",
                    "isArray": false,
                    "type": "ID",
                    "isRequired": true,
                    "attributes": []
                },
                "type": {
                    "name": "type",
                    "isArray": false,
                    "type": "String",
                    "isRequired": true,
                    "attributes": []
                },
                "structure": {
                    "name": "structure",
                    "isArray": false,
                    "type": {
                        "nonModel": "ZpcStandardReportStructure"
                    },
                    "isRequired": true,
                    "attributes": []
                },
                "createdAt": {
                    "name": "createdAt",
                    "isArray": false,
                    "type": "AWSDateTime",
                    "isRequired": false,
                    "attributes": [],
                    "isReadOnly": true
                },
                "updatedAt": {
                    "name": "updatedAt",
                    "isArray": false,
                    "type": "AWSDateTime",
                    "isRequired": false,
                    "attributes": [],
                    "isReadOnly": true
                }
            },
            "syncable": true,
            "pluralName": "ZpcStandardReportShapes",
            "attributes": [
                {
                    "type": "model",
                    "properties": {}
                },
                {
                    "type": "key",
                    "properties": {
                        "name": "byOrderUid",
                        "queryField": null,
                        "fields": [
                            "order_uid"
                        ]
                    }
                },
                {
                    "type": "auth",
                    "properties": {
                        "rules": [
                            {
                                "provider": "oidc",
                                "ownerField": "partner_uid",
                                "allow": "owner",
                                "operations": [
                                    "read"
                                ],
                                "identityClaim": "https://zolar.de/partner_id"
                            },
                            {
                                "groupClaim": "permissions",
                                "provider": "oidc",
                                "allow": "groups",
                                "groups": [
                                    "installer_app:all:sync"
                                ],
                                "operations": [
                                    "create",
                                    "update",
                                    "delete",
                                    "read"
                                ]
                            }
                        ]
                    }
                }
            ]
        }
    },
    "enums": {
        "ReportStatus": {
            "name": "ReportStatus",
            "values": [
                "draft",
                "ready",
                "approved",
                "rejected"
            ]
        },
        "ReportType": {
            "name": "ReportType",
            "values": [
                "ac",
                "dc",
                "osv",
                "rework_com",
                "ac_rework",
                "dc_rework",
                "repair_ac",
                "repair_dc",
                "checkup",
                "inspection",
                "rework_checkup",
                "rework_inspection"
            ]
        },
        "ReportItemEntityStatus": {
            "name": "ReportItemEntityStatus",
            "values": [
                "draft",
                "pending",
                "ready"
            ]
        },
        "LqvReportState": {
            "name": "LqvReportState",
            "values": [
                "open",
                "accepted",
                "cancelled"
            ]
        },
        "LqvReportStepState": {
            "name": "LqvReportStepState",
            "values": [
                "approved",
                "declined",
                "submitted",
                "initialized"
            ]
        },
        "LqvReportType": {
            "name": "LqvReportType",
            "values": [
                "ac",
                "dc",
                "osv",
                "rework_com",
                "ac_rework",
                "dc_rework",
                "repair_ac",
                "repair_dc",
                "checkup",
                "inspection",
                "rework_checkup",
                "rework_inspection"
            ]
        },
        "LqvReportItemType": {
            "name": "LqvReportItemType",
            "values": [
                "picture",
                "text",
                "question"
            ]
        },
        "ComReportState": {
            "name": "ComReportState",
            "values": [
                "Draft",
                "Submitted"
            ]
        }
    },
    "nonModels": {
        "ProductQuantity": {
            "name": "ProductQuantity",
            "fields": {
                "product_id": {
                    "name": "product_id",
                    "isArray": false,
                    "type": "ID",
                    "isRequired": true,
                    "attributes": []
                },
                "quantity": {
                    "name": "quantity",
                    "isArray": false,
                    "type": "String",
                    "isRequired": false,
                    "attributes": []
                }
            }
        },
        "Installer": {
            "name": "Installer",
            "fields": {
                "uid": {
                    "name": "uid",
                    "isArray": false,
                    "type": "String",
                    "isRequired": true,
                    "attributes": []
                },
                "name": {
                    "name": "name",
                    "isArray": false,
                    "type": "String",
                    "isRequired": true,
                    "attributes": []
                }
            }
        },
        "ReportStepEntity": {
            "name": "ReportStepEntity",
            "fields": {
                "key": {
                    "name": "key",
                    "isArray": false,
                    "type": "String",
                    "isRequired": true,
                    "attributes": []
                },
                "items": {
                    "name": "items",
                    "isArray": true,
                    "type": {
                        "nonModel": "ReportItemEntity"
                    },
                    "isRequired": true,
                    "attributes": [],
                    "isArrayNullable": false
                }
            }
        },
        "ReportItemEntity": {
            "name": "ReportItemEntity",
            "fields": {
                "key": {
                    "name": "key",
                    "isArray": false,
                    "type": "String",
                    "isRequired": true,
                    "attributes": []
                },
                "title": {
                    "name": "title",
                    "isArray": false,
                    "type": "String",
                    "isRequired": true,
                    "attributes": []
                },
                "content": {
                    "name": "content",
                    "isArray": false,
                    "type": "String",
                    "isRequired": true,
                    "attributes": []
                },
                "type": {
                    "name": "type",
                    "isArray": false,
                    "type": "String",
                    "isRequired": true,
                    "attributes": []
                },
                "required": {
                    "name": "required",
                    "isArray": false,
                    "type": "Boolean",
                    "isRequired": true,
                    "attributes": []
                },
                "position": {
                    "name": "position",
                    "isArray": false,
                    "type": "Int",
                    "isRequired": true,
                    "attributes": []
                },
                "image_uids": {
                    "name": "image_uids",
                    "isArray": true,
                    "type": "String",
                    "isRequired": true,
                    "attributes": [],
                    "isArrayNullable": false
                },
                "questions": {
                    "name": "questions",
                    "isArray": true,
                    "type": {
                        "nonModel": "ReportQuestionEntity"
                    },
                    "isRequired": true,
                    "attributes": [],
                    "isArrayNullable": true
                },
                "status": {
                    "name": "status",
                    "isArray": false,
                    "type": {
                        "enum": "ReportItemEntityStatus"
                    },
                    "isRequired": true,
                    "attributes": []
                }
            }
        },
        "ReportQuestionEntity": {
            "name": "ReportQuestionEntity",
            "fields": {
                "name": {
                    "name": "name",
                    "isArray": false,
                    "type": "String",
                    "isRequired": true,
                    "attributes": []
                },
                "format": {
                    "name": "format",
                    "isArray": false,
                    "type": "String",
                    "isRequired": true,
                    "attributes": []
                },
                "render_format": {
                    "name": "render_format",
                    "isArray": false,
                    "type": "String",
                    "isRequired": true,
                    "attributes": []
                },
                "required": {
                    "name": "required",
                    "isArray": false,
                    "type": "Boolean",
                    "isRequired": true,
                    "attributes": []
                },
                "position": {
                    "name": "position",
                    "isArray": false,
                    "type": "Int",
                    "isRequired": true,
                    "attributes": []
                },
                "multi_answer": {
                    "name": "multi_answer",
                    "isArray": false,
                    "type": "Boolean",
                    "isRequired": true,
                    "attributes": []
                },
                "possible_answers": {
                    "name": "possible_answers",
                    "isArray": true,
                    "type": "String",
                    "isRequired": true,
                    "attributes": [],
                    "isArrayNullable": true
                },
                "answer": {
                    "name": "answer",
                    "isArray": false,
                    "type": "String",
                    "isRequired": false,
                    "attributes": []
                }
            }
        },
        "LqvReportSection": {
            "name": "LqvReportSection",
            "fields": {
                "id": {
                    "name": "id",
                    "isArray": false,
                    "type": "ID",
                    "isRequired": true,
                    "attributes": []
                },
                "title": {
                    "name": "title",
                    "isArray": false,
                    "type": "String",
                    "isRequired": true,
                    "attributes": []
                },
                "steps": {
                    "name": "steps",
                    "isArray": true,
                    "type": {
                        "nonModel": "LqvReportStep"
                    },
                    "isRequired": true,
                    "attributes": [],
                    "isArrayNullable": false
                }
            }
        },
        "LqvReportStep": {
            "name": "LqvReportStep",
            "fields": {
                "id": {
                    "name": "id",
                    "isArray": false,
                    "type": "ID",
                    "isRequired": true,
                    "attributes": []
                },
                "title": {
                    "name": "title",
                    "isArray": false,
                    "type": "String",
                    "isRequired": true,
                    "attributes": []
                },
                "state": {
                    "name": "state",
                    "isArray": false,
                    "type": {
                        "enum": "LqvReportStepState"
                    },
                    "isRequired": true,
                    "attributes": []
                },
                "submitted_by": {
                    "name": "submitted_by",
                    "isArray": false,
                    "type": "String",
                    "isRequired": true,
                    "attributes": []
                },
                "submitted_at": {
                    "name": "submitted_at",
                    "isArray": false,
                    "type": "String",
                    "isRequired": true,
                    "attributes": []
                },
                "validated_by": {
                    "name": "validated_by",
                    "isArray": false,
                    "type": "String",
                    "isRequired": true,
                    "attributes": []
                },
                "validated_at": {
                    "name": "validated_at",
                    "isArray": false,
                    "type": "String",
                    "isRequired": true,
                    "attributes": []
                },
                "items": {
                    "name": "items",
                    "isArray": true,
                    "type": {
                        "nonModel": "LqvReportItem"
                    },
                    "isRequired": true,
                    "attributes": [],
                    "isArrayNullable": false
                }
            }
        },
        "LqvReportItem": {
            "name": "LqvReportItem",
            "fields": {
                "id": {
                    "name": "id",
                    "isArray": false,
                    "type": "ID",
                    "isRequired": true,
                    "attributes": []
                },
                "title": {
                    "name": "title",
                    "isArray": false,
                    "type": "String",
                    "isRequired": true,
                    "attributes": []
                },
                "content": {
                    "name": "content",
                    "isArray": false,
                    "type": "String",
                    "isRequired": true,
                    "attributes": []
                },
                "type": {
                    "name": "type",
                    "isArray": false,
                    "type": {
                        "enum": "LqvReportItemType"
                    },
                    "isRequired": true,
                    "attributes": []
                },
                "position": {
                    "name": "position",
                    "isArray": false,
                    "type": "Int",
                    "isRequired": true,
                    "attributes": []
                },
                "optional": {
                    "name": "optional",
                    "isArray": false,
                    "type": "Boolean",
                    "isRequired": true,
                    "attributes": []
                },
                "possible_answers": {
                    "name": "possible_answers",
                    "isArray": true,
                    "type": "String",
                    "isRequired": true,
                    "attributes": [],
                    "isArrayNullable": true
                },
                "submitted_values": {
                    "name": "submitted_values",
                    "isArray": true,
                    "type": "String",
                    "isRequired": true,
                    "attributes": [],
                    "isArrayNullable": true
                }
            }
        },
        "PVSystem": {
            "name": "PVSystem",
            "fields": {
                "moduleManufacturer": {
                    "name": "moduleManufacturer",
                    "isArray": false,
                    "type": "String",
                    "isRequired": false,
                    "attributes": []
                },
                "moduleType": {
                    "name": "moduleType",
                    "isArray": false,
                    "type": "String",
                    "isRequired": false,
                    "attributes": []
                },
                "amountModules": {
                    "name": "amountModules",
                    "isArray": false,
                    "type": "Int",
                    "isRequired": false,
                    "attributes": []
                },
                "moduleInstalledCapacityInKwp": {
                    "name": "moduleInstalledCapacityInKwp",
                    "isArray": false,
                    "type": "Float",
                    "isRequired": false,
                    "attributes": []
                }
            }
        },
        "Inverter": {
            "name": "Inverter",
            "fields": {
                "manufacturer": {
                    "name": "manufacturer",
                    "isArray": false,
                    "type": "String",
                    "isRequired": false,
                    "attributes": []
                },
                "type": {
                    "name": "type",
                    "isArray": false,
                    "type": "String",
                    "isRequired": false,
                    "attributes": []
                },
                "serialNumber": {
                    "name": "serialNumber",
                    "isArray": false,
                    "type": "String",
                    "isRequired": false,
                    "attributes": []
                },
                "numberOfMppts": {
                    "name": "numberOfMppts",
                    "isArray": false,
                    "type": "Int",
                    "isRequired": false,
                    "attributes": []
                },
                "numberOfStringsPerMppt": {
                    "name": "numberOfStringsPerMppt",
                    "isArray": false,
                    "type": "Int",
                    "isRequired": false,
                    "attributes": []
                }
            }
        },
        "BatteryStorage": {
            "name": "BatteryStorage",
            "fields": {
                "manufacturer": {
                    "name": "manufacturer",
                    "isArray": false,
                    "type": "String",
                    "isRequired": false,
                    "attributes": []
                },
                "type": {
                    "name": "type",
                    "isArray": false,
                    "type": "String",
                    "isRequired": false,
                    "attributes": []
                },
                "serialNumber": {
                    "name": "serialNumber",
                    "isArray": false,
                    "type": "String",
                    "isRequired": false,
                    "attributes": []
                },
                "capacityInKwh": {
                    "name": "capacityInKwh",
                    "isArray": false,
                    "type": "Float",
                    "isRequired": false,
                    "attributes": []
                }
            }
        },
        "Wallbox": {
            "name": "Wallbox",
            "fields": {
                "manufacturer": {
                    "name": "manufacturer",
                    "isArray": false,
                    "type": "String",
                    "isRequired": false,
                    "attributes": []
                },
                "type": {
                    "name": "type",
                    "isArray": false,
                    "type": "String",
                    "isRequired": false,
                    "attributes": []
                },
                "serialNumber": {
                    "name": "serialNumber",
                    "isArray": false,
                    "type": "String",
                    "isRequired": false,
                    "attributes": []
                }
            }
        },
        "CompassHardware": {
            "name": "CompassHardware",
            "fields": {
                "managerSerialNumber": {
                    "name": "managerSerialNumber",
                    "isArray": false,
                    "type": "String",
                    "isRequired": false,
                    "attributes": []
                },
                "boxSerialNumber": {
                    "name": "boxSerialNumber",
                    "isArray": false,
                    "type": "String",
                    "isRequired": false,
                    "attributes": []
                }
            }
        },
        "ComFormGeneralData": {
            "name": "ComFormGeneralData",
            "fields": {
                "operator": {
                    "name": "operator",
                    "isArray": false,
                    "type": "String",
                    "isRequired": false,
                    "attributes": []
                },
                "specialistPlanner": {
                    "name": "specialistPlanner",
                    "isArray": false,
                    "type": "String",
                    "isRequired": false,
                    "attributes": []
                },
                "installer": {
                    "name": "installer",
                    "isArray": false,
                    "type": "String",
                    "isRequired": false,
                    "attributes": []
                },
                "plantLocationStreet": {
                    "name": "plantLocationStreet",
                    "isArray": false,
                    "type": "String",
                    "isRequired": false,
                    "attributes": []
                },
                "plantLocationPostCode": {
                    "name": "plantLocationPostCode",
                    "isArray": false,
                    "type": "String",
                    "isRequired": false,
                    "attributes": []
                },
                "plantLocationCity": {
                    "name": "plantLocationCity",
                    "isArray": false,
                    "type": "String",
                    "isRequired": false,
                    "attributes": []
                }
            }
        },
        "ComFormPvSystem": {
            "name": "ComFormPvSystem",
            "fields": {
                "hasPvSystem": {
                    "name": "hasPvSystem",
                    "isArray": false,
                    "type": "Boolean",
                    "isRequired": true,
                    "attributes": []
                },
                "moduleManufacturer": {
                    "name": "moduleManufacturer",
                    "isArray": false,
                    "type": "String",
                    "isRequired": false,
                    "attributes": []
                },
                "moduleType": {
                    "name": "moduleType",
                    "isArray": false,
                    "type": "String",
                    "isRequired": false,
                    "attributes": []
                },
                "amountOfModules": {
                    "name": "amountOfModules",
                    "isArray": false,
                    "type": "Int",
                    "isRequired": false,
                    "attributes": []
                },
                "installedCapacityInKwp": {
                    "name": "installedCapacityInKwp",
                    "isArray": false,
                    "type": "Float",
                    "isRequired": false,
                    "attributes": []
                },
                "hasLightningProtectionSystemIntegration": {
                    "name": "hasLightningProtectionSystemIntegration",
                    "isArray": false,
                    "type": "Boolean",
                    "isRequired": false,
                    "attributes": []
                },
                "hasPlantOnLowVoltageGridConditions": {
                    "name": "hasPlantOnLowVoltageGridConditions",
                    "isArray": false,
                    "type": "Boolean",
                    "isRequired": false,
                    "attributes": []
                }
            }
        },
        "ComFormGridManagement": {
            "name": "ComFormGridManagement",
            "fields": {
                "powerLimitPercentage": {
                    "name": "powerLimitPercentage",
                    "isArray": false,
                    "type": "Float",
                    "isRequired": false,
                    "attributes": []
                }
            }
        },
        "ComFormInverter": {
            "name": "ComFormInverter",
            "fields": {
                "manufacturer": {
                    "name": "manufacturer",
                    "isArray": false,
                    "type": "String",
                    "isRequired": false,
                    "attributes": []
                },
                "type": {
                    "name": "type",
                    "isArray": false,
                    "type": "String",
                    "isRequired": false,
                    "attributes": []
                },
                "serialNumber": {
                    "name": "serialNumber",
                    "isArray": false,
                    "type": "String",
                    "isRequired": false,
                    "attributes": []
                }
            }
        },
        "ComFormPvGeneratorMpptStringElectricalValues": {
            "name": "ComFormPvGeneratorMpptStringElectricalValues",
            "fields": {
                "openCircuitVoltageInVolts": {
                    "name": "openCircuitVoltageInVolts",
                    "isArray": false,
                    "type": "Float",
                    "isRequired": false,
                    "attributes": []
                },
                "insulationResistanceInMegaOhms": {
                    "name": "insulationResistanceInMegaOhms",
                    "isArray": false,
                    "type": "Float",
                    "isRequired": false,
                    "attributes": []
                },
                "shortCircuitCurrentInAmperes": {
                    "name": "shortCircuitCurrentInAmperes",
                    "isArray": false,
                    "type": "Float",
                    "isRequired": false,
                    "attributes": []
                }
            }
        },
        "ComFormPvGeneratorMppt": {
            "name": "ComFormPvGeneratorMppt",
            "fields": {
                "amountOfModules": {
                    "name": "amountOfModules",
                    "isArray": false,
                    "type": "Int",
                    "isRequired": false,
                    "attributes": []
                },
                "amountOfStrings": {
                    "name": "amountOfStrings",
                    "isArray": false,
                    "type": "Int",
                    "isRequired": false,
                    "attributes": []
                },
                "stringElectricalValues": {
                    "name": "stringElectricalValues",
                    "isArray": true,
                    "type": {
                        "nonModel": "ComFormPvGeneratorMpptStringElectricalValues"
                    },
                    "isRequired": true,
                    "attributes": [],
                    "isArrayNullable": false
                }
            }
        },
        "ComFormPvGenerator": {
            "name": "ComFormPvGenerator",
            "fields": {
                "mppts": {
                    "name": "mppts",
                    "isArray": true,
                    "type": {
                        "nonModel": "ComFormPvGeneratorMppt"
                    },
                    "isRequired": true,
                    "attributes": [],
                    "isArrayNullable": false
                }
            }
        },
        "ComFormElectricalMaasurement": {
            "name": "ComFormElectricalMaasurement",
            "fields": {
                "measuringEquipment": {
                    "name": "measuringEquipment",
                    "isArray": false,
                    "type": "String",
                    "isRequired": false,
                    "attributes": []
                },
                "testingVoltageInVolts": {
                    "name": "testingVoltageInVolts",
                    "isArray": false,
                    "type": "Float",
                    "isRequired": false,
                    "attributes": []
                },
                "weatherConditions": {
                    "name": "weatherConditions",
                    "isArray": false,
                    "type": "String",
                    "isRequired": false,
                    "attributes": []
                },
                "temperatureInCelsius": {
                    "name": "temperatureInCelsius",
                    "isArray": false,
                    "type": "Float",
                    "isRequired": false,
                    "attributes": []
                }
            }
        },
        "ComFormCompass": {
            "name": "ComFormCompass",
            "fields": {
                "managerSerialNumber": {
                    "name": "managerSerialNumber",
                    "isArray": false,
                    "type": "String",
                    "isRequired": false,
                    "attributes": []
                },
                "boxSerialNumber": {
                    "name": "boxSerialNumber",
                    "isArray": false,
                    "type": "String",
                    "isRequired": false,
                    "attributes": []
                }
            }
        },
        "ComFormBatteryStorage": {
            "name": "ComFormBatteryStorage",
            "fields": {
                "hasBatteryStorage": {
                    "name": "hasBatteryStorage",
                    "isArray": false,
                    "type": "Boolean",
                    "isRequired": true,
                    "attributes": []
                },
                "manufacturer": {
                    "name": "manufacturer",
                    "isArray": false,
                    "type": "String",
                    "isRequired": false,
                    "attributes": []
                },
                "typeAndCapacityInKWh": {
                    "name": "typeAndCapacityInKWh",
                    "isArray": false,
                    "type": "String",
                    "isRequired": false,
                    "attributes": []
                },
                "serialNumber": {
                    "name": "serialNumber",
                    "isArray": false,
                    "type": "String",
                    "isRequired": false,
                    "attributes": []
                }
            }
        },
        "ComFormWallbox": {
            "name": "ComFormWallbox",
            "fields": {
                "hasWallbox": {
                    "name": "hasWallbox",
                    "isArray": false,
                    "type": "Boolean",
                    "isRequired": true,
                    "attributes": []
                },
                "manufacturer": {
                    "name": "manufacturer",
                    "isArray": false,
                    "type": "String",
                    "isRequired": false,
                    "attributes": []
                },
                "typeAndPerformanceInKW": {
                    "name": "typeAndPerformanceInKW",
                    "isArray": false,
                    "type": "String",
                    "isRequired": false,
                    "attributes": []
                },
                "serialNumber": {
                    "name": "serialNumber",
                    "isArray": false,
                    "type": "String",
                    "isRequired": false,
                    "attributes": []
                }
            }
        },
        "ComFormBriefingOfOperation": {
            "name": "ComFormBriefingOfOperation",
            "fields": {
                "isDamageFreeInstallation": {
                    "name": "isDamageFreeInstallation",
                    "isArray": false,
                    "type": "Boolean",
                    "isRequired": true,
                    "attributes": []
                },
                "hasProperCableRoutingInspection": {
                    "name": "hasProperCableRoutingInspection",
                    "isArray": false,
                    "type": "Boolean",
                    "isRequired": true,
                    "attributes": []
                },
                "hasComponentInstallationInspection": {
                    "name": "hasComponentInstallationInspection",
                    "isArray": false,
                    "type": "Boolean",
                    "isRequired": true,
                    "attributes": []
                },
                "hasCustomerSwitchgearPVSticker": {
                    "name": "hasCustomerSwitchgearPVSticker",
                    "isArray": false,
                    "type": "Boolean",
                    "isRequired": true,
                    "attributes": []
                },
                "isCleanSiteNoWaste": {
                    "name": "isCleanSiteNoWaste",
                    "isArray": false,
                    "type": "Boolean",
                    "isRequired": true,
                    "attributes": []
                },
                "hasComponentExplanation": {
                    "name": "hasComponentExplanation",
                    "isArray": false,
                    "type": "Boolean",
                    "isRequired": true,
                    "attributes": []
                },
                "hasShutdownExplanationACDC": {
                    "name": "hasShutdownExplanationACDC",
                    "isArray": false,
                    "type": "Boolean",
                    "isRequired": true,
                    "attributes": []
                },
                "hasSystemMonitoringIntroduction": {
                    "name": "hasSystemMonitoringIntroduction",
                    "isArray": false,
                    "type": "Boolean",
                    "isRequired": true,
                    "attributes": []
                },
                "hasOperationIndicators": {
                    "name": "hasOperationIndicators",
                    "isArray": false,
                    "type": "Boolean",
                    "isRequired": true,
                    "attributes": []
                },
                "hasMaintenanceAndCareGuidancePVA": {
                    "name": "hasMaintenanceAndCareGuidancePVA",
                    "isArray": false,
                    "type": "Boolean",
                    "isRequired": true,
                    "attributes": []
                },
                "remarks": {
                    "name": "remarks",
                    "isArray": false,
                    "type": "String",
                    "isRequired": false,
                    "attributes": []
                }
            }
        },
        "ComFormSignatureItem": {
            "name": "ComFormSignatureItem",
            "fields": {
                "name": {
                    "name": "name",
                    "isArray": false,
                    "type": "String",
                    "isRequired": false,
                    "attributes": []
                },
                "date": {
                    "name": "date",
                    "isArray": false,
                    "type": "String",
                    "isRequired": false,
                    "attributes": []
                },
                "dataUrl": {
                    "name": "dataUrl",
                    "isArray": false,
                    "type": "String",
                    "isRequired": false,
                    "attributes": []
                }
            }
        },
        "ComFormSignature": {
            "name": "ComFormSignature",
            "fields": {
                "installer": {
                    "name": "installer",
                    "isArray": false,
                    "type": {
                        "nonModel": "ComFormSignatureItem"
                    },
                    "isRequired": true,
                    "attributes": []
                },
                "client": {
                    "name": "client",
                    "isArray": false,
                    "type": {
                        "nonModel": "ComFormSignatureItem"
                    },
                    "isRequired": true,
                    "attributes": []
                }
            }
        },
        "ComReportFormData": {
            "name": "ComReportFormData",
            "fields": {
                "generalData": {
                    "name": "generalData",
                    "isArray": false,
                    "type": {
                        "nonModel": "ComFormGeneralData"
                    },
                    "isRequired": true,
                    "attributes": []
                },
                "pvSystem": {
                    "name": "pvSystem",
                    "isArray": false,
                    "type": {
                        "nonModel": "ComFormPvSystem"
                    },
                    "isRequired": true,
                    "attributes": []
                },
                "gridManagement": {
                    "name": "gridManagement",
                    "isArray": false,
                    "type": {
                        "nonModel": "ComFormGridManagement"
                    },
                    "isRequired": false,
                    "attributes": []
                },
                "inverters": {
                    "name": "inverters",
                    "isArray": true,
                    "type": {
                        "nonModel": "ComFormInverter"
                    },
                    "isRequired": true,
                    "attributes": [],
                    "isArrayNullable": true
                },
                "pvGenerators": {
                    "name": "pvGenerators",
                    "isArray": true,
                    "type": {
                        "nonModel": "ComFormPvGenerator"
                    },
                    "isRequired": true,
                    "attributes": [],
                    "isArrayNullable": true
                },
                "electricalMeasurement": {
                    "name": "electricalMeasurement",
                    "isArray": false,
                    "type": {
                        "nonModel": "ComFormElectricalMaasurement"
                    },
                    "isRequired": false,
                    "attributes": []
                },
                "compass": {
                    "name": "compass",
                    "isArray": false,
                    "type": {
                        "nonModel": "ComFormCompass"
                    },
                    "isRequired": false,
                    "attributes": []
                },
                "batteryStorage": {
                    "name": "batteryStorage",
                    "isArray": false,
                    "type": {
                        "nonModel": "ComFormBatteryStorage"
                    },
                    "isRequired": true,
                    "attributes": []
                },
                "wallbox": {
                    "name": "wallbox",
                    "isArray": false,
                    "type": {
                        "nonModel": "ComFormWallbox"
                    },
                    "isRequired": true,
                    "attributes": []
                },
                "briefingOfOperation": {
                    "name": "briefingOfOperation",
                    "isArray": false,
                    "type": {
                        "nonModel": "ComFormBriefingOfOperation"
                    },
                    "isRequired": true,
                    "attributes": []
                },
                "signature": {
                    "name": "signature",
                    "isArray": false,
                    "type": {
                        "nonModel": "ComFormSignature"
                    },
                    "isRequired": true,
                    "attributes": []
                }
            }
        },
        "ZpcStandardReportItem": {
            "name": "ZpcStandardReportItem",
            "fields": {
                "key": {
                    "name": "key",
                    "isArray": false,
                    "type": "String",
                    "isRequired": true,
                    "attributes": []
                },
                "uid": {
                    "name": "uid",
                    "isArray": false,
                    "type": "ID",
                    "isRequired": true,
                    "attributes": []
                },
                "position": {
                    "name": "position",
                    "isArray": false,
                    "type": "Int",
                    "isRequired": true,
                    "attributes": []
                }
            }
        },
        "ZpcStandardReportQuestion": {
            "name": "ZpcStandardReportQuestion",
            "fields": {
                "key": {
                    "name": "key",
                    "isArray": false,
                    "type": "String",
                    "isRequired": true,
                    "attributes": []
                },
                "uid": {
                    "name": "uid",
                    "isArray": false,
                    "type": "ID",
                    "isRequired": true,
                    "attributes": []
                },
                "position": {
                    "name": "position",
                    "isArray": false,
                    "type": "Int",
                    "isRequired": true,
                    "attributes": []
                },
                "possibleAnswers": {
                    "name": "possibleAnswers",
                    "isArray": true,
                    "type": "String",
                    "isRequired": true,
                    "attributes": [],
                    "isArrayNullable": true
                },
                "required": {
                    "name": "required",
                    "isArray": false,
                    "type": "Boolean",
                    "isRequired": true,
                    "attributes": []
                },
                "inputAs": {
                    "name": "inputAs",
                    "isArray": false,
                    "type": "String",
                    "isRequired": false,
                    "attributes": []
                }
            }
        },
        "ZpcStandardReportStructure": {
            "name": "ZpcStandardReportStructure",
            "fields": {
                "items": {
                    "name": "items",
                    "isArray": true,
                    "type": {
                        "nonModel": "ZpcStandardReportItem"
                    },
                    "isRequired": true,
                    "attributes": [],
                    "isArrayNullable": false
                },
                "questions": {
                    "name": "questions",
                    "isArray": true,
                    "type": {
                        "nonModel": "ZpcStandardReportQuestion"
                    },
                    "isRequired": true,
                    "attributes": [],
                    "isArrayNullable": false
                }
            }
        }
    },
    "codegenVersion": "3.4.4",
    "version": "be1203cf08c5a2765b8cc45b09784a44"
};