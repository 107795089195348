import { type CommissioningForm } from '../formValidationSchema'
import { ItemTextInline, Section } from './SharedWrappers'

const PdfSectionBatteryStorage = (props: { data: CommissioningForm['batteryStorage'] }) => {
  const {
    data: { manufacturer, typeAndCapacityInKWh, serialNumber, ...rest },
  } = props
  const hasBatteryStorage = rest.hasBatteryStorage === 'yes'
  return (
    <Section label='Batteriespeicher'>
      <ItemTextInline label='Batteriespeicher' value={hasBatteryStorage ? 'Ja' : 'Nein'} />
      {hasBatteryStorage && (
        <>
          <ItemTextInline label='Hersteller' value={manufacturer} />
          <ItemTextInline label='Modell' value={typeAndCapacityInKWh} />
          <ItemTextInline label='Seriennummer' value={serialNumber} />
        </>
      )}
    </Section>
  )
}

export default PdfSectionBatteryStorage
