import { datadogRum } from '@datadog/browser-rum'
import { DataStore } from '@aws-amplify/datastore'
import { serializeError } from 'serialize-error'
import { getDocumentServiceUrl } from '~/config/documents'
import { ComReport } from '~/models'

export type Task = TaskAcDcReportImage | TaskComReportPdf
type TaskState = 'ReadyToProcess' | 'InProgress' | 'Failed'

export const getProcessorFunction = (task: Task) => {
  switch (task.type) {
    case 'AC_DC_REPORT_IMAGE':
      return processAcDcReportImage
    case 'COM_REPORT_PDF':
      return processComReportPdf
    default:
      return undefined
  }
}

const uploadFileToDocumentService = async (
  token: string,
  file: Blob | File,
  customerHash: string,
  orderId: string
): Promise<{ uid: string }> => {
  const formData = new FormData()
  const headers = new Headers()
  const baseUrl = getDocumentServiceUrl()
  const url = new URL(`${baseUrl}/customers/${customerHash}/files`)
  url.searchParams.set('transactionId', orderId)

  formData.append('file', file)
  headers.set('Authorization', `Bearer ${token}`)
  headers.set('x-auth-method', 'auth0')

  const startedAtInMs = Date.now()
  try {
    const response = await fetch(url, { method: 'POST', body: formData, headers })
    const endedAtInMs = Date.now()
    datadogRum.addAction('document_upload_to_server_success', {
      startedAtIso: new Date(startedAtInMs).toISOString(),
      endedAtIso: new Date(endedAtInMs).toISOString(),
      durationInMilliseconds: endedAtInMs - startedAtInMs,
      documentSizeInBytes: file.size,
      documentType: file.type,
    })
    const result = await response.json()
    return { uid: result.name }
  } catch (error) {
    const endedAtInMs = Date.now()
    datadogRum.addAction('document_upload_to_server_failure', {
      startedAtIso: new Date(startedAtInMs).toISOString(),
      endedAtIso: new Date(endedAtInMs).toISOString(),
      durationInMilliseconds: endedAtInMs - startedAtInMs,
      documentSizeInBytes: file.size,
      documentType: file.type,
      error: error,
    })
    throw error
  }
}

// ################################################################################################

type TaskAcDcReportImage = {
  id: string
  type: 'AC_DC_REPORT_IMAGE'
  queuedAtIso: string
  state: TaskState
  payload: {
    blob: Blob
    reportId: string
    itemId: string
    orderId: string
    customerHash: string
  }
}

const processAcDcReportImage = async (
  task: TaskAcDcReportImage,
  getToken: () => Promise<string>
) => {
  console.log('TBD 1')
}

// ################################################################################################

type TaskComReportPdf = {
  id: string
  type: 'COM_REPORT_PDF'
  queuedAtIso: string
  state: TaskState
  payload: {
    blob: Blob
    reportId: string
    orderId: string
    customerHash: string
  }
}

const processComReportPdf = async (task: TaskComReportPdf, getToken: () => Promise<string>) => {
  const {
    payload: { blob, customerHash, orderId, reportId },
  } = task

  const token = await getToken()
  const { uid } = await uploadFileToDocumentService(token, blob, customerHash, orderId)

  const comReports = await DataStore.query(ComReport, (c) => c.id.eq(reportId))

  const comReport = comReports.at(0)

  if (!comReport) throw Error(`Could not find a comReport for id ${reportId} - orderId ${orderId} `)

  const updatedComReport = ComReport.copyOf(comReport, (draft) => {
    draft.pdfId = uid
  })

  await DataStore.save(updatedComReport)
}
