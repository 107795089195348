import { LoadingIndicatorApp } from '~/components/LoadingIndicatorApp'
import { useAppStore } from '~/stores/useAppStore'

export function LoadingOverlay() {
  const loadingOverlay = useAppStore.use.loadingOverlay()

  return loadingOverlay ? (
    <div className='absolute bottom-0 left-0 right-0 top-0 z-10 bg-white'>
      <LoadingIndicatorApp>
        {typeof loadingOverlay === 'string' && <div className='mt-4'>{loadingOverlay}</div>}
      </LoadingIndicatorApp>
    </div>
  ) : null
}
