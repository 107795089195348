import styled from 'styled-components'

type DirectionType = 'top' | 'bottom' | 'left' | 'right'

interface ChevronProps {
  direction: DirectionType
}

export const Chevron = styled.div<ChevronProps>`
  border-style: solid;
  border-width: 1px 1px 0 0;
  height: 5px;
  width: 5px;
  transition: all 0.3s ease-in-out;

  transform: ${(p) => p.direction === 'top' && 'rotate(-45deg)'};
  transform: ${(p) => p.direction === 'left' && 'rotate(-135deg)'};
  transform: ${(p) => p.direction === 'right' && 'rotate(45deg)'};
  transform: ${(p) => p.direction === 'bottom' && 'rotate(135deg)'};
`
