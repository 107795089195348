import { datadogRum } from '@datadog/browser-rum'
import { datadogLogs } from '@datadog/browser-logs'

const DATADOG_RUM_SERVICE = 'installer-app'

const getConfig = () => {
  const enabled = process.env.DATADOG_RUM_ENABLED === 'true'
  const applicationId = process.env.DATADOG_RUM_APPLICATION_ID
  const clientToken = process.env.DATADOG_RUM_CLIENT_TOKEN
  const env = process.env.VITE_ENV_NAME
  const version = process.env.APP_VERSION
  return {
    enabled,
    applicationId,
    clientToken,
    env,
    version,
  }
}

export const initDatadogLogs = () => {
  const { enabled, clientToken, env, version } = getConfig()
  if (!enabled || !clientToken) return
  datadogLogs.init({
    clientToken,
    env,
    service: DATADOG_RUM_SERVICE,
    version,
    site: 'datadoghq.eu',
    forwardErrorsToLogs: true,
    forwardConsoleLogs: ['error', 'warn'],
    forwardReports: 'all',
    sessionSampleRate: 100,
    trackingConsent: 'granted',
  })
}

export const initDatadogRum = () => {
  const { enabled, applicationId, clientToken, env, version } = getConfig()
  if (!enabled || !applicationId || !clientToken) return
  datadogRum.init({
    applicationId,
    clientToken,
    env,
    service: DATADOG_RUM_SERVICE,
    version,
    site: 'datadoghq.eu',
    defaultPrivacyLevel: 'mask-user-input',
    sessionReplaySampleRate: 100,
    sessionSampleRate: 100,
    trackLongTasks: true,
    trackResources: true,
    trackUserInteractions: true,
    trackingConsent: 'granted',
  })
}
