import { Button, Divider, Icon } from '~/components'
import { useAppStore } from '~/stores'

export function BottomModal() {
  const modalState = useAppStore.use.bottomModal()
  const { closeBottomModal } = useAppStore.use.modalActions()

  const onClose = !modalState.enforceAction ? closeBottomModal : undefined

  if (!modalState.visible) return null

  return (
    <div className='absolute h-screen w-full overflow-hidden'>
      <div className='modal backdrop-blur-sm modal-active animate-opacity z-30' onClick={onClose} />
      <div className='absolute modal-dialog max-w-none bottom-0 w-full z-40 bg-white rounded-t-2xl animate-slide-in-bottom'>
        {onClose && (
          <Button
            size='sm'
            variant='outlined-dark'
            isIconButton
            className='absolute right-4 top-2'
            onClick={onClose}
          >
            <Icon icon='general:x-close' size='md' />
          </Button>
        )}
        <div className='text-headline-6 p-4'>{modalState.title}</div>
        <Divider />
        <div className='p-4 max-h-[85vh] overflow-y-auto'>{modalState.description}</div>
        {(modalState.primaryButton || modalState.secondaryButton) && (
          <div className='flex gap-4 px-4 pb-8'>
            {modalState.secondaryButton && (
              <Button
                variant='outlined-dark'
                className='grow'
                onClick={modalState.secondaryButton.action}
              >
                {modalState.secondaryButton.label}
              </Button>
            )}
            {modalState.primaryButton && (
              <Button
                variant='filled-accent'
                className='grow'
                onClick={modalState.primaryButton.action}
              >
                {modalState.primaryButton.label}
              </Button>
            )}
          </div>
        )}
      </div>
    </div>
  )
}
