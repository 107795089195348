import { type MouseEvent } from 'react'
import { NavLink } from 'react-router-dom'
import { type SpriteName } from '@zolargmbh/zolar-icons'
import Icon from './Icon/Icon'

export function Navigation() {
  return (
    <div className='flex justify-around p-3 shadow-md'>
      <NavigationButton to='/overview' icon='zpc:navigation:projects'>
        Projekte
      </NavigationButton>
      <NavigationButton to='/profile' icon='users:user-01'>
        Konto
      </NavigationButton>
    </div>
  )
}

interface NavigationButtonProps extends NavigationBaseProps {
  to: string
}

function NavigationButton(props: NavigationButtonProps) {
  const { to, ...rest } = props

  return props.disabled ? (
    NavigationItem({ isActive: false, ...rest })
  ) : (
    <NavLink to={to}>{({ isActive }) => NavigationItem({ isActive, ...rest })}</NavLink>
  )
}

interface NavigationItemProps extends NavigationBaseProps {
  isActive?: boolean
}

interface NavigationBaseProps {
  icon?: SpriteName
  onClick?: (event: React.MouseEvent<HTMLDivElement>) => void
  disabled?: boolean
  children: string
}

const NavigationItem = (props: NavigationItemProps) => {
  function handleClick(event: MouseEvent<HTMLDivElement>) {
    if (props.disabled) return
    if (props.onClick) {
      props.onClick(event)
    }
  }

  function getClassNames() {
    if (props.disabled) {
      return {
        iconClasses: 'text-grey-500',
        textClasses: 'text-grey-500',
      }
    }
    if (props.isActive) {
      return {
        iconClasses: 'text-accent',
        textClasses: 'text-accent',
      }
    }
    return {
      iconClasses: 'text-icon-dark-primary',
      textClasses: 'text-black',
    }
  }
  const { iconClasses, textClasses } = getClassNames()

  return (
    <div
      className='flex flex-col items-center'
      onClick={(event: React.MouseEvent<HTMLDivElement>) => handleClick(event)}
    >
      {props.icon && <Icon icon={props.icon} size='lg' className={iconClasses} />}
      <span className={textClasses}>{props.children}</span>
    </div>
  )
}
