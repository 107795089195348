import { type MouseEventHandler, type ReactNode, useEffect, useRef, useState } from 'react'
import styled from 'styled-components'
import { Chevron } from '~/components/Chevron'

interface ExpandableProps {
  expanded: boolean
  children: ReactNode
  head?: ReactNode
  onExpand?: MouseEventHandler<HTMLDivElement>
}

export function Expandable({ expanded = false, children, head, onExpand }: ExpandableProps) {
  const contentRef = useRef<HTMLDivElement>(null)
  const [contentHeight, setContentHeight] = useState(0)

  useEffect(() => {
    setContentHeight(expanded ? contentRef.current?.scrollHeight ?? 0 : 0)
  }, [expanded])

  return (
    <>
      {head && (
        <div className='flex items-center' onClick={onExpand}>
          {head}
          <div className='m-4'>
            <Chevron direction={expanded ? 'top' : 'bottom'} className='border-neutral' />
          </div>
        </div>
      )}
      <ContentWrapper maxHeight={contentHeight} ref={contentRef}>
        {children}
      </ContentWrapper>
    </>
  )
}

const ContentWrapper = styled.div<{ maxHeight: number }>`
  max-height: ${(p) => `${p.maxHeight}px`};
  transition: max-height 0.25s ease-in-out;
  overflow: hidden;
`
