import { useAuth0 } from '@auth0/auth0-react'
import { Link } from 'react-router-dom'
import { Button, Divider, Icon, InlineMessage } from '~/components'
import { isProduction } from '~/config/environments'
import { useLogoutPrompt } from '~/hooks'

const APP_VERSION = `v${process.env.APP_VERSION}`

const ZOLAR_FEEDBACK_EMAIL = 'installerapp@zolar.de'
const ZOLAR_FEEDBACK_EMAIL_SUBJECT = 'Installer App Feedback'
const ZOLAR_FEEDBACK_EMAIL_BODY = `Teile hier deine Ideen, Verbesserungsvorschläge oder Probleme mit der App.
Danke vorab für dein Feedback.

App version: ${APP_VERSION}`

export const ZOLAR_FEEDBACK_HREF = `mailto:${ZOLAR_FEEDBACK_EMAIL}?subject=${encodeURIComponent(ZOLAR_FEEDBACK_EMAIL_SUBJECT)}&body=${encodeURIComponent(ZOLAR_FEEDBACK_EMAIL_BODY)}`

const ProfilePage = () => {
  const logoutPrompt = useLogoutPrompt()
  const { user } = useAuth0()

  const fullName =
    `${user?.given_name ?? ''} ${user?.family_name ?? ''}`.trim() || 'Unbekannte:r Benutzer:in'

  return (
    <div className='flex h-full flex-col justify-between'>
      <div className='flex h-full flex-col gap-4 px-4 py-6'>
        <h1 className='text-headline-2'>Konto</h1>
        <div className='flex items-center gap-4'>
          <img
            src={user?.picture}
            alt={user?.name}
            className='h-16 w-16 rounded-full object-cover'
          />
          <div className='flex flex-col'>
            <p className='text-static-xl-regular'>{fullName}</p>
            <p className='text-static-xl-regular'>{user?.email}</p>
          </div>
        </div>
        <Link to='/privacy' className='flex w-full items-center justify-between'>
          <span className='text-static-xl-regular'>Datenschutzerklärung</span>
          <Icon icon='arrows:chevron-right' size='md' />
        </Link>
        <Divider />
        <a href='https://zolar.de/impressum' className='flex w-full items-center justify-between'>
          <span className='text-static-xl-regular'>Impressum</span>
          <Icon icon='arrows:arrow-up-right' size='md' />
        </a>
        <Divider />
        {!isProduction() && (
          <>
            <Link to='/experimental-features' className='flex w-full items-center justify-between'>
              <span className='text-static-xl-regular'>Experimentelle Funktionen</span>
              <Icon icon='arrows:chevron-right' size='md' />
            </Link>
            <Divider />
          </>
        )}
        <a href={ZOLAR_FEEDBACK_HREF} className='mt-10'>
          <InlineMessage variant='success' icon='social:envelope' headline='Feedback geben'>
            <p>Teile mit uns deine Ideen, Verbesserungsvorschläge oder Probleme mit der App.</p>
            <p className='link-dark link-sm px-0 mt-3'>{ZOLAR_FEEDBACK_EMAIL}</p>
          </InlineMessage>
        </a>
      </div>
      <div className='flex flex-col gap-4 px-4 py-6'>
        <Button variant='outlined-dark' size='lg' className='w-full' onClick={logoutPrompt}>
          Ausloggen
        </Button>
        <p className='text-static-md-regular text-center'>Version: {APP_VERSION}</p>
      </div>
    </div>
  )
}

export default ProfilePage
