import { CheckboxGroup, FormInput } from '~/components'

const FormBriefingOfOperation = () => {
  return (
    <div className='flex flex-col gap-6'>
      <CheckboxGroup label='Abschnitt #1'>
        <FormInput
          type='checkbox'
          name='briefingOfOperation.isDamageFreeInstallation'
          label='Anlage und Gebäude ohne sichtbare Schäden durch die Montage'
        />
        <FormInput
          type='checkbox'
          name='briefingOfOperation.hasProperCableRoutingInspection'
          label='Sichtprüfung auf ordnungsgemäße Kabelführung'
        />
        <FormInput
          type='checkbox'
          name='briefingOfOperation.hasComponentInstallationInspection'
          label='Sichtprüfung Installation der Komponente'
        />
        <FormInput
          type='checkbox'
          name='briefingOfOperation.hasCustomerSwitchgearPVSticker'
          label='PV Aufkleber auf Schaltschrank des Kunden'
        />
        <FormInput
          type='checkbox'
          name='briefingOfOperation.isCleanSiteNoWaste'
          label='Die Baustelle wurde sauber und ohne Abfall hinterlassen'
        />
      </CheckboxGroup>
      <CheckboxGroup label='Abschnitt #2'>
        <FormInput
          type='checkbox'
          name='briefingOfOperation.hasComponentExplanation'
          label='Erklärung der Komponenten und deren Funktionen'
        />
        <FormInput
          type='checkbox'
          name='briefingOfOperation.hasShutdownExplanationACDC'
          label='Erklärung der Abschalteinrichtung (AC&DC)'
        />
        <FormInput
          type='checkbox'
          name='briefingOfOperation.hasSystemMonitoringIntroduction'
          label='Vorstellung des Anlagen-Monitorings'
        />
        <FormInput
          type='checkbox'
          name='briefingOfOperation.hasOperationIndicators'
          label='Kontrollmöglichkeiten die auf einen korrekten Betrieb der Anlage hinweisen'
        />
        <FormInput
          type='checkbox'
          name='briefingOfOperation.hasMaintenanceAndCareGuidancePVA'
          label='Hinweise auf Wartung und Pflege der PVA'
        />
      </CheckboxGroup>
      <FormInput
        type='textarea'
        name='briefingOfOperation.remarks'
        label='Bemerkungen'
        maxLength={500}
      />
    </div>
  )
}

export default FormBriefingOfOperation
