// @ts-check
import { initSchema } from '@aws-amplify/datastore';
import { schema } from './schema';

const ReportStatus = {
  "DRAFT": "draft",
  "READY": "ready",
  "APPROVED": "approved",
  "REJECTED": "rejected"
};

const ReportType = {
  "AC": "ac",
  "DC": "dc",
  "OSV": "osv",
  "REWORK_COM": "rework_com",
  "AC_REWORK": "ac_rework",
  "DC_REWORK": "dc_rework",
  "REPAIR_AC": "repair_ac",
  "REPAIR_DC": "repair_dc",
  "CHECKUP": "checkup",
  "INSPECTION": "inspection",
  "REWORK_CHECKUP": "rework_checkup",
  "REWORK_INSPECTION": "rework_inspection"
};

const ReportItemEntityStatus = {
  "DRAFT": "draft",
  "PENDING": "pending",
  "READY": "ready"
};

const LqvReportState = {
  "OPEN": "open",
  "ACCEPTED": "accepted",
  "CANCELLED": "cancelled"
};

const LqvReportStepState = {
  "APPROVED": "approved",
  "DECLINED": "declined",
  "SUBMITTED": "submitted",
  "INITIALIZED": "initialized"
};

const LqvReportType = {
  "AC": "ac",
  "DC": "dc",
  "OSV": "osv",
  "REWORK_COM": "rework_com",
  "AC_REWORK": "ac_rework",
  "DC_REWORK": "dc_rework",
  "REPAIR_AC": "repair_ac",
  "REPAIR_DC": "repair_dc",
  "CHECKUP": "checkup",
  "INSPECTION": "inspection",
  "REWORK_CHECKUP": "rework_checkup",
  "REWORK_INSPECTION": "rework_inspection"
};

const LqvReportItemType = {
  "PICTURE": "picture",
  "TEXT": "text",
  "QUESTION": "question"
};

const ComReportState = {
  "DRAFT": "Draft",
  "SUBMITTED": "Submitted"
};

const { Activity, Comment, OrderDocument, Order, Product, Appointment, Report, LqvReport, ComReportDefaultData, ComReport, ZpcStandardReportShape, ProductQuantity, Installer, ReportStepEntity, ReportItemEntity, ReportQuestionEntity, LqvReportSection, LqvReportStep, LqvReportItem, PVSystem, Inverter, BatteryStorage, Wallbox, CompassHardware, ComFormGeneralData, ComFormPvSystem, ComFormGridManagement, ComFormInverter, ComFormPvGeneratorMpptStringElectricalValues, ComFormPvGeneratorMppt, ComFormPvGenerator, ComFormElectricalMaasurement, ComFormCompass, ComFormBatteryStorage, ComFormWallbox, ComFormBriefingOfOperation, ComFormSignatureItem, ComFormSignature, ComReportFormData, ZpcStandardReportItem, ZpcStandardReportQuestion, ZpcStandardReportStructure } = initSchema(schema);

export {
  Activity,
  Comment,
  OrderDocument,
  Order,
  Product,
  Appointment,
  Report,
  LqvReport,
  ComReportDefaultData,
  ComReport,
  ZpcStandardReportShape,
  ReportStatus,
  ReportType,
  ReportItemEntityStatus,
  LqvReportState,
  LqvReportStepState,
  LqvReportType,
  LqvReportItemType,
  ComReportState,
  ProductQuantity,
  Installer,
  ReportStepEntity,
  ReportItemEntity,
  ReportQuestionEntity,
  LqvReportSection,
  LqvReportStep,
  LqvReportItem,
  PVSystem,
  Inverter,
  BatteryStorage,
  Wallbox,
  CompassHardware,
  ComFormGeneralData,
  ComFormPvSystem,
  ComFormGridManagement,
  ComFormInverter,
  ComFormPvGeneratorMpptStringElectricalValues,
  ComFormPvGeneratorMppt,
  ComFormPvGenerator,
  ComFormElectricalMaasurement,
  ComFormCompass,
  ComFormBatteryStorage,
  ComFormWallbox,
  ComFormBriefingOfOperation,
  ComFormSignatureItem,
  ComFormSignature,
  ComReportFormData,
  ZpcStandardReportItem,
  ZpcStandardReportQuestion,
  ZpcStandardReportStructure
};