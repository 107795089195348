import {
  type LqvReport,
  type LqvReportItem,
  type LqvReportSection,
  type LqvReportStep,
  LqvReportStepState,
} from '~/models'
import { type Button, type TagColor } from '~/components'
import { type SpriteName } from '@zolargmbh/zolar-icons'
import { type ComponentProps } from 'react'

// DISCLAIMER: since LqvReport is not fully supported yet from the BFF we stopped halfway through

export function getSectionByStepId(
  lqvReport: LqvReport | undefined,
  stepId: string | undefined
): LqvReportSection | undefined {
  return lqvReport?.sections?.find((section) => {
    return section.steps.find((step) => step.id === stepId)
  })
}

export function getStepById(
  lqvReport: LqvReport | undefined,
  stepId: string | undefined
): LqvReportStep | undefined {
  return lqvReport?.sections?.flatMap((section) => section.steps).find((step) => step.id === stepId)
}

export function getStepStateProperties(state: string) {
  return stepStateProperties[state as LqvReportStepState]
}

export function hasPicture(item: LqvReportItem): boolean {
  return (item.submitted_values && item.submitted_values.length > 0) || false
}

export interface StepStateProperties {
  title: string
  tagColor: TagColor
  icon?: SpriteName
  variant: ComponentProps<typeof Button>['variant']
  bgColor: 'bg-moss-400' | 'bg-mist-500' | 'bg-white'
}

const stepStateProperties: Record<LqvReportStepState, StepStateProperties> = {
  [LqvReportStepState.SUBMITTED]: {
    title: 'Übermittelt',
    tagColor: 'tag-lavender',
    icon: 'zpc:appointment:scheduled',
    variant: 'filled-dark',
    bgColor: 'bg-white',
  },
  [LqvReportStepState.INITIALIZED]: {
    title: 'Offen',
    tagColor: 'tag-sky',
    icon: 'time:lqv-empty',
    variant: 'filled-dark',
    bgColor: 'bg-white',
  },
  [LqvReportStepState.DECLINED]: {
    title: 'Revision erbeten',
    tagColor: 'tag-warning',
    icon: 'zpc:lqv-revision',
    variant: 'filled-dark',
    bgColor: 'bg-white',
  },
  [LqvReportStepState.APPROVED]: {
    title: 'Abgenommen',
    tagColor: 'tag-success',
    icon: 'general:check',
    variant: 'filled-dark',
    bgColor: 'bg-white',
  },
}
