import { type ComponentProps, type ReactElement, type ReactNode, forwardRef, useId } from 'react'
import classNames from 'classnames'
import type FormInput from './FormInput'

export type RadioProps = ComponentProps<'input'> & {
  id?: string
  name: string
  label: ReactNode
  description?: ReactNode
  size?: 'sm' | 'md'
  hasError?: boolean
  type?: 'radio'
}

const Radio = forwardRef<HTMLInputElement, RadioProps>((props, ref) => {
  const { id, label, description, size, disabled, className = '', hasError, ...rest } = props
  const generatedId = useId()
  const radioId = id ?? generatedId

  return (
    <div
      className={classNames('form-radio', className, {
        'form-radio-disabled': disabled,
        'form-radio-error': hasError,
        'form-radio-small': size === 'sm',
      })}
    >
      <input
        ref={ref}
        className='form-radio-input'
        id={radioId}
        type='radio'
        disabled={disabled}
        {...rest}
      />
      <label className='form-radio-label' htmlFor={radioId}>
        <span>{label}</span>
        {description && <span className='form-radio-description'>{description}</span>}
      </label>
    </div>
  )
})

Radio.displayName = 'Radio'

export default Radio

type RadioGroupProps = {
  label?: ReactNode
  children: ReactElement<typeof Radio>[] | ReactElement<typeof FormInput>[]
  description?: ReactNode
  orientation?: 'vertical' | 'horizontal'
  size?: 'sm' | 'md'
  className?: string
  error?: ReactNode
}

export const RadioGroup = (props: RadioGroupProps) => {
  const { label, description, orientation, size, error, className = '', children } = props
  return (
    <div
      role='radiogroup'
      className={classNames('form-radio-group gap-0', className, {
        'form-radio-group-horizontal': orientation === 'horizontal',
        'form-radio-group-small': size === 'sm',
      })}
    >
      {label && <span className='form-radio-group-label !text-static-lg-semibold'>{label}</span>}
      {children}
      {description && <span className='form-radio-group-description'>{description}</span>}
      {error && <span className='form-radio-group-description text-red'>{error}</span>}
    </div>
  )
}
