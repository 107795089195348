import { Text } from '@react-pdf/renderer'
import { Section } from './SharedWrappers'

const PdfSectionBriefingOfOperation = (props: { data: string | undefined | null }) => {
  const { data } = props
  return (
    <Section label='Zusätzliche Bemerkungen'>
      <Text
        style={{
          fontSize: 10,
          fontWeight: 400,
          color: '#212121',
        }}
      >
        {data || '-'}
      </Text>
    </Section>
  )
}

export default PdfSectionBriefingOfOperation
