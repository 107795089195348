import { useState } from 'react'
import { Divider, Expandable, Icon } from '~/components'
import { type GetBlobByUrl, type ProductWithQuantity, getProductManuals } from '~/hooks'
import { openCacheDocumentByUrlInTab } from '~/utils/openCacheDocumentByUrlInTab'

interface ProductsProps {
  getBlobByUrl: GetBlobByUrl
  showLoadingOverlay?: (loading: boolean | string) => void
  products: ProductWithQuantity[]
}

export function Products(props: ProductsProps) {
  const { products } = props
  const [expandedId, setExpandedId] = useState<string>('')

  return products.length > 0 ? (
    <>
      <Divider className='mb-6 mt-6' />
      <div className='flex flex-col gap-6'>
        <div className='flex w-full flex-col'>
          <div className='text-headline-2'>Hardware Komponenten</div>
          {products.map((product) => (
            <ProductAccordion
              product={product}
              getBlobByUrl={props.getBlobByUrl}
              showLoadingOverlay={props.showLoadingOverlay}
              expanded={expandedId === product.id}
              onExpandToggle={() => setExpandedId(product.id === expandedId ? '' : product.id)}
              key={product.id}
            />
          ))}
        </div>
      </div>
    </>
  ) : null
}

interface ProductAccordionProps {
  product: ProductWithQuantity
  getBlobByUrl: GetBlobByUrl
  showLoadingOverlay?: (loading: boolean | string) => void
  expanded?: boolean
  onExpandToggle: () => void
}

function ProductAccordion(props: ProductAccordionProps) {
  const { product, expanded, showLoadingOverlay } = props

  const manuals = getProductManuals(product)

  const productHeader = (
    <div className='text-headline-6 grow'>
      {product.quantity} × {product.family}
      <div className='text-body-2-regular grow text-dark-tertiary'>{product.name}</div>
    </div>
  )
  return (
    <div className='mt-4'>
      {manuals.length > 0 ? (
        <Expandable expanded={!!expanded} onExpand={props.onExpandToggle} head={productHeader}>
          <div className='pt-2'>
            {manuals.map((manual, index) => {
              return (
                <div
                  className='mb-2 flex items-center rounded border border-dark-3 p-3 pl-4 pr-4'
                  key={manual.url + index}
                  onClick={() =>
                    openCacheDocumentByUrlInTab(
                      manual.url,
                      props.getBlobByUrl,
                      false,
                      undefined,
                      showLoadingOverlay
                    )
                  }
                >
                  <div className='text-body-2-bold grow pr-4 text-left'>
                    {manual.label}
                    <div className='text-description-1 break-all text-dark-tertiary'>
                      {manual.filename}
                    </div>
                  </div>
                  <Icon icon='general:download' size='md' />
                </div>
              )
            })}
          </div>
        </Expandable>
      ) : (
        productHeader
      )}
    </div>
  )
}
