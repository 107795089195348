import { type ReactNode } from 'react'
import { Link, useParams } from 'react-router-dom'
import { Button, Icon, OrderCoverImage } from '~/components'
import { useOrder } from '~/hooks'

const ActivityPageLayout = ({ children }: { children: ReactNode | ReactNode[] }) => {
  const { orderId = '' } = useParams()
  if (!orderId) throw new Error('Invalid order id')
  const order = useOrder(orderId)

  return (
    <div className='relative'>
      <Link to={`/order/${orderId}`}>
        <Button
          isIconButton
          variant='filled-light'
          size='lg'
          className='absolute left-4 top-8 z-10'
        >
          <Icon icon='arrows:arrow-left' />
        </Button>
      </Link>
      <OrderCoverImage
        orderId={order.id}
        imageUrl={order.getCoverImage()}
        documents={order.getDocuments()}
        products={order.getProductsWithQuantity()}
        reports={order.getReports()}
      />
      <div className='relative mx-4 mt-6'>{children}</div>
    </div>
  )
}

export default ActivityPageLayout
