import { Fragment } from 'react'
import { useAuth0 } from '@auth0/auth0-react'
import Logo from '~/svg/logo.svg?react'
import { Icon, Button } from '~/components'
import { getCookie } from '~/utils/cookie'
import { Link } from 'react-router-dom'

export function Login() {
  const { loginWithRedirect } = useAuth0()

  return (
    <Fragment>
      <PwaInstallationInstructions />
      <Footer />
      <div className='container h-full w-full flex-col gap-4 pt-8'>
        <Logo className='mb-4 h-10 w-10 rounded' />
        <h1 className='text-display-3 mb-4'>
          Willkommen in der
          <br />
          Installer-App
        </h1>
        <p className='mb-4'>
          Um die App zu nutzen, logge Dich
          <br />
          bitte ein.
        </p>
        <Button
          className='mb-4 w-full'
          variant='filled-accent'
          onClick={() => loginWithRedirect({ authorizationParams: { prompt: 'login' } })}
        >
          Einloggen
        </Button>
      </div>
    </Fragment>
  )
}

function PwaInstallationInstructions() {
  const ua = navigator.userAgent
  const isPWA = window.matchMedia('(display-mode: standalone)').matches
  const isIOS = /iP(ad|hone|od)/.test(ua)
  const isSafari = ua.includes('Safari') && !/(iOS|OPT|OPR|mercury)/i.test(ua)
  const isChrome =
    /(Chrome|CriOS)/.test(ua) &&
    ua.includes('Android') &&
    !/(FxiOS|EdgiOS|OPT|OPR|mercury)/i.test(ua)
  const osPlatform = getCookie('app-platform')

  if (isPWA || osPlatform === 'iOS App Store') return <Fragment />

  return (
    <div className='absolute bottom-12 left-4 right-4 rounded-md border border-grey-400'>
      <div className='p-4'>
        <Icon icon='general:info-circle' size='lg' className='mb-2 text-accent-500' />
        <h1 className='text-headline-2 mb-4'>Installiere die App</h1>
        {((isIOS && !isSafari) || (!isIOS && !isChrome)) && (
          <Fragment>
            Um diese App zu installieren und alle Features vollumfänglich nutzen zu können, öffne
            den Link bitte im {isIOS ? 'Safari' : 'Chrome'} Browser.
          </Fragment>
        )}
        {((isIOS && isSafari) || (!isIOS && isChrome)) && (
          <p className='mb-4'>
            Um die Anwendungserfahrung zu verbessern, füge diese App zum Home-Bildschirm hinzu.
          </p>
        )}
        {isIOS && isSafari && (
          <Fragment>
            <b className='flex items-center gap-2'>
              1. Tippe auf das
              {/* should be replaced by an actual icon as soon as this is available in the icons library */}
              <svg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 512 512' className='w-4'>
                <path d='M273 7c-9.4-9.4-24.6-9.4-33.9 0L127 119c-9.4 9.4-9.4 24.6 0 33.9s24.6 9.4 33.9 0l71-71V328c0 13.3 10.7 24 24 24s24-10.7 24-24V81.9l71 71c9.4 9.4 24.6 9.4 33.9 0s9.4-24.6 0-33.9L273 7zM72 32C32.2 32 0 64.2 0 104V440c0 39.8 32.2 72 72 72H440c39.8 0 72-32.2 72-72V104c0-39.8-32.2-72-72-72h-8c-13.3 0-24 10.7-24 24s10.7 24 24 24h8c13.3 0 24 10.7 24 24V440c0 13.3-10.7 24-24 24H72c-13.3 0-24-10.7-24-24V104c0-13.3 10.7-24 24-24h8c13.3 0 24-10.7 24-24s-10.7-24-24-24H72z' />
              </svg>
              -Symbol
            </b>
            <b>2. Wähle dann "Zum Home-Bildschirm".</b>
          </Fragment>
        )}
        {!isIOS && isChrome && (
          <Fragment>
            <b className='block'>1. Tippe auf das Menüsymbol.</b>
            <b>2. Wähle dann "Zum Startbildschirm hinzufügen".</b>
          </Fragment>
        )}
      </div>
    </div>
  )
}

function Footer() {
  return (
    <div className='absolute bottom-0 left-0 mt-12 flex w-full justify-center p-2 pb-4'>
      <Link to='/privacy' className='underline'>
        Datenschutzerklärung
      </Link>
    </div>
  )
}
