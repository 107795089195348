import { Button, Icon } from '~/components'
import { useEffect, useState } from 'react'
import { useAppStore } from '~/stores'

type AppointmentsFilterButtonProps = {
  onClick: () => void
}

export function AppointmentsFilterButton({ onClick }: AppointmentsFilterButtonProps) {
  const appointmentsFilter = useAppStore.use.appointmentsFilter()
  const [enabledFilters, setEnabledFilters] = useState<0 | 1 | 2>(0)

  useEffect(() => {
    const isActivityFilterEnabled =
      !appointmentsFilter.activityType.includes('dc') ||
      !appointmentsFilter.activityType.includes('ac')
    const isAssignedToMeFilterEnabled = appointmentsFilter.assignedToMe

    if (isAssignedToMeFilterEnabled && isActivityFilterEnabled) {
      setEnabledFilters(2)
      return
    }
    if (isAssignedToMeFilterEnabled || isActivityFilterEnabled) {
      setEnabledFilters(1)
      return
    }
    setEnabledFilters(0)
  }, [appointmentsFilter])

  return (
    <Button className='relative' variant='outlined-dark' isIconButton onClick={onClick}>
      <Icon icon='general:settings-toggle' className='!size-5 !mr-0' />
      {enabledFilters > 0 && (
        <span className='absolute -top-2 -right-2 inline-flex items-center justify-center size-[1.38rem] rounded-full bg-black text-white text-description-1 font-bold'>
          {enabledFilters}
        </span>
      )}
    </Button>
  )
}
