import { useRef, useState } from 'react'
import {
  TransformComponent,
  TransformWrapper,
  type ReactZoomPanPinchContentRef,
} from 'react-zoom-pan-pinch'

interface TouchableImageProps {
  children: React.ReactNode
}
export const TouchableImage = (props: TouchableImageProps) => {
  const [panningDisabled, setPanningDisabled] = useState(true)
  const ref = useRef<ReactZoomPanPinchContentRef>(null)

  return (
    <TransformWrapper
      ref={ref}
      doubleClick={{ disabled: true }}
      initialScale={1}
      panning={{ disabled: panningDisabled }}
      onZoomStop={(ref) => {
        if (ref.state.scale <= 1) {
          setPanningDisabled(true)
        } else if (panningDisabled) {
          setPanningDisabled(false)
        }
      }}
    >
      {({ resetTransform, instance, setTransform }) => (
        <div
          className='relative left-0 top-0 h-full w-full'
          onClick={(e) => {
            if (e.detail !== 2 || !ref.current?.instance?.wrapperComponent) return

            if (instance.getContext().state.scale <= 1) {
              setPanningDisabled(false)

              const maxScale = 4
              const wrapper = instance.wrapperComponent ?? ref.current.instance.wrapperComponent
              const coordinates = {
                x: wrapper.getBoundingClientRect().left - e.clientX,
                y: wrapper.getBoundingClientRect().top - e.clientY,
              }

              setTransform(
                (coordinates.x + wrapper?.clientWidth / (maxScale * 2)) * maxScale,
                (coordinates.y + wrapper?.clientHeight / (maxScale * 2)) * maxScale,
                maxScale
              )
            } else {
              resetTransform()
              setPanningDisabled(true)
            }
          }}
        >
          <TransformComponent
            wrapperStyle={{ width: '100%', height: '100%' }}
            contentStyle={{ width: '100%', height: '100%' }}
          >
            {props.children}
          </TransformComponent>
        </div>
      )}
    </TransformWrapper>
  )
}
