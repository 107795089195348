import Icon from '../Icon/Icon'
import { type SpriteName } from '@zolargmbh/zolar-icons'
import classNames from 'classnames'
import { forwardRef } from 'react'

type BannerVariants = 'success' | 'warning' | 'error' | 'update'
const DEFAULT_BANNER_ICONS: Record<BannerVariants, SpriteName> = {
  success: 'general:check-circle',
  warning: 'general:alert-triangle',
  error: 'general:alert-circle',
  update: 'zpc:navigation:updates',
}

type Props = {
  variant: BannerVariants
  /**
   * @default
   * variant='success' -> 'general:check-circle'
   * variant='warning' -> 'general:alert-triangle'
   * variant='error' -> 'general:alert-circle'
   * variant='update' -> 'zpc:navigation:updates'
   */
  icon?: SpriteName
  headline: string
  content?: string
}
const Banner = forwardRef<HTMLHeadElement, Props>((props, ref) => {
  const { variant, icon = DEFAULT_BANNER_ICONS[variant], headline, content } = props
  return (
    <header
      ref={ref}
      className={classNames('product-banner', {
        'product-banner-success': variant === 'success',
        'product-banner-warning': variant === 'warning',
        'product-banner-error': variant === 'error',
        'product-banner-update': variant === 'update',
      })}
    >
      <Icon className='product-banner-icon' icon={icon} />
      <h2 className='product-banner-headline'>{headline}</h2>
      {content && <p className='product-banner-content'>{content}</p>}
    </header>
  )
})

Banner.displayName = 'Banner'

export default Banner
