import { type ReportItemEntity } from '~/models'
import { localImagePrefix } from '~/components/Documentation/utitlities'

export function itemIsReady(item: ReportItemEntity): boolean {
  if (Array.isArray(item.questions)) {
    return false
  }

  // From the Mozilla docu: "every() acts like the "for all" quantifier in mathematics"
  // meaning it returns true for empty arrays. Obviously when required the array can't be empty
  return (
    item.image_uids.length > 0 &&
    item.image_uids.every((imageUid) => !imageUid.includes(localImagePrefix))
  )
}
