import { type Report, ReportStatus } from '~/models'
import { DataStore } from 'aws-amplify/datastore'

interface SaveReportOptions {
  forced?: boolean
}

export async function saveReport(report: Report, options?: SaveReportOptions): Promise<Report> {
  if (report.status === ReportStatus.READY && !options?.forced) {
    console.log('not saving, already submitted')
    return report
  }

  return DataStore.save(report)
}
