import { Fragment, type ReactNode } from 'react'
import { DefaultModal } from './DefaultModal'
import { BottomModal } from './BottomModal'

interface ModalAction {
  label: string
  action: () => void
}

/**
 * @deprecated This interface is being deprecated alongside its component `ModalOld`. Please use `Modal` from `src > components > Modal > Modal.tsx` instead
 */
export interface ModalState {
  visible: boolean
  title: string
  description: ReactNode
  enforceAction?: boolean
  primaryButton?: ModalAction
  secondaryButton?: ModalAction
}

/**
 *
 * @deprecated This component is being deprecated. Please use `Modal` from `src > components > Modal > Modal.tsx` instead
 */
export function ModalOld() {
  // Note: Modals have to remain in DOM as CSS transitions are used,
  // CSS classes are adapted based on the specified visible variant.
  return (
    <Fragment>
      <DefaultModal />
      <BottomModal />
    </Fragment>
  )
}
