import { Icon } from '~/components'
import { type Activity, type Appointment, type Order } from '~/models'
import { type ActivityType } from '~/stores'
import { ZPC_BASE_URL } from '~/config/environments'
import { activityTypeHeaders } from '~/components/Order/Appointments'
import React, { type ReactNode } from 'react'
import { type SpriteName } from '@zolargmbh/zolar-icons'
import { useExperimentalFeatures } from '~/pages/ExperimentalFeaturesPage'
import { Link } from 'react-router-dom'

type AppointmentsProps = {
  activities: Activity[]
  appointments: Appointment[]
  order: Order
}

export function ActivitiesWithoutAppointments(props: AppointmentsProps) {
  const activitiesWithoutAppointments = props.activities.filter(
    (activity) =>
      activity.activity_type === 'com' &&
      !props.appointments.find((appointment) => appointment.activity_uid === activity.id)
  )

  if (!activitiesWithoutAppointments.length) {
    return null
  }

  return (
    <div className='mt-2 flex flex-col gap-2'>
      <div className='text-static-lg-semibold'>Weitere Dokumentationen</div>
      <div className='flex flex-col gap-4'>
        {activitiesWithoutAppointments.map((activity) => (
          <ActivityCard
            key={activity.id}
            activity={activity}
            order={props.order}
            showDocumentationLink
            label={activityTypeHeaders[activity.activity_type as ActivityType].label}
            icon={activityTypeHeaders[activity.activity_type as ActivityType].icon}
          />
        ))}
      </div>
    </div>
  )
}

type ActivityCardProps = {
  activity: Activity
  showDocumentationLink?: boolean
  order?: Order
  label: string
  icon: SpriteName
}

const ActivityCard = (props: ActivityCardProps) => {
  const { icon, label, activity, showDocumentationLink } = props
  const { FF_COMMISSIONING_REPORT_IN_APP_ACTIVE } = useExperimentalFeatures()

  // FF, to be removed after implementatio
  const linkArrowIcon =
    FF_COMMISSIONING_REPORT_IN_APP_ACTIVE && activity.activity_type === 'com'
      ? 'arrows:arrow-right'
      : 'arrows:arrow-up-right'

  return (
    <ActivityCardWrapper {...props}>
      <div className='relative flex items-center justify-between gap-3 rounded border border-dark-3 px-4 py-2'>
        <div className='absolute left-0 top-1/2 z-0 h-[85%] w-1 -translate-y-1/2 rounded-l-[5000px] rounded-r-full bg-sky-800' />
        <div className='flex flex-wrap items-center gap-x-2'>
          <Icon size='md' icon={icon} />
          <p className='text-static-lg-semibold flex-1 text-dark-primary'>{label}</p>
          <p className='text-static-md-regular w-full text-sky-800'>Offen</p>
        </div>
        {showDocumentationLink && (
          <Icon size='md' icon={linkArrowIcon} className='text-icon-dark-tertiary' />
        )}
      </div>
    </ActivityCardWrapper>
  )
}

const ActivityCardWrapper = (props: ActivityCardProps & { children: ReactNode }) => {
  const { children, activity, order, showDocumentationLink } = props
  const { FF_COMMISSIONING_REPORT_IN_APP_ACTIVE } = useExperimentalFeatures()

  const getZPCHref = () => {
    if (!order?.extern_hash || !activity) return ''
    switch (activity.activity_type) {
      case 'com':
        return `${ZPC_BASE_URL}/project-details/extern/${order.extern_hash}/commissioning`
      case 'osv':
        return `${ZPC_BASE_URL}/project-details/extern/${order.extern_hash}/osv`
      case 'ac_rework':
        return `${ZPC_BASE_URL}/project-details/extern/${order.extern_hash}/electro/rework/${activity.id}`
      case 'dc_rework':
        return `${ZPC_BASE_URL}/project-details/extern/${order.extern_hash}/roof/rework/${activity.id}`
      default:
        return ''
    }
  }

  const zpcLink = getZPCHref()

  // FF, to be removed after implementatio
  if (FF_COMMISSIONING_REPORT_IN_APP_ACTIVE && activity.activity_type === 'com') {
    return <Link to={`/order/${order?.id}/${activity.id}/com`}>{children}</Link>
  }

  if (showDocumentationLink && zpcLink) {
    return (
      <a target='_blank' href={zpcLink} rel='noreferrer'>
        {children}
      </a>
    )
  }

  return <>{children}</>
}
