import { FormProvider } from 'react-hook-form'
import { Link, type LoaderFunction, useLocation, useNavigate, useParams } from 'react-router-dom'
import { Button, Icon, toast } from '~/components'
import { type MouseEventHandler, type ReactNode, useState } from 'react'
import classNames from 'classnames'
import FormGeneralData from './FormGeneralData'
import FormPvSystem from './FormPvSystem'
import FormGridManagement from './FormGridManagement'
import FormInverter from './FormInverters'
import FormBatteryStorage from './FormBatteryStorage'
import FormElectricalMeasurement from './FormElectricalMeasurement'
import FormPvGenerators from './FormPvGenerators'
import FormWallbox from './FormWallbox'
import FormCompassManager from './FormCompassManager'
import FormBriefingOfOperation from './FormBriefingOfOperation'
import FormSummary from './FormSummary'
import FormSignature from './FormSignature'
import { pdf } from '@react-pdf/renderer'
import PdfCommissioningContent from './Pdf/PdfCommissioningContent'
import * as Sentry from '@sentry/react'
import useCommissioningForm from './useCommissioningForm'
import { type CommissioningForm } from './formValidationSchema'

import { useAppStore } from '~/stores'

export type CommissioningStepName =
  | 'generalData'
  | 'pvSystem'
  | 'gridManagement'
  | 'inverters'
  | 'pvGenerators'
  | 'electricalMeasurement'
  | 'compass'
  | 'batteryStorage'
  | 'wallbox'
  | 'briefingOfOperation'
  | 'summary'
  | 'signature'

export type CommissioningDocumentationParams = {
  orderId: string
  activityId: string
  stepItemId: CommissioningStepName | 'submitted'
}

export const loader: LoaderFunction = async ({ params }) => {
  const { orderId, activityId } = params
  const { orders, activities } = useAppStore.getState()
  const order = orders.find(({ id }) => id === orderId)
  const activity = activities.find(
    ({ id, order_uid }) => id === activityId && order_uid === orderId
  )
  if (!order || !activity) throw new Error('Order or activity not found')
  return null
}

const CommissioningDocumentationPage = () => {
  const { stepItemId } = useParams<CommissioningDocumentationParams>()
  const { formMethods, onSubmit } = useCommissioningForm()
  const hasPvSystem = formMethods.watch('pvSystem.hasPvSystem') === 'yes'

  if (stepItemId === 'submitted') return <Submitted formValues={formMethods.getValues()} />

  const availableSteps = getAvailableStepsByHasPvSystem(hasPvSystem)

  const currentStepIdx = availableSteps.findIndex((step) => step.name === stepItemId)

  if (currentStepIdx < 0) throw new Error('Invalid step')

  const { Component, headline } = availableSteps[currentStepIdx]

  return (
    <div className='flex flex-col h-screen w-full'>
      <Header headline={headline} />
      <form noValidate onSubmit={onSubmit}>
        <FormProvider {...formMethods}>
          <div className='w-full flex flex-col pb-30 px-4 pt-6'>{Component}</div>
          <Footer steps={availableSteps} />
        </FormProvider>
      </form>
    </div>
  )
}

export default CommissioningDocumentationPage

const Header = ({ headline }: { headline: string }) => {
  const { orderId = '', activityId = '' } = useParams<CommissioningDocumentationParams>()
  return (
    <div className='w-full bg-black flex justify-between gap-2 items-center p-4'>
      <div className='flex flex-col gap-1'>
        <h2 className='text-light-secondary text-subheadline-3'>Inbetriebnahme</h2>
        <h1 className='text-light-primary text-headline-6'>{headline}</h1>
      </div>
      <Link to={`/order/${orderId}/${activityId}/com`}>
        <Button isIconButton size='lg' variant='text-light'>
          <Icon icon='general:x-close' size='md' />
        </Button>
      </Link>
    </div>
  )
}

const Footer = ({ steps }: { steps: CommissioningStep[] }) => {
  const {
    orderId = '',
    activityId = '',
    stepItemId,
  } = useParams<CommissioningDocumentationParams>()
  const navigate = useNavigate()
  const location = useLocation()

  const currentStepIdx = steps.findIndex((step) => step.name === stepItemId)

  const onPrevious: MouseEventHandler = () => {
    const prevStep = steps.at(currentStepIdx - 1)
    if (!prevStep) return
    navigate(`/documentation/${orderId}/${activityId}/com/${prevStep.name}`)
  }

  const onNext: MouseEventHandler = (e) => {
    e.preventDefault()
    const nextStep = steps.at(currentStepIdx + 1)
    if (!nextStep) return
    navigate(`/documentation/${orderId}/${activityId}/com/${nextStep.name}`)
  }

  const currentStepName = steps.at(currentStepIdx)?.name || ''
  const isSummaryPreviousRoute = !!location.state?.isSummaryPreviousRoute
  const isSignatureNext = steps.at(currentStepIdx + 1)?.name === 'signature'
  const isSummaryNext = steps.at(currentStepIdx + 1)?.name === 'summary'
  const isLastStep = currentStepIdx === steps.length - 1
  const showStepCounter = !(isSummaryNext || isLastStep)
  const showPreviousButton = currentStepIdx !== 0
  const totalStepsWithoutSummaryAndSignature = steps.filter(
    (step) => !['summary', 'signature'].includes(step.name)
  ).length

  if (isSummaryPreviousRoute)
    return (
      <div className='fixed bottom-0 left-0 w-full px-4 pb-6 pt-2 grid place-items-center bg-white shadow-md'>
        <Link to={`/documentation/${orderId}/${activityId}/com/summary#${currentStepName}`}>
          <Button variant='filled-dark' size='lg'>
            Zusammenfassung
          </Button>
        </Link>
      </div>
    )

  if (isSummaryNext || isSignatureNext || isLastStep)
    return (
      <div className='fixed bottom-0 left-0 w-full px-4 pb-6 pt-2 flex items-center justify-between bg-white shadow-md'>
        <Button variant='outlined-dark' size='lg' onClick={onPrevious}>
          Zurück
        </Button>
        <Button
          type={isLastStep ? 'submit' : 'button'}
          variant='filled-accent'
          size='lg'
          {...(!isLastStep && { onClick: onNext, type: 'submit' })}
        >
          {isSummaryNext && 'Zusammenfassung'}
          {isSignatureNext && 'Zur Unterschrift'}
          {isLastStep && 'An zolar übermitteln'}
        </Button>
      </div>
    )

  return (
    <div className='fixed bottom-0 left-0 w-full px-4 pb-6 pt-2 grid grid-cols-3 items-center bg-white shadow-md'>
      <span>
        {showPreviousButton && (
          <Button variant='outlined-dark' size='lg' onClick={onPrevious}>
            Zurück
          </Button>
        )}
      </span>
      {showStepCounter && (
        <span className='flex justify-center'>
          {currentStepIdx + 1}/{totalStepsWithoutSummaryAndSignature}
        </span>
      )}
      <span
        className={classNames('flex justify-end', {
          'col-span-2': !showStepCounter,
        })}
      >
        <Button variant='filled-dark' size='lg' onClick={onNext}>
          Weiter
        </Button>
      </span>
    </div>
  )
}

const Submitted = ({ formValues }: { formValues: CommissioningForm }) => {
  const { orderId = '', activityId = '' } = useParams<CommissioningDocumentationParams>()
  const order = useAppStore.use.orders().find((order) => order.id === orderId)
  const [isDownloadingPdf, setIsDownloadingPdf] = useState(false)

  const onDownloadReport = async () => {
    setIsDownloadingPdf(true)
    try {
      const blob = await pdf(<PdfCommissioningContent formValues={formValues} />).toBlob()
      const uri = URL.createObjectURL(blob)
      const link = document.createElement('a')
      link.download = `Pruefbericht-${order?.customer_name || order?.order_name}.pdf`
      link.href = uri
      document.body.appendChild(link)
      link.click()
      document.body.removeChild(link)
      URL.revokeObjectURL(uri)
    } catch (error) {
      Sentry.captureException(error, { extra: { orderId, activityId } })
      toast.spawn({
        variant: 'error',
        headline: 'Fehler beim PDF-Download',
        content:
          'Es tut uns leid, aber es gab ein Problem beim Generieren und Herunterladen des PDF-Dokuments. Bitte versuchen Sie es später erneut.',
      })
    }
    setIsDownloadingPdf(false)
  }

  return (
    <div className='flex flex-col h-screen w-full'>
      <Header headline='Übermittelt' />
      <div className='flex flex-col items-center gap-4 mt-20 px-8 pb-8.5 flex-1'>
        <div className='grid place-items-center bg-green-100 rounded-full p-2.5 border-8 border-green-50'>
          <Icon icon='general:check-circle' className='text-success' size='lg' />
        </div>
        <h4 className='text-headline-4'>Geschafft!</h4>
        <p className='text-body-1-regular text-center'>
          Wir prüfen den eingereichten Inbetriebnahmebericht und melden uns bei dir zeitnah mit den
          Ergebnissen.
        </p>
        <Button
          variant='outlined-dark'
          className='w-full mt-auto'
          size='lg'
          disabled={isDownloadingPdf}
          onClick={onDownloadReport}
        >
          <Icon icon='files:file-download' />
          <span>Report Herunterladen</span>
        </Button>
        <Link to={`/order/${orderId}`} className='w-full'>
          <Button variant='filled-dark' size='lg' className='w-full'>
            Projektdetails
          </Button>
        </Link>
      </div>
    </div>
  )
}

const getAvailableStepsByHasPvSystem = (hasPvSystem: boolean) => {
  if (!hasPvSystem)
    return ALL_STEPS_IN_ORDER.filter(
      (step) =>
        ![
          'gridManagement',
          'inverters',
          'electricalMeasurement',
          'pvGenerators',
          'compass',
        ].includes(step.name)
    )
  return ALL_STEPS_IN_ORDER
}

type CommissioningStep = {
  name: CommissioningStepName
  headline: string
  Component: ReactNode
}

const ALL_STEPS_IN_ORDER: CommissioningStep[] = [
  {
    name: 'generalData',
    headline: 'Allgemeine Daten',
    Component: <FormGeneralData />,
  },
  {
    name: 'pvSystem',
    headline: 'PV System',
    Component: <FormPvSystem />,
  },
  {
    name: 'gridManagement',
    headline: 'Einspeisemanagement',
    Component: <FormGridManagement />,
  },
  {
    name: 'inverters',
    headline: 'Wechselrichter',
    Component: <FormInverter />,
  },
  {
    name: 'pvGenerators',
    headline: 'PV Generator',
    Component: <FormPvGenerators />,
  },
  {
    name: 'electricalMeasurement',
    headline: 'Elektrische Messung',
    Component: <FormElectricalMeasurement />,
  },
  {
    name: 'compass',
    headline: 'Compass Manager',
    Component: <FormCompassManager />,
  },
  {
    name: 'batteryStorage',
    headline: 'Batteriespeicher',
    Component: <FormBatteryStorage />,
  },
  {
    name: 'wallbox',
    headline: 'Wallbox',
    Component: <FormWallbox />,
  },
  {
    name: 'briefingOfOperation',
    headline: 'Einweisung des Betriebes',
    Component: <FormBriefingOfOperation />,
  },
  {
    name: 'summary',
    headline: 'Zusammenfassung',
    Component: <FormSummary />,
  },
  {
    name: 'signature',
    headline: 'Unterschrift',
    Component: <FormSignature />,
  },
]
