import { create } from 'zustand'
import { type ModalState } from '~/components'
import {
  type Activity,
  type Appointment,
  type Comment,
  type ComReportDefaultData,
  type LqvReport,
  type Order,
  type OrderDocument,
  type Product,
  type Report,
  type ComReport,
  type ZpcStandardReportShape,
} from '~/models'
import { devtools, persist } from 'zustand/middleware'
import { createSelectors } from '~/utils/createSelectors'

const emptyModalProps = {
  visible: false,
  title: '',
  description: '',
}

export const activityTypes = [
  'ac',
  'dc',
  'com',
  'osv',
  'vivi_osv',
  'rework_com',
  'mc',
  'ms',
  'ac_rework',
  'dc_rework',
  'repair_ac',
  'repair_dc',
  'checkup',
  'inspection',
  'rework_checkup',
  'rework_inspection',
] as const

export type ActivityType = (typeof activityTypes)[number]

export type AppointmentsFilter = {
  activityType: ActivityType[]
  assignedToMe: boolean
}

export interface DataStoreStatus {
  ready: boolean
  models: { [key: string]: boolean }
  network: 'offline' | 'online'
  syncQueriesReady: boolean
}

export type Timeframe = 'future' | 'past'

interface UseAppStore {
  defaultModal: ModalState
  bottomModal: ModalState
  modalActions: {
    setDefaultModal: (modal: Omit<ModalState, 'visible'>) => void
    closeDefaultModal: () => void
    setBottomModal: (modal: Omit<ModalState, 'visible'>) => void
    closeBottomModal: () => void
  }
  timeframe: Timeframe
  setTimeframe: (timeframe: Timeframe) => void
  appointmentsFilter: AppointmentsFilter
  setAppointmentsFilter: (appointmentsFilter: AppointmentsFilter) => void
  activities: Activity[]
  appointments: Appointment[]
  comments: Comment[]
  products: Product[]
  orders: Order[]
  orderDocuments: OrderDocument[]
  reports: Report[]
  lqvReports: LqvReport[]
  comReportDefaultData: ComReportDefaultData[]
  comReports: ComReport[]
  reportShapes: ZpcStandardReportShape[]
  datastoreModelActions: {
    setActivities: (activities: Activity[]) => void
    setAppointments: (appointments: Appointment[]) => void
    setComments: (comments: Comment[]) => void
    setProducts: (products: Product[]) => void
    setOrders: (orders: Order[]) => void
    setOrderDocuments: (orderDocuments: OrderDocument[]) => void
    setReports: (reports: Report[]) => void
    setLqvReports: (lqvReports: LqvReport[]) => void
    setComReportDefaultData: (comReportDefaultData: ComReportDefaultData[]) => void
    setComReports: (comReports: ComReport[]) => void
    setZpcStandardReportShape: (reportShape: ZpcStandardReportShape[]) => void
  }
  loadingOverlay: boolean | string
  showLoadingOverlay: (loading: boolean | string) => void
  dataStoreStatus: DataStoreStatus
  dataStoreFullStatus: DataStoreStatus
  updateDataStoreStatus: (status: Partial<DataStoreStatus>) => void
  updateDataStoreFullStatus: (status: Partial<DataStoreStatus>) => void
}

export const useAppStore = createSelectors(
  create<UseAppStore>()(
    devtools(
      persist(
        (set, get) => ({
          defaultModal: emptyModalProps,
          bottomModal: emptyModalProps,
          modalActions: {
            setDefaultModal: (modal) => set({ defaultModal: { ...modal, visible: true } }),
            closeDefaultModal: () =>
              set({ defaultModal: { ...get().defaultModal, visible: false } }),
            setBottomModal: (modal) => set({ bottomModal: { ...modal, visible: true } }),
            closeBottomModal: () => set({ bottomModal: { ...get().bottomModal, visible: false } }),
          },
          timeframe: 'future',
          setTimeframe: (timeframe) => set({ timeframe }),
          appointmentsFilter: {
            activityType: [...activityTypes],
            assignedToMe: false,
          },
          setAppointmentsFilter: (appointmentsFilter) => set({ appointmentsFilter }),
          activities: [],
          appointments: [],
          comments: [],
          products: [],
          orders: [],
          orderDocuments: [],
          reports: [],
          lqvReports: [],
          comReportDefaultData: [],
          comReports: [],
          reportShapes: [],
          datastoreModelActions: {
            setActivities: (activities) => {
              set({ activities })
            },
            setAppointments: (appointments) => {
              set({ appointments })
            },
            setComments: (comments) => {
              set({ comments })
            },
            setProducts: (products) => {
              set({ products })
            },
            setOrders: (orders) => {
              set({ orders })
            },
            setOrderDocuments: (orderDocuments) => {
              set({ orderDocuments })
            },
            setReports: (reports) => {
              set({ reports })
            },
            setLqvReports: (lqvReports) => {
              set({ lqvReports })
            },
            setComReportDefaultData: (comReportDefaultData) => {
              set({ comReportDefaultData })
            },
            setComReports: (comReports) => {
              set({ comReports })
            },
            setZpcStandardReportShape: (reportShapes) => {
              set({ reportShapes })
            },
          },
          loadingOverlay: false,
          showLoadingOverlay: (loadingOverlay) => set({ loadingOverlay }),
          dataStoreStatus: {
            network: navigator.onLine ? 'online' : 'offline',
            models: {},
            ready: false,
            syncQueriesReady: false,
          },
          dataStoreFullStatus: {
            network: navigator.onLine ? 'online' : 'offline',
            models: {},
            ready: false,
            syncQueriesReady: false,
          },
          updateDataStoreStatus: (partialStatus) => {
            set((state) => ({ dataStoreStatus: { ...state.dataStoreStatus, ...partialStatus } }))
          },
          updateDataStoreFullStatus: (partialStatus) => {
            set((state) => ({
              dataStoreFullStatus: { ...state.dataStoreFullStatus, ...partialStatus },
            }))
          },
        }),
        {
          name: 'installerApp',
          partialize: (state) => ({
            timeframe: state.timeframe,
            appointmentsFilter: state.appointmentsFilter,
          }),
        }
      ),
      { name: 'AppStore' }
    )
  )
)
