import '@zolargmbh/design-system/tailwind.css'
import ReactDOM from 'react-dom/client'
import { App } from './App'
import { Auth0Provider } from '@auth0/auth0-react'
import { getApiAudience, getApiAuthority, getAppClientId, scope } from './config/auth'
import React, { useState } from 'react'
import { ModalOld, LoadingOverlay } from '~/components'
import { deleteClientData } from '~/services/cache'
import useServiceWorkerPrompt from './hooks/useServiceWorkerPrompt'
import { initGoogleTagManager, useTrackingConsent } from './config/googleTagManager'

initGoogleTagManager()

const root = ReactDOM.createRoot(document.getElementById('root') as HTMLElement)
root.render(
  <React.StrictMode>
    <AppFrame />
  </React.StrictMode>
)

function AppFrame() {
  const [isClearing, setIsClearing] = useState(false)
  useServiceWorkerPrompt()
  useTrackingConsent()

  return (
    <>
      <ModalOld />
      <LoadingOverlay />
      <Auth0Provider
        domain={getApiAuthority()}
        clientId={getAppClientId()}
        useRefreshTokens
        onRedirectCallback={async (_, user) => {
          if (localStorage.getItem('user_id') !== user?.sub) {
            setIsClearing(true)
            await deleteClientData()
            localStorage.setItem('user_id', user?.sub || '')
            setIsClearing(false)
          }
        }}
        authorizationParams={{
          redirect_uri: window.location.origin,
          audience: getApiAudience(),
          scope,
        }}
        cacheLocation='localstorage'
      >
        {isClearing ? '' : <App />}
      </Auth0Provider>
    </>
  )
}
