import { type LqvReport } from '~/models'
import { Button, Divider, Icon, Tag } from '~/components'
import { Fragment } from 'react'
import { useNavigate } from 'react-router-dom'
import classNames from 'classnames'
import { type StepStateProperties, getStepStateProperties } from '~/services/lqvReport'

interface ActivityLqvDocumentationProps {
  orderId: string
  activityType: string
  report: LqvReport
}

export function ActivityLqvDocumentation(props: ActivityLqvDocumentationProps) {
  const { orderId, activityType, report } = props

  return (
    <Fragment>
      <div className='flex'>
        <h1 className='text-headline-4 flex-grow'>
          Dokumentation {activityType === 'ac' ? 'Elektroinstallation' : 'Dachinstallation'}
        </h1>
      </div>
      <Divider className='mb-6 mt-6 bg-neutral' />
      {report.sections.map((section, index) => {
        return (
          <div className='pb-6' key={'step-' + activityType + '-' + index}>
            <h1 className='text-subheadline-2 mb-4'>{section.title}</h1>
            <div className='flex flex-col justify-between gap-2'>
              {section.steps.map((step) => {
                return (
                  <Tile
                    key={step.id}
                    title={step.title}
                    stateProperties={getStepStateProperties(step.state)}
                    from={`/order/${orderId}/${activityType}`}
                    to={`/documentation/lqv/${orderId}/${activityType}/${step.id}`}
                  />
                )
              })}
            </div>
          </div>
        )
      })}
    </Fragment>
  )
}

interface TileProps {
  title: string
  stateProperties: StepStateProperties
  to: string
  from: string
}

function Tile(props: TileProps) {
  const navigate = useNavigate()
  const data = props.stateProperties

  return (
    <div className={classNames('rounded-md border border-dark-3 p-4', data.bgColor)}>
      <div className='flex flex-row items-center justify-between'>
        <div>
          <div className='text-body-2-bold mb-2'>{props.title}</div>
          <Tag small tagColor={data.tagColor} label={data.title} icon={data.icon} />
        </div>
        <Button
          variant={data.variant}
          isIconButton
          size='sm'
          onClick={() => {
            if (props.to) navigate(props.to, { state: { from: props.from } })
          }}
        >
          <Icon icon='arrows:arrow-right' />
        </Button>
      </div>
    </div>
  )
}
