import { globeEnvName, isProduction } from './environments'

export const baseApiName = 'installer-app-api'

export const getApiAuthority = () => {
  return isProduction() ? 'login.zolar.de' : 'login.zolar.rocks'
}

export const getApiAudience = () => {
  return isProduction()
    ? `https://${baseApiName}.zolar.de`
    : `https://${globeEnvName}-${baseApiName}.zolar.rocks`
}
export const getAppClientId = () => {
  return isProduction() ? 'Hvx8Kkuf1lc5izSiSxudsiKhO2ILSv61' : '2xroVI5IHiFgp6XNVNmPlm0XMS8YTAtU'
}

export const scope =
  'openid profile email https://zolar.de/partner_id https://zolar.de/installer_id offline_access'
