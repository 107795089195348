import { type GroupedTaskProgress, useTaskStore } from '~/stores/useTaskStore'
import { type ComponentProps, useEffect, useRef } from 'react'
import { useOrders } from '../useOrders'
import { type Alert, toast } from '~/components'
import { useLocation, useNavigate } from 'react-router-dom'
import { useAppStore } from '~/stores'
import { type Id } from 'react-toastify'

export function useTaskManagerProgress(
  orderId: string,
  group?: string
): Omit<GroupedTaskProgress, 'payload'> | undefined {
  const progress = useTaskStore.use.progress()
  const orderProgress = progress[orderId]
  if (!orderProgress) {
    return
  }
  if (group) {
    return orderProgress[group]
  }
  return Object.values(orderProgress).reduce(
    (accumulatedProgress, progress) => {
      return {
        value: accumulatedProgress.value + progress.value,
        total: accumulatedProgress.total + progress.total,
      }
    },
    { value: 0, total: 0 }
  )
}

const isCameraViewPage = (pathname: string) => /documentation\/.*\/(ac|dc)\/.*$/.test(pathname)

const progressToast = (toastId: Id | undefined, alertProps: ComponentProps<typeof Alert>) => {
  const isToastActive = toastId ? toast.isActive(toastId) : false
  if (isToastActive) {
    toast.update(toastId!, alertProps, {
      autoClose: false,
      toastId,
      onClose: alertProps.onClose,
    })
  } else {
    toast.spawn(alertProps, {
      autoClose: false,
      toastId,
      onClose: alertProps.onClose,
    })
  }
}

const successToast = (toastId: Id | undefined, alertProps: ComponentProps<typeof Alert>) => {
  const isToastActive = toastId ? toast.isActive(toastId) : false
  if (isToastActive) {
    toast.update(toastId!, alertProps, {
      autoClose: 5000,
      toastId,
      onClose: alertProps.onClose,
    })
  } else {
    toast.spawn(alertProps, {
      autoClose: 5000,
      toastId,
      onClose: alertProps.onClose,
    })
  }
}

export const useUploadProgress = () => {
  const navigate = useNavigate()
  const { pathname } = useLocation()
  const { network } = useAppStore.use.dataStoreStatus()
  const progress = useTaskStore.use.progress()
  const clearTaskProgressByOrderId = useTaskStore.use.actions().clearTaskProgressByOrderId
  const { getById } = useOrders()
  const reports = useAppStore.use.reports()
  const dismissedProgressToast = useRef<Map<Id, true>>(new Map())

  useEffect(() => {
    if (network === 'online') {
      dismissedProgressToast.current.clear()
    }
  }, [network])

  useEffect(() => {
    Object.entries(progress).forEach(([orderId, groups]) => {
      const { total, currentlyDone, reportId } = Object.values(groups).reduce<{
        total: number
        currentlyDone: number
        reportId: string | undefined
      }>(
        (acc, { total: t, value: v, payload }) => {
          acc.total += t ?? 0
          acc.currentlyDone += v ?? 0
          // @ts-expect-error payload is a union of 2 types with different structure, one for upload and one for download
          // if reportId is present it means that this is an upload task (at least at the moment we don't have other kinds)
          if (!acc.reportId && payload && payload.reportId) acc.reportId = payload.reportId
          return acc
        },
        { total: 0, currentlyDone: 0, reportId: undefined }
      )
      const order = getById(orderId)
      const report = reports.find((report) => report.id === reportId)
      const toastId = `toast-${orderId}`
      const isToastActive = toast.isActive(toastId)
      const isToastDismissedButInProgress = dismissedProgressToast.current.get(toastId)
      const progressValue = total === 0 ? 100 : Math.ceil((100 / total) * currentlyDone)

      if (!order || !report || isCameraViewPage(pathname) || network === 'offline') {
        if (isToastActive) toast.dismiss(toastId)
        return
      }

      if (progressValue < 100 && !isToastDismissedButInProgress) {
        progressToast(toastId, {
          variant: 'loading',
          progressValue,
          headline: 'Dateien werden hochgeladen',
          content: `Bitte schließe die App nicht, bis alle Dateien fertig sind. Es sind ${currentlyDone}/${total} verbleibend.`,
          cta: {
            label: 'Zur Dokumentation',
            onClick: () => {
              navigate(`/order/${order?.id}/${report.report_type.toLowerCase()}`)
            },
          },
          onClose: () => {
            dismissedProgressToast.current.set(toastId, true)
          },
        })
      }
      if (progressValue === 100) {
        successToast(toastId, {
          variant: 'success',
          headline: 'Dateien erfolgreich hochgeladen',
          content: (
            <span>
              Deine Dateien zu{' '}
              <b>
                {order?.order_number} - {order?.customer_name}
              </b>{' '}
              wurden hochgeladen. Vielen Dank!
            </span>
          ),
          onClose: () => {
            dismissedProgressToast.current.delete(toastId)
          },
        })
        clearTaskProgressByOrderId(orderId)
      }
    })
  }, [progress, getById, navigate, clearTaskProgressByOrderId, reports, pathname, network])
}
