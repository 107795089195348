import { Report } from '~/models'
import { DataStore } from 'aws-amplify/datastore'

export async function getLatestReport(report: Report): Promise<Report> {
  const existingReport = await DataStore.query(Report, report.id)
  if (existingReport) return existingReport
  return DataStore.save(report)
}

export async function getLatestReportById(reportId: string): Promise<Report> {
  const report = await DataStore.query(Report, reportId)

  if (report === undefined) {
    throw Error(`Could not find a report for id ${reportId} `)
  }
  return report
}
