import { useEffect, useRef } from 'react'
import { useAuth0 } from '@auth0/auth0-react'
import { emitter, initEventHandlers } from './taskEventEmitter'
import { type Task } from './taskProcessors'
import { create } from 'zustand'
import { datadogRum } from '@datadog/browser-rum'
import { serializeError } from 'serialize-error'
import { getAllTasksFromIdb } from './taskIdbOperations'

// Export only used in testsuite
export const ERROR_MESSAGES = {
  syncFailed: 'Syncing zustand state with idb failed',
} as const

const onOnline = () => emitter.emit('online')

// Export only used in testsuite
export const syncStateWithIdb = async () => {
  try {
    const tasks = await getAllTasksFromIdb()
    const readyToProcess = []
    const inProgress = []
    const failed = []

    for (const task of tasks) {
      if (task.state === 'ReadyToProcess') readyToProcess.push(task)
      if (task.state === 'InProgress') inProgress.push(task)
      if (task.state === 'Failed') failed.push(task)
    }

    useTaskManagerState.getState().update({
      readyToProcess,
      inProgress,
      failed,
    })
  } catch (error) {
    datadogRum.addError(error, {
      message: ERROR_MESSAGES.syncFailed,
    })
  }
}

type TaskManagerState = {
  readyToProcess: Task[]
  inProgress: Task[]
  failed: Task[]
  update: (lists: Omit<Partial<TaskManagerState>, 'update'>) => void
}

export const useTaskManagerState = create<TaskManagerState>((set, get) => ({
  readyToProcess: [],
  inProgress: [],
  failed: [],
  update: (lists) => {
    set({
      ...get(),
      ...lists,
    })
  },
}))

export const useTaskManagerStart = () => {
  const isActive = useRef(false)
  const { getAccessTokenSilently, isAuthenticated } = useAuth0()

  useEffect(() => {
    if (!isActive.current && isAuthenticated) {
      initEventHandlers(getAccessTokenSilently, syncStateWithIdb)
      emitter.emit('start')
      window.addEventListener('online', onOnline)
      isActive.current = true
    }
    return () => {
      emitter.all.clear()
      window.removeEventListener('online', onOnline)
      isActive.current = false
    }
  }, [getAccessTokenSilently, isAuthenticated])
}
