import classNames from 'classnames'
import { type ComponentProps, type ReactElement, type ReactNode, forwardRef } from 'react'
import type FormInput from './FormInput'

export type CheckboxProps = ComponentProps<'input'> & {
  name: string
  label?: ReactNode
  size?: 'sm' | 'md'
  description?: ReactNode
  hasError?: boolean
  type?: 'checkbox'
  variant?: 'plain' | 'card'
}

const Checkbox = forwardRef<HTMLInputElement, CheckboxProps>((props, ref) => {
  const { label, size, description, hasError, disabled, variant, className = '', ...rest } = props

  return (
    <label
      className={classNames('form-checkbox', className, {
        'form-checkbox-small': size === 'sm',
        'form-checkbox-disabled': disabled,
        'form-checkbox-error': hasError,
        'form-checkbox-card': variant === 'card',
      })}
    >
      <input
        ref={ref}
        className='form-checkbox-input'
        disabled={disabled}
        type='checkbox'
        {...rest}
      />
      <span className='form-checkbox-label'>{label}</span>
      {description && <span className='form-checkbox-description'>{description}</span>}
    </label>
  )
})

Checkbox.displayName = 'Checkbox'

export default Checkbox

type CheckboxGroupProps = {
  label?: ReactNode
  children: ReactElement<typeof Checkbox>[] | ReactElement<typeof FormInput>[]
  description?: ReactNode
  orientation?: 'vertical' | 'horizontal'
  size?: 'sm' | 'md'
  className?: string
  error?: ReactNode
  variant?: 'plain' | 'card'
}

export const CheckboxGroup = (props: CheckboxGroupProps) => {
  const {
    label,
    description,
    orientation,
    size = 'md',
    error,
    variant,
    className = '',
    children,
  } = props

  if (variant === 'card') return <CheckboxCardGroup {...props}>{children}</CheckboxCardGroup>

  return (
    <div
      role='group'
      className={classNames('form-checkbox-group', className, {
        'form-checkbox-group-horizontal': orientation === 'horizontal',
        'form-checkbox-group-small': size === 'sm',
      })}
    >
      {label && <span className='form-checkbox-group-label !text-headline-6'>{label}</span>}
      {children}
      {description && <span className='form-checkbox-group-description'>{description}</span>}
      {error && <span className='form-checkbox-group-description text-red'>{error}</span>}
    </div>
  )
}

const CheckboxCardGroup = (props: CheckboxGroupProps) => {
  const { label, description, orientation, size = 'md', error, className = '', children } = props
  return (
    <div role='group' className={classNames('form-checkbox-card-group', className)}>
      {label && <span className='form-checkbox-card-group-label'>{label}</span>}
      <div
        className={classNames('form-checkbox-card-group-items', {
          'form-checkbox-card-group-items-vertical': orientation !== 'horizontal',
          'form-checkbox-card-small-group-items': size === 'sm',
        })}
      >
        {children}
      </div>
      {description && <span className='form-checkbox-group-description'>{description}</span>}
      {error && <span className='form-checkbox-group-description text-red'>{error}</span>}
    </div>
  )
}
