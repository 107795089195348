import { type CommissioningForm } from '../formValidationSchema'
import { ItemTextInline, Section } from './SharedWrappers'

const PdfSectionGeneralData = (props: { data: CommissioningForm['generalData'] }) => {
  const {
    data: {
      operator,
      installer,
      specialistPlanner,
      plantLocationStreet,
      plantLocationCity,
      plantLocationPostCode,
    },
  } = props
  return (
    <Section label='Allgemeine Daten'>
      <ItemTextInline label='Betreiber:innen' value={operator} />
      <ItemTextInline label='Installateur' value={installer} />
      <ItemTextInline label='Fachplaner' value={specialistPlanner} />
      <ItemTextInline label='Straße und Hausnummer' value={plantLocationStreet} />
      <ItemTextInline label='PLZ' value={plantLocationCity} />
      <ItemTextInline label='Ort' value={plantLocationPostCode} />
    </Section>
  )
}

export default PdfSectionGeneralData
