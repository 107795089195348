import { AppointmentsFilterForm, Button, Icon, Modal } from '~/components'

type AppointmentsFilterModalProps = {
  isOpen: boolean
  onClose: () => void
}

export function AppointmentsFilterModal({ isOpen, onClose }: AppointmentsFilterModalProps) {
  return (
    <Modal
      isOpen={isOpen}
      onClose={onClose}
      variant='bottom'
      className='flex flex-col gap-6 bg-white rounded-t-2xl animate-slide-in-bottom max-h-screen overflow-y-auto w-full sm:w-auto'
    >
      <header className='flex justify-between items-center border-b-[1px] border-b-dark-3 p-4'>
        <div className='text-headline-6'>Filter</div>
        <Button
          aria-label='close'
          variant='text-dark'
          size='md'
          className='text-icon-dark-tertiary flex'
          isIconButton
          onClick={onClose}
        >
          <Icon icon='general:x-close' className='!size-5' />
        </Button>
      </header>
      <AppointmentsFilterForm onClose={onClose} />
    </Modal>
  )
}
