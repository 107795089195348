import { FormInput } from '~/components'

const FormElectricalMeasurement = () => {
  return (
    <div className='flex flex-col gap-4'>
      <FormInput type='text' name='electricalMeasurement.measuringEquipment' label='Messgerät' />
      <FormInput
        type='decimal'
        name='electricalMeasurement.testingVoltageInVolts'
        label='Prüfspannung'
        suffix='V'
      />
      <FormInput
        type='text'
        name='electricalMeasurement.weatherConditions'
        label='Wetterbedingungen'
      />
      <FormInput
        type='decimal'
        name='electricalMeasurement.temperatureInCelsius'
        label='Temperatur'
        suffix='°C'
      />
    </div>
  )
}
export default FormElectricalMeasurement
