const RadialProgress = ({ className, value }: { className?: string; value: number }) => (
  <svg
    aria-valuemax={100}
    aria-valuemin={0}
    aria-valuenow={value}
    className={className}
    height='32'
    role='progressbar'
    style={{
      transform: 'rotate(-90deg)',
    }}
    viewBox='0 0 32 32'
    width='32'
  >
    <circle cx='16' cy='16' fill='none' r='14' stroke='#EAECE6' strokeWidth='3' />
    <circle
      cx='16'
      cy='16'
      fill='none'
      pathLength='100'
      r='14'
      stroke='#EB8755'
      strokeLinecap='round'
      strokeWidth='3'
      className='transition-all'
      style={{
        strokeDasharray: 100,
        strokeDashoffset: 100 - value,
      }}
    />
  </svg>
)

export default RadialProgress
