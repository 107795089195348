import { Report, type ReportItemEntity, ReportItemEntityStatus } from '~/models'
import { findItemInReport } from './findItemInReport'
import { saveReport } from './saveReport'
import { getLatestReport } from './getLatestReport'
import { type Draft } from 'immer'

export async function removeImageFromReport(
  report: Report,
  itemId: string,
  index: number
): Promise<Report> {
  const latestReport = await getLatestReport(report)
  const updatedReport = Report.copyOf(latestReport, (reportDraft) => {
    const itemDraft: Draft<ReportItemEntity> = findItemInReport(reportDraft, itemId)
    itemDraft.image_uids.splice(index, 1)
    const isItemEmptyAfterRemoval = itemDraft.image_uids.length === 0

    if (itemDraft?.status === ReportItemEntityStatus.READY && isItemEmptyAfterRemoval)
      itemDraft.status = ReportItemEntityStatus.DRAFT
  })

  return saveReport(updatedReport)
}
