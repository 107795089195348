import { Text, View } from '@react-pdf/renderer'
import { ItemTextVertical, Section, SectionSubtitle, formatNumber } from './SharedWrappers'
import { type CommissioningForm } from '../formValidationSchema'
import { type ReactNode } from 'react'

const LetterOmega = () => <Text style={{ fontFamily: 'Roboto-regular', fontSize: 10 }}>Ω</Text>

const Subscript = ({ children }: { children: ReactNode }) => {
  return <Text style={{ verticalAlign: 'sub', fontSize: 7 }}>{children}</Text>
}

const SingleStringElectricalValues = ({
  values,
}: {
  values: NonNullable<
    CommissioningForm['pvGenerators']
  >[number]['mppts'][number]['stringElectricalValues'][number]
}) => {
  const {
    openCircuitVoltageInVolts,
    insulationResistanceInMegaOhms,
    shortCircuitCurrentInAmperes,
  } = values
  return (
    <>
      <ItemTextVertical
        label='Leerlaufspannung'
        value={
          openCircuitVoltageInVolts && (
            <>
              {formatNumber(openCircuitVoltageInVolts)} U<Subscript>OC</Subscript>
              [V]
            </>
          )
        }
      />
      <ItemTextVertical
        label='Isolationswiderstand'
        value={
          insulationResistanceInMegaOhms && (
            <>
              {formatNumber(insulationResistanceInMegaOhms)} R<Subscript>ISO</Subscript>[M
              <LetterOmega />]
            </>
          )
        }
      />
      <ItemTextVertical
        label='Kurzschlussstrom'
        value={
          shortCircuitCurrentInAmperes && (
            <>
              {formatNumber(shortCircuitCurrentInAmperes)} I<Subscript>SC</Subscript>[A]
            </>
          )
        }
      />
    </>
  )
}

const MultiStringElectricalValues = ({
  stringElectricalValues,
}: {
  stringElectricalValues: NonNullable<
    CommissioningForm['pvGenerators']
  >[number]['mppts'][number]['stringElectricalValues']
}) => {
  if (stringElectricalValues.length > 4) return <></>

  return (
    <>
      {stringElectricalValues.map((values, idx) => (
        <View
          key={`string-${idx}`}
          style={{
            width: '100%',
            gap: 8,
          }}
        >
          <Text
            style={{
              color: '#616161',
              fontSize: 10,
              fontWeight: 400,
            }}
          >
            String {idx + 1}:
          </Text>

          <SingleStringElectricalValues values={values} />
        </View>
      ))}
    </>
  )
}

const PdfSectionPvGenerator = (props: {
  data: NonNullable<CommissioningForm['pvGenerators']>[number]
  inverterIdx: number
}) => {
  const { data, inverterIdx } = props

  return (
    <Section label='PV Generator'>
      <View style={{ flexDirection: 'row', width: '100%', gap: 12 }}>
        {data.mppts.map(({ amountOfModules, amountOfStrings, stringElectricalValues }, mpptIdx) => {
          return (
            <View key={`pvGenerators-mppt-${mpptIdx}`} style={{ gap: 12, flex: 1 }}>
              <SectionSubtitle>
                MPPT{mpptIdx + 1} - Wechselrichter #{inverterIdx + 1}
              </SectionSubtitle>
              <ItemTextVertical
                label='Anzahl der Module'
                value={amountOfModules ? formatNumber(amountOfModules) : undefined}
              />
              <ItemTextVertical
                label='Anzahl der Strings'
                value={amountOfStrings ? formatNumber(amountOfStrings) : undefined}
              />
              {stringElectricalValues.length === 1 && (
                <SingleStringElectricalValues values={stringElectricalValues[0]} />
              )}
              {stringElectricalValues.length > 1 && (
                <MultiStringElectricalValues stringElectricalValues={stringElectricalValues} />
              )}
            </View>
          )
        })}
      </View>
    </Section>
  )
}

export default PdfSectionPvGenerator
