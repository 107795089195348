import { type ActivityType, useAppStore } from '~/stores'
import { Button, FormInput, RadioGroup, CheckboxGroup } from '~/components'
import { type FormEvent, useEffect, useMemo, useState } from 'react'
import { FormProvider, useForm, useWatch } from 'react-hook-form'
import { type AppointmentsFilter } from '~/stores/useAppStore'
import { useTimeline } from '~/hooks'
import { datadogRum } from '@datadog/browser-rum'

interface AppointmentsFilterFormProps {
  onClose: () => void
}

export type AppointmentsFilterFormFields = {
  appointmentFilter: {
    isACActivity: boolean
    isDCActivity: boolean
    assignedToMe: 'true' | 'false'
  }
}

type AppointmentActivityTypeFilter = 'ac' | 'dc'

export const activityTypeFilterGroups: Record<
  AppointmentActivityTypeFilter,
  Array<ActivityType>
> = {
  ac: [
    'ac',
    'osv',
    'ms',
    'ac_rework',
    'repair_ac',
    'checkup',
    'inspection',
    'rework_checkup',
    'rework_inspection',
    'com',
    'rework_com',
    'mc',
    'vivi_osv',
  ],
  dc: [
    'dc',
    'osv',
    'dc_rework',
    'repair_dc',
    'checkup',
    'inspection',
    'rework_checkup',
    'rework_inspection',
    'vivi_osv',
  ],
}

const initialFilterFormFieldValues: AppointmentsFilterFormFields = {
  appointmentFilter: {
    isACActivity: true,
    isDCActivity: true,
    assignedToMe: 'false',
  },
}

function getFilterFormValues(appointmentsFilter: AppointmentsFilter): {
  isACActivity: boolean
  isDCActivity: boolean
  assignedToMe: 'true' | 'false'
} {
  const { activityType, assignedToMe } = appointmentsFilter ?? {}
  return {
    isACActivity: activityType?.includes('ac'),
    isDCActivity: activityType?.includes('dc'),
    assignedToMe: assignedToMe ? 'true' : 'false',
  }
}

const mapFormValuesToAppointmentsFilter = (
  formValues: ReturnType<typeof useWatch<AppointmentsFilterFormFields>>
) => {
  const activityType: ActivityType[] = []
  if (formValues?.appointmentFilter?.isACActivity) {
    activityType.push(...activityTypeFilterGroups.ac)
  }
  if (formValues?.appointmentFilter?.isDCActivity) {
    activityType.push(...activityTypeFilterGroups.dc)
  }
  return {
    activityType: [...new Set<ActivityType>(activityType)],
    assignedToMe: formValues?.appointmentFilter?.assignedToMe === 'true',
  }
}

export function AppointmentsFilterForm({ onClose }: AppointmentsFilterFormProps) {
  const appointmentsFilter = useAppStore.use.appointmentsFilter()
  const setAppointmentsFilter = useAppStore.use.setAppointmentsFilter()
  const dataStoreFullStatus = useAppStore.use.dataStoreFullStatus()
  const isFullSyncFinished = dataStoreFullStatus.syncQueriesReady

  const [ordersCount, setOrdersCount] = useState<number>(0)

  const { getGroupedOrdersCount } = useTimeline()

  const defaultValues = useMemo<AppointmentsFilterFormFields>(() => {
    return {
      appointmentFilter: {
        ...getFilterFormValues(appointmentsFilter),
      },
    }
  }, [appointmentsFilter])

  const formMethods = useForm<AppointmentsFilterFormFields>({
    defaultValues,
  })
  const formValues = useWatch<AppointmentsFilterFormFields>({ control: formMethods.control })

  const onApplyFilters = (values: AppointmentsFilterFormFields) => {
    datadogRum.addAction('appointments_filter_changed', {
      filter: values?.['appointmentFilter'],
      changedAt: Date.now(),
    })
    setAppointmentsFilter(mapFormValuesToAppointmentsFilter(values))
  }

  const onSubmit = (values: AppointmentsFilterFormFields) => {
    onApplyFilters(values)
    onClose()
  }

  const onReset = (event: FormEvent) => {
    event.preventDefault()
    formMethods.reset(initialFilterFormFieldValues)
    onApplyFilters(initialFilterFormFieldValues)
  }

  useEffect(() => {
    const ordersCount = getGroupedOrdersCount(mapFormValuesToAppointmentsFilter(formValues))
    setOrdersCount(ordersCount.total ?? 0)
  }, [formValues, getGroupedOrdersCount])

  return (
    <form noValidate>
      <FormProvider {...formMethods}>
        <div className='flex flex-col gap-6'>
          <CheckboxGroup className='px-4' label='Termin Typ'>
            <FormInput
              type='checkbox'
              name='appointmentFilter.isDCActivity'
              label='Dachinstallation'
            />
            <FormInput
              type='checkbox'
              name='appointmentFilter.isACActivity'
              label='Elektroinstallation'
            />
          </CheckboxGroup>
          <RadioGroup className='!gap-4 px-4'>
            <span className='form-radio-group-label !text-headline-6'>Zugewiesen an</span>
            <FormInput
              type='radio'
              name='appointmentFilter.assignedToMe'
              label='Alle Projekte'
              value='false'
            />
            <FormInput
              type='radio'
              name='appointmentFilter.assignedToMe'
              label='Mir zugewiesen'
              value='true'
            />
          </RadioGroup>
          <div
            // pb-3 = 0.75rem
            style={{ paddingBottom: 'calc(0.75rem + env(safe-area-inset-bottom, 0)' }}
            className='flex flex-col gap-y-2 justify-center px-6 pt-3 shadow-2xl'
          >
            <Button
              type='submit'
              className='h-10 w-full'
              variant='filled-accent'
              onClick={formMethods.handleSubmit(onSubmit)}
            >
              <span className='text-static-lg-semibold text-dark-primary'>
                {isFullSyncFinished ? ordersCount : ''} Ergebnisse anzeigen
              </span>
            </Button>
            <Button type='reset' className='h-10 w-full' variant='outlined-dark' onClick={onReset}>
              <span className='text-static-lg-semibold text-dark-primary'>Zurücksetzen</span>
            </Button>
          </div>
        </div>
      </FormProvider>
    </form>
  )
}
