import { Fragment, type ReactNode } from 'react'
import { useFormContext } from 'react-hook-form'
import { type CommissioningDocumentationParams } from './CommissioningDocumentationPage'
import { Button, Checkbox, Divider, Icon } from '~/components'
import { Link, useParams } from 'react-router-dom'
import classNames from 'classnames'
import { type CommissioningForm } from './formValidationSchema'

type InfoBlockProps = {
  label: ReactNode
  value: ReactNode
  className?: HTMLElement['className']
}

const InfoBlock = (props: InfoBlockProps) => {
  const { label, value, className = 'w-full' } = props
  return (
    <div className={classNames('flex flex-col gap-1', className)}>
      <span className='text-static-sm-regular text-dark-tertiary'>{label}</span>
      <span className='text-static-md-semibold text-dark-primary'>{value || '-'}</span>
    </div>
  )
}

const SectionGeneralData = () => {
  const { orderId = '', activityId = '' } = useParams<CommissioningDocumentationParams>()
  const { getValues } = useFormContext<CommissioningForm>()
  const {
    operator,
    installer,
    plantLocationCity,
    plantLocationPostCode,
    plantLocationStreet,
    specialistPlanner,
  } = getValues('generalData')
  return (
    <div className='flex flex-wrap gap-4'>
      <div className='flex justify-between items-center w-full'>
        <h5 className='text-headline-5'>Allgemeine Daten</h5>
        <Link
          className='scroll-smooth'
          id='generalData'
          to={`/documentation/${orderId}/${activityId}/com/generalData`}
          state={{ isSummaryPreviousRoute: true }}
        >
          <Button isIconButton variant='text-dark'>
            <Icon icon='general:edit-pen' />
          </Button>
        </Link>
      </div>
      <InfoBlock label='Betreiber:innen' value={operator} />
      <InfoBlock label='Installateur' value={installer} />
      <InfoBlock label='Fachplaner' value={specialistPlanner} />
      <InfoBlock label='Straße und Hausnummer' value={plantLocationStreet} />
      <InfoBlock label='PLZ' value={plantLocationPostCode} className='w-1/3' />
      <InfoBlock label='Ort' value={plantLocationCity} className='w-1/3' />
    </div>
  )
}

const SectionPvSystem = () => {
  const { orderId = '', activityId = '' } = useParams<CommissioningDocumentationParams>()
  const { getValues } = useFormContext<CommissioningForm>()
  const {
    amountOfModules,
    installedCapacityInKwp,
    hasLightningProtectionSystemIntegration,
    moduleManufacturer,
    moduleType,
    hasPlantOnLowVoltageGridConditions,
    ...rest
  } = getValues('pvSystem')

  const hasPvSystem = rest.hasPvSystem === 'yes'

  return (
    <div className='flex flex-wrap gap-4'>
      <div className='flex justify-between items-center w-full'>
        <h5 className='text-headline-5'>PV System</h5>
        <Link
          className='scroll-smooth'
          id='pvSystem'
          to={`/documentation/${orderId}/${activityId}/com/pvSystem`}
          state={{ isSummaryPreviousRoute: true }}
        >
          <Button isIconButton variant='text-dark'>
            <Icon icon='general:edit-pen' />
          </Button>
        </Link>
      </div>
      <InfoBlock label='Solaranlage' value={hasPvSystem ? 'Ja' : 'Nein'} />
      <InfoBlock
        label='Hersteller & Typ'
        value={hasPvSystem && `${moduleManufacturer} ${moduleType}`.trim()}
      />
      <InfoBlock label='Anzahl Module' value={hasPvSystem && amountOfModules} className='w-1/3' />
      <InfoBlock
        label='Installierte Leistung/kWp'
        value={hasPvSystem && installedCapacityInKwp}
        className='w-1/2'
      />
      <InfoBlock
        label='Einbindung in das äußere Blitzschutzsystem'
        value={hasPvSystem && (hasLightningProtectionSystemIntegration === 'yes' ? 'Ja' : 'Nein')}
      />
      <InfoBlock
        label='Die Betriebsparameter der WR sind entsprechend der örtlichen Anschlussbedienungen (VDE-AR-N 4105) eingestellt'
        value={hasPvSystem && (hasPlantOnLowVoltageGridConditions === 'yes' ? 'Ja' : 'Nein')}
      />
    </div>
  )
}

const SectionGridManagement = () => {
  const { orderId = '', activityId = '' } = useParams<CommissioningDocumentationParams>()
  const { getValues } = useFormContext<CommissioningForm>()
  const { powerLimitPercentage } = getValues('gridManagement') ?? {}
  return (
    <div className='flex flex-wrap gap-4'>
      <div className='flex justify-between items-center w-full'>
        <h5 className='text-headline-5'>Einspeisemanagement</h5>
        <Link
          className='scroll-smooth'
          id='inverters'
          to={`/documentation/${orderId}/${activityId}/com/gridManagement`}
          state={{ isSummaryPreviousRoute: true }}
        >
          <Button isIconButton variant='text-dark'>
            <Icon icon='general:edit-pen' />
          </Button>
        </Link>
      </div>
      <InfoBlock
        label='Wert'
        value={powerLimitPercentage ? `${powerLimitPercentage}%` : '-'}
        className='w-1/2'
      />
    </div>
  )
}

const SectionInverters = () => {
  const { orderId = '', activityId = '' } = useParams<CommissioningDocumentationParams>()
  const { getValues } = useFormContext<CommissioningForm>()
  const inverters = getValues('inverters') ?? []
  return (
    <div className='flex flex-wrap gap-4'>
      <div className='flex justify-between items-center w-full'>
        <h5 className='text-headline-5'>Wechselrichter</h5>
        <Link
          className='scroll-smooth'
          id='inverters'
          to={`/documentation/${orderId}/${activityId}/com/inverters`}
          state={{ isSummaryPreviousRoute: true }}
        >
          <Button isIconButton variant='text-dark'>
            <Icon icon='general:edit-pen' />
          </Button>
        </Link>
      </div>
      {inverters.map(({ manufacturer, type, serialNumber }, idx) => (
        <Fragment key={`inverter-${idx}`}>
          <p className='text-static-lg-bold'>Wechselrichter #{idx + 1}</p>
          <InfoBlock label='Seriennummer' value={serialNumber} />
          <InfoBlock label='Hersteller & Typ' value={`${manufacturer} ${type}`.trim()} />
        </Fragment>
      ))}
    </div>
  )
}

const SectionBatteryStorage = () => {
  const { orderId = '', activityId = '' } = useParams<CommissioningDocumentationParams>()
  const { getValues } = useFormContext<CommissioningForm>()
  const { manufacturer, serialNumber, typeAndCapacityInKWh, ...rest } = getValues('batteryStorage')
  const hasBatteryStorage = rest.hasBatteryStorage === 'yes'
  return (
    <div className='flex flex-wrap gap-4'>
      <div className='flex justify-between items-center w-full'>
        <h5 className='text-headline-5'>Batteriespeicher</h5>
        <Link
          className='scroll-smooth'
          id='batteryStorage'
          to={`/documentation/${orderId}/${activityId}/com/batteryStorage`}
          state={{ isSummaryPreviousRoute: true }}
        >
          <Button isIconButton variant='text-dark'>
            <Icon icon='general:edit-pen' />
          </Button>
        </Link>
      </div>
      <InfoBlock label='Batteriespeicher' value={hasBatteryStorage ? 'Ja' : 'Nein'} />
      <InfoBlock label='Seriennummer' value={hasBatteryStorage && serialNumber} />
      <InfoBlock
        label='Hersteller & Typ'
        value={hasBatteryStorage && `${manufacturer} ${typeAndCapacityInKWh}`.trim()}
      />
    </div>
  )
}

const SectionElectricalMeasurement = () => {
  const { orderId = '', activityId = '' } = useParams<CommissioningDocumentationParams>()
  const { getValues } = useFormContext<CommissioningForm>()
  const { measuringEquipment, temperatureInCelsius, testingVoltageInVolts, weatherConditions } =
    getValues('electricalMeasurement') ?? {}
  return (
    <div className='flex flex-wrap gap-4'>
      <div className='flex justify-between items-center w-full'>
        <h5 className='text-headline-5'>Elektrische Messung</h5>
        <Link
          className='scroll-smooth'
          id='electricalMeasurement'
          to={`/documentation/${orderId}/${activityId}/com/electricalMeasurement`}
          state={{ isSummaryPreviousRoute: true }}
        >
          <Button isIconButton variant='text-dark'>
            <Icon icon='general:edit-pen' />
          </Button>
        </Link>
      </div>
      <InfoBlock label='Messgerät' value={measuringEquipment} />
      <InfoBlock
        label='Prüfspannung'
        value={typeof testingVoltageInVolts === 'number' && `${testingVoltageInVolts} V`}
      />
      <InfoBlock label='Wetterbedingungen' value={weatherConditions} />
      <InfoBlock
        label='Temperatur'
        value={typeof temperatureInCelsius === 'number' && `${temperatureInCelsius} °C`}
      />
    </div>
  )
}

const SectionPvGenerator = () => {
  const { orderId = '', activityId = '' } = useParams<CommissioningDocumentationParams>()
  const { getValues } = useFormContext<CommissioningForm>()
  const pvGenerators = getValues('pvGenerators') ?? []
  return (
    <div className='flex flex-wrap gap-4'>
      <div className='flex justify-between items-center w-full'>
        <h5 className='text-headline-5'>PV Generator</h5>
        <Link
          className='scroll-smooth'
          id='pvGenerators'
          to={`/documentation/${orderId}/${activityId}/com/pvGenerators`}
          state={{ isSummaryPreviousRoute: true }}
        >
          <Button isIconButton variant='text-dark'>
            <Icon icon='general:edit-pen' />
          </Button>
        </Link>
      </div>
      {pvGenerators.map(({ mppts }, inverterIdx) =>
        mppts.map(({ amountOfModules, amountOfStrings, stringElectricalValues }, mpptIdx) => (
          <Fragment key={`mppt-${mpptIdx}`}>
            <p className='text-static-lg-bold w-full'>
              MPPT {mpptIdx + 1} - Wechselrichter #{inverterIdx + 1}
            </p>
            <InfoBlock label='Anzahl der Module' value={amountOfModules} className='w-1/2' />
            <InfoBlock label='Anzahl der Strings' value={amountOfStrings} className='w-1/3' />
            {stringElectricalValues.map((values, stringIdx) => (
              <div key={`mppt-${mpptIdx}-string-${stringIdx}`} className='mt-2 w-full'>
                <p className='text-static-lg-semibold w-full'>String {stringIdx + 1}</p>
                <div className='flex flex-wrap gap-4'>
                  <InfoBlock
                    label='Leerlaufspannung'
                    value={
                      values.openCircuitVoltageInVolts && (
                        <span>
                          {values.openCircuitVoltageInVolts} U<sub>OC</sub>[V]
                        </span>
                      )
                    }
                    className='w-1/2'
                  />
                  <InfoBlock
                    label='Isolationswiderstand'
                    value={
                      values.insulationResistanceInMegaOhms && (
                        <span>
                          {values.insulationResistanceInMegaOhms} R<sub>ISO</sub>[MΩ]
                        </span>
                      )
                    }
                    className='w-1/3'
                  />
                  <InfoBlock
                    label='Kurzschlussstrom'
                    value={
                      values.shortCircuitCurrentInAmperes && (
                        <span>
                          {values.shortCircuitCurrentInAmperes} I<sub>SC</sub>[A]
                        </span>
                      )
                    }
                  />
                </div>
              </div>
            ))}
          </Fragment>
        ))
      )}
    </div>
  )
}

const SectionWallbox = () => {
  const { orderId = '', activityId = '' } = useParams<CommissioningDocumentationParams>()
  const { getValues } = useFormContext<CommissioningForm>()
  const { manufacturer, typeAndPerformanceInKW, serialNumber, ...rest } = getValues('wallbox')
  const hasWallbox = rest.hasWallbox === 'yes'
  return (
    <div className='flex flex-wrap gap-4'>
      <div className='flex justify-between items-center w-full'>
        <h5 className='text-headline-5'>Wallbox</h5>
        <Link
          className='scroll-smooth'
          id='wallbox'
          to={`/documentation/${orderId}/${activityId}/com/wallbox`}
          state={{ isSummaryPreviousRoute: true }}
        >
          <Button isIconButton variant='text-dark'>
            <Icon icon='general:edit-pen' />
          </Button>
        </Link>
      </div>
      <InfoBlock label='Wallbox' value={hasWallbox ? 'Ja' : 'Nein'} />
      <InfoBlock
        label='Hersteller & Typ'
        value={hasWallbox && `${manufacturer} ${typeAndPerformanceInKW}`.trim()}
      />
      <InfoBlock label='Seriennummer' value={hasWallbox && serialNumber} />
    </div>
  )
}

const SectionCompass = () => {
  const { orderId = '', activityId = '' } = useParams<CommissioningDocumentationParams>()
  const { getValues } = useFormContext<CommissioningForm>()
  const { managerSerialNumber } = getValues('compass') ?? {}
  return (
    <div className='flex flex-wrap gap-4'>
      <div className='flex justify-between items-center w-full'>
        <h5 className='text-headline-5'>Compass Manager</h5>
        <Link
          className='scroll-smooth'
          id='compass'
          to={`/documentation/${orderId}/${activityId}/com/compass`}
          state={{ isSummaryPreviousRoute: true }}
        >
          <Button isIconButton variant='text-dark'>
            <Icon icon='general:edit-pen' />
          </Button>
        </Link>
      </div>
      <InfoBlock label='Compass Manager Seriennummer' value={managerSerialNumber} />
    </div>
  )
}

const SectionBriefingOfOperation = () => {
  const { orderId = '', activityId = '' } = useParams<CommissioningDocumentationParams>()
  const { getValues } = useFormContext<CommissioningForm>()
  const {
    isDamageFreeInstallation,
    hasProperCableRoutingInspection,
    hasComponentInstallationInspection,
    hasCustomerSwitchgearPVSticker,
    isCleanSiteNoWaste,
    hasComponentExplanation,
    hasShutdownExplanationACDC,
    hasSystemMonitoringIntroduction,
    hasOperationIndicators,
    hasMaintenanceAndCareGuidancePVA,
    remarks,
  } = getValues('briefingOfOperation')
  return (
    <div className='flex flex-wrap gap-6'>
      <div className='flex justify-between items-center w-full'>
        <h5 className='text-headline-5'>Einweisung des Betriebesg</h5>
        <Link
          className='scroll-smooth'
          id='briefingOfOperation'
          to={`/documentation/${orderId}/${activityId}/com/briefingOfOperation`}
          state={{ isSummaryPreviousRoute: true }}
        >
          <Button isIconButton variant='text-dark'>
            <Icon icon='general:edit-pen' />
          </Button>
        </Link>
      </div>
      <div className='flex flex-col gap-4'>
        <h6 className='text-headline-6'>Abschnitt #1</h6>
        <Checkbox
          name=''
          label='Anlage und Gebäude ohne sichtbare Schäden durch die Montage'
          checked={isDamageFreeInstallation}
          disabled
        />
        <Checkbox
          name=''
          label='Sichtprüfung auf ordnungsgemäße Kabelführung'
          checked={hasProperCableRoutingInspection}
          disabled
        />
        <Checkbox
          name=''
          label='Sichtprüfung Installation der Komponente'
          checked={hasComponentInstallationInspection}
          disabled
        />
        <Checkbox
          name=''
          label='PV Aufkleber auf Schaltschrank des Kunden'
          checked={hasCustomerSwitchgearPVSticker}
          disabled
        />
        <Checkbox
          name=''
          label='Die Baustelle wurde sauber und ohne Abfall hinterlassen'
          checked={isCleanSiteNoWaste}
          disabled
        />
      </div>
      <div className='flex flex-col gap-4'>
        <h6 className='text-headline-6'>Abschnitt #2</h6>
        <Checkbox
          name=''
          label='Erklärung der Komponenten und deren Funktionen'
          checked={hasComponentExplanation}
          disabled
        />
        <Checkbox
          name=''
          label='Erklärung der Abschalteinrichtung (AC, DC)'
          checked={hasShutdownExplanationACDC}
          disabled
        />
        <Checkbox
          name=''
          label='Vorstellung des Anlagen-Monitorings'
          checked={hasSystemMonitoringIntroduction}
          disabled
        />
        <Checkbox
          name=''
          label='Kontrollmöglichektein die auf einen korrekten Betrieb der Anlage hinweisen'
          checked={hasOperationIndicators}
          disabled
        />
        <Checkbox
          name=''
          label='Hinweise auf Wartung und Pflege der PVA'
          checked={hasMaintenanceAndCareGuidancePVA}
          disabled
        />
      </div>
      <InfoBlock label='Bemerkungen' value={remarks} />
    </div>
  )
}

const FormSummary = () => {
  const { watch } = useFormContext<CommissioningForm>()
  const hasPvSystem = watch('pvSystem.hasPvSystem') === 'yes'

  return (
    <div className='flex flex-col gap-6'>
      <SectionGeneralData />
      <Divider />
      <SectionPvSystem />
      {hasPvSystem && (
        <>
          <Divider />
          <SectionGridManagement />
          <Divider />
          <SectionInverters />
          <Divider />
          <SectionPvGenerator />
          <Divider />
          <SectionElectricalMeasurement />
          <Divider />
          <SectionCompass />
        </>
      )}
      <Divider />
      <SectionBatteryStorage />
      <Divider />
      <SectionWallbox />
      <Divider />
      <SectionBriefingOfOperation />
    </div>
  )
}

export default FormSummary
