import { type FieldError, useController } from 'react-hook-form'
import classNames from 'classnames'

interface SelectProps {
  name: string
  options: Array<string>
  placeholder?: string
  required?: boolean
  disabled?: boolean
  error?: FieldError
}

export function NativeDropdown(props: SelectProps) {
  const { field } = useController({
    name: props.name,
    rules: { required: props.required && 'Pflichtfeld' },
  })

  return (
    <div className={classNames('form-dropdown', { 'form-dropdown-error': props.error })}>
      <select
        className='form-dropdown-field'
        id={props.name}
        {...field}
        value={field.value ?? ''}
        disabled={props.disabled}
      >
        <option value='' {...{ disabled: props.required, hidden: props.required }}>
          {props.placeholder ?? ''}
        </option>
        {props.options.map((option) => (
          <option key={`${props.name}-${option}`}>{option}</option>
        ))}
      </select>
      {props.error && <div className='form-dropdown-description'>{props.error.message}</div>}
    </div>
  )
}
