import Input, { type InputProps } from './Input'
import Radio, { type RadioProps } from './Radio'
import Checkbox, { type CheckboxProps } from './Checkbox'
import Textarea, { type TextareaProps } from './Textarea'
import { useFormContext } from 'react-hook-form'
import { type ComponentProps, type ComponentType } from 'react'
import { get } from 'lodash-es'

type FormInputProps = {
  type:
    | 'date'
    | 'datetime-local'
    | 'email'
    | 'month'
    | 'password'
    | 'search'
    | 'tel'
    | 'text'
    | 'time'
    | 'url'
    | 'week'
    | 'integer'
    | 'decimal'
} & InputProps

type FormInputRadio = {
  type: 'radio'
} & RadioProps

type FormInputCheckbox = {
  type: 'checkbox'
} & CheckboxProps

type FormInpuTextarea = {
  type: 'textarea'
} & TextareaProps

type Props = FormInputProps | FormInputRadio | FormInputCheckbox | FormInpuTextarea

const componentMap: Record<Props['type'], ComponentType<any>> = {
  date: Input,
  'datetime-local': Input,
  email: Input,
  month: Input,
  integer: Input,
  decimal: Input,
  password: Input,
  search: Input,
  tel: Input,
  text: Input,
  time: Input,
  url: Input,
  week: Input,
  radio: Radio,
  checkbox: Checkbox,
  textarea: Textarea,
} as const

const decimalInputProps: ComponentProps<'input'> = {
  type: 'text',
  inputMode: 'decimal',
  pattern: '[0-9]+([.,][0-9]+)?',
  step: 0.01,
}

const integerInputProps: ComponentProps<'input'> = {
  type: 'number',
  inputMode: 'numeric',
  pattern: '[0-9]*',
  step: 1,
}

const FormInput = (props: Props) => {
  const { register, formState } = useFormContext()
  const hasError = !!get(formState.errors, props.name)

  const Component = componentMap[props.type] || Input

  return (
    <Component
      {...props}
      {...(props.type === 'integer' && integerInputProps)}
      {...(props.type === 'decimal' && decimalInputProps)}
      {...register(props.name, {
        required: props.required && 'Pflichtfeld',
        setValueAs: (v) => {
          if (['integer', 'decimal'].includes(props.type))
            return v ? Number(String(v).replace(',', '.')) : undefined
          return v
        },
      })}
      hasError={hasError}
    />
  )
}

export default FormInput
