import classNames from 'classnames'
import { Icon } from '~/components'
import { type UseOrder } from '~/hooks/useOrder'

type OrderHeadProps = {
  order: UseOrder
  disableMapsLink?: boolean
}

const OrderHead = (props: OrderHeadProps) => {
  const {
    order: { order_number, order_name, customer_name, order_type },
    disableMapsLink = false,
  } = props

  return (
    <div className='flex flex-col items-start mb-2'>
      <p className='text-subheadline-3 text-dark-tertiary'>{order_number}</p>
      <h4 className='text-headline-4'>{customer_name || order_name}</h4>
      <OrderAddress order={props.order} disableMapsLink={disableMapsLink} />
      <OrderType orderType={order_type} />
    </div>
  )
}

export default OrderHead

type OrderAddressProps = {
  order: UseOrder
  disableMapsLink?: boolean
}

const OrderAddress = (props: OrderAddressProps) => {
  const {
    order: { getGpsCoordinates, customer_street, customer_post_code, customer_city },
    disableMapsLink,
  } = props

  const address = `${customer_street}, ${customer_post_code} ${customer_city}`

  if (disableMapsLink) {
    return (
      <p className='my-2 link-sm px-0'>
        <Icon icon='general:marker-pin' size='md' />
        <span>{address}</span>
      </p>
    )
  }

  const coordinates = getGpsCoordinates()

  return (
    <a
      className={classNames('my-2 link-sm px-0', {
        'pointer-events-none cursor-default': !coordinates,
      })}
      href={`https://www.google.com/maps/place/${coordinates?.latitude},${coordinates?.longitude}`}
      target='_blank'
      rel='noreferrer'
    >
      <Icon icon='general:marker-pin' size='md' />
      <span>{address}</span>
    </a>
  )
}

type OrderTypeProps = {
  orderType: string
}
const OrderType = (props: OrderTypeProps) => {
  const { orderType = '' } = props
  const tags = orderType.split('+')
  return (
    <div className='flex items-center gap-1'>
      {tags.map((tag, i) => (
        <div key={`${tag}-${i}`} className='tag tag-sm bg-neutral'>
          {tag}
        </div>
      ))}
    </div>
  )
}
