import { type ReactNode } from 'react'
import { type SpriteName } from '@zolargmbh/zolar-icons'
import Icon from '../Icon/Icon'
import classNames from 'classnames'

type InlineMessageVariant = 'success' | 'warning' | 'error' | 'neutral'

const DEFAULT_INLINE_MESSAGE_ICONS: Record<InlineMessageVariant, SpriteName> = {
  success: 'general:check-circle',
  warning: 'general:alert-triangle',
  error: 'general:alert-circle',
  neutral: 'general:placeholder',
}

type Props = {
  variant: InlineMessageVariant
  /**
   * @default
   * variant='success' -> 'general:check-circle'
   * variant='warning' -> 'general:alert-triangle'
   * variant='error' -> 'general:alert-circle'
   * variant='neutral' -> 'general:placeholder'
   */
  icon?: SpriteName
  headline?: ReactNode
  children?: ReactNode
}

const InlineMessage = (props: Props) => {
  const { variant, icon = DEFAULT_INLINE_MESSAGE_ICONS[variant], headline, children } = props

  return (
    <div className='inline-message' role='status'>
      <div
        className={classNames('icon-underlay', {
          'icon-underlay-success': variant === 'success',
          'icon-underlay-warning': variant === 'warning',
          'icon-underlay-error': variant === 'error',
          'icon-underlay-lavender': variant === 'neutral',
        })}
      >
        <Icon icon={icon} />
      </div>
      {headline && <p className='inline-message-headline'>{headline}</p>}
      {children && <div className='inline-message-content'>{children}</div>}
    </div>
  )
}

export default InlineMessage
