import { Outlet, useLocation } from 'react-router-dom'
import { Navigation } from '~/components'
import Banner from '~/components/Banner/Banner'
import { useAppStore, useTaskStore } from '~/stores'
import { useEffect, useRef } from 'react'
import { useUploadProgress } from '~/hooks/task/useTaskManagerProgress'

export function RootWithNavigation() {
  const { pathname } = useLocation()
  const { network } = useAppStore.use.dataStoreStatus()
  const pendingTasks = useTaskStore.use.pending()
  useUploadProgress()
  const bannerRef = useRef<HTMLHeadElement>(null)
  const layoutRef = useRef<HTMLDivElement>(null)

  useEffect(() => {
    layoutRef.current?.scrollTo?.(0, 0)
  }, [pathname])

  useEffect(() => {
    const layout = layoutRef.current
    const bannerContent = bannerRef.current?.querySelector('p')
    let animationFrameRequestId: number

    if (!layout || !bannerContent) return
    bannerRef.current?.classList.add('z-10')
    bannerContent.classList.add('overflow-hidden')

    let bannerContentInitialHeight = bannerContent.clientHeight
    let prevScrollTop = 0
    let prevMaxHeight = bannerContent.clientHeight

    const calculateNewMaxHeight = (direction: 'up' | 'down', delta: number) => {
      return direction === 'up'
        ? // grow -> increase max height to max full
          Math.min(prevMaxHeight + delta, bannerContentInitialHeight)
        : // shrink -> decrease max height to min 0
          Math.max(prevMaxHeight - delta, 0)
    }

    const manipulateContentHeight = () => {
      animationFrameRequestId = requestAnimationFrame(() => {
        const currentScrollTop = layout.scrollTop
        const scrollDirection = currentScrollTop < prevScrollTop ? 'up' : 'down'

        const delta = Math.abs(prevScrollTop - currentScrollTop)
        const newMaxHeight = calculateNewMaxHeight(scrollDirection, delta)

        bannerContent.style.maxHeight = `${newMaxHeight}px`

        prevScrollTop = currentScrollTop
        prevMaxHeight = newMaxHeight
      })
    }

    layout.addEventListener('scroll', manipulateContentHeight)

    return () => {
      layout.removeEventListener('scroll', manipulateContentHeight)
      cancelAnimationFrame(animationFrameRequestId)
    }
  }, [network])

  return (
    <div className='absolute flex h-full w-full flex-col text-dark-primary'>
      {network === 'offline' && (
        <Banner
          ref={bannerRef}
          variant='update'
          icon='general:no-signal'
          headline={`Kein Internet ${
            pendingTasks.length ? `(${pendingTasks.length} Dateien pausiert)` : ''
          }`.trim()}
          content='Du kannst mit der Dokumentation fortfahren. Sobald du wieder eine Verbindung zum Internet hast, werden die Dateien hochgeladen. Solange werden sie zwischengespeichert. Bitte lasse die App dafür geöffnet.'
        />
      )}
      <div ref={layoutRef} className='relative flex grow flex-col overflow-auto'>
        <Outlet />
      </div>
      <Navigation />
    </div>
  )
}
