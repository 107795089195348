import { useAppStore } from '~/stores'
import { useNavigate, useParams } from 'react-router-dom'
import { Button, Divider, Icon, OrderCoverImage } from '~/components'
import { useAuthenticatedCache, useOrder } from '~/hooks'
import {
  Appointments,
  Configuration,
  CustomerInfo,
  Documents,
  Products,
  NoteAndComments,
  OrderHead,
} from '~/components/Order'
import { ActivitiesWithoutAppointments } from '~/components/Order/ActivitiesWithoutAppointments'

export function Order() {
  const { orderId = '' } = useParams<{ orderId: string }>()
  const order = useOrder(orderId)
  const { getBlobByUrl } = useAuthenticatedCache(order.id)
  const showLoadingOverlay = useAppStore.use.showLoadingOverlay()
  const navigate = useNavigate()

  return (
    <div className='relative mb-6'>
      <Button
        isIconButton
        variant='filled-light'
        size='lg'
        onClick={() => navigate('/overview')}
        className='absolute left-4 top-8 z-10'
      >
        <Icon icon='arrows:arrow-left' />
      </Button>
      <OrderCoverImage
        orderId={order.id}
        imageUrl={order.getCoverImage()}
        documents={order.getDocuments()}
        products={order.getProductsWithQuantity()}
        reports={order.getReports()}
      />
      <div className='container mt-6'>
        <OrderHead order={order} />
        <CustomerInfo phone={order.customer_phone} email={order.customer_email} />
        <Appointments appointments={order.getAppointments()} order={order.getOrder()} />
        <ActivitiesWithoutAppointments
          appointments={order.getAppointments()}
          activities={order.getActivities()}
          order={order.getOrder()}
        />
        <Configuration
          configuration={order.getConfiguration()}
          getBlobByUrl={getBlobByUrl}
          showLoadingOverlay={showLoadingOverlay}
        />
        <Products
          getBlobByUrl={getBlobByUrl}
          products={order.getProductsWithQuantity()}
          showLoadingOverlay={showLoadingOverlay}
        />
        <NoteAndComments comments={order.getComments(['ac', 'dc'])} note={order.order_notes} />
        <Documents
          documents={order.getDocuments({ mimeType: 'application/pdf' })}
          getBlobByUrl={getBlobByUrl}
          showLoadingOverlay={showLoadingOverlay}
        />
        <Divider className='mb-6 mt-6' />
        <div className='flex w-full flex-col gap-4'>
          <h2 className='text-headline-2'>Support</h2>
          <p className='text-body-1-regular'>Probleme mit der Hardware oder Installation?</p>
          <a href='tel:+493022027082' className='button-md button-primary mx-auto'>
            <Icon icon='social:phone' />
            <span>zolar kontaktieren</span>
          </a>
        </div>
      </div>
    </div>
  )
}
