import { datadogRum } from '@datadog/browser-rum'
import { createStore, setMany, values, update, del, keys, get, clear } from 'idb-keyval'
import { serializeError } from 'serialize-error'
import { type Task } from './taskProcessors'

const DB_NAME_PREFIX = 'Zolar-Installer-Tasks'
const STORE_NAME = 'Items'

// Export only used in testsuite
export const getTasksStore = () => createStore(DB_NAME_PREFIX, STORE_NAME)

// Export only used in testsuite
export const ERROR_MESSAGES = {
  invalidTasksType: (tasksType: string) =>
    `Invalid argument of type "${tasksType}" for parameter "tasks"`,
  invalidIdType: (idType: string) => `Invalid argument of type "${idType}" for parameter "id"`,
  invalidTasksShape: `Invalid argument shape for parameter "tasks"`,
  oneOrMoreTasksNotFound: `One or more tasks were not found`,
  oneOrMoreTasksAlreadyExist: `One or more tasks already exist`,
  taskNotFound: `Task not found`,
  failedAddingToTasksStore: `Failed adding tasks to ${DB_NAME_PREFIX} > ${STORE_NAME} store`,
  failedRemovingFromTasksStore: `Failed removing task from ${DB_NAME_PREFIX} > ${STORE_NAME} store`,
  failedGettingAllFromTasksStore: `Failed getting all tasks from ${DB_NAME_PREFIX} > ${STORE_NAME} store`,
  failedUpdatingInTasksStore: `Failed updating tasks in ${DB_NAME_PREFIX} > ${STORE_NAME} store`,
  failedClearingAllFromTasksStore: `Failed clearing all tasks from ${DB_NAME_PREFIX} > ${STORE_NAME} store`,
} as const

const isValidTasksType = (tasks: unknown) => Array.isArray(tasks)
const isValidTasksShape = (tasks: any[]) => tasks.every((el) => el?.id)
const isValidId = (id: unknown) => typeof id === 'string'

/**
 * @description Adds a batch of tasks in IndexedDB Tasks store.
 */
export const addTasksToIdb = async (tasks: Task[]) => {
  try {
    if (!isValidTasksType(tasks)) throw new Error(ERROR_MESSAGES.invalidTasksType(typeof tasks))
    if (!isValidTasksShape(tasks)) throw new Error(ERROR_MESSAGES.invalidTasksShape)
    if (!tasks.length) return false
    const store = getTasksStore()
    const ids = await keys(store)
    const existingTask = tasks.some(({ id }) => ids.includes(id))
    if (existingTask) throw new Error(ERROR_MESSAGES.oneOrMoreTasksAlreadyExist)
    const tuples = tasks.map<[string, Task]>((task) => [task.id, task])
    await setMany(tuples, store)
    return true
  } catch (error) {
    datadogRum.addError(error, {
      message: ERROR_MESSAGES.failedAddingToTasksStore,
    })
    return false
  }
}

/**
 * @description Removes a single task from IndexedDB Tasks store.
 */
export const removeTaskFromIdb = async (id: string) => {
  try {
    if (!isValidId(id)) throw new Error(ERROR_MESSAGES.invalidIdType(typeof id))
    const store = getTasksStore()
    const key = await get(id, store)
    if (!key) throw new Error(ERROR_MESSAGES.taskNotFound)
    await del(id, store)
    return true
  } catch (error) {
    datadogRum.addError(error, {
      message: ERROR_MESSAGES.failedRemovingFromTasksStore,
    })
    return false
  }
}

/**
 * @description Retrieves all tasks from IndexedDB Tasks store.
 */
export const getAllTasksFromIdb = async () => {
  try {
    const store = getTasksStore()
    const tasks = await values<Task>(store)
    return tasks
  } catch (error) {
    datadogRum.addError(error, {
      message: ERROR_MESSAGES.failedGettingAllFromTasksStore,
    })
    return []
  }
}

/**
 * @description Updates a batch of tasks in IndexedDB Tasks store.
 * Upserts are not allowed.
 */
export const updateTasksInIdb = async (updatedTasks: Task[]) => {
  try {
    if (!isValidTasksType(updatedTasks))
      throw new Error(ERROR_MESSAGES.invalidTasksType(typeof updatedTasks))
    if (!isValidTasksShape(updatedTasks)) throw new Error(ERROR_MESSAGES.invalidTasksShape)
    if (!updatedTasks.length) return false
    const store = getTasksStore()
    const promises = updatedTasks.map((updatedTask) =>
      update<Task>(
        updatedTask.id,
        (oldValue) => {
          if (!oldValue) throw new Error(ERROR_MESSAGES.oneOrMoreTasksNotFound)
          return updatedTask
        },
        store
      )
    )
    await Promise.all(promises)
    return true
  } catch (error) {
    datadogRum.addError(error, {
      message: ERROR_MESSAGES.failedUpdatingInTasksStore,
    })
    return false
  }
}

/**
 * @description Clears all tasks from IndexedDB Tasks store.
 */
export const clearAllTasksFromIdb = async () => {
  try {
    const store = getTasksStore()
    await clear(store)
    return true
  } catch (error) {
    datadogRum.addError(error, {
      message: ERROR_MESSAGES.failedClearingAllFromTasksStore,
    })
    return false
  }
}
