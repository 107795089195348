import { create } from 'zustand'
import { createSelectors } from '~/utils/createSelectors'

interface ReportSyncState {
  isEmpty: boolean
  updateOutboxStatus: (isEmpty: boolean) => void
}

export const useReportSyncStore = createSelectors(
  create<ReportSyncState>()((set) => ({
    isEmpty: false,
    updateOutboxStatus: (isEmpty: boolean) => set(() => ({ isEmpty })),
  }))
)
