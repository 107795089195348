import { Icon } from '~/components'
import {
  type Appointment,
  type ComReport,
  type Activity,
  type Report,
  type Order,
  ComReportState,
} from '~/models'
import { type ActivityType, useAppStore } from '~/stores'
import { orderAppointments } from '~/hooks/useTimeline'
import { de } from 'date-fns/locale'
import { format, startOfDay, isSameDay, type FormatOptions } from 'date-fns'
import { type SpriteName } from '@zolargmbh/zolar-icons'
import { Link } from 'react-router-dom'
import { ZPC_BASE_URL } from '~/config/environments'
import { type ReactNode } from 'react'
import classNames from 'classnames'
import { ReportStatus } from '~/models'
import { ActivityState } from '~/services/ActivityStates'

interface AppointmentsProps {
  appointments: Appointment[]
  order: Order
}

const AC_REWORK = 'ac_rework'
const DC_REWORK = 'dc_rework'
type AC_DC_REWORK = typeof AC_REWORK | typeof DC_REWORK
type FormattedAppointment = {
  appointment: Appointment
  label: string
  icon: SpriteName
  key: string
}

export const formatOrderAppointments = (orderedAppointments: Appointment[]) => {
  const filteredAppointments = orderedAppointments.filter(
    (app) => activityTypeHeaders[app?.activity_type as ActivityType]
  )
  const isAscending =
    filteredAppointments.length < 2 ||
    filteredAppointments[0].start_date <=
      filteredAppointments[filteredAppointments.length - 1].start_date

  const reworkCounter = {
    [AC_REWORK]: 0,
    [DC_REWORK]: 0,
  }

  const formattedAppointments = filteredAppointments[isAscending ? 'reduce' : 'reduceRight']<
    FormattedAppointment[]
  >((acc, appointment) => {
    const activityType = appointment.activity_type as ActivityType
    const activityTypeHeader = activityTypeHeaders[activityType]
    let label = activityTypeHeader?.label

    if ([AC_REWORK, DC_REWORK].includes(activityType)) {
      const reworkNumber = ++reworkCounter[activityType as AC_DC_REWORK]
      label = label.replace(' (', ` #${reworkNumber} (`)
    }

    acc.push({
      key: `${activityType}-${appointment.id}`,
      label,
      icon: activityTypeHeader?.icon,
      appointment,
    })

    return acc
  }, [])

  if (isAscending) {
    return formattedAppointments
  }
  return formattedAppointments.toReversed()
}

export function Appointments(props: AppointmentsProps) {
  const today = startOfDay(new Date())

  const futureAppointments = formatOrderAppointments(
    orderAppointments(
      props.appointments.filter((a) => {
        const startDate = new Date(a.end_date)
        return startDate >= today
      }),
      'asc'
    )
  )

  const pastAppointments = formatOrderAppointments(
    orderAppointments(
      props.appointments.filter((a) => {
        const startDate = new Date(a.start_date)
        return startDate < today
      }),
      'desc'
    )
  )

  return (
    <div className='mt-2 flex w-full flex-col gap-5'>
      <div className='flex flex-col gap-2'>
        {futureAppointments.length > 0 && (
          <div className='text-static-lg-semibold'>Anstehende Termine</div>
        )}
        <div className='flex flex-col gap-4'>
          {futureAppointments.map((formattedAppointment) => (
            <AppointmentWidget
              key={formattedAppointment.key}
              appointment={formattedAppointment.appointment}
              order={props.order}
              showDocumentationLink
              label={formattedAppointment.label}
              icon={formattedAppointment.icon}
            />
          ))}
        </div>
      </div>
      <div className='flex flex-col gap-2'>
        {pastAppointments.length > 0 && (
          <div
            className={classNames('text-static-lg-semibold', {
              'mt-2': futureAppointments.length > 0,
            })}
          >
            Vergangene Termine
          </div>
        )}
        <div className='flex flex-col gap-4'>
          {pastAppointments.map((formattedAppointment) => (
            <AppointmentWidget
              key={formattedAppointment.key}
              appointment={formattedAppointment.appointment}
              order={props.order}
              showDocumentationLink
              label={formattedAppointment.label}
              icon={formattedAppointment.icon}
            />
          ))}
        </div>
      </div>
    </div>
  )
}

export const activityTypeHeaders: Record<
  ActivityType,
  {
    icon: SpriteName
    label: string
  }
> = {
  ac: { icon: 'zpc:projectphase:electro', label: 'Elektroinstallation' },
  dc: { icon: 'zpc:projectphase:roof', label: 'Dachinstallation' },
  com: { icon: 'zpc:projectphase:commissioning', label: 'Inbetriebnahme' },
  osv: { icon: 'zpc:projectphase:osv', label: 'Vor-Ort-Besichtigung' },
  vivi_osv: { icon: 'zpc:projectphase:osv', label: 'Vor-Ort-Besichtigung (check-in)' },
  rework_com: { icon: 'zpc:projectphase:osv', label: 'Nacharbeit Inbetriebnahme' },
  mc: { icon: 'zpc:projectphase:meter-swap', label: 'Zählerwechsel' },
  ms: { icon: 'zpc:projectphase:meter-swap', label: 'Zählerwechsel' },
  ac_rework: { icon: 'zpc:projectphase:electro', label: 'Nacharbeit (AC)' },
  dc_rework: { icon: 'zpc:projectphase:repair', label: 'Nacharbeit (DC)' },
  repair_ac: { icon: 'zpc:projectphase:roof', label: 'Reparatur (AC)' },
  repair_dc: { icon: 'zpc:projectphase:repair', label: 'Reparatur (DC)' },
  checkup: { icon: 'zpc:projectphase:checkup', label: 'Untersuchung' },
  inspection: { icon: 'zpc:projectphase:inspection', label: 'Inspektion' },
  rework_checkup: { icon: 'zpc:projectphase:checkup', label: 'Nacharbeit Untersuchung' },
  rework_inspection: { icon: 'zpc:projectphase:inspection', label: 'Nacharbeit Inspektion' },
}

type AppointmentWidgetProps = {
  appointment: Appointment
  showDocumentationLink?: boolean
  order?: Order
  reportStatus?: any
  label: string
  icon: SpriteName
}
export const AppointmentWidget = (props: AppointmentWidgetProps) => {
  const { appointment, icon, label, showDocumentationLink } = props
  const isAcOrDc = ['ac', 'dc'].includes(appointment?.activity_type)
  const isCommissioning = appointment?.activity_type === 'com'

  const activities = useAppStore.use.activities()
  const activity = activities.find((a) => a.id === appointment?.activity_uid)

  const reports = useAppStore.use.reports()
  const report = reports.find((r) => r.activity_uid === appointment?.activity_uid)
  const comReports = useAppStore.use.comReports()
  const comReport = comReports.find((cR) => cR.activity_uid === appointment?.activity_uid)

  const isReportSubmittedViaZPC = getIsReportSubmittedViaZPC(activity, report, comReport)

  const isReportSubmittedViaApp = getIsReportSubmittedViaApp(activity, report, comReport)

  const isReportStartedViaApp = getIsReportStartedViaApp(activity, report, comReport)

  const isReportNotStarted = getIsNotReportStarted(activity, report, comReport)

  return (
    <AppointmentWidgetWrapper
      {...props}
      isReportSubmittedViaZPC={isReportSubmittedViaZPC}
      activity={activity}
    >
      <div className='relative flex items-center justify-between gap-3 rounded border border-dark-3 px-4 py-2'>
        <div
          className={classNames(
            'absolute left-0 top-1/2 z-0 h-[90%] w-1 -translate-y-1/2 rounded-l-[5000px] rounded-r-full',
            {
              'bg-sky-900': isReportNotStarted,
              'bg-mist-900': isReportStartedViaApp,
              'bg-lavender-900': isReportSubmittedViaZPC || isReportSubmittedViaApp,
            }
          )}
        />
        <div className='flex flex-col gap-y-3'>
          <div className='flex flex-wrap items-center gap-x-2'>
            <Icon className='w-10 h-10' icon={icon} />
            <div className='text-static-lg-bold flex-1 text-dark-primary'>
              {isSameDay(appointment.start_date, appointment.end_date) ? (
                <>
                  <p>{formatAppointmentDate(appointment.start_date)}</p>
                  <p>
                    {`${formatAppointmentTime(appointment.start_date)} - ${formatAppointmentTime(appointment.end_date)}`}
                  </p>
                </>
              ) : (
                <>
                  <p>{formatAppointmentDateTime(appointment.start_date)}</p>
                  <p>{formatAppointmentDateTime(appointment.end_date)}</p>
                </>
              )}
            </div>
          </div>
          <div className='flex flex-col gap-y-2'>
            <div className='flex flex-col gap-y-1'>
              <p
                className={classNames('text-static-sm-semibold w-full', {
                  'text-sky-900': isReportNotStarted,
                  'text-mist-900': isReportStartedViaApp,
                  'text-lavender-900': isReportSubmittedViaZPC || isReportSubmittedViaApp,
                })}
              >
                {!isCommissioning && (
                  <>
                    {isReportNotStarted && 'Offen'}
                    {isReportStartedViaApp && 'Begonnen'}
                    {isReportSubmittedViaApp && 'Übermittelt'}
                    {isReportSubmittedViaZPC && 'Übermittelt in ZPC'}
                  </>
                )}
                {isCommissioning && (
                  <>
                    {isReportStartedViaApp && 'Begonnen'}
                    {isReportSubmittedViaApp && 'Abgeschlossen'}
                    {isReportSubmittedViaZPC && 'Übermittelt in ZPC'}
                  </>
                )}
              </p>
              <p className='text-static-lg-regular text-dark-primary'>{label}</p>
            </div>
            <div className='flex flex-col gap-y-1'>
              {(appointment.assignedInstallers && appointment.assignedInstallers?.length > 0
                ? appointment.assignedInstallers
                : [{ name: '---', uid: '---' }]
              ).map(({ uid, name }) => (
                <p key={uid} className='flex flex-wrap items-center gap-x-2'>
                  <span className='rounded-full border border-solid border-neutral-200 p-1'>
                    <Icon icon='users:user-01' size='sm' />
                  </span>
                  <span className='text-static-md-regular text-dark-primary'>{name}</span>
                </p>
              ))}
            </div>
          </div>
        </div>
        {showDocumentationLink && (
          <Icon
            size='md'
            icon={
              (isAcOrDc || isCommissioning) && !isReportSubmittedViaZPC
                ? 'arrows:arrow-right'
                : 'arrows:arrow-up-right'
            }
            className='text-icon-dark-tertiary'
          />
        )}
      </div>
    </AppointmentWidgetWrapper>
  )
}

const dateTimeFormatOptions: FormatOptions = { locale: de }

const formatAppointmentTime = (date: Date | string) =>
  format(new Date(date), 'HH:mm', dateTimeFormatOptions)

const formatAppointmentDate = (date: Date | string) =>
  format(new Date(date), 'EEEE, do MMMM', dateTimeFormatOptions)

const formatAppointmentDateTime = (date: Date | string) =>
  format(new Date(date), 'EEEE, do MMM HH:mm', dateTimeFormatOptions)

type AppointmentWidgetWrapperProps = AppointmentWidgetProps & {
  children: ReactNode
  isReportSubmittedViaZPC: boolean
  activity?: Activity
}

const AppointmentWidgetWrapper = (props: AppointmentWidgetWrapperProps) => {
  const {
    children,
    appointment,
    order,
    showDocumentationLink,
    label,
    isReportSubmittedViaZPC,
    activity,
  } = props
  const getZPCHref = () => {
    if (!order?.extern_hash || !appointment) return ''
    switch (appointment.activity_type) {
      case 'com':
        return isReportSubmittedViaZPC
          ? `${ZPC_BASE_URL}/project-details/extern/${order.extern_hash}/commissioning`
          : ''
      case 'osv':
        return `${ZPC_BASE_URL}/project-details/extern/${order.extern_hash}/osv`
      case 'ac_rework':
        return `${ZPC_BASE_URL}/project-details/extern/${order.extern_hash}/electro/rework/${appointment.activity_uid}`
      case 'dc_rework':
        return `${ZPC_BASE_URL}/project-details/extern/${order.extern_hash}/roof/rework/${appointment.activity_uid}`
      case 'ac':
        return isReportSubmittedViaZPC
          ? `${ZPC_BASE_URL}/project-details/extern/${order.extern_hash}/electro`
          : ''
      case 'dc':
        return isReportSubmittedViaZPC
          ? `${ZPC_BASE_URL}/project-details/extern/${order.extern_hash}/roof`
          : ''
      default:
        return ''
    }
  }

  const zpcLink = getZPCHref()
  const viviLink = activity?.link_vivi_osv ?? ''
  const isAcOrDc = ['ac', 'dc'].includes(appointment?.activity_type)
  const isCommissioning = appointment?.activity_type === 'com'

  if (showDocumentationLink && (zpcLink || viviLink)) {
    return (
      <a
        aria-label={label}
        className='hover:bg-neutral-50'
        target='_blank'
        href={zpcLink || viviLink}
        rel='noreferrer'
      >
        {children}
      </a>
    )
  }
  if (showDocumentationLink && isAcOrDc) {
    return (
      <Link
        aria-label={label}
        className='hover:bg-neutral-50'
        to={`/order/${order?.id}/${appointment?.activity_type}`}
        state={{ from: `/order/${order?.id}` }}
      >
        {children}
      </Link>
    )
  }

  if (showDocumentationLink && isCommissioning) {
    return (
      <Link
        aria-label={label}
        className='hover:bg-neutral-50'
        to={`/order/${order?.id}/${appointment?.activity_uid}/${appointment?.activity_type}`}
      >
        {children}
      </Link>
    )
  }

  return <>{children}</>
}

const getIsReportSubmittedViaZPC = (
  activity?: Activity,
  report?: Report,
  comReport?: ComReport
) => {
  const isCommissioning = activity?.activity_type === 'com'
  const isComReportInDraft = comReport?.state === ComReportState.DRAFT
  const isComActivitySubmittable = [
    'initialized',
    'documentation_pending_online',
    'documentation_pending_offline',
  ].includes(activity?.state ?? '')
  if (isCommissioning && isComReportInDraft && !isComActivitySubmittable) return true
  return (
    !(comReport || report) &&
    [
      ActivityState.finished,
      ActivityState.creditMemoApproved,
      ActivityState.creditMemoBlocked,
      ActivityState.creditMemoPending,
      ActivityState.documentationFailed,
      ActivityState.documentationValidation,
      ActivityState.qualityValidation,
      ActivityState.qualityValidationFailed,
      ActivityState.qualityValidationAdjusted,
    ].includes((activity?.state ?? '') as ActivityState)
  )
}

const getIsReportSubmittedViaApp = (
  activity?: Activity,
  report?: Report,
  comReport?: ComReport
) => {
  return (
    (report && report.status !== ReportStatus.DRAFT) ||
    (comReport && comReport.state !== ComReportState.DRAFT)
  )
}

const getIsReportStartedViaApp = (activity?: Activity, report?: Report, comReport?: ComReport) => {
  const isCommissioning = activity?.activity_type === 'com'
  const isComReportInDraft = comReport?.state === ComReportState.DRAFT
  const isAcDcReportInDraft = report?.status === ReportStatus.DRAFT
  const isComActivitySubmittable = [
    'initialized',
    'documentation_pending_online',
    'documentation_pending_offline',
  ].includes(activity?.state ?? '')
  return isAcDcReportInDraft || (isCommissioning && isComActivitySubmittable && isComReportInDraft)
}

const getIsNotReportStarted = (activity?: Activity, report?: Report, comReport?: ComReport) => {
  const isCommissioning = activity?.activity_type === 'com'
  const isAcOrDc = activity?.activity_type && ['ac', 'dc'].includes(activity?.activity_type)
  return (
    !(isCommissioning && comReport) &&
    !(isAcOrDc && report) &&
    [
      ActivityState.initialized,
      ActivityState.scheduled,
      ActivityState.confirmed,
      ActivityState.documentationPending,
    ].includes((activity?.state ?? '') as ActivityState)
  )
}
