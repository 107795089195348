import { type Report, type ReportItemEntity } from '~/models'

export function findItemInReport(report: Report, id: string): ReportItemEntity {
  for (const step of report.steps) {
    const item = step.items?.find((item) => item.key === id)
    if (item) {
      return item
    }
  }
  throw Error(`item with id=${id} could not be found`)
}
