import * as Sentry from '@sentry/react'
import React, { useEffect } from 'react'
import {
  createRoutesFromChildren,
  matchRoutes,
  useLocation,
  useNavigationType,
} from 'react-router-dom'
import { ErrorPage } from '~/pages'
import { globeEnvName, isProduction } from './environments'
import { serializeError } from 'serialize-error'

const DSN = 'https://86b65360a3704e7cbba0603541e2df41@o576193.ingest.sentry.io/4505556527153152'

export const initSentry = () => {
  Sentry.init({
    dsn: DSN,
    environment: globeEnvName,
    enabled: window?.location.hostname !== 'localhost',
    release: `installer-app@${process.env.APP_VERSION}`,
    beforeSend: (event, hint) => {
      try {
        if (event.exception) {
          // Serialize the error object to ensure nested objects are properly formatted
          const serializedError = serializeError(event.exception)
          event.exception = serializedError as typeof event.exception
        }
      } catch (error) {
        Sentry.captureException(error, {
          level: 'warning',
          extra: { hint, message: 'Error occurred during serialization' },
        })
      }
      // Send updated or original event
      return event
    },
    // see sentry documentation for ignoreErrors and denyUrls section
    // https://docs.sentry.io/platforms/javascript/guides/react/configuration/filtering/#decluttering-sentry
    ignoreErrors: [
      // Random plugins/extensions
      'top.GLOBALS',
      // See: http://blog.errorception.com/2012/03/tale-of-unfindable-js-error.html
      'originalCreateNotification',
      'canvas.contentDocument',
      'MyApp_RemoveAllHighlights',
      'http://tt.epicplay.com',
      "Can't find variable: ZiteReader",
      'jigsaw is not defined',
      'ComboSearch is not defined',
      'http://loading.retry.widdit.com/',
      'atomicFindClose',
      // Facebook borked
      'fb_xd_fragment',
      // ISP "optimizing" proxy - `Cache-Control: no-transform` seems to reduce this. (thanks @acdha)
      // See http://stackoverflow.com/questions/4113268/how-to-stop-javascript-injection-from-vodafone-proxy
      'bmi_SafeAddOnload',
      'EBCallBackMessageReceived',
      // See http://toolbar.conduit.com/Developer/HtmlAndGadget/Methods/JSInjection.aspx
      'conduitPage',
      // Generic error code from errors outside the security sandbox
      // You can delete this if using raven.js > 1.0, which ignores these automatically.
      'Script error.',
      'fbq is not defined',
      "Can't find variable: fbq",
      "'fbq' is not defined",
    ],
    denyUrls: [
      // Facebook flakiness
      /graph\.facebook\.com/i,
      // Facebook blocked
      /connect\.facebook\.net\/en_US\/all\.js/i,
      // Woopra flakiness
      /eatdifferent\.com\.woopra-ns\.com/i,
      /static\.woopra\.com\/js\/woopra\.js/i,
      // Chrome extensions
      /extensions\//i,
      /^chrome:\/\//i,
      // Other plugins
      /127\.0\.0\.1:4001\/isrunning/i, // Cacaoweb
      /webappstoolbarba\.texthelp\.com\//i,
      /metrics\.itunes\.apple\.com\.edgesuite\.net\//i,
    ],
    integrations: [
      new Sentry.Replay({
        maskAllInputs: true,
      }),
      new Sentry.BrowserTracing({
        routingInstrumentation: Sentry.reactRouterV6Instrumentation(
          useEffect,
          useLocation,
          useNavigationType,
          createRoutesFromChildren,
          matchRoutes
        ),
      }),
    ],
    tracesSampleRate: isProduction() ? 0.1 : 1.0,
    replaysSessionSampleRate: 0.1,
    replaysOnErrorSampleRate: 1.0,
  })
  Sentry.setTag('custom_env_name', globeEnvName)
}

export function SentryErrorBoundary(props: { children?: React.ReactNode | string }) {
  return (
    <Sentry.ErrorBoundary
      showDialog={true}
      fallback={<ErrorPage />}
      dialogOptions={{
        dsn: DSN,
        title: 'Ein Fehler ist aufgetreten!',
        subtitle: 'Das IT Team wurde bereits benachrichtigt.',
        subtitle2:
          'Um uns bei der Behebung des Fehlers zu unterstützen, gib uns Hinweise, was zu dem Fehler geführt hat.',
        labelName: 'Name',
        labelEmail: 'E-Mail',
        labelComments: 'Welche Aktionen haben zum Fehler geführt?',
        labelClose: 'Schließen',
        labelSubmit: 'Feedback senden',
        errorGeneric: 'Bitte versuche es noch einmal.',
        errorFormEntry: 'Bitte kontrolliere deine Eingaben.',
        successMessage: 'Vielen Dank! Dein Feedback wurde an uns versandt.',
      }}
    >
      {props.children}
    </Sentry.ErrorBoundary>
  )
}
