import { create } from 'zustand'
import { devtools, persist } from 'zustand/middleware'
import { type Task } from '~/services'
import { createSelectors } from '~/utils/createSelectors'
import { nanoid } from 'nanoid'
import { produce } from 'immer'
import { wait } from '~/utils/wait'

interface TaskStoreState {
  conditions: ConditionState
  pending: Array<StoreTask>
  current: StoreTask | undefined
  progress: TasksProgress
  retryTime: number
  actions: {
    queueTask: (orderId: string, task: Task) => void
    queueTasks: (orderId: string, tasks: Array<Task>, groupId?: string) => void
    clearTasks: () => void
    clearTaskProgressByOrderId: (orderId: string) => void
    runNextTask: (taskExecutor: TaskExecutor) => Promise<boolean>
  }
}

export type StoreTask = Task & {
  orderId: string
  groupId: string
}

export interface GroupedTaskProgress {
  value: number
  total: number
  payload: StoreTask['payload']
}

interface TasksProgress {
  [orderId: string]: {
    [groupId: string]: GroupedTaskProgress
  }
}

type TaskExecutor = (task: StoreTask) => Promise<void>

export const useTaskStore = createSelectors(
  create<TaskStoreState>()(
    devtools(
      persist(
        (set, get) => ({
          pending: [],
          current: undefined,
          progress: {},
          conditions: { authenticated: false, datastoreReady: false, online: false },
          retryTime: 1000,
          actions: {
            queueTask: (orderId, task) => {
              get().actions.queueTasks(orderId, [task])
            },
            queueTasks: (orderId, tasks, groupId = nanoid()) => {
              const storeTasks = tasks.map<StoreTask>((task) => ({
                ...task,
                orderId,
                groupId,
              }))
              set(
                produce<TaskStoreState>((draft) => {
                  const { pending, progress } = draft
                  pending.push(...storeTasks)
                  progress[orderId] = {
                    ...progress[orderId],
                    [groupId]: {
                      value: 0,
                      total: storeTasks.length,
                      payload: storeTasks[0].payload,
                    },
                  }
                })
              )
            },
            clearTasks: () => {
              set(() => ({ pending: [], progress: {}, current: undefined }))
            },
            clearTaskProgressByOrderId: (orderId) => {
              set(
                produce<TaskStoreState>((draft) => {
                  delete draft.progress[orderId]
                })
              )
            },
            runNextTask: async (taskExecutor) => {
              const failedConditions = failingConditions(get().conditions)
              if (failedConditions.length > 0) {
                return false
              }
              if (get().current) {
                return false
              }
              const pending = get().pending
              if (pending.length === 0) {
                return false
              }
              const currentTask = pending[0]
              set(() => ({ current: currentTask }))
              try {
                await taskExecutor(currentTask)
                set(
                  produce<TaskStoreState>((draft) => {
                    const { pending, progress } = draft
                    draft.current = undefined
                    draft.retryTime = 1000
                    pending.splice(0, 1)
                    const { orderId, groupId } = currentTask
                    progress[orderId][groupId].value++
                  })
                )
                return true
              } catch (e) {
                console.log(
                  `Error occured while running the task, requeueing task at end and backing off for ${
                    get().retryTime / 1000
                  } seconds`,
                  e
                )
                await wait(get().retryTime)
                set(
                  produce<TaskStoreState>((draft) => {
                    const { pending } = draft
                    draft.current = undefined
                    draft.retryTime = Math.min(draft.retryTime * 2, 5 * 60 * 1000)
                    pending.splice(0, 1)
                    pending.push(currentTask)
                  })
                )
                return true
              }
            },
          },
        }),
        {
          name: 'tasks',
          partialize: (state) => ({
            pending: state.pending,
            progress: state.progress,
          }),
        }
      ),
      { name: 'TaskStore' }
    )
  )
)

export type Condition = 'online' | 'authenticated' | 'datastoreReady'
type ConditionState = Record<Condition, boolean>

export function failingConditions(conditions: ConditionState) {
  return Object.entries(conditions).filter((value) => !value[1])
}

export function setTaskRunnerCondition(key: Condition, condition: boolean) {
  useTaskStore.setState((state) => ({
    conditions: { ...state.conditions, [key]: condition },
  }))
}
