import { Fragment, useState } from 'react'
import { type AppointmentsFilter, type Timeframe, useAppStore } from '~/stores/useAppStore'
import { type GroupedOrders, useTimeline } from '~/hooks'
import { SegmentedControls, OrderList, Icon } from '~/components'
import { AppointmentsFilterModal } from '~/components/AppointmentFilter/AppointmentsFilterModal'
import { AppointmentsFilterButton } from '~/components/AppointmentFilter/AppointmentsFilterButton'
import { datadogRum } from '@datadog/browser-rum'

export function Overview() {
  const [isAppointmentsFilterOpen, setIsAppointmentsFilterOpen] = useState<boolean>(false)
  const { getGroupedOrders, getGroupedOrdersCount } = useTimeline()
  const timeframe = useAppStore.use.timeframe()
  const setTimeframe = useAppStore.use.setTimeframe()
  const appointmentsFilter = useAppStore.use.appointmentsFilter()
  const dataStoreFullStatus = useAppStore.use.dataStoreFullStatus()

  const groupedOrders = getGroupedOrders(timeframe, appointmentsFilter)
  const groupedOrdersCount = getGroupedOrdersCount(appointmentsFilter)
  const isFullSyncFinished = dataStoreFullStatus.syncQueriesReady

  const onSegmentChange = (segment: Timeframe) => {
    datadogRum.addAction('timeframe_changed', {
      timeframe: segment,
      changedAt: Date.now(),
    })
    setTimeframe(segment)
  }

  const getSegmentControlLabel = (timeframe: Timeframe) =>
    `${timeframe === 'future' ? 'Anstehend' : 'Vergangen'}${
      isFullSyncFinished ? ` (${groupedOrdersCount?.[timeframe]})` : ''
    }`

  return (
    <div className='px-4 pb-6'>
      <div className='sticky top-0 z-10 -mx-4 flex flex-col gap-3 bg-white px-4 pb-2 pt-6'>
        <div className='flex justify-between'>
          <h1 className='select-none text-headline-1 text-dark-primary'>Projekte</h1>
          <AppointmentsFilterButton onClick={() => setIsAppointmentsFilterOpen(true)} />
        </div>
        <SegmentedControls<Timeframe>
          segments={[
            {
              label: getSegmentControlLabel('future'),
              value: 'future',
            },
            {
              label: getSegmentControlLabel('past'),
              value: 'past',
            },
          ]}
          selected={timeframe}
          onChange={onSegmentChange}
          fullWidth
        />
      </div>
      <div className='flex w-full flex-col'>
        <GroupedOrderList
          appointmentsFilter={appointmentsFilter}
          timeframe={timeframe}
          grouped={groupedOrders}
        />
      </div>
      <AppointmentsFilterModal
        isOpen={isAppointmentsFilterOpen}
        onClose={() => setIsAppointmentsFilterOpen(false)}
      />
    </div>
  )
}

const FullSyncProgressLabel = () => {
  return (
    <div className='flex mb-8 place-content-center'>
      <div className='mr-2 animate-spin'>
        <Icon icon='zpc:navigation:refresh' size='lg' className='-scale-x-[1]' />
      </div>
      <div className='text-dark'>Weitere Projekte werden geladen</div>
    </div>
  )
}

interface GroupedOrderListProps {
  appointmentsFilter: AppointmentsFilter
  timeframe: Timeframe
  grouped: GroupedOrders[]
}

function GroupedOrderList({ grouped, appointmentsFilter, timeframe }: GroupedOrderListProps) {
  const dataStoreFullStatus = useAppStore.use.dataStoreFullStatus()
  const dataStoreStatus = useAppStore.use.dataStoreStatus()
  const isFullSyncInProgress =
    !dataStoreFullStatus.syncQueriesReady && dataStoreStatus.network === 'online'

  return (
    <Fragment>
      {grouped.map((group) => (
        <Fragment key={group.timeSection}>
          <h2 className='text-headline-5 mb-2 mt-2'>{group.title}</h2>
          <OrderList
            appointmentsFilter={appointmentsFilter}
            timeframe={timeframe}
            orders={group.orders}
          />
        </Fragment>
      ))}
      {grouped.length > 0 && isFullSyncInProgress && <FullSyncProgressLabel />}
      {grouped.length === 0 && (
        <div className='flex flex-col mt-40 text-center'>
          <div className='text-static-xl-bold text-dark-primary'>
            Hm, der Bildschirm scheint leer zu sein 🤔
          </div>
          <div className='text-static-lg-regular text-dark-secondary'>
            Es gibt leider keine Projekte
          </div>
        </div>
      )}
    </Fragment>
  )
}
