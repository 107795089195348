import styled, { keyframes } from 'styled-components'
import Logo from '~/svg/logo.svg?react'
import { type ReactNode } from 'react'

export function LoadingIndicatorApp(props: { children?: ReactNode }) {
  return (
    <div className='flex h-screen w-full flex-col items-center justify-center'>
      <div>
        <Logo className='mb-4 h-20 w-20 rounded' />
      </div>
      <IndeterminateProgressBar>
        <IndeterminateProgressBarProgress />
      </IndeterminateProgressBar>
      {props.children}
    </div>
  )
}

const IndeterminateProgressBar = styled.div`
  width: 5rem;
  background-color: #d1d5db;
  border-radius: 9999px;
  height: 0.5rem;
  position: relative;
  overflow: hidden;
`

const animate = keyframes`
  from {
    left: -50%;
  }
  to {
    left: 100%;
  }
`

const IndeterminateProgressBarProgress = styled.div`
  background-color: rgb(235, 135, 85);
  border-radius: 9999px;
  position: absolute;
  bottom: 0;
  top: 0;
  width: 50%;
  animation: ${animate} 2s infinite;
`
