import { Fragment } from 'react'
import { ChooseImage } from '~/components/Documentation/ChooseImage'
import SwipeHand from '~/svg/swipe-hand.svg?react'
import { useImageFromUid } from '~/hooks'
import { Icon } from '~/components'
import { ReportStatus } from '~/models'

type PreviewCanvasProps = PreviewProps & HowToProps

export function PreviewCanvas(props: PreviewCanvasProps) {
  return (
    <Fragment>
      {props.imageUid ? (
        <Preview cacheId={props.cacheId} url={props.url} imageUid={props.imageUid} />
      ) : (
        <HowTo onNewImages={props.onNewImages} reportStatus={props.reportStatus} />
      )}
    </Fragment>
  )
}

interface PreviewProps {
  cacheId: string
  url: string
  imageUid?: string
}

function Preview(props: PreviewProps) {
  const imageUrl = useImageFromUid({
    cacheId: props.cacheId,
    url: props.url,
    imageUid: props.imageUid,
  })

  return (
    <>
      {imageUrl === 'not-found' ? (
        <MissingCacheImage />
      ) : (
        <div
          className='h-full w-full'
          style={{
            backgroundImage: `url('${imageUrl}')`,
            backgroundPosition: 'center center',
            backgroundSize: 'cover',
          }}
        />
      )}
    </>
  )
}

interface HowToProps {
  onNewImages: (files: FileList) => void
  reportStatus: ReportStatus | keyof typeof ReportStatus
}

function HowTo(props: HowToProps) {
  return (
    <div className='text-body-1-regular flex h-full flex-col justify-evenly px-4 pb-[50px] pt-[80px] text-xl'>
      <div className='flex flex-col items-center gap-2'>
        <div className='text-center'>
          Bitte den <span className='font-bold'>Arbeitsschritt</span> auswählen.
        </div>
        <SwipeHand className='h-[64px]' />
      </div>
      <div className='flex flex-col items-center gap-2'>
        <div className='text-center'>
          <span className='font-bold'>Starte dann die Kamera</span> und dokumentiere deine
          Installation.
        </div>
        <div>
          <ChooseImage
            label='Kamera starten'
            icon='social:camera'
            onNewImages={props.onNewImages}
            className='!h-20 !w-20'
            iconClassName='!h-12 !w-12'
            variant='outlined-dark'
            camera
            disabled={props.reportStatus !== ReportStatus.DRAFT}
          />
        </div>
      </div>
    </div>
  )
}

function MissingCacheImage() {
  return (
    <div className='text-body-1-regular flex h-full flex-col justify-evenly px-4 pb-[50px] pt-[80px] text-xl'>
      <div className='flex flex-col items-center'>
        <div className='mb-2 text-center'>
          Leider konnte das <span className='font-bold'>Bild nicht gefunden werden.</span>
        </div>
        <Icon icon='files:image-x' size='3xl' />
        <div className='mt-9 text-center'>
          Dies kann bspw. passieren, wenn das Foto mit einem anderen Gerät aufgenommen, aber noch
          nicht übermittelt wurde.
        </div>
      </div>
    </div>
  )
}
