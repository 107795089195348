import { type CommissioningForm } from '../formValidationSchema'
import { ItemTextInline, Section } from './SharedWrappers'

const PdfSectionCompassManager = (props: { data: CommissioningForm['compass'] }) => {
  const { data = {} } = props
  const { managerSerialNumber } = data
  return (
    <Section label='Compass Manager'>
      <ItemTextInline label='Compass Manager Seriennummer' value={managerSerialNumber} />
    </Section>
  )
}

export default PdfSectionCompassManager
