export enum ActivityState {
  adjustment = 'adjustment',
  appointmentPending = 'appointment_pending',
  appointmentRescheduled = 'appointment_rescheduled',
  scheduled = 'scheduled',
  confirmed = 'confirmed',
  processing = 'processing',
  accepted = 'accepted',
  creditMemoApproved = 'credit_memo_approved',
  creditMemoBlocked = 'credit_memo_blocked',
  creditMemoPending = 'credit_memo_pending',
  documentationFailed = 'documentation_failed',
  documentationFailedOffline = 'documentation_failed_offline',
  documentationFailedOnline = 'documentation_failed_online',
  documentationPending = 'documentation_pending',
  documentationPendingOffline = 'documentation_pending_offline',
  documentationPendingOnline = 'documentation_pending_online',
  documentationDraft = 'documentation_draft',
  documentationInProgress = 'documentation_in_progress',
  documentationCompleted = 'documentation_completed',
  documentsSent = 'documents_sent',
  documentationValidation = 'documentation_validation',
  initialized = 'initialized',
  reinitialized = 'reinitialized',
  inEngineeringReview = 'in_engineering_review',
  reviewedByEngineering = 'reviewed_by_engineering',
  qualityValidation = 'quality_validation',
  qualityValidationFailed = 'quality_validation_failed',
  qualityValidationAdjusted = 'quality_validation_adjusted',
  finished = 'finished',
  cancelled = 'cancelled',
  reportApproved = 'report_approved',
  signaturePending = 'signature_pending',
  templatesUploaded = 'templates_uploaded',
}
