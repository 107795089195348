export enum EnvName {
  master = 'master',
  production = 'production',
}

export const globeEnvName = process.env.VITE_ENV_NAME || EnvName.master

export const isProduction = () => globeEnvName === EnvName.production

export const ZPC_BASE_URL = isProduction()
  ? 'https://project-center.zolar.de'
  : 'https://nightly-project-center.zolar.rocks'
