import { type ComponentProps } from 'react'
import Alert from './Alert'
import {
  ToastContainer as ReactToastContainer,
  Slide,
  toast as reactToast,
  type ToastOptions,
  type Id,
} from 'react-toastify'

export const ToastContainer = () => {
  return (
    <ReactToastContainer
      position='top-center'
      hideProgressBar
      autoClose={5000}
      newestOnTop
      closeButton={false}
      closeOnClick={false}
      className='flex flex-col gap-2 !px-2 !py-2'
      draggableDirection='y'
      draggablePercent={50}
      toastClassName='!bg-transparent !shadow-none !overflow-visible !p-0 !cursor-default !font-sans'
      bodyClassName='!p-0'
      limit={3}
      transition={Slide}
    />
  )
}

type AlertProps = ComponentProps<typeof Alert>

const spawnToast = (props: AlertProps, options?: ToastOptions) =>
  reactToast(({ closeToast }) => {
    const onClose = () => {
      props.onClose?.()
      closeToast?.()
    }
    return <Alert {...props} onClose={props.onClose ? onClose : closeToast} />
  }, options)

const updateToast = (id: Id, props: AlertProps, options?: ToastOptions) =>
  reactToast.update(id, {
    render: ({ closeToast }) => {
      const onClose = () => {
        props.onClose?.()
        closeToast?.()
      }
      return <Alert {...props} onClose={props.onClose ? onClose : closeToast} />
    },
    updateId: id,
    ...options,
  })

const dismissToast = (id: Id) => reactToast.dismiss(id)

const isActiveToast = (id: Id) => reactToast.isActive(id)

const toast = Object.freeze({
  spawn: spawnToast,
  update: updateToast,
  dismiss: dismissToast,
  isActive: isActiveToast,
})

export default toast
