import { createBrowserRouter, redirect } from 'react-router-dom'
import * as Sentry from '@sentry/react'
import {
  AcDcActivityPage,
  ErrorPage,
  ImageGallery,
  Login,
  Order,
  Overview,
  PrivacyPolicy,
  Root,
  RootWithNavigation,
  StandardDocumentation,
} from '~/pages'
import { type ReactElement } from 'react'
import { ErrorTest } from '~/pages/ErrorTest'
import ProfilePage from '~/pages/ProfilePage'
import CommissioningActivityPage, {
  loader as loaderCommissioningActivityPage,
} from '~/pages/Activity/CommissioningActivityPage'
import CommissioningDocumentationPage, {
  loader as loaderCommissioningDocumentationPage,
} from '~/pages/Documentation/Commissioning/CommissioningDocumentationPage'
import ExperimentalFeaturesPage from '~/pages/ExperimentalFeaturesPage'
import { isProduction } from './environments'

export const router = (isAuthenticated: boolean) => {
  const whenAuthenticated = canAccess(<Login />, isAuthenticated)
  const sentryCreateBrowserRouter = Sentry.wrapCreateBrowserRouter(createBrowserRouter)
  return sentryCreateBrowserRouter([
    {
      path: '/',
      element: whenAuthenticated(<RootWithNavigation />),
      errorElement: <ErrorPage />,
      children: [
        { path: '', loader: () => redirect('overview') },
        { path: 'overview', element: <Overview /> },
        { path: 'order/:orderId', element: <Order /> },
        { path: 'order/:orderId/:activityType', element: <AcDcActivityPage /> },
        { path: 'profile', element: <ProfilePage /> },
        ...(!isProduction()
          ? [{ path: 'experimental-features', element: <ExperimentalFeaturesPage /> }]
          : []),
      ],
    },
    {
      path: '/',
      element: whenAuthenticated(<Root />),
      errorElement: <ErrorPage />,
      children: [
        { path: 'login', element: <Login /> },
        {
          path: 'gallery/:id',
          element: whenAuthenticated(<ImageGallery />),
        },
        {
          path: 'order/:orderId/:activityId/com',
          element: whenAuthenticated(<CommissioningActivityPage />),
          loader: loaderCommissioningActivityPage,
        },
        {
          path: 'documentation/:orderId/:activityId/com/:stepItemId',
          element: whenAuthenticated(<CommissioningDocumentationPage />),
          loader: loaderCommissioningDocumentationPage,
        },
        {
          path: 'documentation/:orderId/:activityType/:stepItemId',
          element: whenAuthenticated(<StandardDocumentation />),
        },
      ],
    },
    {
      path: '/privacy',
      element: <PrivacyPolicy />,
      errorElement: <ErrorPage />,
    },
    {
      path: '/error-test',
      element: <ErrorTest />,
      errorElement: <ErrorPage />,
    },
  ])
}

function canAccess(fallback: ReactElement, ...args: Array<boolean>) {
  return (element: ReactElement): ReactElement => {
    return args.every((arg) => arg) ? element : fallback
  }
}
