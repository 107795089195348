import { useEffect, useRef } from 'react'

/**
 * Traps a focus within a parent HTML DIV element that the Ref is assigned to
 * If no focusable elements available, disables key navigation within parent element
 *
 * @param isActive {boolean} if the trap is active
 */
export function useFocusTrap(isActive: boolean) {
  const trapRef = useRef<HTMLDivElement>(null)

  useEffect(() => {
    if (!isActive || !trapRef.current) {
      return
    }

    const trapElement = trapRef.current
    const focusableElements = Array.from<HTMLInputElement | HTMLButtonElement | HTMLLinkElement>(
      trapElement.querySelectorAll(
        'a[href], button, textarea, input[type="text"], input[type="radio"], input[type="checkbox"], select, [tabindex]:not([tabindex="-1"])'
      )
    )

    if (!focusableElements.length) {
      const handleKeyDown = (e: KeyboardEvent) => {
        switch (e.key) {
          case 'Tab':
          case 'ArrowUp':
          case 'ArrowDown':
          case 'PageUp':
          case 'PageDown':
            e.preventDefault()
            break
          default:
            break
        }
      }

      document.addEventListener('keydown', handleKeyDown)
      return () => {
        document.removeEventListener('keydown', handleKeyDown)
      }
    }

    const firstElement = focusableElements[0]
    const lastElement = focusableElements[focusableElements.length - 1]

    const handleKeyDown = (e: KeyboardEvent) => {
      if (e.key !== 'Tab') {
        return
      }

      if (e.shiftKey && document.activeElement === firstElement) {
        e.preventDefault()
        lastElement.focus()
        return
      }

      if (!e.shiftKey && document.activeElement === lastElement) {
        e.preventDefault()
        firstElement.focus()
      }
    }

    trapElement.addEventListener('keydown', handleKeyDown)

    firstElement?.focus()

    return () => {
      trapElement.removeEventListener('keydown', handleKeyDown)
    }
  }, [isActive])

  return trapRef
}
