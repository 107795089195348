import { createStore, get, set, type UseStore, del } from 'idb-keyval'
import md5 from 'md5'
import { fetchBlob } from './fetchBlob'

interface DatabaseBlob {
  id: string
  blob: Blob
}
export type InstallerCache = ReturnType<typeof createCache>

export function createCache(name: string) {
  const database = getDatabase(name)

  async function getBlobById(id: string): Promise<Blob | undefined> {
    return get<Blob>(id, database)
  }

  async function setBlob(id: string, blob: Blob): Promise<DatabaseBlob> {
    await set(id, blob, database)
    return { id, blob }
  }

  async function getBlobByUrl(
    url: string,
    token?: string,
    forcedMimeType?: string
  ): Promise<Blob | undefined> {
    const docId = md5(url)
    const cachedBlob = await getBlobById(docId)
    if (cachedBlob) {
      return cachedBlob
    }

    const remoteBlob = await fetchBlob(url, token, forcedMimeType)
    if (remoteBlob) {
      await setBlob(docId, remoteBlob)
    }
    return remoteBlob
  }

  async function clearById(id: string) {
    return del(id, database)
  }

  return {
    getBlobById,
    getBlobByUrl,
    setBlob,
    clearById,
  }
}

const databases: Record<string, UseStore> = {}

function getDatabase(name: string) {
  const dbName = `installer-${name}`
  if (!databases[dbName]) {
    databases[dbName] = createStore(dbName, 'id-blob')
  }
  return databases[dbName]
}
