import React from 'react'
import styled from 'styled-components'

interface CarouselItemProps {
  className?: string
  children: React.ReactNode
  alignItems?: 'center' | 'stretch'
}

export function CarouselItem(props: CarouselItemProps) {
  return (
    <StyledCarouselItem className={props.className} alignItems={props.alignItems ?? 'center'}>
      {props.children}
    </StyledCarouselItem>
  )
}

const StyledCarouselItem = styled.div<{ alignItems: string }>`
  display: inline-flex;
  width: 100%;
  height: 100%;
  align-items: ${(props) => props.alignItems}};

  img {
    object-fit: contain;
    height: 100%;
  }
`
