import { useEffect, useState } from 'react'
import { useAuthenticatedCache } from './useAuthenticatedCache'

export function useFetchImage(cacheId: string, url?: string, secure: boolean = false) {
  const { getBlobByUrl } = useAuthenticatedCache(cacheId)
  const [imageObjectUrl, setImageObjectUrl] = useState<string | undefined>(undefined)

  useEffect(() => {
    let objectUrl: string | undefined = undefined

    async function fetchImage() {
      if (!url || url.startsWith('undefined')) return
      const blob = await getBlobByUrl(url, secure)
      objectUrl = blob ? URL.createObjectURL(blob) : undefined
      setImageObjectUrl(objectUrl)
    }
    fetchImage().catch((e) => console.error('Could not fetch image for: ' + url, e))

    return function cleanup() {
      if (objectUrl) URL.revokeObjectURL(objectUrl)
    }
  }, [url, secure, getBlobByUrl])

  return imageObjectUrl
}
