import { Activity, type Order } from '~/models'

export interface GpsCoordinates {
  latitude: number
  longitude: number
}
function toRadians(degrees: number): number {
  return degrees * (Math.PI / 180)
}

export function extractGpsCoordinates(lon_lat?: string | null): GpsCoordinates | undefined {
  const coordinates = lon_lat?.match(/x=(?<longitude>.*), y=(?<latitude>.*)}/)?.groups

  return coordinates
    ? { longitude: parseFloat(coordinates.longitude), latitude: parseFloat(coordinates.latitude) }
    : undefined
}

function distanceBetween(nextActivityLocation: GpsCoordinates, currentLocation: GpsCoordinates) {
  const earthRadiusInMeters = 6371000
  const latitudeDifference = toRadians(nextActivityLocation.latitude - currentLocation.latitude)
  const longitudeDifference = toRadians(nextActivityLocation.longitude - currentLocation.longitude)
  const a =
    Math.sin(latitudeDifference / 2) * Math.sin(latitudeDifference / 2) +
    Math.cos(toRadians(currentLocation.latitude)) *
      Math.cos(toRadians(nextActivityLocation.latitude)) *
      Math.sin(longitudeDifference / 2) *
      Math.sin(longitudeDifference / 2)
  const c = 2 * Math.atan2(Math.sqrt(a), Math.sqrt(1 - a))
  return earthRadiusInMeters * c
}

export async function determineClosestOrderByGps(orders: Order[]): Promise<string | undefined> {
  try {
    const posData = await getCurrentGeolocationPosition({
      enableHighAccuracy: true,
      timeout: 5000,
      maximumAge: 0,
    })

    const currentLocation: GpsCoordinates = {
      latitude: posData.coords.latitude,
      longitude: posData.coords.longitude,
    }

    const activityLocations: any = []
    orders.forEach((order) => {
      const activityLocation = extractGpsCoordinates(order.customer_lon_lat)
      if (!activityLocation) return
      activityLocations.push({
        activityId: order.id,
        distance: distanceBetween(activityLocation, currentLocation),
      })
    })

    activityLocations.sort((a: any, b: any) => a.distance - b.distance)
    const { activityId, distance } = activityLocations[0]

    if (distance <= 500) {
      return activityId
    }
  } catch (err) {
    console.log(err)
  }
}

const getCurrentGeolocationPosition = (options?: PositionOptions): Promise<GeolocationPosition> =>
  new Promise((resolve, error) => navigator.geolocation.getCurrentPosition(resolve, error, options))
