import classNames from 'classnames'
import { Icon } from '~/components'
import { type ComponentProps, type ReactNode, forwardRef } from 'react'

export type InputProps = Omit<ComponentProps<'input'>, 'prefix'> & {
  label: ReactNode
  name: string
  description?: ReactNode
  prefix?: ReactNode
  suffix?: ReactNode
  hasError?: boolean
}

const Input = forwardRef<HTMLInputElement, InputProps>((props, ref) => {
  const {
    label,
    description,
    prefix,
    suffix,
    className = '',
    disabled,
    readOnly,
    hasError,
    ...rest
  } = props

  return (
    <div
      className={classNames('form-input', className, {
        'form-input-disabled': disabled,
        'form-input-error': hasError,
        'form-input-readonly': readOnly,
      })}
    >
      <label className='form-input-field'>
        <div className='form-input-label'>{label}</div>
        {prefix && <span>{prefix}</span>}
        <input type='text' readOnly={readOnly} disabled={disabled} {...rest} ref={ref} />
        {suffix && <span>{suffix}</span>}
        {hasError && <Icon icon='general:alert-triangle' />}
      </label>
      {description && <div className='form-input-description'>{description}</div>}
    </div>
  )
})

Input.displayName = 'Input'

export default Input
