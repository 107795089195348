import { type Comment } from '~/models'
import { Divider, Expandable, InlineMessage } from '~/components'
import { useState } from 'react'

interface NoteAndCommentsProps {
  note?: string | null
  comments: Comment[]
}
export function NoteAndComments({ note, comments }: NoteAndCommentsProps) {
  const [[acExpanded, dcExpanded], setAcDcExpanded] = useState([false, false])

  const dcComments = comments.filter(({ activity_type }) => activity_type === 'dc')
  const acComments = comments.filter(({ activity_type }) => activity_type === 'ac')

  const notesAvailable = note || acComments.length > 0 || dcComments.length > 0

  return notesAvailable ? (
    <>
      <Divider className='mb-6 mt-6' />
      <div className='text-headline-2 mb-4'>Notizen</div>
      {note && <div className='mb-4' dangerouslySetInnerHTML={{ __html: note }} />}
      <div className='mb-4'>
        <Expandable
          expanded={dcExpanded}
          onExpand={() => setAcDcExpanded([false, !dcExpanded])}
          head={<div className='text-subheadline-2 grow'>Notizen zur Dachinstallation</div>}
        >
          <div className='text-body-1-regular flex grow flex-col gap-2'>
            {dcComments.length > 0 ? (
              dcComments.map((comment) => <p key={comment.id}>{comment.content}</p>)
            ) : (
              <EmptyNotesMessage />
            )}
          </div>
        </Expandable>
      </div>
      <div className='mb-4'>
        <Expandable
          expanded={acExpanded}
          head={<div className='text-subheadline-2 grow'>Notizen zur Elektroinstallation</div>}
          onExpand={() => {
            setAcDcExpanded([!acExpanded, false])
          }}
        >
          <div className='text-body-1-regular flex grow flex-col gap-2'>
            {acComments.length > 0 ? (
              acComments.map((comment) => <p key={comment.id}>{comment.content}</p>)
            ) : (
              <EmptyNotesMessage />
            )}
          </div>
        </Expandable>
      </div>
    </>
  ) : null
}

const EmptyNotesMessage = () => (
  <InlineMessage
    icon='files:notes'
    variant='neutral'
    headline='Derzeit sind keine Notizen hinterlegt'
  >
    Sobald Notizen für das Projekt erstellt wurden, findest du sie hier
  </InlineMessage>
)
