import { ChooseImage } from '~/components/Documentation/ChooseImage'
import { type ReportItemEntity, ReportItemEntityStatus } from '~/models'
import { Thumbnail } from '~/components/Documentation/Thumbnail'

export interface AdditionalDocumentsProps {
  onNewImages: (files: FileList) => void
  removeImage: (index: number) => void
  item: ReportItemEntity
  url: string
  cacheId: string
}

export function AdditionalDocuments(props: AdditionalDocumentsProps) {
  return (
    <div className='text-body-1-regular border-t border-t-neutral-300 py-8'>
      <div>Zusätzliche Dokumente</div>
      <div className='text-description-1 mt-4 text-neutral'>
        <p className='font-bold'>
          Bitte stelle uns einen Screenshot des IP-Scanners mit IP-Adressen aller verbauten Produkte
          zur Verfügung.
        </p>
        <p>Bitte kommentiere hier außerdem alle Abweichungen zu Umsetzung oder Dokumentation.</p>
      </div>
      {props.item.status !== ReportItemEntityStatus.READY && (
        <div className='mt-4 flex justify-evenly'>
          <div className='w-1/3 text-center'>
            <ChooseImage
              label='Mediathek'
              icon='general:image'
              onNewImages={props.onNewImages}
              size='lg'
              variant='outlined-dark'
              imageCount={0}
            />
          </div>
          <div className='w-1/3 text-center'>
            <ChooseImage
              label='Aufname'
              icon='social:camera'
              onNewImages={props.onNewImages}
              size='lg'
              variant='outlined-dark'
              imageCount={0}
              camera
            />
          </div>
        </div>
      )}
      {props.item.image_uids.length > 0 && (
        <div className='mt-4 grid grid-cols-3 gap-2'>
          {props.item.image_uids.map((imageUid, index) => (
            <Thumbnail
              key={imageUid}
              removeImage={() => props.removeImage(index)}
              status={props.item.status}
              cacheId={props.cacheId}
              url={props.url}
              onSelect={() => {}}
              imageUid={imageUid}
              variant='survey'
            />
          ))}
        </div>
      )}
    </div>
  )
}
