import { Text, View } from '@react-pdf/renderer'
import { type ReactNode } from 'react'

type SectionProps = {
  children: ReactNode | ReactNode[]
  label: string
}

export const Section = (props: SectionProps) => {
  const { children, label } = props
  return (
    <View
      style={{
        marginBottom: 16,
        border: '1px solid #E0E0E0',
        borderRadius: 10,
        overflow: 'hidden',
      }}
    >
      <Text
        style={{
          fontFamily: 'Helvetica-Bold',
          padding: '10px 12px',
          backgroundColor: '#D8EAED',
          fontWeight: 700,
          fontSize: 12,
          color: '#212121',
        }}
      >
        {label}
      </Text>
      <View
        style={{
          padding: 12,
          gap: 12,
        }}
      >
        {children}
      </View>
    </View>
  )
}

export const SectionSubtitle = ({ children }: { children: ReactNode }) => {
  return (
    <Text
      style={{
        fontFamily: 'Helvetica-Bold',
        fontWeight: 700,
        fontSize: 10,
        lineHeight: '1.4',
        textDecoration: 'underline',
      }}
    >
      {children}
    </Text>
  )
}

type ItemTextProps = {
  label: string
  value?: ReactNode
}

export const ItemTextInline = (props: ItemTextProps) => {
  const { value, label } = props
  return (
    <View
      style={{
        flexDirection: 'row',
        flexWrap: 'nowrap',
        width: '100%',
        gap: 12,
      }}
    >
      <Text
        style={{
          flex: 1,
          color: '#616161',
          fontSize: 10,
          fontWeight: 400,
        }}
      >
        {label}
      </Text>
      <Text
        style={{
          flex: 1,
          fontSize: 10,
          fontWeight: 400,
          color: '#212121',
        }}
      >
        {value || '-'}
      </Text>
    </View>
  )
}

export const ItemTextVertical = (props: ItemTextProps) => {
  const { value, label } = props
  return (
    <View
      style={{
        width: '100%',
        gap: 4,
      }}
    >
      <Text
        style={{
          color: '#616161',
          fontSize: 10,
          fontWeight: 400,
        }}
      >
        {label}
      </Text>
      <Text
        style={{
          fontSize: 10,
          fontWeight: 400,
          color: '#212121',
        }}
      >
        {value || '-'}
      </Text>
    </View>
  )
}

const formatter = new Intl.NumberFormat('de-DE')

export const formatNumber = (number: number) => formatter.format(number)
