import { DataStore } from 'aws-amplify/datastore'
import { useAppStore, useTaskStore } from '~/stores'
import { deleteLocalStorageValue } from '~/utils/localStorageUtils'
import { DATA_STORE_INITIAL_STATE_LOCAL_STORAGE_KEY } from '~/hooks/useDataStoreListen'
import { taskManager } from '~/utils/taskManager'

const deleteDatabases = async () => {
  const allDbs = (await window.indexedDB.databases?.()) || []
  allDbs.forEach((db) => {
    if (db.name && db.name !== 'amplify-datastore') window.indexedDB.deleteDatabase(db.name)
  })
  if (!allDbs.length) {
    window.indexedDB.deleteDatabase('installer-thumbnails')
    const orderIds = useAppStore.getState().orders.map((order) => order.id)
    orderIds.forEach((orderId) => {
      const request = window.indexedDB.deleteDatabase(`installer-${orderId}`)
      request.onerror = (e) => console.error(e, `delete installer-${orderId} failed`)
      request.onblocked = (e) => console.log(e, `delete installer-${orderId} was blocked`)
    })
  }
}

type DeleteClientDataParams = {
  skipDataStoreClear?: boolean
}

export const deleteClientData = async (
  params: DeleteClientDataParams = { skipDataStoreClear: false }
) => {
  if (!params.skipDataStoreClear) await DataStore.clear()
  useTaskStore.getState().actions.clearTasks()
  if (!window.indexedDB) return
  await taskManager.clear()
  await deleteDatabases()
  deleteLocalStorageValue(DATA_STORE_INITIAL_STATE_LOCAL_STORAGE_KEY)
}
