import { Fragment, useCallback, useEffect } from 'react'
import { useFieldArray, useFormContext } from 'react-hook-form'
import { Button, Divider, FormInput, Icon } from '~/components'
import { type CommissioningForm } from './formValidationSchema'

type FormMpptProps = {
  inverterIdx: number
  mpptIdx: number
}

const FormMppt = (props: FormMpptProps) => {
  const { inverterIdx, mpptIdx } = props
  const { watch, control } = useFormContext<CommissioningForm>()
  const { fields, append, remove } = useFieldArray({
    control,
    name: `pvGenerators.${inverterIdx}.mppts.${mpptIdx}.stringElectricalValues`,
  })
  const fieldsLength = fields.length

  const amountOfStrings = watch(`pvGenerators.${inverterIdx}.mppts.${mpptIdx}.amountOfStrings`) ?? 1

  const removeExtraSections = useCallback(
    (currentLength: number, targetLength: number) => {
      const sectionsToRemove = currentLength - targetLength
      Array.from({ length: sectionsToRemove }).forEach((_, i) => remove(currentLength - 1 - i))
    },
    [remove]
  )

  const addMissingSections = useCallback(
    (currentLength: number, targetLength: number) => {
      const sectionsToAdd = targetLength - currentLength
      Array.from({ length: sectionsToAdd }).forEach(() => append({}))
    },
    [append]
  )

  const isValidAmountOfStrings = useCallback((numberOfStrings: number, currentLength: number) => {
    const isMoreThanMinimum = numberOfStrings >= 1
    const isLessThanMaximum = numberOfStrings <= 2
    const isNotEqualToCurrentLength = numberOfStrings !== currentLength

    return numberOfStrings && isNotEqualToCurrentLength && isMoreThanMinimum && isLessThanMaximum
  }, [])

  useEffect(() => {
    if (!isValidAmountOfStrings(amountOfStrings, fieldsLength)) return

    if (amountOfStrings < fieldsLength) removeExtraSections(fieldsLength, amountOfStrings)
    else addMissingSections(fieldsLength, amountOfStrings)
  }, [
    amountOfStrings,
    fieldsLength,
    addMissingSections,
    removeExtraSections,
    isValidAmountOfStrings,
  ])

  return (
    <div className='flex flex-col gap-4'>
      <FormInput
        type='integer'
        min={1}
        name={`pvGenerators[${inverterIdx}].mppts[${mpptIdx}].amountOfModules`}
        label='Anzahl der Module pro MPPT'
      />
      <FormInput
        type='integer'
        min={1}
        max={2}
        name={`pvGenerators[${inverterIdx}].mppts[${mpptIdx}].amountOfStrings`}
        label='Anzahl der Strings'
      />
      {fields.map((_, stringSectionIdx) => (
        <Fragment key={`string-section-${stringSectionIdx}`}>
          <p>String {stringSectionIdx + 1}</p>
          <FormInput
            type='decimal'
            name={`pvGenerators[${inverterIdx}].mppts[${mpptIdx}].stringElectricalValues[${stringSectionIdx}].openCircuitVoltageInVolts`}
            label='Leerlaufspannung'
            suffix={
              <span>
                U<sub>OC</sub>[V]
              </span>
            }
          />
          <FormInput
            type='decimal'
            name={`pvGenerators[${inverterIdx}].mppts[${mpptIdx}].stringElectricalValues[${stringSectionIdx}].insulationResistanceInMegaOhms`}
            label='Isolationswiderstand'
            suffix={
              <span>
                R<sub>ISO</sub>[MΩ]
              </span>
            }
          />
          <FormInput
            type='decimal'
            name={`pvGenerators[${inverterIdx}].mppts[${mpptIdx}].stringElectricalValues[${stringSectionIdx}].shortCircuitCurrentInAmperes`}
            label='Kurzschlussstrom'
            suffix={
              <span>
                I<sub>SC</sub>[A]
              </span>
            }
          />
        </Fragment>
      ))}
    </div>
  )
}

type FormPvGeneratorProps = {
  inverterIdx: number
}

const FormPvGeneratorPerInverter = (props: FormPvGeneratorProps) => {
  const { inverterIdx } = props
  const { control } = useFormContext<CommissioningForm>()
  const { fields, append, remove } = useFieldArray({
    control,
    name: `pvGenerators.${inverterIdx}.mppts`,
  })

  const onRemoveMppt = (mpptIdx: number) => () => {
    remove(mpptIdx)
  }

  const onAddMppt = () => {
    append({
      amountOfStrings: 1,
      stringElectricalValues: [{}],
    })
  }

  return (
    <>
      <p className='text-static-lg-bold'>Wechselrichter #{inverterIdx + 1}</p>
      {fields.map((_, mpptIdx) => (
        <div className='flex flex-col gap-4' key={`forInverter-${inverterIdx}-forMppt-${mpptIdx}`}>
          <div className='flex items-center justify-between'>
            <p className='text-static-lg-semibold'>MPPT {mpptIdx + 1}</p>
            {fields.length > 1 && (
              <Button
                variant='text-dark'
                isIconButton
                onClick={onRemoveMppt(mpptIdx)}
                aria-label='Wechselrichter löschen'
              >
                <Icon icon='general:trash' />
              </Button>
            )}
          </div>
          <FormMppt inverterIdx={inverterIdx} mpptIdx={mpptIdx} />
        </div>
      ))}
      {fields.length < 2 && (
        <div className='flex items-center justify-between'>
          <p className='text-static-lg-bold'>MPPT hinzufügen</p>
          <Button
            variant='text-dark'
            isIconButton
            aria-label='Wechselrichter hinzufügen'
            onClick={onAddMppt}
          >
            <Icon icon='finance:plus' />
          </Button>
        </div>
      )}
    </>
  )
}

const FormPvGenerators = () => {
  const { watch } = useFormContext<CommissioningForm>()
  const pvGenerators = watch('pvGenerators') || []
  if (!pvGenerators.length) return <span>Add at least one inverter</span>

  return (
    <div className='flex flex-col gap-5'>
      {pvGenerators.map((_, inverterIdx) => {
        return (
          <Fragment key={`forInverter-${inverterIdx}`}>
            {pvGenerators.length > 1 && inverterIdx !== 0 && <Divider />}
            <FormPvGeneratorPerInverter inverterIdx={inverterIdx} />
          </Fragment>
        )
      })}
    </div>
  )
}

export default FormPvGenerators
