import { Fragment, useCallback, useEffect, useRef, useState } from 'react'
import {
  CanvasOverlay,
  Controls,
  PreviewCanvas,
  Steps,
  Survey,
  ThumbnailList,
} from '~/components/Documentation'
import { documentationSteps, getItemWithValues } from './documentationSteps'
import { Report, ReportItemEntity, ReportQuestionEntity, ReportStepEntity } from '~/models'
import { type ActivityType, useAppStore } from '~/stores/useAppStore'
import { type Answers } from '~/components/Documentation/Survey'
import { useOrder, useTaskManager } from '~/hooks'
import { nanoid } from 'nanoid'
import { localImagePrefix, maxImageCountPerStep } from '~/components/Documentation/utitlities'
import { getDocumentServiceUrl } from '~/config/documents'
import { DocumentationStatus } from '~/components/Documentation/DocumentationStatus'
import {
  addDevToolsDocumentation,
  addImageIdsToReport,
  findItemInReport,
  itemIsReady,
  removeImageFromReport,
  submitReport,
} from '~/services/standardReport'
import { createCache } from '~/services/cache'
import { type UploadReportImageTask } from '~/services/taskRunners/uploadReportImageRunner'
import { useParams } from 'react-router-dom'
import { DataStore } from 'aws-amplify/datastore'
import { ReportStatus } from '~/models'
import * as Sentry from '@sentry/react'
import { datadogLogs } from '@datadog/browser-logs'

type StandardDocumentationParams = {
  orderId: string
  activityType: string
  stepItemId?: string
}

export function StandardDocumentation() {
  const { orderId = '', activityType, stepItemId } = useParams<StandardDocumentationParams>()
  const reportRef = useRef<Report>()

  const order = useOrder(orderId)
  const { queueTasks } = useTaskManager()
  const reportable = order.getReport(activityType as ActivityType)
  const activityId = reportable.report?.activity_uid ?? ''

  const [selectedThumbnail, setSelectedThumbnail] = useState<Record<string, number>>({})
  const [selectedItem, setSelectedItem] = useState<string>(
    stepItemId ?? (reportable.type === 'report' ? reportable.report.steps[0].items[0].key : '')
  )

  useEffect(() => {
    const observer = DataStore.observeQuery(Report, (report) =>
      report.activity_uid.eq(activityId)
    ).subscribe({
      next: ({ items }) => {
        if (!activityId) return
        reportRef.current = items[0]
      },
    })

    return () => {
      observer.unsubscribe()
    }
  }, [activityId])

  const onSaveDraftSurvey = useCallback(
    async (values: Answers) => {
      try {
        if (!orderId || !activityId) throw new Error('Invalid order or activity')
        const currentReport = reportRef.current
        if (!currentReport || currentReport?.status !== ReportStatus.DRAFT) return
        const updatedReport = Report.copyOf(currentReport, (draft) => {
          draft.steps = draft.steps.map(
            (s) =>
              new ReportStepEntity({
                ...s,
                items: s.items.map(
                  (i) =>
                    new ReportItemEntity({
                      ...i,
                      ...(i.questions && {
                        questions: i.questions.map((q) => {
                          const answer = values[q.name]
                          return new ReportQuestionEntity({
                            ...q,
                            answer,
                          })
                        }),
                      }),
                    })
                ),
              })
          )
        })
        await DataStore.save(updatedReport)
      } catch (error) {
        Sentry.captureException(error, { extra: { orderId, activityId } })
      }
    },
    [orderId, activityId]
  )

  if (reportable.type != 'report') {
    return <div>Not an valid report</div>
  }
  const customerName = order.customer_name || ''
  const { report, hash } = reportable

  const { setBottomModal } = useAppStore.use.modalActions()
  const item = getItemWithValues(findItemInReport(report, selectedItem))

  async function addImages(files: FileList): Promise<Array<string>> {
    const cache = createCache(orderId)
    const maxNewImagesCount = maxImageCountPerStep - item.image_uids.length
    const imagePromises = Array.from(files)
      .slice(0, maxNewImagesCount)
      .map((file) => {
        const id = `local_${nanoid()}`
        return cache.setBlob(id, file)
      })
    const images = await Promise.all(imagePromises)
    return images.map((blob) => blob.id)
  }

  async function addImagesToItem(files: FileList) {
    const itemImageIds = await addImages(files)
    setSelectedThumbnail((previousSelectedThumbnail) => {
      return {
        ...previousSelectedThumbnail,
        [item.key]: itemImageIds.length - 1,
      }
    })

    await addImageIdsToReport(report, item.key, itemImageIds)
    const tasks = createUploadReportImageTasks(
      itemImageIds.filter((imageID) => imageID.startsWith('local')),
      {
        itemId: item.key,
        reportId: report.id,
        orderId: orderId,
        customerHash: hash,
        queuedAtIso: new Date().toISOString(),
      }
    )
    queueTasks(orderId, tasks, item.key)
  }

  async function removeImage(index: number) {
    const reportResult = await removeImageFromReport(report, item.key, index)
    setSelectedThumbnail((previousSelectedThumbnail) => {
      const itemImages = findItemInReport(reportResult, item.key).image_uids
      if (itemImages.length === 0) {
        const copy = { ...previousSelectedThumbnail }
        delete copy[item.key]
        return copy
      }
      const itemThumbnailIndex = previousSelectedThumbnail[item.key]
      if (itemThumbnailIndex !== undefined && itemThumbnailIndex >= itemImages.length) {
        return { ...previousSelectedThumbnail, [item.key]: itemThumbnailIndex - 1 }
      }
      return previousSelectedThumbnail
    })
  }

  function updateSelectedThumbnail(index: number) {
    if (index === -1) {
      setBottomModal({
        title: 'Fototipp',
        description: (
          <Fragment>
            <div className='text-body-1-regular mb-4 text-2xl'>{item.title}</div>
            <div className='mb-4'>{item.sketch}</div>
            <div className='text-md text-body-1-regular'>{item.description}</div>
          </Fragment>
        ),
      })
      return
    }
    if (item.image_uids[index] === undefined) {
      return
    }
    setSelectedThumbnail({ ...selectedThumbnail, [item.key]: index })
  }

  function updateSelectedItem(id: string) {
    setSelectedItem(id)
  }

  const isSubmittable = isReportSubmittable(report)

  async function onSubmitReport(data: Answers) {
    if (!isSubmittable) {
      datadogLogs.logger.error(
        `[StandardReport] Trying to submit an report that is not currently submittable`,
        { report }
      )
      return
    }
    void submitReport(report, item.key, data)
  }

  const thumbnailIndex = selectedThumbnail[item.key] ?? (item.image_uids.length > 0 ? 0 : undefined)
  const showPhotos = !Array.isArray(item.questions)
  const mainHeight = showPhotos ? 'h-[65%]' : 'h-[85%]'
  const menuHeight = showPhotos ? 'h-[35%]' : 'h-[15%]'
  const stepsHeight = showPhotos ? 'pt-[32px]' : 'pt-0'
  const documentServiceUrlForCustomer = `${getDocumentServiceUrl()}/customers/${hash}/files`
  const missingItems = getMissingItemsFromReport(report)

  addDevToolsDocumentation(report, item)

  return (
    <div className='absolute flex h-full w-full flex-col'>
      <div className={`relative ${mainHeight} overflow-x-auto transition-all`}>
        {showPhotos ? (
          <PreviewCanvas
            onNewImages={addImagesToItem}
            cacheId={orderId}
            url={documentServiceUrlForCustomer}
            imageUid={item.image_uids[thumbnailIndex ?? -1]}
            reportStatus={report.status}
          />
        ) : (
          <Survey
            onSaveDraftSurvey={onSaveDraftSurvey}
            isSubmittable={isSubmittable}
            status={report.status}
            missingItems={missingItems}
            questions={item.questions}
            onSubmit={onSubmitReport}
            additionalDocuments={{
              onNewImages: addImagesToItem,
              removeImage: removeImage,
              item,
              url: documentServiceUrlForCustomer,
              cacheId: orderId,
            }}
          />
        )}

        <CanvasOverlay
          name={customerName}
          orderId={orderId}
          activityType={report.report_type as ActivityType}
          item={item}
        >
          <DocumentationStatus orderId={orderId} item={item} />
        </CanvasOverlay>
      </div>
      <div
        className={`relative flex ${menuHeight} flex-col items-center bg-black text-white transition-all`}
      >
        {showPhotos && (
          <div className='absolute -top-[52px] left-0 w-full'>
            <ThumbnailList
              itemId={item.key}
              cacheId={orderId}
              imageUids={item.image_uids}
              url={documentServiceUrlForCustomer}
              status={item.status}
              onSelect={updateSelectedThumbnail}
              selectedIndex={thumbnailIndex}
              removeImage={removeImage}
            />
          </div>
        )}
        <div className={`h-full w-full flex-grow ${stepsHeight} transition-all`}>
          <Steps steps={report.steps} selectedItem={item.key} onItemChange={updateSelectedItem} />
        </div>
        {showPhotos && (
          <div className='w-full'>
            <Controls
              status={report.status}
              onNewImages={addImagesToItem}
              imageCount={item.image_uids.length}
            />
          </div>
        )}
      </div>
    </div>
  )
}

function createUploadReportImageTasks(
  blobIds: Array<string>,
  restPayload: Omit<UploadReportImageTask['payload'], 'blobId'>
): Array<UploadReportImageTask> {
  return blobIds.map((blobId) => ({
    runner: 'upload',
    payload: {
      ...restPayload,
      blobId,
    },
  }))
}

const isReportSubmittable = (report: Report) => {
  if (report.status !== ReportStatus.DRAFT) return false
  const reportReference = documentationSteps[report.report_type.toLowerCase()]

  for (const step of report.steps) {
    const stepReference = reportReference.find((el) => el.key === step.key)
    if (!stepReference) {
      datadogLogs.logger.error(`[StandardReport] Step reference not found for key ${step.key}`, {
        report,
        step,
      })
      continue
    }

    for (const item of step.items) {
      const isItemAdditionalInformation = item.questions?.length

      if (isItemAdditionalInformation) {
        const isSomeRequiredQuestionUnanswered = !!item.questions.find(
          (el) => el.required && !el.answer
        )
        if (isSomeRequiredQuestionUnanswered) return false
        const isSomeImageNotUploaded = !!item.image_uids.find((el) => el.includes(localImagePrefix))
        if (isSomeImageNotUploaded) return false
      } else {
        const itemReference = stepReference?.items.find((el) => el.key === item.key)
        if (!itemReference) {
          datadogLogs.logger.error(
            `[StandardReport] Item reference not found for key ${item.key}`,
            {
              report,
              step,
              item,
            }
          )
          continue
        }

        if (itemReference?.required && !item.image_uids.length) return false

        const isSomeImageNotUploaded = !!item.image_uids.find((el) => el.includes(localImagePrefix))
        if (isSomeImageNotUploaded) return false
      }
    }
  }
  return true
}

function getMissingItemsFromReport(report: Report): Array<string> {
  const defaultReport = documentationSteps
  return report.steps.reduce<Array<string>>((items, step, stepIndex) => {
    const defaultReportStep = defaultReport[report.report_type.toLowerCase()][stepIndex]
    step.items.forEach((item, itemIndex) => {
      const defaultReportItem = defaultReportStep.items[itemIndex]
      if (item.questions && item.questions.length > 0) {
        return items
      }
      if (
        itemIsReady(item) ||
        //TODO: Remove default report hack after a couple weeks/months when it's no longer needed. Should be safe to remove at latest 1 month after this commit
        !defaultReportItem.required // Using default report as hack to make sure optional steps are also treated as optional in reports that have already been started
      ) {
        return items
      }
      items.push(getItemWithValues(item).title)
    })
    return items
  }, [])
}
