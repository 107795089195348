import { Report, type ReportItemEntity, ReportItemEntityStatus, ReportStatus } from '~/models'
import { type Draft } from 'immer'
import { type Answers } from '~/components/Documentation/Survey'
import { findItemInReport } from './findItemInReport'
import { saveReport } from './saveReport'
import { getLatestReport } from './getLatestReport'

export async function submitReport(report: Report, itemId: string, data: Answers): Promise<Report> {
  const latestReport = await getLatestReport(report)
  const updatedReport = Report.copyOf(latestReport, (reportDraft) => {
    const itemDraft: Draft<ReportItemEntity> = findItemInReport(reportDraft, itemId)

    if (reportDraft.status === ReportStatus.READY) {
      return
    }

    if (!itemDraft || itemDraft.status === ReportItemEntityStatus.READY) {
      return
    }

    if (!Array.isArray(itemDraft.questions)) {
      throw Error('This item has no attached questions')
    }

    itemDraft.questions.forEach((question) => {
      const answer = data[question.name]
      question.answer = answer ?? ''
    })
    itemDraft.status = ReportItemEntityStatus.READY
    reportDraft.status = ReportStatus.READY
  })

  return saveReport(updatedReport, { forced: true })
}
