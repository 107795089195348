import { useEffect, useRef } from 'react'
import { useRegisterSW } from 'virtual:pwa-register/react'
import { type Id } from 'react-toastify'
import { toast } from '~/components'

const UPDATE_CHECK_INTERVAL = 60 * 60 * 1000 // 1h

const useServiceWorkerPrompt = () => {
  const intervalId = useRef<ReturnType<typeof setInterval>>()
  const toastId = useRef<Id>()

  const {
    needRefresh: [doesNeedRefresh, setDoesNeedRefresh],
    updateServiceWorker,
  } = useRegisterSW({
    onRegisteredSW: (_, registration) => {
      if (!registration) throw new Error('No service worker available')
      clearInterval(intervalId.current)
      intervalId.current = setInterval(() => {
        registration.update()
      }, UPDATE_CHECK_INTERVAL)
    },
    onRegisterError: (err) => {
      console.error('service worker did not register correctly', err)
    },
  })

  useEffect(() => {
    return () => {
      clearInterval(intervalId.current)
    }
  }, [])

  useEffect(() => {
    if (!doesNeedRefresh || toastId.current) return
    toastId.current = toast.spawn(
      {
        variant: 'warning',
        headline: 'App Update',
        content: 'Eine neue Version der App ist verfügbar',
        cta: {
          label: 'Jetzt aktualisieren',
          onClick: updateServiceWorker,
        },
        onClose: () => setDoesNeedRefresh(false),
      },
      { autoClose: false }
    )
  }, [doesNeedRefresh, updateServiceWorker, setDoesNeedRefresh])
}

export default useServiceWorkerPrompt
