export async function fetchBlob(
  blobUrl: string,
  token?: string,
  forcedMimeType?: string
): Promise<Blob | undefined> {
  try {
    const headers = new Headers()
    if (token) {
      headers.set('Authorization', `Bearer ${token}`)
      headers.set('x-auth-method', 'auth0')
    }
    const response = await fetch(blobUrl, { headers })

    if (response.status !== 200) return undefined

    const buffer = await response.arrayBuffer()

    return new Blob([buffer], {
      type: (forcedMimeType || response.headers.get('Content-Type')) ?? undefined,
    })
  } catch (error) {
    return undefined
  }
}
