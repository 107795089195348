import { type ReactNode } from 'react'
import { useParams } from 'react-router-dom'
import { InlineMessage } from '~/components'
import { ActivityLqvDocumentation, ActivityDocumentation } from '~/components/Activity'
import { type Reportable } from '~/services/ReportService'
import { useOrder } from '~/hooks'
import ActivityPageLayout from './ActivityPageLayout'

type ActivityParams = {
  orderId: string
  activityType: 'ac' | 'dc'
}

const AcDcActivityPage = () => {
  const { orderId = '', activityType = 'ac' } = useParams<ActivityParams>()
  const order = useOrder(orderId)
  const reportable = order.getReport(activityType)

  return (
    <ActivityPageLayout>
      <div>
        {reportable.type === undefined ? (
          <InlineMessage
            variant='neutral'
            icon='general:info-circle'
            headline='Dokumentation nicht möglich'
          >
            {reportableMessages[reportable.status]}
          </InlineMessage>
        ) : (
          <>
            {reportable.type === 'lqv-report' ? (
              <ActivityLqvDocumentation
                orderId={order.id}
                activityType={activityType}
                report={reportable.report}
              />
            ) : (
              <ActivityDocumentation
                orderId={order.id}
                activityType={activityType}
                report={reportable.report}
              />
            )}
          </>
        )}
      </div>
    </ActivityPageLayout>
  )
}

type ReportableMessages = {
  [Key in Reportable['status']]?: ReactNode
}

const reportableMessages: ReportableMessages = {
  prior: (
    <>
      Der Termin ist entweder noch nicht bestätigt oder es liegt ein anderes Problem vor. Lass den
      Termin bestätigen, um die Dokumentation zu starten.
    </>
  ),
  incompatible: (
    <>
      Für dieses Projekt existiert bereits eine Dokumentation im{' '}
      <a
        href='https://project-center.zolar.de/'
        rel='noreferrer'
        target='_blank'
        className='text-link underline'
      >
        <span>zolar Project Center</span>
      </a>
    </>
  ),
  missing_lqv_report: (
    <>
      Die Dokumentation kann erst gestartet werden, wenn ein LQV-Report im Backend hinterlegt wurde.
    </>
  ),
}

export default AcDcActivityPage
