import { Fragment } from 'react'
import { type OrderDocument } from '~/models'
import { type GetBlobByUrl } from '~/hooks'
import { Divider, Icon } from '~/components'
import { openCacheDocumentByUrlInTab } from '~/utils/openCacheDocumentByUrlInTab'
import md5 from 'md5'

interface DocumentsProps {
  documents: OrderDocument[]
  getBlobByUrl: GetBlobByUrl
  showLoadingOverlay: (loading: boolean | string) => void
}
export function Documents(props: DocumentsProps) {
  const { documents, getBlobByUrl, showLoadingOverlay } = props

  return documents.length > 0 ? (
    <div className='mb-4 flex w-full flex-col'>
      <Divider className='mb-6 mt-6' />
      <div className='text-headline-2 mb-1'>Beigefügte Dokumente</div>
      <div className='mb-4 text-dark-secondary'>
        Lade dir die beigefügten Dokumente herunter und öffne sie ohne Internetverbindung.
      </div>

      {documents.map((doc, index) => (
        <Fragment key={md5(doc.url + index)}>
          <div
            className='mb-2 flex cursor-pointer items-center rounded border border-dark-3 p-3 pl-4 pr-4'
            onClick={() =>
              openCacheDocumentByUrlInTab(
                doc.url,
                getBlobByUrl,
                true,
                doc.mimeType,
                showLoadingOverlay
              )
            }
          >
            <div className='text-body-2-bold grow pr-4'>
              {doc.originalName?.replaceAll('_', ' ')}
            </div>
            <Icon icon='general:download' size='sm' />
          </div>
        </Fragment>
      ))}
    </div>
  ) : null
}
