import { type GetAccessToken } from '~/services/taskRunners/executeRunner'
import { type InstallerCache } from '~/services/cache'
import { getDocumentServiceUrl } from '~/config/documents'
import { Report, type ReportItemEntity, ReportItemEntityStatus } from '~/models'
import {
  findItemInReport,
  getLatestReportById,
  itemIsReady,
  saveReport,
} from '~/services/standardReport'
import { type Draft } from 'immer'
import { datadogRum } from '@datadog/browser-rum'
import { serializeError } from 'serialize-error'

export interface UploadReportImageTask {
  runner: 'upload'
  payload: {
    blobId: string
    customerHash: string
    orderId: string
    reportId: string
    itemId: string
    queuedAtIso: string
  }
}

export async function uploadReportImageRunner(
  task: UploadReportImageTask,
  getToken: GetAccessToken,
  cache: InstallerCache
): Promise<Report | void> {
  const { blobId, customerHash, orderId, reportId, itemId, queuedAtIso } = task.payload
  const token = await getToken()
  const blob = await cache.getBlobById(blobId)

  if (blob === undefined) {
    throw Error('Could not find the blobId in IndexedDB')
  }

  const { name } = await saveBlobInDocumentService(token, blob, customerHash, orderId)
  const completedAtInMilliseconds = Date.now()
  const taskDurationInMilliseconds = completedAtInMilliseconds - new Date(queuedAtIso).valueOf()
  datadogRum.addAction('task_completed', {
    queuedAtIso,
    completedAtIso: new Date(completedAtInMilliseconds).toISOString(),
    taskDurationInMilliseconds,
    taskType: 'uploadReportImage',
  })

  const latestReport = await getLatestReportById(reportId)

  const updatedReport = Report.copyOf(latestReport, (reportDraft) => {
    const draftItem: Draft<ReportItemEntity> = findItemInReport(reportDraft, itemId)
    const replacementIndex = draftItem.image_uids.indexOf(blobId)
    if (replacementIndex === -1) {
      return
    }
    draftItem.image_uids[replacementIndex] = name
    if (itemIsReady(draftItem)) {
      draftItem.status = ReportItemEntityStatus.READY
    }
  })

  return saveReport(updatedReport)
}

async function saveBlobInDocumentService(
  token: string,
  blob: Blob,
  customerId: string,
  transactionId: string
): Promise<{ name: string }> {
  const formData = new FormData()
  const headers = new Headers()
  const baseUrl: string = getDocumentServiceUrl()
  const url = `${baseUrl}/customers/${customerId}/files?transactionId=${transactionId}`

  formData.append('file', blob)
  headers.set('Authorization', `Bearer ${token}`)
  headers.set('x-auth-method', 'auth0')

  const startedAtInMs = Date.now()
  try {
    const response = await fetch(url, { method: 'POST', body: formData, headers })
    const endedAtInMs = Date.now()
    datadogRum.addAction('document_upload_to_server_success', {
      startedAtIso: new Date(startedAtInMs).toISOString(),
      endedAtIso: new Date(endedAtInMs).toISOString(),
      durationInMilliseconds: endedAtInMs - startedAtInMs,
      documentSizeInBytes: blob.size,
      documentType: blob.type,
    })
    return response.json()
  } catch (error) {
    const endedAtInMs = Date.now()
    datadogRum.addAction('document_upload_to_server_failure', {
      startedAtIso: new Date(startedAtInMs).toISOString(),
      endedAtIso: new Date(endedAtInMs).toISOString(),
      durationInMilliseconds: endedAtInMs - startedAtInMs,
      documentSizeInBytes: blob.size,
      documentType: blob.type,
      error: serializeError(error),
    })
    throw error
  }
}
