import { useAuth0 } from '@auth0/auth0-react'
import { useEffect } from 'react'
import { Amplify } from 'aws-amplify'
import awsExports from '~/aws-exports'
import { decodeJWT } from 'aws-amplify/auth'
import { taskManager } from '~/utils/taskManager'
import { datadogRum } from '@datadog/browser-rum'

interface Authentication {
  isAuthenticated: boolean
  isAuthenticating: boolean
  error: Error | undefined
}

export function useAuthentication(): Authentication {
  const { getAccessTokenSilently, isAuthenticated, isLoading, error, logout, user } = useAuth0()
  const installerId = user?.['https://zolar.de/installer_id']

  useEffect(() => {
    if (!isAuthenticated || installerId) {
      return
    }
    datadogRum.addError('The installer ID is missing for the user.')
  }, [installerId, isAuthenticated])

  useEffect(() => {
    Amplify.configure(
      {
        ...Amplify.getConfig(),
        ...awsExports,
      },
      {
        Auth: {
          tokenProvider: {
            getTokens: async () => {
              try {
                const { access_token, id_token } = await getAccessTokenSilently({
                  detailedResponse: true,
                })
                return {
                  accessToken: decodeJWT(access_token),
                  idToken: decodeJWT(id_token),
                }
              } catch (error) {
                await taskManager.clear()
                await logout()
                return null
              }
            },
          },
        },
      }
    )
  }, [getAccessTokenSilently, logout])

  return { isAuthenticated, isAuthenticating: isLoading, error }
}
