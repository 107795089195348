import { useEffect } from 'react'
import {
  DataStore,
  type PersistentModelConstructor,
  Predicates,
  SortDirection,
  type SyncConflict,
  syncExpression,
  DISCARD,
} from 'aws-amplify/datastore'
import { useAppStore } from '~/stores'
import {
  Activity,
  Appointment,
  Comment,
  LqvReport,
  Report,
  Order,
  Product,
  OrderDocument,
  ComReportDefaultData,
  ComReport,
  ZpcStandardReportShape,
} from '~/models'
import { useInitialSyncOrderIds } from '~/hooks/useInitialSyncOrderIds'
import * as Sentry from '@sentry/react'
import { deleteLocalStorageValue, getLocalStorageValue } from '~/utils/localStorageUtils'
import { DATA_STORE_INITIAL_STATE_LOCAL_STORAGE_KEY } from '~/hooks/useDataStoreListen'

let isInitial = getLocalStorageValue(DATA_STORE_INITIAL_STATE_LOCAL_STORAGE_KEY, true)

export const changeIsInitialInUseAmplifyDataStore = () => {
  isInitial = false
}

const resetDataStoreAndRetry = (modelName: string, error: Error) => {
  console.error('Encountered Error trying to load ', modelName, ': ', error)
  console.log('Deleting datastore and reloading page...')
  try {
    if (!window.indexedDB) throw new Error('indexedDB not supported')
    window.indexedDB.deleteDatabase('amplify-datastore')
    deleteLocalStorageValue(DATA_STORE_INITIAL_STATE_LOCAL_STORAGE_KEY)
  } catch (e) {
    console.warn(e)
  }
  // regardless of the success of the deletion, the upgradeneeded event is triggered and
  // causes amplify to execute a migration, so we don't need to react on the success/onblocked/onerror events here
  window.location.reload()
}

export function useAmplifyDataStore(isAuthenticated: boolean) {
  const {
    setActivities,
    setAppointments,
    setComments,
    setProducts,
    setOrders,
    setOrderDocuments,
    setReports,
    setLqvReports,
    setComReportDefaultData,
    setComReports,
    setZpcStandardReportShape,
  } = useAppStore.use.datastoreModelActions()
  const dataStoreFullStatus = useAppStore.use.dataStoreFullStatus()

  const {
    orderData: { orderIds },
    isOrderDataReady,
  } = useInitialSyncOrderIds(isAuthenticated)

  useEffect(() => {
    if (!isAuthenticated || (isInitial && !isOrderDataReady)) {
      return
    }

    const isOrderDataAvailable = orderIds?.length > 0

    DataStore.configure({
      syncPageSize: 5_000, // default is 1_000, how many items per request, appsync chunks requests automatically to keep the size under the 1mb limit
      errorHandler: (error) => {
        Sentry.captureException(error)
      },
      conflictHandler: async (data: SyncConflict) => {
        if ((data.modelConstructor as PersistentModelConstructor<ComReport>) === ComReport) {
          const remoteModel = data.remoteModel as ComReport & { _version: number }
          const localModel = data.localModel as ComReport
          return {
            id: remoteModel.id,
            formData: localModel.formData,
            _version: remoteModel._version,
          }
        }
        return DISCARD
      },
      syncExpressions: [
        syncExpression(Appointment, async () => {
          if (isInitial && isOrderDataAvailable) {
            return (appointment) =>
              appointment?.and(() => [
                appointment?.state.ne('cancelled'),
                appointment?.or((appointment) =>
                  orderIds.map((id) => appointment.order_uid.eq(id))
                ),
              ])
          }
          return (appointment) => appointment?.state.ne('cancelled')
        }),
        syncExpression(Order, async () => {
          if (isInitial && isOrderDataAvailable) {
            return (o) => o?.or((o) => orderIds.map((id) => o.id.eq(id)))
          }
          return Predicates.ALL as never
        }),
        syncExpression(Report, async () => {
          if (isInitial && isOrderDataAvailable) {
            return (o) => o?.or((o) => orderIds.map((id) => o.order_hash.eq(id)))
          }
          return Predicates.ALL as never
        }),

        syncExpression(Activity, async () => {
          if (isInitial && isOrderDataAvailable) {
            return (o) => o?.or((o) => orderIds.map((id) => o.order_uid.eq(id)))
          }
          return Predicates.ALL as never
        }),
        syncExpression(Comment, async () => {
          if (isInitial && isOrderDataAvailable) {
            return (o) => o?.or((o) => orderIds.map((id) => o.order_uid.eq(id)))
          }
          return Predicates.ALL as never
        }),
        syncExpression(ZpcStandardReportShape, async () => {
          if (isInitial && isOrderDataAvailable) {
            return (o) => o?.or((o) => orderIds.map((id) => o.order_uid.eq(id)))
          }
          return Predicates.ALL as never
        }),
        syncExpression(ComReport, async () => {
          if (isInitial && isOrderDataAvailable) {
            return (o) => o?.or((o) => orderIds.map((id) => o.order_uid.eq(id)))
          }
          return Predicates.ALL as never
        }),
        syncExpression(ComReportDefaultData, async () => {
          if (isInitial && isOrderDataAvailable) {
            return (o) => o?.or((o) => orderIds.map((id) => o.order_uid.eq(id)))
          }
          return Predicates.ALL as never
        }),
        // The Product & LqvReport entity is not filtered
        syncExpression(Product, () => Predicates.ALL),
        syncExpression(LqvReport, () => Predicates.ALL),
        // Disable filtering for OrderDocuments because it leads to a lot of backend requests
        syncExpression(OrderDocument, () => Predicates.ALL),
      ],
    })

    const activitySubscription = DataStore.observeQuery(Activity, Predicates.ALL).subscribe(
      ({ items }) => {
        setActivities(items)
      },
      (error) => resetDataStoreAndRetry('activity', error)
    )

    const appointmentSubscription = DataStore.observeQuery(
      Appointment,
      (w) => w.and((a) => [a.state.ne('cancelled')]),
      {
        sort: (s) => s.start_date(SortDirection.DESCENDING),
      }
    ).subscribe(
      ({ items }) => {
        setAppointments(items)
      },
      (error) => resetDataStoreAndRetry('appointments', error)
    )

    const commentSubscription = DataStore.observeQuery(Comment, Predicates.ALL, {
      sort: (s) => s.updated_at(SortDirection.ASCENDING),
    }).subscribe(
      ({ items }) => {
        setComments(items)
      },
      (error) => resetDataStoreAndRetry('comments', error)
    )

    const productSubscription = DataStore.observeQuery(Product, Predicates.ALL, {}).subscribe(
      ({ items }) => {
        setProducts(items)
      },
      (error) => resetDataStoreAndRetry('products', error)
    )

    const orderSubscription = DataStore.observeQuery(Order, Predicates.ALL).subscribe(
      ({ items }) => {
        setOrders(items)
      },
      (error) => resetDataStoreAndRetry('orders', error)
    )

    const orderDocumentsSubscription = DataStore.observeQuery(
      OrderDocument,
      Predicates.ALL
    ).subscribe({
      next: ({ items }) => {
        setOrderDocuments(items)
      },
      error: (error) => resetDataStoreAndRetry('orders', error),
    })

    const reportsSubscription = DataStore.observeQuery(Report, Predicates.ALL).subscribe({
      next: ({ items }) => {
        setReports(items)
      },
      error: (error) => resetDataStoreAndRetry('reports', error),
    })

    const lqvReportsSubscription = DataStore.observeQuery(LqvReport, Predicates.ALL).subscribe({
      next: ({ items }) => {
        setLqvReports(items)
      },
      error: (error) => resetDataStoreAndRetry('lqvReports', error),
    })

    const comReportDefaultDataSubscription = DataStore.observeQuery(
      ComReportDefaultData,
      Predicates.ALL
    ).subscribe({
      next: ({ items }) => {
        setComReportDefaultData(items)
      },
      error: (error) => resetDataStoreAndRetry('comReportDefaultData', error),
    })

    const comReportsSubscription = DataStore.observeQuery(ComReport, Predicates.ALL).subscribe({
      next: ({ items }) => {
        setComReports(items)
      },
      error: (error) => resetDataStoreAndRetry('comReports', error),
    })

    const zpcStandardReportShapeSubscription = DataStore.observeQuery(
      ZpcStandardReportShape,
      Predicates.ALL
    ).subscribe({
      next: ({ items }) => {
        setZpcStandardReportShape(items)
      },
      error: (error) => resetDataStoreAndRetry('comReports', error),
    })

    return () => {
      activitySubscription.unsubscribe()
      appointmentSubscription.unsubscribe()
      commentSubscription.unsubscribe()
      productSubscription.unsubscribe()
      orderSubscription.unsubscribe()
      orderDocumentsSubscription.unsubscribe()
      reportsSubscription.unsubscribe()
      lqvReportsSubscription.unsubscribe()
      comReportDefaultDataSubscription.unsubscribe()
      comReportsSubscription.unsubscribe()
      zpcStandardReportShapeSubscription.unsubscribe()
    }
  }, [
    setActivities,
    setAppointments,
    setComments,
    setOrders,
    setOrderDocuments,
    setReports,
    setLqvReports,
    setProducts,
    setComReportDefaultData,
    setComReports,
    setZpcStandardReportShape,
    isAuthenticated,
    orderIds,
    isOrderDataReady,
    dataStoreFullStatus.syncQueriesReady,
  ])
}
