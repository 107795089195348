import { Image, Text, View } from '@react-pdf/renderer'
import { type CommissioningForm } from '../formValidationSchema'

const Signature = ({ src }: { src: string }) => {
  return (
    <Image
      src={src}
      style={{
        width: 190,
        position: 'absolute',
        bottom: 0,
        right: 0,
      }}
    />
  )
}

const PdfSectionSignature = (props: { data: CommissioningForm['signature'] }) => {
  const {
    data: { installer, client },
  } = props
  return (
    <View
      style={{
        flexDirection: 'row',
        flexWrap: 'nowrap',
        width: '100%',
        gap: 24,
        marginTop: 80,
      }}
    >
      <Text style={{ fontSize: 10, flex: 1 }}>
        Mit Ihrer Unterschrift bestätigen Sie die Richtigkeit der Angaben im obigen Prüfbericht.
      </Text>
      <View style={{ flex: 1, gap: 32 }}>
        <View style={{ position: 'relative' }}>
          {installer.dataUrl && <Signature src={installer.dataUrl} />}
          <Text style={{ fontSize: 10, lineHeight: 1.6, borderBottom: '1px solid black' }}>
            Unterschrift (Prüfer)
          </Text>
        </View>
        <View style={{ position: 'relative' }}>
          {client.dataUrl && <Signature src={client.dataUrl} />}
          <Text style={{ fontSize: 10, lineHeight: 1.6, borderBottom: '1px solid black' }}>
            Unterschrift (Kunde)
          </Text>
        </View>
      </View>
    </View>
  )
}

export default PdfSectionSignature
