import { type Appointment, type Order } from '~/models'
import {
  type ActivityType,
  type AppointmentsFilter,
  type Timeframe,
  useAppStore,
} from '~/stores/useAppStore'
import { endOfDay, endOfWeek, format, startOfMonth, startOfToday, startOfWeek } from 'date-fns'
import { de } from 'date-fns/locale'
import { useCallback } from 'react'
import { useAuth0 } from '@auth0/auth0-react'
import { type AuthOUser } from '~/utils/types'

type TimeSections =
  | 'today'
  | 'thisWeek'
  | 'nextWeek'
  | 'thisMonth'
  | 'later'
  | 'yesterday'
  | 'earlierThisWeek'
  | 'earlierThisMonth'
  | 'older'

export interface GroupedOrders {
  title: string
  timeSection: TimeSections
  orders: Order[]
}

export interface UseTimeline {
  getGroupedOrders: (
    timeframe: Timeframe,
    appointmentsFilter: AppointmentsFilter
  ) => GroupedOrders[]
  getGroupedOrdersCount: (
    appointmentsFilter: AppointmentsFilter
  ) => Record<Timeframe | 'total', number>
}

export function useTimeline(): UseTimeline {
  const orders = useAppStore.use.orders()
  const appointments = useAppStore.use.appointments()

  const { user } = useAuth0<AuthOUser>()
  const installerId = user?.['https://zolar.de/installer_id'] ?? null

  const getGroupedOrders = useCallback<UseTimeline['getGroupedOrders']>(
    (timeframe, appointmentsFilter) => {
      const func = timeframe === 'future' ? getFutureOrders : getPastOrders
      return func(orders, appointments, appointmentsFilter, installerId)
    },
    [orders, appointments, installerId]
  )

  const getGroupedOrdersCount = useCallback<UseTimeline['getGroupedOrdersCount']>(
    (appointmentsFilter) => {
      const futureOrders = filterOrders(
        orders,
        filterFutureAppointments(appointments, appointmentsFilter, installerId)
      )
      const pastOrders = filterOrders(
        orders,
        filterPastAppointments(appointments, appointmentsFilter, installerId)
      )
      return {
        future: futureOrders.length,
        past: pastOrders.length,
        total: futureOrders.length + pastOrders.length,
      }
    },
    [orders, appointments, installerId]
  )

  return {
    getGroupedOrders,
    getGroupedOrdersCount,
  }
}

function getFutureOrders(
  orders: Order[],
  appointments: Appointment[],
  appointmentsFilter: AppointmentsFilter,
  installerId: string | null
) {
  const groupedOrders: GroupedOrders[] = []
  const { today, thisWeek, nextWeek, later } = groupFutureAppointments(
    appointments,
    appointmentsFilter,
    installerId
  )

  if (today.length) {
    const filteredOrders = filterOrders(orders, today)
    if (filteredOrders.length) {
      groupedOrders.push({
        title: 'Heute, ' + format(new Date(), 'd. MMMM', { locale: de }),
        timeSection: 'today',
        orders: filteredOrders,
      })
    }
  }

  if (thisWeek.length) {
    const filteredOrders = filterOrders(orders, thisWeek)
    if (filteredOrders.length) {
      groupedOrders.push({
        title: 'Diese Woche',
        timeSection: 'thisWeek',
        orders: filteredOrders,
      })
    }
  }

  if (nextWeek.length) {
    const filteredOrder = filterOrders(orders, nextWeek)
    if (filteredOrder.length) {
      groupedOrders.push({
        title: 'Nächste Woche',
        timeSection: 'nextWeek',
        orders: filterOrders(orders, nextWeek),
      })
    }
  }

  if (later.length) {
    const filteredOrders = filterOrders(orders, later)
    if (filteredOrders.length) {
      groupedOrders.push({
        title: 'Später',
        timeSection: 'later',
        orders: filteredOrders,
      })
    }
  }

  return groupedOrders
}

function getPastOrders(
  orders: Order[],
  appointments: Appointment[],
  appointmentsFilter: AppointmentsFilter,
  installerId: string | null
) {
  const groupedOrders: GroupedOrders[] = []
  const { yesterday, lastWeek, lastMonth, older } = groupPastAppointments(
    filterPastAppointments(appointments, appointmentsFilter, installerId)
  )

  if (yesterday.length) {
    const filteredOrders = filterOrders(orders, yesterday)
    if (filteredOrders.length) {
      groupedOrders.push({
        title: 'Gestern',
        timeSection: 'yesterday',
        orders: filteredOrders,
      })
    }
  }

  if (lastWeek.length) {
    const filteredOrders = filterOrders(orders, lastWeek)
    if (filteredOrders.length) {
      groupedOrders.push({
        title: 'Diese Woche',
        timeSection: 'earlierThisWeek',
        orders: filteredOrders,
      })
    }
  }

  if (lastMonth.length) {
    const filteredOrders = filterOrders(orders, lastMonth)
    if (filteredOrders.length) {
      groupedOrders.push({
        title: 'Dieser Monat',
        timeSection: 'earlierThisMonth',
        orders: filteredOrders,
      })
    }
  }

  if (older.length) {
    const filteredOrders = filterOrders(orders, older)
    if (filteredOrders.length) {
      groupedOrders.push({
        title: 'Älter',
        timeSection: 'older',
        orders: filteredOrders,
      })
    }
  }

  return groupedOrders
}

interface GroupedUpcoomingAppointments {
  today: Appointment[]
  thisWeek: Appointment[]
  nextWeek: Appointment[]
  later: Appointment[]
}

export function isAppointmentValidByFilter(
  appointment: Appointment,
  appointmentsFilter: AppointmentsFilter,
  installerId: string | null
) {
  const { assignedToMe, activityType } = appointmentsFilter

  const isValidByActivityType = activityType?.includes(appointment.activity_type as ActivityType)

  if (!assignedToMe) {
    return isValidByActivityType
  }

  if (!installerId) {
    return false
  }

  const isAssignedToMe = appointment.assignedInstallers?.some(
    (installer) => installer.uid === installerId
  )

  return isValidByActivityType && isAssignedToMe
}

function filterFutureAppointments(
  appointments: Appointment[],
  appointmentsFilter: AppointmentsFilter,
  installerId: string | null
): Appointment[] {
  return appointments.filter((a) => {
    const isFutureAppointment = startOfToday() < endOfDay(new Date(a.end_date))

    return isFutureAppointment && isAppointmentValidByFilter(a, appointmentsFilter, installerId)
  })
}

function groupFutureAppointments(
  appointments: Appointment[],
  appointmentsFilter: AppointmentsFilter,
  installerId: string | null
): GroupedUpcoomingAppointments {
  const today = filterToday(filterFutureAppointments(appointments, appointmentsFilter, installerId))
  const thisWeek = filterThisWeek(today.rest)
  const nextWeek = filterNextWeek(thisWeek.rest)

  return {
    today: orderAsc(today.result),
    thisWeek: orderAsc(thisWeek.result),
    nextWeek: orderAsc(nextWeek.result),
    later: orderAsc(nextWeek.rest),
  }
}

interface AppointmentFilterResult {
  result: Appointment[]
  rest: Appointment[]
}

function filterToday(appointments: Appointment[]): AppointmentFilterResult {
  const startOfDay = startOfToday().getTime()
  const endOfDay = startOfDay + 86400000

  const result = appointments.filter((a) => {
    const startDate = new Date(a.start_date).getTime()
    const endDate = new Date(a.end_date).getTime()

    if (startOfDay >= startDate && endDate >= startOfDay && startDate < endOfDay) return true
    return startOfDay <= startDate && startDate < endOfDay
  })

  return { result, rest: rest(appointments, result) }
}

function filterThisWeek(appointments: Appointment[]): AppointmentFilterResult {
  const weekEnd = endOfWeek(new Date(), { weekStartsOn: 1 }).getTime()
  const result = appointments.filter((a) => new Date(a.end_date!).getTime() < weekEnd)

  return { result, rest: rest(appointments, result) }
}

function filterNextWeek(appointments: Appointment[]): AppointmentFilterResult {
  const nextWeekEnd = endOfWeek(new Date()).getTime() + 604800000
  const result = appointments.filter((a) => new Date(a.end_date!).getTime() < nextWeekEnd)

  return { result, rest: rest(appointments, result) }
}

interface GroupedPastAppointments {
  yesterday: Appointment[]
  lastWeek: Appointment[]
  lastMonth: Appointment[]
  older: Appointment[]
}

function filterPastAppointments(
  appointments: Appointment[],
  appointmentsFilter: AppointmentsFilter,
  installerId: string | null
): Appointment[] {
  return appointments.filter((a) => {
    const isPastAppointment = startOfToday() > endOfDay(new Date(a.start_date))
    return isPastAppointment && isAppointmentValidByFilter(a, appointmentsFilter, installerId)
  })
}

function groupPastAppointments(appointments: Appointment[]): GroupedPastAppointments {
  const yesterday = filterYesterday(appointments)

  const lastWeek = filterLastWeek(yesterday.rest)
  const lastMonth = filterLastMonth(lastWeek.rest)

  return {
    yesterday: orderDesc(yesterday.result),
    lastWeek: orderDesc(lastWeek.result),
    lastMonth: orderDesc(lastMonth.result),
    older: orderDesc(lastMonth.rest),
  }
}

function filterYesterday(appointments: Appointment[]): AppointmentFilterResult {
  const endOfYesterday = startOfToday().getTime() - 1
  const startOfYesterday = endOfYesterday - 86399999

  const result = appointments.filter((a) => {
    const startDate = new Date(a.start_date).getTime()
    const endDate = new Date(a.end_date).getTime()

    return (
      startOfYesterday >= startDate && endDate >= startOfYesterday && startDate < endOfYesterday
    )
  })

  return { result, rest: rest(appointments, result) }
}

function filterLastWeek(appointments: Appointment[]): AppointmentFilterResult {
  const lastWeekStart = startOfWeek(new Date(), { weekStartsOn: 1 }).getTime()
  const result = appointments.filter((a) => new Date(a.end_date).getTime() > lastWeekStart)

  return { result, rest: rest(appointments, result) }
}

function filterLastMonth(appointments: Appointment[]): AppointmentFilterResult {
  const lastMonthStart = startOfMonth(new Date()).getTime()
  const result = appointments.filter((a) => new Date(a.end_date).getTime() > lastMonthStart)

  return { result, rest: rest(appointments, result) }
}

// -------------------------
// utility functions
// -------------------------
type OrderDirection = 'asc' | 'desc'
export function orderAppointments(
  appointments: Appointment[],
  direction: OrderDirection
): Appointment[] {
  return direction === 'asc' ? orderAsc(appointments) : orderDesc(appointments)
}

function filterOrders(orders: Order[], appointments: Appointment[]): Order[] {
  const filtered: Order[] = []
  appointments.forEach((a) => {
    const order = orders.find((order) => order.id === a.order_uid)
    if (order) {
      if (filtered.includes(order)) return
      filtered.push(order)
    }
  })
  return filtered
}

function orderAsc(appointments: Appointment[]) {
  return appointments.sort(
    (a, b) => new Date(a.start_date).getTime() - new Date(b.start_date).getTime()
  )
}

function orderDesc(appointments: Appointment[]) {
  return appointments.sort(
    (a, b) => new Date(b.start_date).getTime() - new Date(a.start_date).getTime()
  )
}

const rest = (app: any, result: any) =>
  app.filter((a: any) => !result.map((a: any) => a.order_uid).includes(a.order_uid))
