import { Report, type ReportItemEntity, ReportItemEntityStatus, ReportStatus } from '~/models'
import { findItemInReport } from './findItemInReport'
import { saveReport } from './saveReport'
import { type Draft } from 'immer'

function resetItem(report: Report, itemId: string): void {
  const updatedReport = Report.copyOf(report, (reportDraft) => {
    const itemDraft: Draft<ReportItemEntity> = findItemInReport(reportDraft, itemId)
    if (!itemDraft) {
      return
    }
    reportDraft.status = ReportStatus.DRAFT
    itemDraft.status = ReportItemEntityStatus.DRAFT
    itemDraft.image_uids = []
  })
  void saveReport(updatedReport, { forced: true })
}

// This is for dev purposes so that we can reset an item over the console
declare global {
  interface Window {
    resetAllItems: () => void
    resetItemForId: (key: string) => void
    resetCurrentItem: () => void
  }
}

export function addDevToolsDocumentation(report: Report, item: ReportItemEntity): void {
  window['resetItemForId'] = (key) => resetItem(report, key)
  window['resetCurrentItem'] = () => resetItem(report, item.key)
  window['resetAllItems'] = () => {
    report.steps.forEach((step) => {
      step.items.forEach((item) => {
        resetItem(report, item.key)
      })
    })
  }
}
