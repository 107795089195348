import { type SpriteName } from '@zolargmbh/zolar-icons'
import Icon from './Icon/Icon'

interface IconLinkProps {
  icon: SpriteName
  to: string
  label: string
}

export function IconLink(props: IconLinkProps) {
  return (
    <>
      <a className='flex items-baseline gap-2' href={props.to}>
        <Icon icon={props.icon} size='xs' />
        <span>{props.label}</span>
      </a>
    </>
  )
}
