import { type ChangeEvent } from 'react'
import { create } from 'zustand'
import { persist } from 'zustand/middleware'
import { Checkbox } from '~/components'

type ExperimentalFeaturesForm = {
  FF_COMMISSIONING_REPORT_IN_APP_ACTIVE: boolean
}

type UseExperimentalFeatures = ExperimentalFeaturesForm & {
  setFeatureFlags: (data: ExperimentalFeaturesForm) => void
}

export const useExperimentalFeatures = create(
  persist<UseExperimentalFeatures>(
    (set) => ({
      FF_COMMISSIONING_REPORT_IN_APP_ACTIVE: true,
      setFeatureFlags: (data) => {
        set(data)
      },
    }),
    { name: 'installer-app-experimental-features' }
  )
)

const ExperimentalFeaturesPage = () => {
  const { setFeatureFlags, FF_COMMISSIONING_REPORT_IN_APP_ACTIVE } = useExperimentalFeatures()

  const onChange = (name: keyof ExperimentalFeaturesForm) => (e: ChangeEvent<HTMLInputElement>) => {
    const isChecked = e.currentTarget.checked
    setFeatureFlags({ [name]: isChecked })
  }

  return (
    <div className='flex flex-col gap-6 p-4'>
      <h1 className='text-headline-1'>Experimentelle Funktionen</h1>
      <p className='text-body-2-regular'>
        Diese Seite ermöglicht es Ihnen, experimentelle Funktionen zu aktivieren oder deaktivieren.
        Experimentieren Sie mit neuen Features und teilen Sie uns Ihr Feedback mit, um die
        Weiterentwicklung unserer Plattform zu unterstützen.
      </p>
      <div className='flex flex-col gap-2'>
        <Checkbox
          checked={FF_COMMISSIONING_REPORT_IN_APP_ACTIVE}
          disabled
          onChange={onChange('FF_COMMISSIONING_REPORT_IN_APP_ACTIVE')}
          name='FF_COMMISSIONING_REPORT_IN_APP_ACTIVE'
          label='In-App-Inbetriebnahme-Bericht'
          description='Werfen Sie einen ersten Blick auf den In-App-Inbetriebnahme-Bericht, probieren Sie es aus und geben Sie wertvolles Feedback.'
        />
      </div>
    </div>
  )
}

export default ExperimentalFeaturesPage
