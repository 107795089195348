import { type ComponentProps } from 'react'
import cx from 'classnames'
import { type Prettify } from '~/utils/types'

type BaseProps = {
  /**
   * @default 'filled-dark'
   */
  variant?:
    | 'filled-accent'
    | 'filled-dark'
    | 'filled-light'
    | 'filled-destructive'
    | 'outlined-dark'
    | 'outlined-light'
    | 'text-dark'
    | 'text-light'
    | 'text-destructive'
  /**
   * @default 'md'
   */
  size?: 'sm' | 'md' | 'lg'
  /**
   * @default false
   */
  isIconButton?: boolean
}

type Props = Prettify<BaseProps & ComponentProps<'button'>>

const Button = (props: Props) => {
  const {
    children,
    className = '',
    variant = 'filled-dark',
    size = 'md',
    isIconButton = false,
    ...rest
  } = props

  return (
    <button
      type='button'
      className={cx(
        {
          'button-sm': size === 'sm',
          'button-md': size === 'md',
          'button-lg': size === 'lg',
          'button-icon': isIconButton,
          'button-accent button-primary': variant === 'filled-accent',
          'button-primary': variant === 'filled-dark',
          'button-primary button-light': variant === 'filled-light',
          'button-primary button-destructive': variant === 'filled-destructive',
          'button-secondary': variant === 'outlined-dark',
          'button-light button-secondary': variant === 'outlined-light',
          'button-tertiary': variant === 'text-dark',
          'button-light button-tertiary': variant === 'text-light',
          'button-destructive button-tertiary': variant === 'text-destructive',
        },
        className
      )}
      {...rest}
    >
      {children}
    </button>
  )
}

export default Button
