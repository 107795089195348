import classNames from 'classnames'
import { type MouseEvent } from 'react'
import { type SpriteName } from '@zolargmbh/zolar-icons'
import Icon from './Icon/Icon'

export type TagColor =
  | 'bg-black'
  | 'tag-mist'
  | 'tag-sky'
  | 'tag-leaf'
  | 'tag-lavender'
  | 'tag-moss'
  | 'tag-forest'
  | 'tag-success'
  | 'tag-warning'
  | 'tag-error'
  | 'tag-neutral'

export interface TagProps {
  label?: string
  className?: string
  tagColor?: TagColor
  textColor?: 'white'
  iconPosition?: 'left' | 'right' | 'both'
  icon?: SpriteName
  outlined?: boolean
  small?: boolean
  onClick?: (e: MouseEvent<HTMLElement>) => void
}

export function Tag(props: TagProps) {
  const {
    tagColor = 'default',
    textColor = '',
    className = '',
    icon,
    iconPosition = 'left',
    outlined = false,
    label = 'UNKNOWN STATUS',
    small,
    onClick,
  } = props

  const clsName = classNames('tag', tagColor !== 'default' ? tagColor : '', className, {
    'tag-outlined': outlined,
    'tag-sm': small,
  })

  return (
    <div className={clsName} onClick={onClick}>
      {icon && ['left', 'both'].includes(iconPosition) && <Icon icon={icon} />}
      <span style={{ color: textColor }}>{label}</span>
      {icon && ['right', 'both'].includes(iconPosition) && <Icon icon={icon} />}
    </div>
  )
}
