/* tslint:disable */
/* eslint-disable */
// this is an auto generated file. This will be overwritten

import * as APITypes from "../API";
type GeneratedQuery<InputType, OutputType> = string & {
  __generatedQueryInput: InputType;
  __generatedQueryOutput: OutputType;
};

export const getActivity = /* GraphQL */ `query GetActivity($id: ID!) {
  getActivity(id: $id) {
    id
    state
    updated_at
    activity_type
    request_uid
    request_state
    partner_uid
    order_uid
    report_uid
    lqv_report_uid
    use_lqv
    link_vivi_osv
    appointments {
      items {
        id
        start_date
        end_date
        state
        updated_at
        activity_uid
        activity_type
        partner_uid
        order_uid
        assignedInstallers {
          uid
          name
          __typename
        }
        createdAt
        updatedAt
        _version
        _deleted
        _lastChangedAt
        __typename
      }
      nextToken
      startedAt
      __typename
    }
    comments {
      items {
        id
        updated_at
        created_at
        content
        activity_uid
        activity_type
        partner_uid
        order_uid
        createdAt
        updatedAt
        _version
        _deleted
        _lastChangedAt
        __typename
      }
      nextToken
      startedAt
      __typename
    }
    createdAt
    updatedAt
    _version
    _deleted
    _lastChangedAt
    __typename
  }
}
` as GeneratedQuery<
  APITypes.GetActivityQueryVariables,
  APITypes.GetActivityQuery
>;
export const listActivities = /* GraphQL */ `query ListActivities(
  $filter: ModelActivityFilterInput
  $limit: Int
  $nextToken: String
) {
  listActivities(filter: $filter, limit: $limit, nextToken: $nextToken) {
    items {
      id
      state
      updated_at
      activity_type
      request_uid
      request_state
      partner_uid
      order_uid
      report_uid
      lqv_report_uid
      use_lqv
      link_vivi_osv
      appointments {
        items {
          id
          start_date
          end_date
          state
          updated_at
          activity_uid
          activity_type
          partner_uid
          order_uid
          assignedInstallers {
            uid
            name
            __typename
          }
          createdAt
          updatedAt
          _version
          _deleted
          _lastChangedAt
          __typename
        }
        nextToken
        startedAt
        __typename
      }
      comments {
        items {
          id
          updated_at
          created_at
          content
          activity_uid
          activity_type
          partner_uid
          order_uid
          createdAt
          updatedAt
          _version
          _deleted
          _lastChangedAt
          __typename
        }
        nextToken
        startedAt
        __typename
      }
      createdAt
      updatedAt
      _version
      _deleted
      _lastChangedAt
      __typename
    }
    nextToken
    startedAt
    __typename
  }
}
` as GeneratedQuery<
  APITypes.ListActivitiesQueryVariables,
  APITypes.ListActivitiesQuery
>;
export const syncActivities = /* GraphQL */ `query SyncActivities(
  $filter: ModelActivityFilterInput
  $limit: Int
  $nextToken: String
  $lastSync: AWSTimestamp
) {
  syncActivities(
    filter: $filter
    limit: $limit
    nextToken: $nextToken
    lastSync: $lastSync
  ) {
    items {
      id
      state
      updated_at
      activity_type
      request_uid
      request_state
      partner_uid
      order_uid
      report_uid
      lqv_report_uid
      use_lqv
      link_vivi_osv
      appointments {
        items {
          id
          start_date
          end_date
          state
          updated_at
          activity_uid
          activity_type
          partner_uid
          order_uid
          assignedInstallers {
            uid
            name
            __typename
          }
          createdAt
          updatedAt
          _version
          _deleted
          _lastChangedAt
          __typename
        }
        nextToken
        startedAt
        __typename
      }
      comments {
        items {
          id
          updated_at
          created_at
          content
          activity_uid
          activity_type
          partner_uid
          order_uid
          createdAt
          updatedAt
          _version
          _deleted
          _lastChangedAt
          __typename
        }
        nextToken
        startedAt
        __typename
      }
      createdAt
      updatedAt
      _version
      _deleted
      _lastChangedAt
      __typename
    }
    nextToken
    startedAt
    __typename
  }
}
` as GeneratedQuery<
  APITypes.SyncActivitiesQueryVariables,
  APITypes.SyncActivitiesQuery
>;
export const getComment = /* GraphQL */ `query GetComment($id: ID!) {
  getComment(id: $id) {
    id
    updated_at
    created_at
    content
    activity_uid
    activity_type
    partner_uid
    order_uid
    createdAt
    updatedAt
    _version
    _deleted
    _lastChangedAt
    __typename
  }
}
` as GeneratedQuery<
  APITypes.GetCommentQueryVariables,
  APITypes.GetCommentQuery
>;
export const listComments = /* GraphQL */ `query ListComments(
  $filter: ModelCommentFilterInput
  $limit: Int
  $nextToken: String
) {
  listComments(filter: $filter, limit: $limit, nextToken: $nextToken) {
    items {
      id
      updated_at
      created_at
      content
      activity_uid
      activity_type
      partner_uid
      order_uid
      createdAt
      updatedAt
      _version
      _deleted
      _lastChangedAt
      __typename
    }
    nextToken
    startedAt
    __typename
  }
}
` as GeneratedQuery<
  APITypes.ListCommentsQueryVariables,
  APITypes.ListCommentsQuery
>;
export const syncComments = /* GraphQL */ `query SyncComments(
  $filter: ModelCommentFilterInput
  $limit: Int
  $nextToken: String
  $lastSync: AWSTimestamp
) {
  syncComments(
    filter: $filter
    limit: $limit
    nextToken: $nextToken
    lastSync: $lastSync
  ) {
    items {
      id
      updated_at
      created_at
      content
      activity_uid
      activity_type
      partner_uid
      order_uid
      createdAt
      updatedAt
      _version
      _deleted
      _lastChangedAt
      __typename
    }
    nextToken
    startedAt
    __typename
  }
}
` as GeneratedQuery<
  APITypes.SyncCommentsQueryVariables,
  APITypes.SyncCommentsQuery
>;
export const getOrderDocument = /* GraphQL */ `query GetOrderDocument($id: ID!) {
  getOrderDocument(id: $id) {
    id
    partner_uids
    order_id
    url
    name
    originalName
    mimeType
    category
    tags
    createdAt
    updatedAt
    _version
    _deleted
    _lastChangedAt
    __typename
  }
}
` as GeneratedQuery<
  APITypes.GetOrderDocumentQueryVariables,
  APITypes.GetOrderDocumentQuery
>;
export const listOrderDocuments = /* GraphQL */ `query ListOrderDocuments(
  $filter: ModelOrderDocumentFilterInput
  $limit: Int
  $nextToken: String
) {
  listOrderDocuments(filter: $filter, limit: $limit, nextToken: $nextToken) {
    items {
      id
      partner_uids
      order_id
      url
      name
      originalName
      mimeType
      category
      tags
      createdAt
      updatedAt
      _version
      _deleted
      _lastChangedAt
      __typename
    }
    nextToken
    startedAt
    __typename
  }
}
` as GeneratedQuery<
  APITypes.ListOrderDocumentsQueryVariables,
  APITypes.ListOrderDocumentsQuery
>;
export const syncOrderDocuments = /* GraphQL */ `query SyncOrderDocuments(
  $filter: ModelOrderDocumentFilterInput
  $limit: Int
  $nextToken: String
  $lastSync: AWSTimestamp
) {
  syncOrderDocuments(
    filter: $filter
    limit: $limit
    nextToken: $nextToken
    lastSync: $lastSync
  ) {
    items {
      id
      partner_uids
      order_id
      url
      name
      originalName
      mimeType
      category
      tags
      createdAt
      updatedAt
      _version
      _deleted
      _lastChangedAt
      __typename
    }
    nextToken
    startedAt
    __typename
  }
}
` as GeneratedQuery<
  APITypes.SyncOrderDocumentsQueryVariables,
  APITypes.SyncOrderDocumentsQuery
>;
export const getOrder = /* GraphQL */ `query GetOrder($id: ID!) {
  getOrder(id: $id) {
    id
    partner_uids
    system_power
    storage_capacity
    grid_operator
    grid_type
    number_of_modules
    required_power_limit
    order_name
    order_number
    order_type
    order_notes
    order_hash
    extern_hash
    customer_uid
    customer_hash
    customer_name
    customer_email
    customer_mobile
    customer_phone
    customer_street
    customer_post_code
    customer_city
    customer_country
    customer_lon_lat
    products {
      product_id
      quantity
      __typename
    }
    createdAt
    updatedAt
    _version
    _deleted
    _lastChangedAt
    __typename
  }
}
` as GeneratedQuery<APITypes.GetOrderQueryVariables, APITypes.GetOrderQuery>;
export const listOrders = /* GraphQL */ `query ListOrders(
  $filter: ModelOrderFilterInput
  $limit: Int
  $nextToken: String
) {
  listOrders(filter: $filter, limit: $limit, nextToken: $nextToken) {
    items {
      id
      partner_uids
      system_power
      storage_capacity
      grid_operator
      grid_type
      number_of_modules
      required_power_limit
      order_name
      order_number
      order_type
      order_notes
      order_hash
      extern_hash
      customer_uid
      customer_hash
      customer_name
      customer_email
      customer_mobile
      customer_phone
      customer_street
      customer_post_code
      customer_city
      customer_country
      customer_lon_lat
      products {
        product_id
        quantity
        __typename
      }
      createdAt
      updatedAt
      _version
      _deleted
      _lastChangedAt
      __typename
    }
    nextToken
    startedAt
    __typename
  }
}
` as GeneratedQuery<
  APITypes.ListOrdersQueryVariables,
  APITypes.ListOrdersQuery
>;
export const syncOrders = /* GraphQL */ `query SyncOrders(
  $filter: ModelOrderFilterInput
  $limit: Int
  $nextToken: String
  $lastSync: AWSTimestamp
) {
  syncOrders(
    filter: $filter
    limit: $limit
    nextToken: $nextToken
    lastSync: $lastSync
  ) {
    items {
      id
      partner_uids
      system_power
      storage_capacity
      grid_operator
      grid_type
      number_of_modules
      required_power_limit
      order_name
      order_number
      order_type
      order_notes
      order_hash
      extern_hash
      customer_uid
      customer_hash
      customer_name
      customer_email
      customer_mobile
      customer_phone
      customer_street
      customer_post_code
      customer_city
      customer_country
      customer_lon_lat
      products {
        product_id
        quantity
        __typename
      }
      createdAt
      updatedAt
      _version
      _deleted
      _lastChangedAt
      __typename
    }
    nextToken
    startedAt
    __typename
  }
}
` as GeneratedQuery<
  APITypes.SyncOrdersQueryVariables,
  APITypes.SyncOrdersQuery
>;
export const getProduct = /* GraphQL */ `query GetProduct($id: ID!) {
  getProduct(id: $id) {
    id
    updated_at
    name
    family
    url_installation_manual
    url_installation_manual2
    url_fuer_datenblaetter
    url_installation_video
    url_installation_presentation
    url_guarantee_documents
    partner_uids
    createdAt
    updatedAt
    _version
    _deleted
    _lastChangedAt
    __typename
  }
}
` as GeneratedQuery<
  APITypes.GetProductQueryVariables,
  APITypes.GetProductQuery
>;
export const listProducts = /* GraphQL */ `query ListProducts(
  $filter: ModelProductFilterInput
  $limit: Int
  $nextToken: String
) {
  listProducts(filter: $filter, limit: $limit, nextToken: $nextToken) {
    items {
      id
      updated_at
      name
      family
      url_installation_manual
      url_installation_manual2
      url_fuer_datenblaetter
      url_installation_video
      url_installation_presentation
      url_guarantee_documents
      partner_uids
      createdAt
      updatedAt
      _version
      _deleted
      _lastChangedAt
      __typename
    }
    nextToken
    startedAt
    __typename
  }
}
` as GeneratedQuery<
  APITypes.ListProductsQueryVariables,
  APITypes.ListProductsQuery
>;
export const syncProducts = /* GraphQL */ `query SyncProducts(
  $filter: ModelProductFilterInput
  $limit: Int
  $nextToken: String
  $lastSync: AWSTimestamp
) {
  syncProducts(
    filter: $filter
    limit: $limit
    nextToken: $nextToken
    lastSync: $lastSync
  ) {
    items {
      id
      updated_at
      name
      family
      url_installation_manual
      url_installation_manual2
      url_fuer_datenblaetter
      url_installation_video
      url_installation_presentation
      url_guarantee_documents
      partner_uids
      createdAt
      updatedAt
      _version
      _deleted
      _lastChangedAt
      __typename
    }
    nextToken
    startedAt
    __typename
  }
}
` as GeneratedQuery<
  APITypes.SyncProductsQueryVariables,
  APITypes.SyncProductsQuery
>;
export const getAppointment = /* GraphQL */ `query GetAppointment($id: ID!) {
  getAppointment(id: $id) {
    id
    start_date
    end_date
    state
    updated_at
    activity_uid
    activity_type
    partner_uid
    order_uid
    assignedInstallers {
      uid
      name
      __typename
    }
    createdAt
    updatedAt
    _version
    _deleted
    _lastChangedAt
    __typename
  }
}
` as GeneratedQuery<
  APITypes.GetAppointmentQueryVariables,
  APITypes.GetAppointmentQuery
>;
export const listAppointments = /* GraphQL */ `query ListAppointments(
  $filter: ModelAppointmentFilterInput
  $limit: Int
  $nextToken: String
) {
  listAppointments(filter: $filter, limit: $limit, nextToken: $nextToken) {
    items {
      id
      start_date
      end_date
      state
      updated_at
      activity_uid
      activity_type
      partner_uid
      order_uid
      assignedInstallers {
        uid
        name
        __typename
      }
      createdAt
      updatedAt
      _version
      _deleted
      _lastChangedAt
      __typename
    }
    nextToken
    startedAt
    __typename
  }
}
` as GeneratedQuery<
  APITypes.ListAppointmentsQueryVariables,
  APITypes.ListAppointmentsQuery
>;
export const syncAppointments = /* GraphQL */ `query SyncAppointments(
  $filter: ModelAppointmentFilterInput
  $limit: Int
  $nextToken: String
  $lastSync: AWSTimestamp
) {
  syncAppointments(
    filter: $filter
    limit: $limit
    nextToken: $nextToken
    lastSync: $lastSync
  ) {
    items {
      id
      start_date
      end_date
      state
      updated_at
      activity_uid
      activity_type
      partner_uid
      order_uid
      assignedInstallers {
        uid
        name
        __typename
      }
      createdAt
      updatedAt
      _version
      _deleted
      _lastChangedAt
      __typename
    }
    nextToken
    startedAt
    __typename
  }
}
` as GeneratedQuery<
  APITypes.SyncAppointmentsQueryVariables,
  APITypes.SyncAppointmentsQuery
>;
export const getReport = /* GraphQL */ `query GetReport($id: ID!) {
  getReport(id: $id) {
    id
    activity_uid
    order_uid
    customer_hash
    order_hash
    report_uid
    report_type
    status
    partner_uid
    steps {
      key
      items {
        key
        title
        content
        type
        required
        position
        image_uids
        questions {
          name
          format
          render_format
          required
          position
          multi_answer
          possible_answers
          answer
          __typename
        }
        status
        __typename
      }
      __typename
    }
    createdAt
    updatedAt
    _version
    _deleted
    _lastChangedAt
    __typename
  }
}
` as GeneratedQuery<APITypes.GetReportQueryVariables, APITypes.GetReportQuery>;
export const listReports = /* GraphQL */ `query ListReports(
  $filter: ModelReportFilterInput
  $limit: Int
  $nextToken: String
) {
  listReports(filter: $filter, limit: $limit, nextToken: $nextToken) {
    items {
      id
      activity_uid
      order_uid
      customer_hash
      order_hash
      report_uid
      report_type
      status
      partner_uid
      steps {
        key
        items {
          key
          title
          content
          type
          required
          position
          image_uids
          questions {
            name
            format
            render_format
            required
            position
            multi_answer
            possible_answers
            answer
            __typename
          }
          status
          __typename
        }
        __typename
      }
      createdAt
      updatedAt
      _version
      _deleted
      _lastChangedAt
      __typename
    }
    nextToken
    startedAt
    __typename
  }
}
` as GeneratedQuery<
  APITypes.ListReportsQueryVariables,
  APITypes.ListReportsQuery
>;
export const syncReports = /* GraphQL */ `query SyncReports(
  $filter: ModelReportFilterInput
  $limit: Int
  $nextToken: String
  $lastSync: AWSTimestamp
) {
  syncReports(
    filter: $filter
    limit: $limit
    nextToken: $nextToken
    lastSync: $lastSync
  ) {
    items {
      id
      activity_uid
      order_uid
      customer_hash
      order_hash
      report_uid
      report_type
      status
      partner_uid
      steps {
        key
        items {
          key
          title
          content
          type
          required
          position
          image_uids
          questions {
            name
            format
            render_format
            required
            position
            multi_answer
            possible_answers
            answer
            __typename
          }
          status
          __typename
        }
        __typename
      }
      createdAt
      updatedAt
      _version
      _deleted
      _lastChangedAt
      __typename
    }
    nextToken
    startedAt
    __typename
  }
}
` as GeneratedQuery<
  APITypes.SyncReportsQueryVariables,
  APITypes.SyncReportsQuery
>;
export const getLqvReport = /* GraphQL */ `query GetLqvReport($id: ID!) {
  getLqvReport(id: $id) {
    id
    type
    state
    activity_uid
    accepted_by
    accepted_at
    partner_uid
    sections {
      id
      title
      steps {
        id
        title
        state
        submitted_by
        submitted_at
        validated_by
        validated_at
        items {
          id
          title
          content
          type
          position
          optional
          possible_answers
          submitted_values
          __typename
        }
        __typename
      }
      __typename
    }
    createdAt
    updatedAt
    _version
    _deleted
    _lastChangedAt
    __typename
  }
}
` as GeneratedQuery<
  APITypes.GetLqvReportQueryVariables,
  APITypes.GetLqvReportQuery
>;
export const listLqvReports = /* GraphQL */ `query ListLqvReports(
  $filter: ModelLqvReportFilterInput
  $limit: Int
  $nextToken: String
) {
  listLqvReports(filter: $filter, limit: $limit, nextToken: $nextToken) {
    items {
      id
      type
      state
      activity_uid
      accepted_by
      accepted_at
      partner_uid
      sections {
        id
        title
        steps {
          id
          title
          state
          submitted_by
          submitted_at
          validated_by
          validated_at
          items {
            id
            title
            content
            type
            position
            optional
            possible_answers
            submitted_values
            __typename
          }
          __typename
        }
        __typename
      }
      createdAt
      updatedAt
      _version
      _deleted
      _lastChangedAt
      __typename
    }
    nextToken
    startedAt
    __typename
  }
}
` as GeneratedQuery<
  APITypes.ListLqvReportsQueryVariables,
  APITypes.ListLqvReportsQuery
>;
export const syncLqvReports = /* GraphQL */ `query SyncLqvReports(
  $filter: ModelLqvReportFilterInput
  $limit: Int
  $nextToken: String
  $lastSync: AWSTimestamp
) {
  syncLqvReports(
    filter: $filter
    limit: $limit
    nextToken: $nextToken
    lastSync: $lastSync
  ) {
    items {
      id
      type
      state
      activity_uid
      accepted_by
      accepted_at
      partner_uid
      sections {
        id
        title
        steps {
          id
          title
          state
          submitted_by
          submitted_at
          validated_by
          validated_at
          items {
            id
            title
            content
            type
            position
            optional
            possible_answers
            submitted_values
            __typename
          }
          __typename
        }
        __typename
      }
      createdAt
      updatedAt
      _version
      _deleted
      _lastChangedAt
      __typename
    }
    nextToken
    startedAt
    __typename
  }
}
` as GeneratedQuery<
  APITypes.SyncLqvReportsQueryVariables,
  APITypes.SyncLqvReportsQuery
>;
export const getComReportDefaultData = /* GraphQL */ `query GetComReportDefaultData($id: ID!) {
  getComReportDefaultData(id: $id) {
    id
    updated_at
    partner_uid
    order_uid
    specialistPlanner
    pv_system {
      moduleManufacturer
      moduleType
      amountModules
      moduleInstalledCapacityInKwp
      __typename
    }
    inverters {
      manufacturer
      type
      serialNumber
      numberOfMppts
      numberOfStringsPerMppt
      __typename
    }
    batteryStorage {
      manufacturer
      type
      serialNumber
      capacityInKwh
      __typename
    }
    wallbox {
      manufacturer
      type
      serialNumber
      __typename
    }
    compassHardware {
      managerSerialNumber
      boxSerialNumber
      __typename
    }
    createdAt
    updatedAt
    _version
    _deleted
    _lastChangedAt
    __typename
  }
}
` as GeneratedQuery<
  APITypes.GetComReportDefaultDataQueryVariables,
  APITypes.GetComReportDefaultDataQuery
>;
export const listComReportDefaultData = /* GraphQL */ `query ListComReportDefaultData(
  $filter: ModelComReportDefaultDataFilterInput
  $limit: Int
  $nextToken: String
) {
  listComReportDefaultData(
    filter: $filter
    limit: $limit
    nextToken: $nextToken
  ) {
    items {
      id
      updated_at
      partner_uid
      order_uid
      specialistPlanner
      pv_system {
        moduleManufacturer
        moduleType
        amountModules
        moduleInstalledCapacityInKwp
        __typename
      }
      inverters {
        manufacturer
        type
        serialNumber
        numberOfMppts
        numberOfStringsPerMppt
        __typename
      }
      batteryStorage {
        manufacturer
        type
        serialNumber
        capacityInKwh
        __typename
      }
      wallbox {
        manufacturer
        type
        serialNumber
        __typename
      }
      compassHardware {
        managerSerialNumber
        boxSerialNumber
        __typename
      }
      createdAt
      updatedAt
      _version
      _deleted
      _lastChangedAt
      __typename
    }
    nextToken
    startedAt
    __typename
  }
}
` as GeneratedQuery<
  APITypes.ListComReportDefaultDataQueryVariables,
  APITypes.ListComReportDefaultDataQuery
>;
export const syncComReportDefaultData = /* GraphQL */ `query SyncComReportDefaultData(
  $filter: ModelComReportDefaultDataFilterInput
  $limit: Int
  $nextToken: String
  $lastSync: AWSTimestamp
) {
  syncComReportDefaultData(
    filter: $filter
    limit: $limit
    nextToken: $nextToken
    lastSync: $lastSync
  ) {
    items {
      id
      updated_at
      partner_uid
      order_uid
      specialistPlanner
      pv_system {
        moduleManufacturer
        moduleType
        amountModules
        moduleInstalledCapacityInKwp
        __typename
      }
      inverters {
        manufacturer
        type
        serialNumber
        numberOfMppts
        numberOfStringsPerMppt
        __typename
      }
      batteryStorage {
        manufacturer
        type
        serialNumber
        capacityInKwh
        __typename
      }
      wallbox {
        manufacturer
        type
        serialNumber
        __typename
      }
      compassHardware {
        managerSerialNumber
        boxSerialNumber
        __typename
      }
      createdAt
      updatedAt
      _version
      _deleted
      _lastChangedAt
      __typename
    }
    nextToken
    startedAt
    __typename
  }
}
` as GeneratedQuery<
  APITypes.SyncComReportDefaultDataQueryVariables,
  APITypes.SyncComReportDefaultDataQuery
>;
export const getComReport = /* GraphQL */ `query GetComReport($id: ID!) {
  getComReport(id: $id) {
    id
    partner_uid
    order_uid
    activity_uid
    customer_hash
    order_hash
    state
    submittedAt
    formData {
      generalData {
        operator
        specialistPlanner
        installer
        plantLocationStreet
        plantLocationPostCode
        plantLocationCity
        __typename
      }
      pvSystem {
        hasPvSystem
        moduleManufacturer
        moduleType
        amountOfModules
        installedCapacityInKwp
        hasLightningProtectionSystemIntegration
        hasPlantOnLowVoltageGridConditions
        __typename
      }
      gridManagement {
        powerLimitPercentage
        __typename
      }
      inverters {
        manufacturer
        type
        serialNumber
        __typename
      }
      pvGenerators {
        mppts {
          amountOfModules
          amountOfStrings
          stringElectricalValues {
            openCircuitVoltageInVolts
            insulationResistanceInMegaOhms
            shortCircuitCurrentInAmperes
            __typename
          }
          __typename
        }
        __typename
      }
      electricalMeasurement {
        measuringEquipment
        testingVoltageInVolts
        weatherConditions
        temperatureInCelsius
        __typename
      }
      compass {
        managerSerialNumber
        boxSerialNumber
        __typename
      }
      batteryStorage {
        hasBatteryStorage
        manufacturer
        typeAndCapacityInKWh
        serialNumber
        __typename
      }
      wallbox {
        hasWallbox
        manufacturer
        typeAndPerformanceInKW
        serialNumber
        __typename
      }
      briefingOfOperation {
        isDamageFreeInstallation
        hasProperCableRoutingInspection
        hasComponentInstallationInspection
        hasCustomerSwitchgearPVSticker
        isCleanSiteNoWaste
        hasComponentExplanation
        hasShutdownExplanationACDC
        hasSystemMonitoringIntroduction
        hasOperationIndicators
        hasMaintenanceAndCareGuidancePVA
        remarks
        __typename
      }
      signature {
        installer {
          name
          date
          dataUrl
          __typename
        }
        client {
          name
          date
          dataUrl
          __typename
        }
        __typename
      }
      __typename
    }
    pdfId
    createdAt
    updatedAt
    _version
    _deleted
    _lastChangedAt
    __typename
  }
}
` as GeneratedQuery<
  APITypes.GetComReportQueryVariables,
  APITypes.GetComReportQuery
>;
export const listComReports = /* GraphQL */ `query ListComReports(
  $filter: ModelComReportFilterInput
  $limit: Int
  $nextToken: String
) {
  listComReports(filter: $filter, limit: $limit, nextToken: $nextToken) {
    items {
      id
      partner_uid
      order_uid
      activity_uid
      customer_hash
      order_hash
      state
      submittedAt
      formData {
        generalData {
          operator
          specialistPlanner
          installer
          plantLocationStreet
          plantLocationPostCode
          plantLocationCity
          __typename
        }
        pvSystem {
          hasPvSystem
          moduleManufacturer
          moduleType
          amountOfModules
          installedCapacityInKwp
          hasLightningProtectionSystemIntegration
          hasPlantOnLowVoltageGridConditions
          __typename
        }
        gridManagement {
          powerLimitPercentage
          __typename
        }
        inverters {
          manufacturer
          type
          serialNumber
          __typename
        }
        pvGenerators {
          mppts {
            amountOfModules
            amountOfStrings
            __typename
          }
          __typename
        }
        electricalMeasurement {
          measuringEquipment
          testingVoltageInVolts
          weatherConditions
          temperatureInCelsius
          __typename
        }
        compass {
          managerSerialNumber
          boxSerialNumber
          __typename
        }
        batteryStorage {
          hasBatteryStorage
          manufacturer
          typeAndCapacityInKWh
          serialNumber
          __typename
        }
        wallbox {
          hasWallbox
          manufacturer
          typeAndPerformanceInKW
          serialNumber
          __typename
        }
        briefingOfOperation {
          isDamageFreeInstallation
          hasProperCableRoutingInspection
          hasComponentInstallationInspection
          hasCustomerSwitchgearPVSticker
          isCleanSiteNoWaste
          hasComponentExplanation
          hasShutdownExplanationACDC
          hasSystemMonitoringIntroduction
          hasOperationIndicators
          hasMaintenanceAndCareGuidancePVA
          remarks
          __typename
        }
        signature {
          installer {
            name
            date
            dataUrl
            __typename
          }
          client {
            name
            date
            dataUrl
            __typename
          }
          __typename
        }
        __typename
      }
      pdfId
      createdAt
      updatedAt
      _version
      _deleted
      _lastChangedAt
      __typename
    }
    nextToken
    startedAt
    __typename
  }
}
` as GeneratedQuery<
  APITypes.ListComReportsQueryVariables,
  APITypes.ListComReportsQuery
>;
export const syncComReports = /* GraphQL */ `query SyncComReports(
  $filter: ModelComReportFilterInput
  $limit: Int
  $nextToken: String
  $lastSync: AWSTimestamp
) {
  syncComReports(
    filter: $filter
    limit: $limit
    nextToken: $nextToken
    lastSync: $lastSync
  ) {
    items {
      id
      partner_uid
      order_uid
      activity_uid
      customer_hash
      order_hash
      state
      submittedAt
      formData {
        generalData {
          operator
          specialistPlanner
          installer
          plantLocationStreet
          plantLocationPostCode
          plantLocationCity
          __typename
        }
        pvSystem {
          hasPvSystem
          moduleManufacturer
          moduleType
          amountOfModules
          installedCapacityInKwp
          hasLightningProtectionSystemIntegration
          hasPlantOnLowVoltageGridConditions
          __typename
        }
        gridManagement {
          powerLimitPercentage
          __typename
        }
        inverters {
          manufacturer
          type
          serialNumber
          __typename
        }
        pvGenerators {
          mppts {
            amountOfModules
            amountOfStrings
            __typename
          }
          __typename
        }
        electricalMeasurement {
          measuringEquipment
          testingVoltageInVolts
          weatherConditions
          temperatureInCelsius
          __typename
        }
        compass {
          managerSerialNumber
          boxSerialNumber
          __typename
        }
        batteryStorage {
          hasBatteryStorage
          manufacturer
          typeAndCapacityInKWh
          serialNumber
          __typename
        }
        wallbox {
          hasWallbox
          manufacturer
          typeAndPerformanceInKW
          serialNumber
          __typename
        }
        briefingOfOperation {
          isDamageFreeInstallation
          hasProperCableRoutingInspection
          hasComponentInstallationInspection
          hasCustomerSwitchgearPVSticker
          isCleanSiteNoWaste
          hasComponentExplanation
          hasShutdownExplanationACDC
          hasSystemMonitoringIntroduction
          hasOperationIndicators
          hasMaintenanceAndCareGuidancePVA
          remarks
          __typename
        }
        signature {
          installer {
            name
            date
            dataUrl
            __typename
          }
          client {
            name
            date
            dataUrl
            __typename
          }
          __typename
        }
        __typename
      }
      pdfId
      createdAt
      updatedAt
      _version
      _deleted
      _lastChangedAt
      __typename
    }
    nextToken
    startedAt
    __typename
  }
}
` as GeneratedQuery<
  APITypes.SyncComReportsQueryVariables,
  APITypes.SyncComReportsQuery
>;
export const getZpcStandardReportShape = /* GraphQL */ `query GetZpcStandardReportShape($id: ID!) {
  getZpcStandardReportShape(id: $id) {
    id
    updated_at
    partner_uid
    order_uid
    activity_uid
    type
    structure {
      items {
        key
        uid
        position
        __typename
      }
      questions {
        key
        uid
        position
        possibleAnswers
        required
        inputAs
        __typename
      }
      __typename
    }
    createdAt
    updatedAt
    _version
    _deleted
    _lastChangedAt
    __typename
  }
}
` as GeneratedQuery<
  APITypes.GetZpcStandardReportShapeQueryVariables,
  APITypes.GetZpcStandardReportShapeQuery
>;
export const listZpcStandardReportShapes = /* GraphQL */ `query ListZpcStandardReportShapes(
  $filter: ModelZpcStandardReportShapeFilterInput
  $limit: Int
  $nextToken: String
) {
  listZpcStandardReportShapes(
    filter: $filter
    limit: $limit
    nextToken: $nextToken
  ) {
    items {
      id
      updated_at
      partner_uid
      order_uid
      activity_uid
      type
      structure {
        items {
          key
          uid
          position
          __typename
        }
        questions {
          key
          uid
          position
          possibleAnswers
          required
          inputAs
          __typename
        }
        __typename
      }
      createdAt
      updatedAt
      _version
      _deleted
      _lastChangedAt
      __typename
    }
    nextToken
    startedAt
    __typename
  }
}
` as GeneratedQuery<
  APITypes.ListZpcStandardReportShapesQueryVariables,
  APITypes.ListZpcStandardReportShapesQuery
>;
export const syncZpcStandardReportShapes = /* GraphQL */ `query SyncZpcStandardReportShapes(
  $filter: ModelZpcStandardReportShapeFilterInput
  $limit: Int
  $nextToken: String
  $lastSync: AWSTimestamp
) {
  syncZpcStandardReportShapes(
    filter: $filter
    limit: $limit
    nextToken: $nextToken
    lastSync: $lastSync
  ) {
    items {
      id
      updated_at
      partner_uid
      order_uid
      activity_uid
      type
      structure {
        items {
          key
          uid
          position
          __typename
        }
        questions {
          key
          uid
          position
          possibleAnswers
          required
          inputAs
          __typename
        }
        __typename
      }
      createdAt
      updatedAt
      _version
      _deleted
      _lastChangedAt
      __typename
    }
    nextToken
    startedAt
    __typename
  }
}
` as GeneratedQuery<
  APITypes.SyncZpcStandardReportShapesQueryVariables,
  APITypes.SyncZpcStandardReportShapesQuery
>;
export const commentsByActivity_uidAndUpdated_at = /* GraphQL */ `query CommentsByActivity_uidAndUpdated_at(
  $activity_uid: ID!
  $updated_at: ModelStringKeyConditionInput
  $sortDirection: ModelSortDirection
  $filter: ModelCommentFilterInput
  $limit: Int
  $nextToken: String
) {
  commentsByActivity_uidAndUpdated_at(
    activity_uid: $activity_uid
    updated_at: $updated_at
    sortDirection: $sortDirection
    filter: $filter
    limit: $limit
    nextToken: $nextToken
  ) {
    items {
      id
      updated_at
      created_at
      content
      activity_uid
      activity_type
      partner_uid
      order_uid
      createdAt
      updatedAt
      _version
      _deleted
      _lastChangedAt
      __typename
    }
    nextToken
    startedAt
    __typename
  }
}
` as GeneratedQuery<
  APITypes.CommentsByActivity_uidAndUpdated_atQueryVariables,
  APITypes.CommentsByActivity_uidAndUpdated_atQuery
>;
export const appointmentsByActivity_uidAndStart_date = /* GraphQL */ `query AppointmentsByActivity_uidAndStart_date(
  $activity_uid: ID!
  $start_date: ModelStringKeyConditionInput
  $sortDirection: ModelSortDirection
  $filter: ModelAppointmentFilterInput
  $limit: Int
  $nextToken: String
) {
  appointmentsByActivity_uidAndStart_date(
    activity_uid: $activity_uid
    start_date: $start_date
    sortDirection: $sortDirection
    filter: $filter
    limit: $limit
    nextToken: $nextToken
  ) {
    items {
      id
      start_date
      end_date
      state
      updated_at
      activity_uid
      activity_type
      partner_uid
      order_uid
      assignedInstallers {
        uid
        name
        __typename
      }
      createdAt
      updatedAt
      _version
      _deleted
      _lastChangedAt
      __typename
    }
    nextToken
    startedAt
    __typename
  }
}
` as GeneratedQuery<
  APITypes.AppointmentsByActivity_uidAndStart_dateQueryVariables,
  APITypes.AppointmentsByActivity_uidAndStart_dateQuery
>;
