import { createCache } from '~/services/cache'
import { type StoreTask } from '~/stores/useTaskStore'
import { downloadRunner, type DownloadTask } from './downloadRunner'
import { uploadReportImageRunner, type UploadReportImageTask } from './uploadReportImageRunner'
import {
  uploadCommissioningPdfRunner,
  type UploadCommissioningPdfTask,
} from './uploadCommissioningPdfRunner'

export type GetAccessToken = () => Promise<string>
export type Task = UploadReportImageTask | DownloadTask | UploadCommissioningPdfTask

export const executeRunner =
  (getAccessToken: GetAccessToken) =>
  async (task: StoreTask): Promise<void> => {
    const cache = createCache(task.orderId)
    switch (task.runner) {
      case 'download': {
        await downloadRunner(task, getAccessToken, cache)
        return
      }
      case 'upload': {
        await uploadReportImageRunner(task, getAccessToken, cache)
        return
      }
      case 'upload-com-pdf': {
        await uploadCommissioningPdfRunner(task, getAccessToken, cache)
        return
      }
      default: {
        assertNever(task)
      }
    }
  }

function assertNever(arg: never) {
  throw Error(arg)
}
