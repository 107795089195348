import { Report, type ReportItemEntity, ReportItemEntityStatus } from '~/models'
import { findItemInReport } from './findItemInReport'
import { saveReport } from './saveReport'
import { type Draft } from 'immer'
import { getLatestReport } from './getLatestReport'

export async function addImageIdsToReport(
  report: Report,
  itemId: string,
  images: string[]
): Promise<Report> {
  if (images.length === 0) {
    return report
  }
  const latestReport = await getLatestReport(report)
  const updatedReport = Report.copyOf(latestReport, (reportDraft) => {
    const itemDraft: Draft<ReportItemEntity> = findItemInReport(reportDraft, itemId)
    itemDraft.image_uids = itemDraft.image_uids.concat(images)
  })

  return saveReport(updatedReport)
}
