import * as yup from 'yup'

const generalDataSchema = yup.object().shape({
  operator: yup.string().notOneOf(['']).required(),
  specialistPlanner: yup.string().notOneOf(['']).required(),
  installer: yup.string().notOneOf(['']).required(),
  plantLocationStreet: yup.string().notOneOf(['']).required(),
  plantLocationPostCode: yup.string().notOneOf(['']).required(),
  plantLocationCity: yup.string().notOneOf(['']).required(),
})

const pvSystemSchema = yup.object().shape({
  hasPvSystem: yup.string().oneOf(['yes', 'no']).required(),
  moduleManufacturer: yup.string().when('hasPvSystem', {
    is: 'yes',
    then: (schema) => schema.required(),
    otherwise: (schema) => schema.nullable().optional(),
  }),
  moduleType: yup.string().when('hasPvSystem', {
    is: 'yes',
    then: (schema) => schema.required(),
    otherwise: (schema) => schema.nullable().optional(),
  }),
  amountOfModules: yup
    .number()
    .integer()
    .positive()
    .when('hasPvSystem', {
      is: 'yes',
      then: (schema) => schema.required(),
      otherwise: (schema) => schema.nullable().optional(),
    }),
  installedCapacityInKwp: yup
    .number()
    .positive()
    .when('hasPvSystem', {
      is: 'yes',
      then: (schema) => schema.required(),
      otherwise: (schema) => schema.nullable().optional(),
    }),
  hasLightningProtectionSystemIntegration: yup
    .string()
    .oneOf(['yes', 'no'])
    .when('hasPvSystem', {
      is: 'yes',
      then: (schema) => schema.required(),
      otherwise: (schema) => schema.nullable().optional(),
    }),
  hasPlantOnLowVoltageGridConditions: yup
    .string()
    .oneOf(['yes', 'no'])
    .when('hasPvSystem', {
      is: 'yes',
      then: (schema) => schema.required(),
      otherwise: (schema) => schema.nullable().optional(),
    }),
})

const gridManagementSchema = yup.object().shape({
  powerLimitPercentage: yup.number().positive().max(100).required(),
})

const invertersSchema = yup
  .array()
  .of(
    yup
      .object()
      .shape({
        manufacturer: yup.string().required(),
        type: yup.string().required(),
        serialNumber: yup.string().required(),
      })
      .required()
  )
  .optional()
  .nullable()

const pvGeneratorsSchema = yup
  .array()
  .of(
    yup
      .object()
      .shape({
        mppts: yup
          .array()
          .of(
            yup
              .object()
              .shape({
                amountOfModules: yup.number().integer().positive().required(),
                amountOfStrings: yup.number().integer().positive().required(),
                stringElectricalValues: yup
                  .array()
                  .of(
                    yup.object().shape({
                      openCircuitVoltageInVolts: yup.number().positive().required(),
                      insulationResistanceInMegaOhms: yup.number().positive().required(),
                      shortCircuitCurrentInAmperes: yup.number().positive().required(),
                    })
                  )
                  .min(1)
                  .max(2),
              })
              .required()
          )
          .min(1)
          .max(2)
          .required(),
      })
      .required()
  )
  .min(1)
  .max(2)
  .optional()
  .nullable()

const electricalMeasurementSchema = yup
  .object()
  .shape({
    measuringEquipment: yup.string().required(),
    testingVoltageInVolts: yup.number().positive().required(),
    weatherConditions: yup.string().required(),
    temperatureInCelsius: yup.number().required(),
  })
  .optional()
  .nullable()

const compassSchema = yup
  .object()
  .shape({
    managerSerialNumber: yup.string().required(),
  })
  .optional()
  .nullable()

const batteryStorageSchema = yup.object({
  hasBatteryStorage: yup.string().oneOf(['yes', 'no']).required(),
  manufacturer: yup.string().when('hasBatteryStorage', {
    is: 'yes',
    then: (schema) => schema.required(),
    otherwise: (schema) => schema.nullable().optional(),
  }),
  typeAndCapacityInKWh: yup.string().when('hasBatteryStorage', {
    is: 'yes',
    then: (schema) => schema.required(),
    otherwise: (schema) => schema.nullable().optional(),
  }),
  serialNumber: yup.string().when('hasBatteryStorage', {
    is: 'yes',
    then: (schema) => schema.required(),
    otherwise: (schema) => schema.nullable().optional(),
  }),
})

const wallboxSchema = yup.object({
  hasWallbox: yup.string().oneOf(['yes', 'no']).required(),
  manufacturer: yup.string().when('hasWallbox', {
    is: 'yes',
    then: (schema) => schema.required(),
    otherwise: (schema) => schema.nullable().optional(),
  }),
  typeAndPerformanceInKW: yup.string().when('hasWallbox', {
    is: 'yes',
    then: (schema) => schema.required(),
    otherwise: (schema) => schema.nullable().optional(),
  }),
  serialNumber: yup.string().when('hasWallbox', {
    is: 'yes',
    then: (schema) => schema.required(),
    otherwise: (schema) => schema.nullable().optional(),
  }),
})

const briefingOfOperationSchema = yup.object({
  isDamageFreeInstallation: yup.boolean().required(),
  hasProperCableRoutingInspection: yup.boolean().required(),
  hasComponentInstallationInspection: yup.boolean().required(),
  hasCustomerSwitchgearPVSticker: yup.boolean().required(),
  isCleanSiteNoWaste: yup.boolean().required(),
  hasComponentExplanation: yup.boolean().required(),
  hasShutdownExplanationACDC: yup.boolean().required(),
  hasSystemMonitoringIntroduction: yup.boolean().required(),
  hasOperationIndicators: yup.boolean().required(),
  hasMaintenanceAndCareGuidancePVA: yup.boolean().required(),
  remarks: yup.string().nullable(),
})

const signatureSchema = yup.object({
  installer: yup.object({
    name: yup.string().required(),
    date: yup
      .string()
      .required()
      .test((v) => /^\d{4}-\d{2}-\d{2}$/.test(v || '')),
    dataUrl: yup.string().required(),
  }),
  client: yup.object({
    name: yup.string().required(),
    date: yup
      .string()
      .test((v) => /^\d{4}-\d{2}-\d{2}$/.test(v || ''))
      .nullable(),
    dataUrl: yup.string().nullable(),
  }),
})

export const commissioningFormSchema = yup.object({
  generalData: generalDataSchema,
  pvSystem: pvSystemSchema,
  gridManagement: gridManagementSchema.when('pvSystem.hasPvSystem', {
    is: 'yes',
    then: (schema) => schema.required(),
    otherwise: () => yup.mixed().nullable(),
  }),
  inverters: invertersSchema.when('pvSystem.hasPvSystem', {
    is: 'yes',
    then: (schema) => schema.required(),
    otherwise: () => yup.mixed().nullable(),
  }),
  pvGenerators: pvGeneratorsSchema.when('pvSystem.hasPvSystem', {
    is: 'yes',
    then: (schema) => schema.required(),
    otherwise: () => yup.mixed().nullable(),
  }),
  electricalMeasurement: electricalMeasurementSchema.when('pvSystem.hasPvSystem', {
    is: 'yes',
    then: (schema) => schema.required(),
    otherwise: () => yup.mixed().nullable(),
  }),
  compass: compassSchema.when('pvSystem.hasPvSystem', {
    is: 'yes',
    then: (schema) => schema.required(),
    otherwise: () => yup.mixed().nullable(),
  }),
  batteryStorage: batteryStorageSchema,
  wallbox: wallboxSchema,
  briefingOfOperation: briefingOfOperationSchema,
  signature: signatureSchema,
})

export type CommissioningForm = {
  generalData: {
    operator?: string | null
    specialistPlanner?: string | null
    installer?: string | null
    plantLocationStreet?: string | null
    plantLocationPostCode?: string | null
    plantLocationCity?: string | null
  }
  pvSystem: {
    hasPvSystem?: 'yes' | 'no'
    moduleManufacturer?: string | null
    moduleType?: string | null
    amountOfModules?: number | null
    installedCapacityInKwp?: number | null
    hasLightningProtectionSystemIntegration: 'yes' | 'no'
    hasPlantOnLowVoltageGridConditions: 'yes' | 'no'
  }
  gridManagement?: {
    powerLimitPercentage?: number | null
  }
  inverters?: {
    manufacturer?: string | null
    type?: string | null
    serialNumber?: string | null
  }[]
  pvGenerators?: {
    mppts: {
      amountOfModules?: number | null
      amountOfStrings?: number | null
      stringElectricalValues: {
        openCircuitVoltageInVolts?: number | null
        insulationResistanceInMegaOhms?: number | null
        shortCircuitCurrentInAmperes?: number | null
      }[]
    }[]
  }[]
  electricalMeasurement?: {
    measuringEquipment?: string | null
    testingVoltageInVolts?: number | null
    weatherConditions?: string | null
    temperatureInCelsius?: number | null
  }
  compass?: {
    managerSerialNumber?: string | null
  }
  batteryStorage: {
    hasBatteryStorage: 'yes' | 'no'
    manufacturer?: string | null
    typeAndCapacityInKWh?: string | null
    serialNumber?: string | null
  }
  wallbox: {
    hasWallbox: 'yes' | 'no'
    manufacturer?: string | null
    typeAndPerformanceInKW?: string | null
    serialNumber?: string | null
  }
  briefingOfOperation: {
    isDamageFreeInstallation: boolean
    hasProperCableRoutingInspection: boolean
    hasComponentInstallationInspection: boolean
    hasCustomerSwitchgearPVSticker: boolean
    isCleanSiteNoWaste: boolean
    hasComponentExplanation: boolean
    hasShutdownExplanationACDC: boolean
    hasSystemMonitoringIntroduction: boolean
    hasOperationIndicators: boolean
    hasMaintenanceAndCareGuidancePVA: boolean
    remarks?: string | null
  }
  signature: {
    installer: {
      name?: string | null
      date?: string | null
      dataUrl?: string | null
    }
    client: {
      name?: string | null
      date?: string | null
      dataUrl?: string | null
    }
  }
}
