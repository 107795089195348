import { type ReactNode } from 'react'
import { type SpriteName } from '@zolargmbh/zolar-icons'
import { Divider, Icon } from '~/components'
import { type GetBlobByUrl } from '~/hooks'
import { openCacheDocumentByUrlInTab } from '~/utils/openCacheDocumentByUrlInTab'

export interface ConfigurationInterface {
  amountOfModules: string
  systemPower: string
  storageCapacity: string
  requiredPowerLimit: string
  inverterName: string
  storageName: string
  gridType: string
  gridOperator: string
  stringPlanUrl?: string
}

interface ConfigurationProps {
  configuration: ConfigurationInterface
  getBlobByUrl: GetBlobByUrl
  showLoadingOverlay: (loading: boolean | string) => void
}
export function Configuration(props: ConfigurationProps) {
  const { configuration, getBlobByUrl, showLoadingOverlay } = props

  const properties = initProperties(
    configuration,
    configuration.stringPlanUrl
      ? () =>
          openCacheDocumentByUrlInTab(
            configuration.stringPlanUrl,
            getBlobByUrl,
            true,
            'application/pdf',
            showLoadingOverlay
          )
      : undefined
  )

  return (
    <div className='flex w-full flex-col'>
      <Divider className='mb-6 mt-6' />
      <div className='text-headline-2'>Konfiguration</div>
      {properties.map((property) => (
        <div className='mt-4 flex align-baseline' key={property.name}>
          {property.icon && <Icon icon={property.icon} size='sm' className='mr-3 mt-1' />}
          <div className='flex flex-col'>
            <span>{property.label}</span>
            <span className='font-bold'>{property.value}</span>
          </div>
        </div>
      ))}
    </div>
  )
}

interface Property {
  name: string
  label: string
  icon?: SpriteName
  value: string | ReactNode
}

function initProperties(
  configuration: ConfigurationInterface,
  stringPlanFunction: (() => void) | undefined
): Property[] {
  const properties: Property[] = [
    {
      name: 'number_of_modules',
      label: 'Module',
      icon: 'electricity:panel-default',
      value: configuration.amountOfModules,
    },
    {
      name: 'system_power',
      label: 'Anlagengröße',
      icon: 'electricity:flash',
      value: configuration.systemPower + ' kWp',
    },
    {
      name: 'storage_capacity',
      label: 'Speicherkapazität',
      icon: 'electricity:battery-charging',
      value: configuration.storageCapacity + ' kWh',
    },
    {
      name: 'required_power_limit',
      label: 'Geforderte Wirkleistungsbegrenzung',
      icon: 'electricity:meter-inspection',
      value: configuration.requiredPowerLimit,
    },
    {
      name: 'inverter_name',
      label: 'Wechselrichter',
      icon: 'electricity:inverter',
      value: configuration.inverterName,
    },
    {
      name: 'storage_name',
      label: 'Speicher',
      icon: 'electricity:two-batteries-mid',
      value: configuration.storageName,
    },
    {
      name: 'grid_type',
      label: 'Netzform',
      icon: 'general:signal',
      value: configuration.gridType,
    },
    {
      name: 'grid_operator',
      label: 'Netzbetreiber',
      icon: 'zpc:projectphase:grid-application',
      value: configuration.gridOperator,
    },
  ]

  if (stringPlanFunction !== undefined) {
    properties.push({
      name: 'stringplan',
      label: 'Stringplan',
      icon: 'zpc:navigation:circuit-diagram',
      value: (
        <>
          Vorhanden{' '}
          <span className='cursor-pointer text-link' onClick={stringPlanFunction}>
            Anzeigen
          </span>
        </>
      ),
    })
  }

  return properties
}
