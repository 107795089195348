import { type Order } from '~/models'
import { useAppStore } from '~/stores/useAppStore'
import { useCallback } from 'react'

export interface UseOrders {
  getById: (id: string) => Order | undefined
  exists: (id: string) => boolean
}

export function useOrders(): UseOrders {
  const orders = useAppStore.use.orders()

  const getById = useCallback<UseOrders['getById']>(
    (id) => {
      return orders.find((order) => order.id === id)
    },
    [orders]
  )

  const exists = useCallback<UseOrders['exists']>(
    (id) => {
      return getById(id) !== undefined
    },
    [getById]
  )

  return { getById, exists }
}
