import { Button, Expandable } from '~/components'
import { useState } from 'react'
import { type Appointment } from '~/models'
import { type Timeframe } from '~/stores'
import { orderAppointments } from '~/hooks/useTimeline'
import { AppointmentWidget, formatOrderAppointments } from '~/components/Order/Appointments'
import { type SpriteName } from '@zolargmbh/zolar-icons'

interface AppointmentListProps {
  appointments: Appointment[]
  timeframe: Timeframe
}
export function AppointmentList(props: AppointmentListProps) {
  const formattedAppointments = formatOrderAppointments(
    orderAppointments(props.appointments, props.timeframe === 'future' ? 'asc' : 'desc')
  )
  if (formattedAppointments.length === 0) {
    return null
  }

  const nextFormattedAppointment = formattedAppointments[0]
  const moreFormattedAppointments = formattedAppointments.slice(1)

  return (
    <div className='mt-2 flex w-full flex-col'>
      <div className='flex flex-col gap-2'>
        <div className='text-static-lg-semibold'>
          {props.timeframe === 'past' ? 'Letzter' : 'Nächster'} Termin
        </div>
        <AppointmentWidget {...nextFormattedAppointment} />
      </div>
      <MoreAppointments
        formattedAppointments={moreFormattedAppointments}
        timeframe={props.timeframe}
      />
    </div>
  )
}

interface MoreAppointmentsProp {
  formattedAppointments: {
    key: string
    appointment: Appointment
    label: string
    icon: SpriteName
  }[]
  timeframe: Timeframe
}
function MoreAppointments({ formattedAppointments, timeframe }: MoreAppointmentsProp) {
  const [expanded, setExpanded] = useState(false)

  return formattedAppointments.length ? (
    <>
      <Expandable expanded={expanded}>
        <div className='mt-5 flex flex-col gap-2'>
          <div className='text-static-lg-semibold'>
            {timeframe === 'past' ? 'Ältere' : 'Weitere'} Termine
          </div>
          {formattedAppointments.map(({ key, ...rest }) => (
            <AppointmentWidget key={key} {...rest} />
          ))}
        </div>
      </Expandable>
      <Button
        className='mt-3 w-full'
        variant='outlined-dark'
        onClick={(e) => {
          e.stopPropagation()
          setExpanded(!expanded)
        }}
      >
        {expanded ? 'Weniger Anzeigen' : 'Mehr Anzeigen'}
      </Button>
    </>
  ) : null
}
