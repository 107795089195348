import { Outlet, ScrollRestoration } from 'react-router-dom'
import Banner from '~/components/Banner/Banner'
import { useUploadProgress } from '~/hooks/task/useTaskManagerProgress'
import { useAppStore, useTaskStore } from '~/stores'

export function Root() {
  const { network } = useAppStore.use.dataStoreStatus()
  const pendingTasks = useTaskStore.use.pending()
  useUploadProgress()

  return (
    <>
      {network === 'offline' && (
        <Banner
          variant='update'
          icon='general:no-signal'
          headline={`Kein Internet ${
            pendingTasks.length ? `(${pendingTasks.length} Dateien pausiert)` : ''
          }`.trim()}
          content='Du kannst mit der Dokumentation fortfahren. Sobald du wieder eine Verbindung zum Internet hast, werden die Dateien hochgeladen. Solange werden sie zwischengespeichert. Bitte lasse die App dafür geöffnet.'
        />
      )}
      <div className='text-dark-primary'>
        <Outlet />
      </div>
      <ScrollRestoration />
    </>
  )
}
