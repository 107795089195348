import { RouterProvider } from 'react-router-dom'
import {
  useAmplifyDataStore,
  useAuthentication,
  useDataStoreListen,
  useTaskManagerInitialize,
} from '~/hooks'
import { router } from '~/config/router'
import { useAppStore, useTaskStore } from '~/stores'
import { LoadingSplashScreen, ToastContainer } from '~/components'
import { SentryErrorBoundary } from '~/config/sentry'
import { TaskManagerDevTool } from '~/components/TaskManager'
import 'react-toastify/dist/ReactToastify.css'
import { useEffect } from 'react'
import { useExperimentalFeatures } from './pages/ExperimentalFeaturesPage'
import { datadogRum } from '@datadog/browser-rum'
import { useTaskManagerStart } from './utils/taskManager/useTaskManager'

const onAppClose = () => {
  const numberOfPendingTasks = useTaskStore.getState().pending.length
  datadogRum.addAction('app_closed', {
    numberOfPendingTasks,
  })
}

const onVisibilityChange = () => {
  const isAppCurrentlyActive = document.visibilityState === 'visible'
  if (isAppCurrentlyActive) return
  const numberOfPendingTasks = useTaskStore.getState().pending.length
  datadogRum.addAction('app_minimised', {
    numberOfPendingTasks,
  })
}

let lastTimeOfflineSinceAppOpenedInMilliseconds = navigator.onLine ? undefined : Date.now()

const onOnline = () => {
  const wentOfflineAtIso = new Date(lastTimeOfflineSinceAppOpenedInMilliseconds ?? 0).toISOString()
  const nowInMilliseconds = Date.now()
  const backOnlineAtIso = new Date(nowInMilliseconds).toISOString()
  const durationOfflineStateSinceAppOpenedInMilliseconds =
    lastTimeOfflineSinceAppOpenedInMilliseconds === undefined
      ? 0
      : nowInMilliseconds - lastTimeOfflineSinceAppOpenedInMilliseconds
  lastTimeOfflineSinceAppOpenedInMilliseconds = undefined
  if (durationOfflineStateSinceAppOpenedInMilliseconds === 0) return
  const numberOfPendingTasks = useTaskStore.getState().pending.length
  datadogRum.addAction('app_back_online', {
    numberOfPendingTasks,
    wentOfflineAtIso,
    backOnlineAtIso,
    durationOfflineStateSinceAppOpenedInMilliseconds,
  })
}

const onOffline = () => {
  lastTimeOfflineSinceAppOpenedInMilliseconds = Date.now()
}

export function App() {
  const { setFeatureFlags, FF_COMMISSIONING_REPORT_IN_APP_ACTIVE } = useExperimentalFeatures()

  useEffect(() => {
    document.addEventListener('visibilitychange', onVisibilityChange)
    window.addEventListener('beforeunload', onAppClose)
    window.addEventListener('online', onOnline)
    window.addEventListener('offline', onOffline)
    return () => {
      document.removeEventListener('visibilitychange', onVisibilityChange)
      window.removeEventListener('beforeunload', onAppClose)
      window.removeEventListener('online', onOnline)
      window.removeEventListener('offline', onOffline)
    }
  }, [])

  useEffect(() => {
    if (!FF_COMMISSIONING_REPORT_IN_APP_ACTIVE)
      setFeatureFlags({ FF_COMMISSIONING_REPORT_IN_APP_ACTIVE: true })
  }, [setFeatureFlags, FF_COMMISSIONING_REPORT_IN_APP_ACTIVE])

  useDataStoreListen()
  useTaskManagerInitialize() // Old task manager - localstorage
  useTaskManagerStart() // New task manager - idb

  const dataStoreStatus = useAppStore.use.dataStoreStatus()
  const { isAuthenticated, isAuthenticating } = useAuthentication()
  useAmplifyDataStore(isAuthenticated)

  const isSyncing = isAuthenticated && !dataStoreStatus.ready
  const showLoadingIndicator = isAuthenticating || isSyncing

  return (
    <>
      <SentryErrorBoundary>
        <LoadingSplashScreen show={showLoadingIndicator && !isAuthenticating} />
        {!showLoadingIndicator && (
          <>
            <TaskManagerDevTool />
            <RouterProvider router={router(isAuthenticated)} />
          </>
        )}
      </SentryErrorBoundary>
      <ToastContainer />
    </>
  )
}
