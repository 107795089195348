import { useAppStore } from '~/stores/useAppStore'
import classNames from 'classnames'
import { ProgressBar } from '~/components/ProgressBar'
import Logo from '~/svg/logo.svg?react'
import Icon from './Icon/Icon'
import InlineMessage from './InlineMessage/InlineMessage'

type Props = {
  show?: boolean
}

export const LoadingSplashScreen = ({ show = true }: Props) => {
  const { models } = useAppStore.use.dataStoreStatus()
  const { total, progress } = getProgressStatus(models)
  const steps = ['Deine Projekte', 'Deine Termine', 'Fotos und Dateien']

  return (
    <div
      className={classNames(
        'absolute bottom-0 left-0 right-0 top-0 z-10 flex items-center justify-center bg-white',
        'transition-opacity duration-[1s]',
        show ? 'opacity-100' : 'pointer-events-none opacity-0'
      )}
    >
      <div className='absolute top-0 left-0 w-full'>
        <InlineMessage variant='neutral' icon='general:info-circle' headline='Lange Ladezeit'>
          <p>
            Bitte App offen lassen. Beim ersten Login dauert es aktuell ca 1-2 Minuten alle
            Projektdaten initial zu laden. Danach geht's schneller.
          </p>
        </InlineMessage>
      </div>
      <div className='flex flex-col items-center justify-center'>
        <h2 className='text-headline-1 mb-12'>Willkommen bei zolar!</h2>
        <div className='mb-12 w-50 grow items-center'>
          <div className='mb-1 flex justify-center'>
            <Logo className='mb-4 h-20 w-20 rounded' />
          </div>
          <ProgressBar progress={progress} max={total ? total : 1}>
            {total
              ? 'Daten werden geladen: ' + Math.floor((100 / total) * progress) + '%'
              : 'Initialisiere ...'}
          </ProgressBar>
        </div>
        <div className='mb-4'>Wir laden für dich folgende Daten:</div>
        <div className='flex flex-col items-start gap-4'>
          {steps.map((step, index) => (
            <SyncingLabel
              key={index}
              ready={progress >= (total / steps.length) * (index + 1)}
              label={step}
            />
          ))}
        </div>
      </div>
    </div>
  )
}

interface SyncingLabelProps {
  ready: boolean
  label: string
}

const SyncingLabel = (props: SyncingLabelProps) => {
  const { ready, label } = props
  return (
    <div className='flex items-center'>
      {ready ? (
        <Icon icon='general:check-circle' size='lg' className='mr-2 text-green-500' />
      ) : (
        <div className='mr-2 animate-spin'>
          <Icon icon='zpc:navigation:refresh' size='lg' className='-scale-x-[1]' />
        </div>
      )}
      <div className={ready ? 'text-green' : 'text-dark'}>{label}</div>
    </div>
  )
}

function getProgressStatus(models: { [key: string]: boolean }) {
  const progressionWeights: { [key: string]: number } = {
    Activity: 3,
    Appointments: 2,
  }

  let total = Object.keys(models).length
  let progress = Object.values(models).filter((ready) => ready).length

  Object.keys(progressionWeights).forEach((model) => {
    total += progressionWeights[model]
    if (models[model]) progress += progressionWeights[model]
  })

  return { total, progress }
}
