/**
 * Gets a value from Local Storage
 *
 * @param key {string} Local Storage key
 * @param defaultValue {T} default value to return if the value is not available
 */
export function getLocalStorageValue<T>(key: string, defaultValue: T | null = null) {
  try {
    const value = localStorage.getItem(key)
    return value === null ? defaultValue : JSON.parse(value)
  } catch (e) {
    console.log('Error getting Local Storage value %s: %s', key, e)
    return defaultValue
  }
}

/**
 * Sets a value to Local Storage
 *
 * @param key {string} Local Storage key
 * @param value {T} Local Storage value
 */
export function setLocalStorageValue<T>(key: string, value: T) {
  try {
    const persistedValue = JSON.stringify(value)
    localStorage.setItem(key, persistedValue)
  } catch (e) {
    console.log('Error setting Local Storage value %s: %s', key, e)
  }
}

/**
 * Deletes a value from Local Storage
 *
 * @param key {string} Local Storage key
 */
export function deleteLocalStorageValue(key: string) {
  try {
    localStorage.removeItem(key)
  } catch (e) {
    console.log('Error deleting Local Storage value %s: %s', key, e)
  }
}
